import { ICellBehaviourParams }                                               from '../interfaces/ICellBehaviourParams';
import { RowButton }                                                          from '../classes/row-button';
import { GridDataCell }                                                       from './grid-data-cell.model';
import { DataGridType }                                                       from '../classes/data-grid-type';
import { CompareRowState, DataGridCellType, GridItemType, RowState, UiTypes } from '../enums/data-grid.enum';
import { createToObjectWithLowerCaseKeys }                                    from '@cs/components/util';
import { DataGridHelpers }                                                    from '../utils/data-grid-helpers';
import { IProperty }                                                          from '@cs/core';
import { GridRule }                                                           from './grid-rule.model';
import { GridOptions }                                                        from '../classes/grid-options';
import { IMatchLevelKeys }                                                    from '../interfaces/IMatchLevelKeys';

export class GridDataRow implements DataGridType {
	spinnerOnSelected = false;
	isGroupTotal      = false;

	get selected(): boolean {
		return this._selected;
	}

	set selected(value: boolean) {
		this._selected = value;
		this._selected = value;
	}

	dataGridType: GridItemType       = GridItemType.Row;
	cssClass: string;
	values: Array<GridDataCell>      = [];
	id                               = '';
	isGroup                          = false;
	private _keys: any;
	properties?: IProperty;
	popoverIsOpen                    = false;
	compareRowState: CompareRowState = CompareRowState.NotSet;
	rowState: RowState               = RowState.Default;
	expansion: {
		sheets: Array<IMatchLevelKeys>,
		options: GridOptions,
		parentRow: GridDataRow,
		expandParams: ICellBehaviourParams
	};
	isExpanded                       = false;
	parent: string                   = null;
	private _selected                = false;
	disabled                         = false;
	isLoading                        = false;
	buttons: Array<RowButton>        = [];
	rules: GridRule[]                = [];
	group: GridDataRow               = null;
	isSubGroup                       = false;

	get keys(): any {
		return this._keys;
	}

	set keys(value: any) {
		const copy = {};
		for (const key of Object.getOwnPropertyNames(value)) {
			copy[key] = value[key];
		}
		this._keys = createToObjectWithLowerCaseKeys(copy);
	}

	/**
	 * Initiate a new row for the datagrid.
	 * @param data The data for this row
	 * @param keys An object of keys to identify the row based on indicators
	 */
	constructor(data: Array<GridDataCell>, keys: any = null) {
		this.values = data;
		if (keys !== null) {
			this.keys = keys;
			this.id   = DataGridHelpers.createKeysString(keys);
		}
	}

	enableSpinnerRow() {
		this.rowState = RowState.Spinner;
		this.values.forEach(cell => {
			if (!cell.cellState.readonly
				&& !cell.cellState.readonly
				&& cell.cellType === DataGridCellType.Data) {
				cell.cellUIState._previousUiType = cell.cellUIState.uiType;
				cell.cellUIState.uiType          = UiTypes.Spinner;
				cell.cellState.editable          = true;
			}
		});
	}

	disableSpinnerRow() {
		this.values.forEach(cell => {
			if (!cell.cellState.readonly
				&& !cell.cellState.readonly
				&& cell.cellType === DataGridCellType.Data) {
				cell.cellUIState.uiType = cell.cellUIState._previousUiType;
				cell.cellState.editable = false;
			}
		});
		this.rowState = RowState.Default;
	}

}
