import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { CsHttpRequestOptions, DataDescribed, LoggerUtil }                    from '@cs/core';
import { UntilDestroy, untilDestroyed }                                       from '@ngneat/until-destroy';
import { delay, mapTo }                                                       from 'rxjs/operators';
import { DataDescribedLoginService, LoginConfigService }                      from '../login-config.service';
import { LoginService }                                                       from '../state/login.service';
import { LoginQuery }                                                         from '../state/login.query';
import { ActivatedRoute, Router }                                             from '@angular/router';
import { DefaultComponent }                                                   from '../default/default.component';
import { AuthenticationQuery }                                                from '@cs/performance-manager/shared';
import { TranslateService }                                                   from '@ngx-translate/core';
import { AuthMethod }                                                         from '../models/auth-method.model';
import { CsToastManagerService }                                              from '@cs/components';
import { DomSanitizer, SafeHtml }                                             from '@angular/platform-browser';
import { of }                                                                 from 'rxjs';

@UntilDestroy()
@Component({
	selector:    'pmc-auto-login',
	templateUrl: './auto-login.component.html'
})
export class AutoLoginComponent extends DefaultComponent implements OnInit, OnDestroy {
	private activeMethod: AuthMethod;

	constructor(
		readonly loginService: LoginService,
		readonly loginQuery: LoginQuery,
		@Inject(LoginConfigService) loginConfig: DataDescribedLoginService,
		readonly route: ActivatedRoute,
		readonly router: Router,
		readonly toastService: CsToastManagerService,
		readonly l8n: TranslateService,
		readonly renderer: Renderer2,
		readonly sanitizer: DomSanitizer,
		readonly changeRef: ChangeDetectorRef,
		readonly authenticationQuery: AuthenticationQuery
	) {
		super(loginService, loginQuery, loginConfig, authenticationQuery, route, toastService, l8n);

		// Start timeout timer
		of(null).pipe(mapTo('Hello'),
							delay(loginConfig.EXTERNAL_LOGIN_TIMEOUT),
							untilDestroyed(this)
						)
						.subscribe(value => {
							this.router.navigate(['/'], {queryParamsHandling: null});
						});

	}

	ngOnInit() {
		super.ngOnInit();
		if (this.route.snapshot.queryParamMap.has('method')) {
			const method      = this.route.snapshot.queryParamMap.get('method');
			const options     = this.loginQuery.getValue().loginOptions;
			this.activeMethod = options.find(value => value.key === method);
			if (this.activeMethod)
				this.setAuthMethodMessage();
		}
	}

	private setAuthMethodMessage() {
		this.l8n.get('AUTO_LOGIN_IN_PROGRESS', {methodName: this.activeMethod.label}).subscribe(value => {
			this.loginService.setUnderTitleMessage(`<i class="mr-2 ${this.activeMethod.icon}"></i> ${value}`);
		});

	}


	ngOnDestroy(): void {
		super.ngOnDestroy();
	}


	getAuthIcon(option: string) {

		const method = option.toLowerCase();

		if (method.startsWith('ldap'))
			return 'icon icon-microsoft-account';
		if (method === 'usernamepassword')
			return 'icon-cohelion';
		if (method === 'duo')
			return 'icon icon-duo-account';

	}
}
