import { CsTemplate }                                            from './table-template.directive';
import {
	Component, Input, EventEmitter, Output, OnInit, TemplateRef,
	AfterContentInit, ContentChildren, QueryList, ChangeDetectorRef
}                                                                from '@angular/core';
import { Column }                                                from './table-column.component';
import { Subscription }                                          from 'rxjs';
import { ElementRef, IterableDiffers, DoCheck, KeyValueDiffers } from '@angular/core';
import { isNullOrUndefined }                                     from '@cs/core';


/**
 * The Table displays data in a tabular format and provides a full spectrum of configuration options.
 */
@Component({
	selector:    'cs-table',
	templateUrl: './table.component.html'
})
export class CsTable implements OnInit, AfterContentInit, DoCheck {
	@ContentChildren(CsTemplate) templates: QueryList<any>;
	@ContentChildren(Column) cols: QueryList<Column>;

	// Rows.

	private _rows;
	/**
	 * Rows that will be rendered inside the table.
	 */
	get rows() {
		return this._rows;
	}

	/**
	 * Rows / Datasource.
	 */
	@Input() set rows(val: Array<any>) {
		if (val === undefined) {
			val = [];
		} else {
			if (this.allRows === undefined) {
				this.allRows = val;
			}
		}
		this._rows = val;
		this.page  = 1;
		this.showData(this.page);
	}

	/**
	 * Optional: Columns that will be visible inside the table.
	 * When not setted it will display all columns of the row.
	 */
	@Input() columns: Array<any>;

	/**
	 * Optional: Identifier which is used for sending back on "onRowClick".
	 */
	@Input() rowIdentifier: any | Array<any>;

	/**
	 * Enables selection of multiple rows.
	 * Add's radiobuttons as first column.
	 */
	@Input() multiple                = false;
	/**
	 * Enables selection visibility.
	 */
	@Input() selectable              = true;
	/**
	 * When a row is clicked it will send the whole row object back.
	 * If multiple selection is possible it will send all the selected rows back.
	 * If "rowIdentifier" is setted it will return that value.
	 */
	@Output() rowClick               = new EventEmitter<any>();
	/**
	 * Column which is sorting applied to.
	 */
	@Input() sortColumn              = '';
	/**
	 * Enables the filter.
	 */
	@Input() filter                  = false;
	/**
	 * In what sorting the direct the column is sorted.
	 */
	@Input() sortDir: 'asc' | 'desc' = 'asc';

	/**
	 * The height of the table, if not set there is no height.
	 * If set, there will be a vertical scrollbar inside the table.
	 */
	@Input() height;
	/**
	 * Sortable per column.
	 */
	@Input() sortable                       = true;
	/**
	 * Table contains paginator.
	 */
	@Input() pageable                       = true;
	/**
	 * A list of page sizes (number of rows displayed on a page) for pagination.
	 */
	@Input() pageSizeOptions: Array<number> = [];
	/**
	 * Page size determines how many number of rows should be displayed on a page when pagination is enabled.
	 */
	@Input() selectedPageSize: number;
	/**
	 * Default page size when developer has not provided any value
	 */
	private defaultPageSize                 = 5;
	/**
	 * Trackers for pagination
	 */
	pageStartRow                            = 0;
	pageEndRow                              = 0;

	/**
	 * Text for when there are no items found.
	 */
	@Input() noResults = 'No data to display.';

	@Input() set selectRows(val: Array<any>) {
		if (val === undefined) {
			val = [];
		}
		val.forEach(element => {
			if (this.rowIdentifier === undefined) {
				const foundElement = this.allRows.find(elem => this.isEquivalent(elem, element));
				if (foundElement !== undefined) {
					if (this.multiple) {
						this.selectedRows.push(foundElement);
					} else {
						this.selectedRows = [foundElement];
					}
				}
			} else {
				if (this.multiple) {
					this.selectedRows.push(element);
				} else {
					this.selectedRows = [element];
				}
			}
		});
	}

	editTemplate: TemplateRef<any>;

	page                     = 1; // Default start page.
	allRows: Array<any>      = []; // This are all the rows which are not filterd yet.
	currentRows: Array<any>  = []; // The rows which are currently displayed in the page of the table.
	selectedRows: Array<any> = []; // All selected rows. Can be 1 or multiple.
	columnTemplates: Column[];
	columnsSubscription: Subscription;
	rowsIterableDiffer;
	columnsIterableDiffer;
	differ: any;
	objDiffer: any           = {};

	constructor(public el: ElementRef,
							public changeDetector: ChangeDetectorRef,
							private _iterableDiffers: IterableDiffers,
							private differs: KeyValueDiffers
	) {
		this.differ                = differs.find([]).create();
		this.rowsIterableDiffer    = this._iterableDiffers.find([]).create(null);
		this.columnsIterableDiffer = this._iterableDiffers.find([]).create(null);
		if (isNullOrUndefined(this.selectedPageSize)) {
			this.selectedPageSize = this.defaultPageSize;
		}
		if (this.pageSizeOptions.length === 0) {
			this.pageSizeOptions.push(this.selectedPageSize);
		}
	}

	ngOnInit() {
		// Let the table be selectable if there is subscribed too the 'rowClick' event.
		// if (this.rowClick.observers.length !== 0) {
		//   this.selectable = true;
		// }
		this.objDiffer = {};
		this.rows.forEach((elt) => {
			this.objDiffer[elt] = this.differs.find(elt).create();
		});
	}

	ngDoCheck() {
		const changes = this.rowsIterableDiffer.diff(this.rows) || this.columnsIterableDiffer.diff(this.columns);
		if (changes) {
			this.initColumns();
			this.changeDetector.markForCheck();
		}

		// changes = this.differ.diff(this.rows);
		// if (changes) {
		//   console.log('arghs');
		// }
	}

	ngAfterContentInit() {
		this.initColumns();
		this.columnsSubscription = this.cols.changes.subscribe(_ => {
			this.initColumns();
			this.changeDetector.markForCheck();
		});
		this.templates.forEach((item) => {
			switch (item.getType()) {
				case 'edit':
					this.editTemplate = item.template;
					this.selectable   = true;
					break;
			}
		});
	}

	/**
	 * Toggle selected rows.
	 *
	 */
	toggleSelectedRows() {
		if (this.selectedRows.length === 0) {
			// Select all rows.
			this.selectedRows = this.allRows;
		} else {
			// empty selected rows.
			this.selectedRows = [];
		}
	}

	/**
	 * Initialize the columns.
	 *
	 */
	initColumns(): void {
		if (!this.cols) {
			return;
		}
		this.columnTemplates = this.cols ? this.cols.toArray() : [];
		if (this.columns !== undefined && this.rows.length > 0 && this.columnTemplates.length > 0) {
			this.columns = undefined;
		} else if (this.columns === undefined && this.rows.length > 0 && this.columnTemplates.length === 0) {
			// Fill columns based on properties of a row is none colums provided.
			this.columns = [];
			Object.keys(this.rows[0]).forEach(element => {
				this.columns.push(element);
			});
		}
		if (this.sortColumn === '' && this.sortable) {
			if (this.columns) {
				this.sortColumn = this.columns[0];
			} else {
				this.sortColumn = this.columnTemplates[0].field;
			}
		}
		this.showData(this.page);
	}

	/**
	 * Shows the data for the current page.
	 */
	showData(page: number) {
		if (!this.pageable || (page > 0 && page <= Math.ceil(this.rows.length / this.selectedPageSize))) {
			this.page         = page;
			const data: any[] = JSON.parse(JSON.stringify(this.rows));
			if (this.sortable) {
				data.sort(this.sortValues);
			}
			this.currentRows  = !this.pageable ? data : data.slice((this.page - 1) * this.selectedPageSize, this.page * this.selectedPageSize);
			this.pageStartRow = (this.page - 1) * this.selectedPageSize + 1;
			this.pageEndRow   = this.pageStartRow + this.selectedPageSize - 1;
			// If number of rows on current page are less than page size then update pageEndRow
			if (this.pageEndRow > this.rows.length) {
				this.pageEndRow = this.rows.length;
			}
		} else if (this.rows.length === 0) {
			this.currentRows = [];
		}
	}

	/**
	 * Sort values.
	 */
	private sortValues = (field1, field2) => {
		const value1 = field1[this.sortColumn];
		const value2 = field2[this.sortColumn];
		let result   = 0;
		if (value1 == null && value2 != null) {
			result = -1;
		} else if (value1 != null && value2 == null) {
			result = 1;
		} else if (value1 == null && value2 == null) {
			result = 0;
		} else if (typeof value1 === 'string' && typeof value2 === 'string') {
			result = value1.localeCompare(value2);
		} else {
			result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
		}
		return (this.sortDir === 'asc' ? result : -result);
	}

	/**
	 * When a page is changed.
	 */
	onPageChange(page) {
		this.showData(page);
	}

	/*onPageSizeChange(selectedPageSize) {
	 if (isNumeric(selectedPageSize)) {
	 this.selectedPageSize = parseInt(selectedPageSize, 0);
	 this.showData(this.page);
	 }
	 }*/

	/**
	 * When the header is clicked for sorting.
	 */
	onHeaderClick(column) {
		if (this.sortable && column) {
			if (column === this.sortColumn) {
				this.sortDir = this.sortDir === 'desc' ? 'asc' : 'desc';
			} else {
				this.sortColumn = column;
				this.sortDir    = 'asc';
			}
			this.showData(this.page);
		}
	}

	/**
	 * Returns columns for the table row.
	 */
	getColumns(row: any): Array<any> {
		const result = [];
		const keys   = this.columns || Object.keys(row);

		keys.forEach(element => {
			result.push(row[element]);
		});

		return result;
	}

	/**
	 * When a row is clicked.
	 */
	onRowClick(row: any, index) {
		this.toggleRow(row);
	}

	toggleRow(row: any) {
		const rid = this.rowIdentifier;
		if ((this.multiple === undefined) || (this.multiple === false)) {
			// Emits 'rowClick'.
			if (this.rowIdentifier === undefined) {
				this.selectedRows = [row];
				this.rowClick.emit(row);
			} else {
				if (rid) {
					this.selectedRows = [row[rid]];
					this.rowClick.emit(row[this.rowIdentifier]);
				} else {
					this.selectedRows = [row];
					this.rowClick.emit(row);
				}
			}
		} else {
			// Logic for removing or adding it.
			if (rid) {
				if (this.selectedRows.indexOf(row[this.rowIdentifier]) > -1) {
					this.selectedRows = this.selectedRows.filter(function (e) {
						return e !== row[rid];
					});
				} else {
					this.selectedRows.push(row[rid]);
				}
			} else {
				const findElement = this.selectedRows.findIndex(elem => this.isEquivalent(elem, row));
				if (findElement >= 0) {
					this.selectedRows.splice(findElement, 1);
				} else {
					this.selectedRows.push(row);
				}
			}
			// Emits 'rowClick'.
			if (this.rowIdentifier === undefined) {
				this.rowClick.emit(this.selectedRows);
			} else {
				// this.rowClick.emit(this.selectedRows.map((a) => { return a[this.rowIdentifier]; }));
				this.rowClick.emit(this.selectedRows);
			}
		}
	}

	/**
	 * Gives back a boolean if the row is selected.
	 * If the index is found.
	 */
	selected(row: any) {
		// TODO: Something is wrong here, after page changes nothing happens.
		// return this.selectedRows.indexOf(row) > -1;
		// const returnV = this.selectedRows.some((e) => e[rid] === row[rid]);
		// const returnV = this.selectedRows.some((e) => this.isEquivalent(e, row));
		if (this.rowIdentifier === undefined) {
			const returnV = this.selectedRows.some((e) => this.isEquivalent(e, row));
			return returnV;
		} else {
			const returnV = this.selectedRows.indexOf(row[this.rowIdentifier]) > -1;
			return returnV;
		}
	}

	isEquivalent(a: any, b: any): boolean {
		// Create arrays of property names
		const aProps = Object.getOwnPropertyNames(a);
		const bProps = Object.getOwnPropertyNames(b);
		// If number of properties is different,
		// objects are not equivalent
		if (aProps.length !== bProps.length) {
			return false;
		}
		for (let i = 0; i < aProps.length; i++) {
			const propName = aProps[i];
			// If values of same property are not equal,
			// objects are not equivalent
			if (a[propName] !== b[propName]) {
				return false;
			}
		}
		// If we made it this far, objects
		// are considered equivalent
		return true;
	}

}
