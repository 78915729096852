import { Component, forwardRef, Inject } from '@angular/core';
import { DashboardChartData }            from '../../models/dashboard-chart-data';
import { IDashboardPanelComponent }      from '../../models/i-dashboard-panel-component';
import { CsChartLoaderSetup }            from '@cs/components/cs-chart-loader';
import { ArrayUtils }                    from '@cs/core';


@Component({
	selector:    'cs-dashboard-chart',
	templateUrl: './dashboard-chart.component.html'
})
export class DashboardChartComponent implements IDashboardPanelComponent<DashboardChartData> {

	name: string;

	set data(value: DashboardChartData) {
		this._data = value;
		this.chartSetup.mapToDataTable(this._data, null).then(value1 => {
			this.chartData = value1.dataTable;
		});
	}

	get data() {
		return this._data;
	}

	private _data: DashboardChartData;


	chartData: DashboardChartData;

	constructor(@Inject(forwardRef(() => CsChartLoaderSetup)) private chartSetup: CsChartLoaderSetup) {

	}

	update(data: DashboardChartData): void {
		if (this.data && ArrayUtils.isEqual(this.data, data))
			return;

		this.data = data;
		//this.chartData = this.chartSetup.mapToDataTable(this.data, null);
	}

}
