import { Injectable }                                    from '@angular/core';
import { Observable }                                    from 'rxjs/internal/Observable';
import { MessageBusMessage }                             from './models/message-bus.message';
import { IDashboardComponent }                           from './models/i-dashboard-component';
import { NotifyServerForChangesDashboardPanelEventArgs } from './models/notify-server-for-changes-dashboard-panel-event-args';
import { RequestDashboardPanelEventArgs }                from './models/request-dashboard-panel-event-args';
import { LoggerUtil, SelectionTargetResult }             from '@cs/core';
import { Subject }                                       from 'rxjs';
import { IDashboardPanelComponent }                      from './models';


@Injectable()
/**
 * The DashboardEventHub is scoped to each dashboard component
 */
export class DashboardEventHub {
	get registeredPanelComponents(): IDashboardPanelComponent<any>[] {
		return this._registeredPanelComponents;
	}

	private _parentDashboard: IDashboardComponent;
	private _registeredPanelComponents: IDashboardPanelComponent<any>[];

	isDashboardEntryIsClicked: Subject<SelectionTargetResult>                            = new Subject();
	onNotifyChangesToServer: Subject<NotifyServerForChangesDashboardPanelEventArgs<any>> = new Subject();
	requestingDashboardPanel: Subject<RequestDashboardPanelEventArgs>                    = new Subject();
	messageBus: Subject<MessageBusMessage<any, string>>                                  = new Subject();

	resetRegisteredPanels() {
		this._registeredPanelComponents = [];
	}

	triggerDashboardEntryClicked(value: SelectionTargetResult) {
		if (value.panelName == null)
			LoggerUtil.error(`No panel name provided`);

		this.isDashboardEntryIsClicked.next(value);
	}

	getPanelData(request: RequestDashboardPanelEventArgs) {
		this.requestingDashboardPanel.next(request);
	}

	panelIsLoading(isLoading: boolean, panelName: string) {
		this.messageBus.next({action: 'IsLoading', panelName: panelName, data: isLoading});
	}

	/**
	 * Will trigger an event so the panel could notify the dashboard that it's data has been changed by the user
	 */
	notifyChangesToServer(eventArgs: NotifyServerForChangesDashboardPanelEventArgs<any>) {
		this.onNotifyChangesToServer.next(eventArgs);
	}

	getParent() {
		return this._parentDashboard;
	}

	registerAsParent(component: IDashboardComponent) {
		this._parentDashboard = component;
	}

	getParentContext() {
		return this._parentDashboard.contextObject;
	}

	registerPanelComponent(component: IDashboardPanelComponent<any>) {
		this._registeredPanelComponents.push(component);
	}

	notifyMessageBus(eventArgs: SelectionTargetResult) {
		this.messageBus.next({action: eventArgs.selectionRoute, panelName: eventArgs.panelName, data: eventArgs.selectionObject});
	}

	getActionsHook<TData, TAction extends string>(): Subject<MessageBusMessage<TData, TAction>> {
		return this.messageBus as Subject<MessageBusMessage<TData, TAction>>;
	}
}
