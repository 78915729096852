import { Injector }                                                     from '@angular/core';
import { isNullOrUndefined }                                            from '@cs/core';
import { FilterCompareBarQuery, IChartDataSet, IChartSerie, IInitData } from '@cs/components';
import { DataEntryConfigService }                                       from '../data-entry-config.service';
import { CsChartPanelComponent }                                        from '../components/pm-chart-panel/chart-panel.component';
import { DataEntryResultParams }                                        from '../models/data-entry-result-params';
import { StatisticsResult }                                             from '../models/statistics-result.model';
import { Observable }                                                   from 'rxjs';
import { DataEntryStateQuery }                                          from '../state/data-entry-state.query';
import { DataEntryStateService }                                        from '../state/data-entry-state.service';

export class ChartPanelAgent {


	private config: DataEntryConfigService;
	private filterCompareBarQuery: FilterCompareBarQuery;
	private dataEntryStateQuery: DataEntryStateQuery;
	private dataEntryStateService: DataEntryStateService;

	/**
	 * Flag indicating that the chart is collapsed
	 */
	isChartCollapsed$: Observable<boolean>;

	eventPanelActive$: Observable<boolean>;

	showChart: boolean;

	statsData: StatisticsResult[];

	activeTabStatistics: string;

	chartOptions: any;

	chartData: IChartDataSet;

	chartPanel: CsChartPanelComponent;

	constructor(chartPanel: CsChartPanelComponent,
							private injector: Injector) {
		this.chartPanel            = chartPanel;
		this.config                = this.injector.get(DataEntryConfigService);
		this.filterCompareBarQuery = this.injector.get(FilterCompareBarQuery);
		this.dataEntryStateQuery   = this.injector.get(DataEntryStateQuery);
		this.dataEntryStateService = this.injector.get(DataEntryStateService);
		this.initAgent();
	}

	static canShowChart(data: IInitData) {
		if (data && data.layout && !data.layout.hasChart)
			return false;

		const cs = data.config.structure.chartSeries;
		return cs.native.series.length > 0;
	}

	private initAgent() {

		this.isChartCollapsed$ = this.dataEntryStateQuery.select(store => store.isChartCollapsed);

		this.eventPanelActive$ = this.dataEntryStateQuery.select(store => store.showEventPanel);

	}

	/**
	 *  Google charts initialization settings, and setup the chartpanel
	 */
	initChart(data: IInitData, selection: { [key: string]: any }) {
		this.dataEntryStateService.update({
			isChartPanelLoading: true
		});

		const obs = new Observable(subscriber => {
			const cs        = data.config.structure.chartSeries;
			const showChart = ChartPanelAgent.canShowChart(data);

			this.dataEntryStateService.update({showChart: showChart});

			if (showChart) {
				const nv = cs.native as IChartSerie;

				const customSeries = [];
				for (const seriesElement of nv.series) {
					if (seriesElement.preset) {
						customSeries.push(seriesElement.preset.chartStyle);
					} else {
						customSeries.push({});
					}
				}
				// Move first series to last (so it renders on top)
				if (customSeries.length) {
					customSeries.push(customSeries.shift());
				}
				const mainbarResult = this.filterCompareBarQuery.getValue().mainbarResultParams as DataEntryResultParams;

				this.config.getFactTablesReportData(nv.factTableName, nv, selection).subscribe(
					result => {
						const chartData = result.value;
						/*
						 To do:
						 - Find solution to remove the top horizontal grid line. Doesn't seem to be possible with removing other lines.
						 Maybe just hide it with css somehow.
						 - Shadow under data line may not be possible
						 - Legend should be rendered with angular/html, outside the chart?
						 - Is it possible to align the horizontal axis to certain grids?
						 */

						const options: google.visualization.ComboChartOptions = {
							height:        304
							, focusTarget: 'category'
							, lineWidth:   2
							, pointSize:   0
							, dataOpacity: 0.8
							, areaOpacity: 0.06
							, bar:         {
								groupWidth: '25%'
							}
							, colors:      ['#0077c2', '#d75659', '#0bd998', '#0bbed9']
							, chartArea:   {
								left:     0
								, right:  0
								, top:    0
								, bottom: 32
								, width:  '100%'
							}
							, legend:      {
								position: 'none'
							}
							, hAxis:       {
								baselineColor:  'FFF'
								, textPosition: 'out'
								, gridlines:    {
									color: 'FFF'
								},
								textStyle:      {
									fontSize: 12
									, color:  '777'
								}

								, viewWindow: {
									min:   cs.hasLeftEdgeData ? 0.5 : 0
									, max: cs.mainAxisMembers.length + (cs.hasLeftEdgeData ? 0.5 : 0) + (cs.hasRightEdgeData ? -1 : 0)
								}
							}
							, vAxis:       {
								baselineColor:    '888'
								, textPosition:   'in'
								, gridlines:      {
									color:   '000000'
									, count: 0
								}
								, minorGridlines: {
									count: 0
								}
								, textStyle:      {
									fontSize: 11
									, color:  '888'
								}
								, format:         'short'
								, viewWindow:     {
									min: 0						// Keep the 0 axis always visible. Google tends to zoom in on the data if the values don't vary much.
								}
							}
							, series:      customSeries
							, tooltip:     {
								isHtml:    true,
								textStyle: {
									fontSize: 12
								}
							}
							, animation:   {
								duration: 300,
								easing:   'inAndOut',
								startup:  true
							}
							, annotations: {
								textStyle: {
									fontSize: 13
								}
							}
						};

						this.chartOptions = options;

						// Move first series to last (so it renders on top)
						if (chartData.series.length) {
							chartData.series.push(chartData.series.shift());
						}
						if (nv.series.length) {
							nv.series.push(nv.series.shift());
						}

						// set the activeSerie direct because this way the chart isn't rendering twice
						if (!isNullOrUndefined(this.chartPanel))
							this.chartPanel.activeSerie = mainbarResult.presetName;

						this.chartData = <any>{
							mainAxisMembers: cs.mainAxisMembers,
							mainAxisKey:     cs.mainAxisKey,
							chartType:       cs.chartType,
							data:            chartData,
							series:          nv.series
						};

						if (!isNullOrUndefined(this.chartPanel))
							this.chartPanel.changeRef.markForCheck();
					});
				subscriber.next(this.chartData);
				subscriber.complete();
			}
		});

		return obs;

	}

	/**
	 * Get the statistics for the side panel in the chart panel
	 * @param selection Get the stats with current selection from the filterComparebar
	 * @param currentDataEntryGrid The current DataGrid name
	 */
	getStatistics(selection: { [key: string]: string | number },
								currentDataEntryGrid: string,
								comparisonSelection: { [key: string]: string | number } = {}) {
		const obs = new Observable(subscriber => {
			this.dataEntryStateService.update({
				isStatisticsPanelLoading: true
			});
			this.config.getStatsData(currentDataEntryGrid, selection, comparisonSelection).subscribe(result => {
				this.statsData = result.value;
				//this.activeTabStatistics = this.statsData[0];
				subscriber.next(this.statsData);
				subscriber.complete();
			});
		});
		return obs;
	}

}
