import { LoggerUtil }                    from './logger.util';
import { createObjectWithLowerCaseKeys } from './object.utils';

/**
 *  Replace the placeholders like: :idCustomer and provide 'idCustomer' as the value as the first group
 */
export const PlaceholderRegexPathUrlVariable = /:(.\w+)/g;

/**
 * Replace the placeholders like: ${idCustomer} and provide 'idCustomer' as the value as the first group
 */
export const PlaceholderRegexStringInterpolation = /\$\{([^}]+)\}/g;

export function toCamelCase(str: string) {
	return str.charAt(0).toLowerCase() + str.slice(1);
}

export function toKebabCase(str: string) {
	return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

export function isBase64(v, opts) {
	if (v instanceof Boolean || typeof v === 'boolean') {
		return false;
	}

	if (!(opts instanceof Object)) {
		opts = {};
	}

	if (opts.allowEmpty === false && v === '') {
		return false;
	}

	let regex       = '(?:[A-Za-z0-9+\\/]{4})*(?:[A-Za-z0-9+\\/]{2}==|[A-Za-z0-9+\/]{3}=)?';
	const mimeRegex = '(data:\\w+\\/[a-zA-Z\\+\\-\\.]+;base64,)';

	if (opts.mimeRequired === true) {
		regex = mimeRegex + regex;
	} else if (opts.allowMime === true) {
		regex = mimeRegex + '?' + regex;
	}

	if (opts.paddingRequired === false) {
		regex = '(?:[A-Za-z0-9+\\/]{4})*(?:[A-Za-z0-9+\\/]{2}(==)?|[A-Za-z0-9+\\/]{3}=?)?';
	}

	return (new RegExp('^' + regex + '$', 'gi')).test(v);
}


/**
 * Replace placeholders in a string with the values found in the replacement object.
 * @param replacementObject The object {[placeholdername: string] : value}
 * @param stringToReplace String that should contain the placeholders
 * @param placeholderRegex The regular expression that returns for the full match ( ${idCustomer} )
 * and the first group containing the placeholderName ( idCustomer )
 */
export function replacePlaceholders(replacementObject        = null, stringToReplace: string,
																		placeholderRegex: RegExp = PlaceholderRegexStringInterpolation) {

	const replacements = createObjectWithLowerCaseKeys(replacementObject);
	if (replacements) {
		const replaceRegex = placeholderRegex;
		let replaceMatch   = replaceRegex.exec(stringToReplace);
		while (replaceMatch != null) {
			const replaceParam = replaceMatch[0];
			const replacement  = replacements[replaceMatch[1].toLowerCase()];
			if (replacement != null) {
				stringToReplace = stringToReplace.replace(replaceParam, replacement);
			} else {
				LoggerUtil.warn(`${replacement} not found`);
			}
			replaceMatch = replaceRegex.exec(stringToReplace);
		}
	}
	return stringToReplace;
}
