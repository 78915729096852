import { DataKey }                                                                    from '../utils';
import { convertKeysToFnv32a, convertKeysToFnv32aPath, getPropertyOf, isEmptyObject } from '../../../utils/object.utils';
import { generateQuickGuid }                                                          from '../../../generate';

export class DataStructureGroups {
	[key: string]: DataStructureGroup;

	/**
	 * Walks down the tree and set the parent reference for each node/goup. This will be used for navigation and creating
	 * combined keys
	 * @param headerGroups the structure to be scanned and filled with parent references
	 */
	static createParentReferences(headerGroups: DataStructureGroups): void {
		if (headerGroups == null)
			return;

		const lastNodes: Array<DataStructureGroup> = [];

		function checkForChildren(groups: DataStructureGroups, nodes: Array<DataStructureGroup>, parent: DataStructureGroup) {
			for (const groupName of Object.keys(groups)) {
				const groupStructure = groups[groupName];
				// set the parentRef for the group
				groupStructure.setParent(parent);

				// look for more children
				if (!isEmptyObject(groupStructure.children))
					checkForChildren(groupStructure.children, nodes, groupStructure);

			}
		}

		checkForChildren(headerGroups, lastNodes, null);
	}
}

export class DataStructureGroup {

	get numOfChildren(): number {
		return Object.keys(this.children).length;
	}

	get totalNumOfChildren(): number {


		function checkForChildren(groups: DataStructureGroups, amount: number): number {
			for (const groupName of Object.keys(groups)) {

				const item = groups[groupName];

				amount += item.numOfChildren;

				// look for more children
				if (!isEmptyObject(item.children))
					checkForChildren(item.children, amount);


			}
			return amount;
		}

		return checkForChildren(this.children, this.numOfChildren);

	}

	get index(): number {
		return this._index;
	}

	private set index(value: number) {
		this._index = value;
	}


	private set id(value: string) {
		this._id = value;
	}

	/**
	 * String representation of the hierachy by joining the parents @Link{DataStructureGroup.structureKey}
	 * @param path the path that is joined
	 */
	static getFullPath(path: string[]): string {
		return path.join('#');
	}

	key: DataKey;
	children?: DataStructureGroups;
	width: number;
	private _index: number;

	type: string | 'DataStructurePlaceholder';

	/**
	 * The level key of the group. Group2 for example
	 */
	levelKey: string | number;

	/**
	 * The level key value of the group. Like OPS_State
	 */
	levelValue: string | number;

	/**
	 * unique identifier that is generated
	 */
	get id(): string {
		return this._id;
	}

	/**
	 * The combination of the @Link{structureKey} and @Link{structureValue} as a stucture identifiers
	 */
	get structureKey(): string {
		if (this._structureKey == null)
			this._structureKey = convertKeysToFnv32a({[this.levelKey]: this.levelValue});
		return this._structureKey;
	}

	/**
	 * Get the combined keys with the parent Keys
	 */
	get fullPathKey(): DataKey {
		return this._fullPathKey || this.createFullPathKey();
	}

	get path(): string[] {
		return this._path || this.createPath();
	}

	get parent(): DataStructureGroup | null {
		return this._parent;
	}

	get isLastNode() {
		return this.children == null || isEmptyObject(this.children);
	}

	get fullPath() {
		return DataStructureGroup.getFullPath(this.path);
	}

	constructor(init: Pick<DataStructureGroup, 'children' | 'type' | 'levelKey' | 'levelValue'>) {
		this.key        = {[init.levelKey]: init.levelValue};
		this.children   = getPropertyOf(init, 'children', {});
		this.id         = generateQuickGuid();
		this.index      = 0;
		// 	this.path         = init.path;
		this.type       = init.type;
		this.levelKey   = init.levelKey;
		this.levelValue = init.levelValue;
	}

	/**
	 * Explicit function for setting the index. This way it's more clear where updates are happening
	 */
	setIndex(newIndex: number): DataStructureGroup {
		this.index = newIndex;
		return this;
	}

	getWidth(): number {

		function checkChildrenWidth(group: DataStructureGroup) {
			const groupStructure = group;
			let width            = 0;
			if (group.isLastNode) {
				width = group.width;
			} else {
				for (const pathSegment of Object.keys(group.children)) {
					const headerSegment = groupStructure.children[pathSegment];
					width += headerSegment.getWidth();
				}
			}
			return Math.round(width);
		}

		return checkChildrenWidth(this);
	}

	setParent(parent: DataStructureGroup) {
		this._parent = parent;
	}

	private _fullPathKey: DataKey;

	private _structureKey: string;

	private _id: string;


	private _path: string[];

	private _parent: DataStructureGroup | null;

	private createPath() {

		function getPath(currentParent: DataStructureGroup, path: Array<string>) {
			path.unshift(...convertKeysToFnv32aPath(currentParent.key));

			if (currentParent.parent)
				getPath(currentParent.parent, path);

			return path;
		}

		if (this._path == null)
			this._path = getPath(this, []);

		return this._path;
	}

	private createFullPathKey() {

		function getFullPathKey(currentParent: DataStructureGroup, keys: DataKey) {
			Object.assign(keys, currentParent.key);

			if (currentParent.parent)
				getFullPathKey(currentParent.parent, keys);

			return keys;
		}

		if (this._fullPathKey == null)
			this._fullPathKey = getFullPathKey(this, {});

		return this._fullPathKey;
	}
}
