import { HttpErrorResponse } from '@angular/common/http';
import {
	Inject,
	Injectable,
	Injector,
	OnDestroy
}                            from '@angular/core';
import {
	Params
}                            from '@angular/router';
import {
	DateUtils
}                            from '@cs/common';
import {
	CellActionClickEventArgs,
	CellClickEventArgs,
	CellEditedEventArgs,
	createToObjectWithLowerCaseKeys,
	CsDataGridPopoversService,
	CsPopoverComponentSetup,
	CsToastManagerService,
	DataEntrySaveMethod,
	DataGridCellType,
	DataGridHelpers,
	DataGridRuleEnforcer,
	FilterCompareBarQuery,
	FilterCompareBarService,
	get_browser_info,
	PopoverTypes,
	RowButtonClickEventArgs,
	SheetAction,
	SheetActionEventArgs,
	transformToSelectionObject
}                            from '@cs/components';
import {
	CsHttpRequestOptions,
	isNullOrUndefined,
	LoggerUtil,
	LogType
}                            from '@cs/core';
import {
	BottomBarQuery,
	BottomBarService,
	CSImportResultMessage,
	DialogType,
	DynamicButtonBarClickedEventArgs,
	DynamicButtonsAgent
}                            from '@cs/performance-manager/shared';
import {
	UntilDestroy,
	untilDestroyed
}                            from '@ngneat/until-destroy';
import {
	TranslateService
}                            from '@ngx-translate/core';
import {
	Observable, Subject, Subscription
}                                 from 'rxjs';
import { debounceTime, takeLast } from 'rxjs/operators';
import {
	DynamicButtonClickedEventArgs
}                                 from '../components/pm-chart-panel/event-args/dynamic-button-clicked-event-args';
import {
	DataSourceHoverComponent
}                            from '../components/popovers/data-source-hover/data-source-hover.component';
import {
	DataEntryComponent
}                            from '../data-entry.component';
import {
	CellClickedHandlerService
}                            from '../handlers/cell-clicked-handler.service';
import {
	DataEntryResultParams
}                            from '../models/data-entry-result-params';
import {
	DataEntryStateService
}                            from '../state/data-entry-state.service';
import {
	DataGridUtils
}                            from './data-grid.utils';


@UntilDestroy()
export class DataEntryAction<T> {
	name: string;
	actionToExecute: (payload: T, config: DataEntryAgent) => true;
}

@UntilDestroy()
export abstract class DataEntryAgent<T = DataEntryComponent> {

	protected _registeredActions = new Map<string, DataEntryAction<SheetActionEventArgs | DynamicButtonClickedEventArgs>>();

	abstract save(params: Params, dataEntry: DataEntryComponent): Observable<boolean>;

	abstract sheetActionButtonClicked(button: SheetActionEventArgs, dataEntry: T);

	abstract bottomBarButtonClicked(button: DynamicButtonBarClickedEventArgs, dataEntry: T);

	abstract cellsEdited(args: CellEditedEventArgs, dataEntry: T);

	abstract cellClicked(args: CellActionClickEventArgs, dataEntry: T);

	abstract onNavigationRequested(args: CellClickEventArgs, dataEntry: T);

	abstract loadNewData(event: Event, dataEntry: T);

	abstract rowButtonClicked($event: RowButtonClickEventArgs, dataEntryComponent: DataEntryComponent);

	addAction(key: string, action: DataEntryAction<SheetActionEventArgs | DynamicButtonClickedEventArgs>) {
		this._registeredActions.set(key, action);
	}


}

// TODO: Add Angular decorator.
@UntilDestroy()
@Injectable()
export class DefaultDataEntryAgent extends DataEntryAgent implements OnDestroy {


	private isLoadingData: boolean;
	private saveSubscriber: Subscription;

	constructor(@Inject(Injector) private injector: Injector,
							@Inject(CsToastManagerService) private toastService: CsToastManagerService,
							@Inject(TranslateService) private i8n: TranslateService,
							@Inject(CellClickedHandlerService) private cellClickedHandler: CellClickedHandlerService,
							@Inject(CsDataGridPopoversService) private dataGridPopoversService: CsDataGridPopoversService,
							@Inject(BottomBarService) private bottomBarService: BottomBarService,
							@Inject(BottomBarQuery) private bottomBarQuery: BottomBarQuery) {
		super();
		this.initAgent();
	}

	ngOnDestroy(): void {
	}

	save(params: Params = {}, dataEntry: DataEntryComponent) {
		const obs = new Observable<boolean>(subscriber => {
			const filterAppService = this.injector.get(FilterCompareBarQuery);
			const mainbarResult    = filterAppService.getValue().mainbarResultParams as DataEntryResultParams;
			// this.buttonBar.resetRunningSaveRequests();

			for (const grid of dataEntry.grid.getAllDataGrids()) {
				const changedCells = grid.getChangedCellsReadyForApi();


				// if not data don't try to save it
				if (isNullOrUndefined(changedCells)
					|| isNullOrUndefined(changedCells.data)
					|| isNullOrUndefined(changedCells.data.data)
					|| changedCells.data.data.length === 0)
					continue;

				if (this.bottomBarQuery.getValue().inputOptions)
					changedCells.reason = this.bottomBarQuery.getValue().inputValue || undefined;

				const options: CsHttpRequestOptions = new CsHttpRequestOptions();
				options.errorResponseHandler        = (response): boolean => this.handleSaveFactsFailure(response);

				dataEntry.config.saveFacts(
					mainbarResult.dataEntryGrid,
					changedCells,
					params,
					options)
								 .subscribe(data => {
									 // this.buttonBar.runningSaveRequests--;

									 if (!CSImportResultMessage.isImportResultMessage(data.value)) {
										 LoggerUtil.error('Server returned an unexpected response. data:' + JSON.stringify(data.value));
									 }
									 const result = new CSImportResultMessage(data.value);

									 // When all is successful freeze the data and start changeDetection
									 if (result.success) {
										 grid.freezeData(null);
										 dataEntry.updateStatsAndChart();
										 this.bottomBarService.resetButtonBar();
										 // SafeMethods.detectChanges(grid.changeRef);
										 if (result.ignored === 0) {
											 if (grid.options.saveMethod !== DataEntrySaveMethod.AutoSave) {
												 this.toastService.show({
																									type:    'success',
																									content: this.i8n.instant('DATA_SUCCESSFULLY_UPDATED')
																								});
											 } else {
												 this.toastService.show({
																									showTitle:       false,
																									type:            'bare',
																									content:         this.i8n.instant('DATA_SUCCESSFULLY_UPDATED_AUTOSAVE'),
																									clickToClose:    false,
																									showCloseButton: false,
																									size:            'auto',
																									iconClass:       'mdi-content-save-check'
																								});
											 }
											 subscriber.next(true);
											 this.saveSuccessFull(dataEntry);
										 } else {
											 if (result.ignored === result.processed) {
												 // All facts were ignored
												 result.messages.unshift(this.i8n.instant('WARNINGS_DURING_SAVING'));
												 DataGridUtils.displayMultilineUserMessage(this.injector,
																																	 result.messages,
																																	 this.i8n.instant('WARNING'), DialogType.warning);
											 } else {
												 // Data was partially saved
												 result.messages.unshift(this.i8n.instant('WARNINGS_DURING_SAVING_PARTIAL_SAVED'));
												 DataGridUtils.displayMultilineUserMessage(this.injector,
																																	 result.messages,
																																	 this.i8n.instant('WARNING'), DialogType.warning);
												 // reload the page
												 // this.refreshData();
											 }
											 subscriber.next(false);
										 }
									 } else {
										 if (!result.messages.length) {
											 // const apiParams = `${this.mainbarDataSource.resultParams.dataEntryGrid} ${JSON.stringify(changedCells)} ${JSON.stringify(params)}`;
											 // LoggerUtil.write(`Saving error. Server returned failed save without message(s). ${apiParams}`, LogType.Error, true);

										 } else if (result.messages.some((msg) => msg.toLowerCase()
																																 .indexOf('empty dataset') !== -1)) {
											 // no user feedback: some sheets are always readonly, but are saved (e.g. Carrier level)
										 } else {
											 result.messages.unshift('Saving failed.');
											 DataGridUtils.displayMultilineUserMessage(this.injector, result.messages);
										 }
										 subscriber.next(false);
									 }

									 subscriber.complete();
								 });
			}
		});

		return obs;
	}

	private handleSaveFactsFailure(response: HttpErrorResponse) {
		if (response.status === 400) {

			// Display warning about invalid input
			this.toastService.show({
															 type:    'error',
															 title:   response.statusText,
															 content: response.message
														 });

			return true; // Response is regarded as handled
		}
		return false;
	}

	saveSuccessFull(dataEntry: DataEntryComponent) {

	}

	cellClicked(args: CellActionClickEventArgs, dataEntry: DataEntryComponent) {
		this.cellClickedHandler.executeCellAction(args, dataEntry);
	}

	pageButtonClicked(event: DynamicButtonBarClickedEventArgs, dataEntry: DataEntryComponent) {
		const dynamicPageButtonsAgent = this.injector.get(DynamicButtonsAgent) as DynamicButtonsAgent;

		dynamicPageButtonsAgent.goNewState(event.data, this.injector, dataEntry, event.files);
	}

	bottomBarButtonClicked(button: DynamicButtonBarClickedEventArgs, dataEntry: DataEntryComponent) {

		switch (button.data.displayInstance.type) {
			case SheetAction.Save:
				for (const grid of dataEntry.grid.getAllDataGrids()) {
					grid.setSheetsEditable(null, false);
				}
				this.bottomBarService.disableAllButtons();
				button.data.isLoading = true;
				this.save({}, dataEntry)
						.pipe(untilDestroyed(this))
						.subscribe(value => {
							button.data.isLoading = false;
							this.bottomBarService.toggleBottomBar(false);
							this.bottomBarService.registerChange(0);
							this.bottomBarService.enableAllButtons();

							if (dataEntry.dataEntryGrid.refreshAfterSave)
								dataEntry.dataEntryGrid.refreshData();

							console.log(value);
						});
				break;
			case SheetAction.Cancel:
				dataEntry.dataEntryGrid.refreshData();
				this.bottomBarService.toggleBottomBar(false);
				this.bottomBarService.registerChange(0);
				break;
		}

	}

	sheetActionButtonClicked(button: SheetActionEventArgs, dataEntry: DataEntryComponent) {
		switch (button.type) {
			case SheetAction.Save:
				for (const grid of dataEntry.grid.getAllDataGrids()) {
					grid.setSheetsEditable(null, false);
				}
				this.save(button.actionParams, dataEntry)
						.pipe(untilDestroyed(this))
						.subscribe(value => console.log(value));
				break;
			case SheetAction.Cancel:
				dataEntry.dataEntryGrid.refreshData();
				break;
			case SheetAction.Custom: {
				const dynamicPageButtonsAgent = this.injector.get(DynamicButtonsAgent) as DynamicButtonsAgent;


				// ADDED for legacy purposes, prefered way should be setting the button.type
				const type        = button.actionParams.name;
				const foundButton = dynamicPageButtonsAgent.isRegistered(type);
				if (!foundButton.isSuccess) {
					const msg = `${type} is not found as registered button`;
					LoggerUtil.write(msg, LogType.Error);
				} else {
					dynamicPageButtonsAgent.goNewState(foundButton.value, this.injector, dataEntry, button);
				}
				break;
			}
			default: {
				const dynamicPageButtonsAgent = this.injector.get(DynamicButtonsAgent) as DynamicButtonsAgent;
				// the default way to handle actions for the datasheet actions/ do not mistake this for data-entry page actions
				const type                    = button.type.toString();
				const foundButton             = dynamicPageButtonsAgent.isRegistered(type);
				if (!foundButton.isSuccess) {
					const msg = `${type} is not found as registered button`;
					LoggerUtil.write(msg, LogType.Error);
				} else {
					dynamicPageButtonsAgent.goNewState(foundButton.value, this.injector, dataEntry, button);
				}
			}
		}

	}


	save$: Subject<any> = new Subject<void>();


	cellsEdited($event: CellEditedEventArgs, dataEntry: DataEntryComponent) {
		dataEntry.dataEntryGrid.getChangedCells();

		// turn on the autosave feature
		if (dataEntry.grid.options.saveMethod === DataEntrySaveMethod.AutoSave && this.bottomBarQuery.getValue().dataIsValid) {
			if (this.saveSubscriber == null) {
				this.saveSubscriber = this.save$.pipe(debounceTime(500))
																	.subscribe(() => {
																		this.save({}, dataEntry)
																				.pipe(untilDestroyed(this))
																				.subscribe(value => console.log(value));

																	});
			}

			this.save$.next();
		}

		const sheets = $event.gridItem
									 ? [DataGridHelpers.findSheetByCell($event.gridItem, dataEntry.dataEntryGrid.sheets)]
									 : dataEntry.dataEntryGrid.sheets;


		// TODO: Add setting CalculateAfterEdit on the server config that like RefreshAfterSave
		dataEntry.grid.calculateSheetsAsync(sheets)
						 .subscribe(value => {
							 for (const sheet of dataEntry.dataEntryGrid.sheets) {
								 DataGridRuleEnforcer.executeDynamicRules(sheet);
							 }
							 dataEntry.grid.updateCells(DataGridCellType.All);
							 dataEntry.grid.updateCells(DataGridCellType.Total);


						 });


	}

	protected initAgent() {
		const popovers: Array<CsPopoverComponentSetup<any>> = [
			{
				component:         DataSourceHoverComponent,
				popoverType:       PopoverTypes.DataSource,
				elementIdentifier: 'cs-grid-data-cell'
			}
		];

		popovers.forEach(value => {
			this.dataGridPopoversService.registerPopoverComponent(value);
		});

	}

	onNavigationRequested(event: CellClickEventArgs, dataEntry: DataEntryComponent) {
		if (isNullOrUndefined(event))
			return;

		const filterAppService = this.injector.get(FilterCompareBarQuery);
		const mainbarResult    = filterAppService.getValue().mainbarResultParams as DataEntryResultParams;

		if (event.gridItem.behavior.action === 'Expand') {
			// Update current navbar selection with the clicked id and key of the indicator
			const navBarSelection = createToObjectWithLowerCaseKeys(mainbarResult.selection);
			const keySelection    = transformToSelectionObject(event.gridItem.keys);
			const selection       = Object.assign({}, navBarSelection, keySelection);
			const params          = Object.assign({}, mainbarResult, event.gridItem.behavior.params);
			console.log(selection);
			dataEntry.dataEntryGrid.renderExpensionDataGrid(event.gridRow, selection,
																											mainbarResult.dataEntryGrid,
																											params, event.gridSheet);
		} else if (event.gridItem.behavior.action === 'Refresh') {
			const navBarSelection = createToObjectWithLowerCaseKeys(mainbarResult.selection);
			const keySelection    = transformToSelectionObject(event.gridItem.keys);
			const selection       = JSON.stringify(Object.assign({}, navBarSelection, keySelection));
			const params          = Object.assign({}, mainbarResult.params, event.gridItem.behavior.params);
			console.log(selection);
			dataEntry.renderDataGrid(mainbarResult, params);
		} else if (event.gridItem.behavior.action === 'OpenDialog') {
			throw new Error('Not implemented yet');
		} else if (event.gridItem.behavior.action === 'NavigateToUrl') {
			throw new Error('Not implemented yet');
		}
	}

	loadNewData(event: Event, dataEntry: DataEntryComponent) {

		if (!this.isLoadingData) {
			const info = get_browser_info();
			// Minimize loading impact for the older FF in the Sapphire Citrix env

			let minDay = -5;
			let maxDay = -1;

			if (info.name.toLowerCase() === 'firefox' && info.version <= 40) {
				minDay = -3;
				maxDay = -1;
			}

			const filterAppQuery   = this.injector.get(FilterCompareBarQuery);
			const filterAppService = this.injector.get(FilterCompareBarService);
			const mainbarResult    = filterAppQuery.getValue().mainbarResultParams as DataEntryResultParams;

			if (mainbarResult.selection == null)
				return;

			this.isLoadingData = true;
			const idDay        = mainbarResult.selection.idDay;
			const minDate      = DateUtils.convertCfDateToJsDate(idDay.minId);

			const newMinDate = DateUtils.addDays(minDate, minDay);
			const newMaxDate = DateUtils.addDays(minDate, maxDay);

			const newSelection       = JSON.parse(JSON.stringify(mainbarResult.selection));
			newSelection.idDay.minId = DateUtils.convertJsDateToCfDate(newMinDate, true);
			const tempnewSelection   = JSON.parse(JSON.stringify(newSelection));
			newSelection.idDay.maxId = DateUtils.convertJsDateToCfDate(newMaxDate, true);

			const updatedNavbar = Object.assign({...mainbarResult}, {selection: tempnewSelection});
			filterAppService.updateWithoutNotify({
																						 mainbarResultParams: updatedNavbar
																					 });

			this.getContinuesScrollingData(newSelection, updatedNavbar, dataEntry);
		}
	}

	getContinuesScrollingData(selection: { [key: string]: any }, mainbarResult: DataEntryResultParams, dataEntry: DataEntryComponent) {
		const dataEntryGrid         = mainbarResult.dataEntryGrid;
		const params                = Object.assign({}, mainbarResult, {selection: undefined});
		const filterAppService      = this.injector.get(FilterCompareBarService);
		const dataEntryStateService = this.injector.get(DataEntryStateService);
		dataEntryStateService.update({
																	 isLoadingNewContent: true
																 });
		dataEntry.config.getInitBundle(dataEntryGrid, selection, params)

						 .subscribe((struct) => {

							 dataEntry.dataEntryGrid.expandStructure(struct.value)
												.then(value => {
													setTimeout(() => {
														dataEntryStateService.update({
																													 isLoadingNewContent: false
																												 });
													}, 10);
													this.isLoadingData = false;
													this.toastService.show({
																									 type:    'info',
																									 content: this.i8n.instant('NEW_ITEMS_LOADED',
																																						 {amount: struct.value.structureData.dimensionTrees.sheets.memberTree.members.length})
																								 });
												});
						 });
	}

	rowButtonClicked($event: RowButtonClickEventArgs, dataEntryComponent: DataEntryComponent) {
		switch ($event.button.name) {
			case 'DeleteRow':
				dataEntryComponent.grid.deleteRow($event.row, $event.group, $event.sheet);
				break;
		}
	}
}
