import {
	ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter,
	forwardRef,
	Inject, Input, OnChanges, OnInit, Output, ViewChild
}                                                     from '@angular/core';
import { AbstractControl, FormControl, FormGroup }    from '@angular/forms';
import { WizardComponentRegistry }                    from '@cs/components/shared';
import { UntilDestroy, untilDestroyed }               from '@ngneat/until-destroy';
import { WizardConfigService }                        from './services/wizard.service';
import { Step }                                       from './models/step';
import { RequestStepDataEventArgs }                   from './models/request-step-data-event-args';
import { ComponentChanges, gv, LoggerUtil, Result, whenChanging } from '@cs/core';
import { WizardStepsDataDescribed }                   from './models/wizard-data-described';
import { WizardStepperComponent }                     from './components/wizard-stepper';
import { WizardParentContext }                        from './models';
import { ActivatedRoute, Router } 										from '@angular/router';

export abstract class Base {}

@UntilDestroy()
@Component({
						 selector:        'cs-wizard',
						 templateUrl:     './wizard.component.html',
						 changeDetection: ChangeDetectionStrategy.OnPush,
						 providers:       [
							 {provide: Base, useExisting: forwardRef(() => WizardComponent)}
						 ]
					 })
export class WizardComponent implements OnInit,
																				OnChanges {


	@Input() dataContext: WizardStepsDataDescribed;
	@Output() requestStepData: EventEmitter<RequestStepDataEventArgs> = new EventEmitter<RequestStepDataEventArgs>();

	@ViewChild('wizardStepper', {static: true}) stepper: WizardStepperComponent;

	contentComponent: any;
	contentData: unknown;
	contentDataStore: Map<string, any> = new Map<string, any>();

	steps: Array<Step>   = [];
	contentContext: WizardParentContext;
	formgroup: FormGroup = new FormGroup({});

	get isLastStep() {
		return gv(() => this.stepper.isLastStep, false);
	}

	get canNavigateForward(): boolean {
		const name = gv(() => this.contentContext.step.name);
		if (name == null)
			return true;
		return this.formgroup.get(name).valid;
	}

	get canNavigateBack(): boolean {

		if (gv(() => this.stepper.isFirstStep))
			return false;

		return true;
	}

	constructor(@Inject(WizardComponentRegistry) private registry: WizardComponentRegistry,
							@Inject(WizardConfigService) private config: WizardConfigService,
							private readonly changeRef: ChangeDetectorRef,
							public router: Router,
							private route: ActivatedRoute
	) {
	}

	ngOnInit(): void {
		// listen to status changes
		this.formgroup.statusChanges.pipe(untilDestroyed(this))
				.subscribe(value => {
					this.changeRef.detectChanges();
				});
	}

	ngOnChanges(changes: ComponentChanges<WizardComponent>): void {

		whenChanging(changes.dataContext, true)
			.execute(value => {
				this.steps = value.currentValue.data.map(value1 => new Step(value1));
			});

	}

	async setStepActive(step: Step) {

		if (step.data == null) {
			const result: Result<any> = await this.config.getStepData({
																																	currentStep: step.name,
																																	context:     []
																																})
																						.toPromise();

			this.contentData = result.value;

		} else
			this.contentData = step.data;

		if (!this.contentDataStore.has(step.name))
			this.contentDataStore.delete(step.name);

		this.contentDataStore.set(step.name, JSON.parse(JSON.stringify(this.contentData)));

		this.contentComponent = this.registry.getTemplate(step.content).component;

		const formGroup = this.formgroup.contains(step.name)
											? this.formgroup.get(step.name) as FormGroup
											: new FormGroup({}, {updateOn: step.updateOn ?? 'change'});

		this.contentContext = {step: step, formGroup: formGroup};

		if (!this.formgroup.contains(step.name)) {
			this.formgroup.addControl(step.name, this.contentContext.formGroup);

		}

		this.changeRef.detectChanges();
	}


	navigateBack($event: MouseEvent) {
		if (this.canNavigateBack)
			this.stepper.navigateBack();
	}

	navigateNext($event: MouseEvent) {
		if (this.canNavigateForward)
			this.stepper.navigateNext();
	}

	async finishWizard($event: MouseEvent) {
		const result: Result<any> = await this.config.sendUserData(this.formgroup.value).toPromise();

		if(result)
			this.router.navigate(['../../'], {relativeTo: this.route});
		else
			LoggerUtil.error(`Error saving user`);
	}
}
