import { IConfigService }               from '@cs/performance-manager/shared';
import { Observable }                   from 'rxjs';
import { CsHttpRequestOptions, Result } from '@cs/core';
import { ReportCategory }               from './models/reportCategory';
import { Report }                       from './models/report';
import { ReportFilterSelection }        from './models/form-definition-selection';
import { ReportFormDefinition }         from './models/reportFormDefinition';
import { HttpResponse }                 from '@angular/common/http';


export abstract class ReportingConfigService implements IConfigService {
  moduleName = 'reporting';

  abstract getReportInfo(selectedCategoryId: number, selectedReportId: number): Observable<Result<Report>>;

  abstract getReportFormDefinitions(selectedCategoryId: number,
                                    selectedReportId: number,
                                    selection: ReportFilterSelection): Observable<Result<ReportFormDefinition>>;

  abstract getExcelReport(selectedCategoryId: number,
                          selectedReportId: number,
                          selection: ReportFilterSelection,
                          csHttpRequestOptions: CsHttpRequestOptions): Observable<Result<HttpResponse<Blob>>>;

  abstract getPdfReport(selectedCategoryId: number,
                        selectedReportId: number,
                        selection: ReportFilterSelection,
                        csHttpRequestOptions: CsHttpRequestOptions): Observable<Result<HttpResponse<Blob>>>;

  abstract getHtmlReport(selectedCategoryId: number,
                         selectedReportId: number,
                         selection: ReportFilterSelection,
                         csHttpRequestOptions: CsHttpRequestOptions): Observable<Result<string>>;

}
