import { KeyValuePair }  from '../../../generate';
import { getPropertyOf } from '../../../utils';


export class DataLookup<TKey = unknown, TValue = unknown> {

	id: string;
	values: KeyValuePair<TKey, TValue>[];

	constructor(init: DataLookup<TKey, TValue>) {
		this.id     = getPropertyOf(init, 'id');
		this.values = getPropertyOf(init, 'values');
	}

}
