import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef }     from '@angular/core';
import { DataEntryStateStructure }                                           from '../../models/data-entry-state-structure';
import { DashboardPanelComponentBase }                                       from '@cs/components/shared';
import { ArrayUtils, DataDescribed }                                         from '@cs/core';
import { DataEntryStateDataDescribed, DataEntryStateDataDescribedConverter } from '../../data-entry-state-data-described.converter';

@Component({
						 selector:        'cs-dashboard-data-entry-state',
						 templateUrl:     './dashboard-data-entry-state.component.html',
						 changeDetection: ChangeDetectionStrategy.OnPush
					 })
export class DashboardDataEntryStateComponent extends DashboardPanelComponentBase<DataEntryStateDataDescribed> implements OnInit {

	static TYPE_NAME = 'data-entry-state';
	renderSchema: DataEntryStateStructure;

	constructor(private changeRef: ChangeDetectorRef) {super(); }

	ngOnInit(): void {
	}

	update(data: DataEntryStateDataDescribed): void {
		if (this.data && data && ArrayUtils.isEqual(this.data.data, data.data))
			return;

		this.data = data;
	}

	dataChanged(value: DataEntryStateDataDescribed) {
		this.renderSchema = DataEntryStateDataDescribedConverter.convert(this.data);
		this.changeRef.detectChanges();
	}


}
