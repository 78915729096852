import { Component }     from '@angular/core';
import { ControlWidget } from '../models/control-widget.directive';

@Component({
	selector: 'text-widget',
	template: `
				<div class="form-group">
					<textarea [name]="name"
						   class="form-control"
						   [formControl]="control"
						   [attr.id]="id"
						   [disabled]="readOnly"
						   [class.is-valid]="isValid"
						   [class.is-invalid]="isInValid"
						   [style.textAlign]="layout.textAlign"
                           [style.resize]="'vertical'"
                           rows="7"
                           [style.font-family]="'inherit'"

					></textarea>
					<div class="pm-form-element-invalid-feedback" *ngIf="errorMessages?.length> 0 ">
						<small class="{{error.type}}" *ngFor="let error of errorMessages">{{error.errorMessage}}</small>
					</div>
				</div>
						`
})

export class TextWidgetComponent<T> extends ControlWidget<T> {
}
