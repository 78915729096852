import { getPropertyOf, hasPropertyOf } from '@cs/core/utils';


export class ValidationResult {
	errorMessage: string;
	memberNames: string[];
	type: 'invalid' | 'warning';


	constructor(init: { [key: string]: any }) {

		// TOD0: REMOVE WHEN ROBERT IS DONE
		this.errorMessage = hasPropertyOf(init, 'ErrorMessage') ? init.ErrorMessage : init.errorMessage;
		this.memberNames  = hasPropertyOf(init, 'MemberNames') ? init.MemberNames : init.memberNames;
		this.type         = getPropertyOf(init, 'type', 'invalid');
	}
}
