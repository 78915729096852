import {
  Component, forwardRef, Input, OnInit, ViewChildren
}                                                               from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  CSFormGeneratorDataSourceLookupValues, CSFormGeneratorDataSourceTree,
  CSFormGeneratorDataSourceTreeMember
}                                                               from '../../form-generator.models';
import { isNullOrUndefined, isNumber, isString }                from '@cs/core';

export interface CsFormFieldTreeElementParams {
  height?: number;
  maxHeight?: number;
  isScrollable?: boolean;
  showAsHorizontalList?: boolean;
  gridColumns?: number;
  search?: { hasSearchBar?: boolean; };
}

@Component({
  selector:    'cs-form-field-tree',
  templateUrl: './form-field-tree.component.html',
  styles:      [`
                  label.btn.btn-checkbox.disabled,
                  label.btn.btn-radio.disabled {
                    background-color: #d3d3d3;
                    border-color: #B2BFC5;
                  }

                  .expand-icon {
                    opacity: 0.68;
                    transition: all 0.2s ease-out;
                    position: relative;
                    margin-right: 20px;
                    margin-top: 2px;
                    margin-left: auto;
                  }

                  .list-item:hover .expand-icon {
                    opacity: 1;
                  }

                  .expand-icon.open {
                    transform: rotate(-180deg);
                    transform-origin: center;
                  }



                `],
  providers:   [
    {
      provide:     NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CsFormFieldTreeComponent),
      multi:       true
    }
  ]
})
export class CsFormFieldTreeComponent implements OnInit, ControlValueAccessor {
  @Input() type: string;

  @Input() tree: CSFormGeneratorDataSourceTree;

  @Input() elementParameters: CsFormFieldTreeElementParams;
  @ViewChildren('radioButton') radioButtons;
  @ViewChildren('checkbox') checkboxes;

  fieldType: string;
  listType: string;
  listValue: Array<any>                                           = [];
  radiogroup: string                                              = Math.random().toString(36).slice(2); // random name for the radiobuttons "group"
  disabled                                                        = false;
  flatTreeList: Array<CSFormGeneratorDataSourceTreeMember>        = [];
  // for searching
  filteredTreeMembers: Array<CSFormGeneratorDataSourceTreeMember> = [];
  showSearchBar                                                   = true;

  private propagateChange: any   = (obj: any) => {
  };
  private onTouchedCallback: any = (obj: any) => {
  };
  private validateFn: any        = (fc: any) => {
  };

  get value(): any {
    return this.updateSelectedItems();
  }

  set value(value: any) {
    this.parseSelectedItems(value);
    this.propagateChange(this.value);
  }

  constructor() {
  }

  ngOnInit() {
    // Support both checkboxlist and radiogrid naming for old api
    const types       = this.type.match(/(\w+)(tree)$/);
    this.fieldType    = types[1];
    this.listType     = types[2];
    this.flatTreeList = this.flattenTree(this.tree.members);
    if (!isNullOrUndefined(this.elementParameters) && !isNullOrUndefined(this.elementParameters.search) && !isNullOrUndefined(this.elementParameters.search.hasSearchBar)) {
      this.showSearchBar = this.elementParameters.search.hasSearchBar;
    }
  }

  /**
   * Called by the form controls

   */
  onChange(data: CSFormGeneratorDataSourceTreeMember, itemIndex: number) {
    data.selected = !data.selected;

    this.toggleParent(data, itemIndex, (item: CSFormGeneratorDataSourceTreeMember) => {
      return item.selected;
    }, this.selectParentItem);
    this.toggleChild(data, data.selected, this.selectChildItem);

    this.propagateChange(this.value);
  }

  /**
   * Control value set by external Form Control

   */
  writeValue(data: string) {
    this.parseSelectedItems(data);
  }

  /**
   * From control state set by external form builder

   */
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  /**
   * Callback by which we let the external world know this form control value has changed.

   */
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  validate(fc: FormControl) {
    return this.validateFn(fc);
  }

  onListItemClicked(item: CSFormGeneratorDataSourceTreeMember) {
    if (item.depth === 1) {
      item.collapsed = !item.collapsed;
      this.toggleChild(item, item.collapsed, this.hideChildItem);
    }
  }

  onLabelClick(key, isRadio) {
    if (isRadio) {
      let radioButton = this.radioButtons.find(el => el.nativeElement.value === key.toString());
      radioButton.nativeElement.click();
      return;
    } else {
      let checkbox = this.checkboxes.find(el => el.nativeElement.name === key.toString());
      checkbox.nativeElement.click();
      return;
    }
  }

  onUserSearchInput(searchInput: string) {
    this.filterFlatTree(this.flatTreeList, searchInput);
  }

  private flattenTree(members: Array<CSFormGeneratorDataSourceTreeMember>, depth: number = 0) {
    let output: Array<CSFormGeneratorDataSourceTreeMember> = [];
    // create random id
    const groupId                                          = Math.random().toString(36).substr(2, 10);
    depth++;

    for (let index = 0; index < members.length; index++) {
      const item = members[index];
      item.depth = depth;
      item.group = groupId;
      if (depth === 1) {
        this.onListItemClicked(item);
      }
      item.lookupKey = Math.random().toString(36).substr(2, 10);
      output.push(item);
      if (item.hasOwnProperty('members')) {
        output.push(...this.flattenTree(item.members, depth));
      }
    }
    return output;
  }

  private filterFlatTree(members: Array<CSFormGeneratorDataSourceTreeMember>, query = '') {
    // local search only
    let regex = new RegExp(query, 'i');
    let copy  = members.map(x => Object.assign({}, x));

    members.forEach((item) => {
      const text  = (isNullOrUndefined(item.label) && isString(item.label)) ? item.label : item.label.toString();
      item.hidden = !text.match(regex);
    });

    members.forEach((item, idx) => {
      this.toggleParent(item, idx, (x: CSFormGeneratorDataSourceTreeMember) => {
        return !x.hidden;
      }, this.showParentItem);
    });

    return;
  }

  private toggleParent(data: CSFormGeneratorDataSourceTreeMember, itemIndex: number,
                       groupCondition: (item: CSFormGeneratorDataSourceTreeMember) => boolean,
                       execute: (previousItem: CSFormGeneratorDataSourceTreeMember, selectedGroupItems: CSFormGeneratorDataSourceTreeMember[]) => void) {
    if (data.depth > 0) {
      const groupItems         = this.flatTreeList.filter(item => item.group === data.group);
      const selectedGroupItems = groupItems.filter(item => groupCondition(item));

      for (let index = itemIndex; index > -1; index--) {
        const previousItem = this.flatTreeList[index];
        if (previousItem.depth === (data.depth - 1)) {
          execute(previousItem, selectedGroupItems);
          this.toggleParent(previousItem, index, groupCondition, execute);
          break;
        }
      }
    }
  }

  private toggleChild(data: CSFormGeneratorDataSourceTreeMember, toggleValue: boolean,
                      condition: (item: CSFormGeneratorDataSourceTreeMember, toggleValue: boolean) => void) {
    if (data.hasOwnProperty('members')) {
      for (let member of data.members) {
        condition(member, toggleValue);
        if (data.hasOwnProperty('members')) {
          this.toggleChild(member, toggleValue, condition);
        }
      }
    }
  }

  private updateSelectedItems() {
    const output = {};
    for (let index = 0; index < this.tree.levels.length; index++) {
      const level       = this.tree.levels[index];
      output[level.key] = this.flatTreeList.filter(item => item.selected && item.depth === (index + 1)).map(item => item.id);
    }
    return output;
  }

  private parseSelectedItems(value: any) {
    for (let index = 0; index < this.tree.levels.length; index++) {
      const level    = this.tree.levels[index];
      const keyArray = value[level.key];
      if (isNullOrUndefined(keyArray) || keyArray.length === 0) {
        continue;
      }
      const depthList = this.flatTreeList.filter(item => item.depth === (index + 1));
      for (const selectedId of keyArray) {
        const found = depthList.find(item => item.id === selectedId);
        if (!isNullOrUndefined(found)) {
          found.selected   = true;
          const foundIndex = this.flatTreeList.findIndex(item => item.lookupKey === found.lookupKey);
          // this.toggleParent(found, foundIndex, (item: CSFormGeneratorDataSourceTreeMember) => {
          //   return item.selected;
          // }, this.selectParentItem);
          // this.toggleChild(found, found.selected, this.selectChildItem);
        }
      }
    }
  }


  private selectParentItem(previousItem: CSFormGeneratorDataSourceTreeMember, selectedGroupItems: CSFormGeneratorDataSourceTreeMember[]) {
    previousItem.selected = selectedGroupItems.length > 0;
  }

  private showParentItem(previousItem: CSFormGeneratorDataSourceTreeMember, selectedGroupItems: CSFormGeneratorDataSourceTreeMember[]) {
    // parentItem can be in search: un-hide only hidden parent items
    previousItem.hidden = previousItem.hidden && selectedGroupItems.length > 0 ? false : previousItem.hidden;
  }

  private selectChildItem(item: CSFormGeneratorDataSourceTreeMember, toggleValue: boolean) {
    item.selected = toggleValue;
  }

  private hideChildItem(item: CSFormGeneratorDataSourceTreeMember, toggleValue: boolean) {
    item.hidden = toggleValue;
  }

  getSelectedItem() {
    return this.flatTreeList.filter(item => item.selected).length;
  }
}
