import { ServerSideFilter, ServerSidePaging, ServerSideSorting } from '../data-described/server-side-filter.model';
import { getPropertyOf, hasPropertyOf }                          from '@cs/core/utils';
import { TableLayoutAnnotation }                                 from './table-layout-annotation.model';
import { TableHeaderRowAnnotation }                              from './table-header-row-annotation.model';
import { TableHeaderAnnotation }                                 from './table-header-annotation.model';
import { TableAction }                                           from './table-action.model';


export class TableAnnotation<T> {
	/**
	 * Provide the selected pageSize
	 */
	pageSize?: number;

	pageSizes?: Array<number>;

	enablePaging?: boolean;

	showPagination?: boolean;

	hideRowCount?: boolean;

	enableServerSidePaging: ServerSidePaging;

	serverSideFilter: ServerSideFilter;  //why is this not assigned in ctor? --jv

	serverSideSorting: ServerSideSorting; //why is this not assigned in ctor? --jv

	updateDataOnly: boolean;

	isSortable: boolean;

	/**
	 * Indicates parser should preserve the data order when sorting. Default is (false) which is the original (unpredictable) sort, for backward compatibility.
	 */
	preserveDataOrderWhenGrouping: boolean;
	isReadOnly: boolean;


	constructor(init: Partial<TableAnnotation<T>>) {
		this.id     = getPropertyOf(init, 'id', null);
		this.label  = getPropertyOf(init, 'label', '');
		this.layout = hasPropertyOf(init, 'layout')
									? new TableLayoutAnnotation(init.layout)
									: new TableLayoutAnnotation({});

		this.headers = hasPropertyOf(init, 'headers')
									 ?
									 init.headers.map(header => new TableHeaderAnnotation<T>(header))
									 : [];

		this.headerRows = hasPropertyOf(init, 'headerRows')
											?
											init.headerRows.map(hRow => new TableHeaderRowAnnotation(hRow))
											: [];

		this.actions = hasPropertyOf(init, 'actions')
									 ?
									 init.actions.map(hRow => new TableAction(hRow))
									 : [];

		this.enablePaging                  = getPropertyOf(init, 'enablePaging', true);
		this.showPagination                = getPropertyOf(init, 'showPagination', true);
		this.hideRowCount                  = getPropertyOf(init, 'hideRowCount', true);
		this.updateDataOnly                = getPropertyOf(init, 'updateDataOnly', false);
		this.pageSize                      = getPropertyOf(init, 'pageSize', 25);
		this.isSortable                    = getPropertyOf(init, 'isSortable', false);
		this.isReadOnly                    = getPropertyOf(init, 'isReadOnly', true);
		this.enableServerSidePaging        = getPropertyOf(init, 'enableServerSidePaging', null);
		this.pageSizes                     = getPropertyOf(init, 'pageSizes', [10, 25, 50, 100]);
		this.preserveDataOrderWhenGrouping = getPropertyOf(init, 'preserveDataOrderWhenGrouping', false);
	}

	/**
	 * Identification of the Table
	 */
	id: string;
	/**
	 * Name to show in the UI
	 */
	label?: string;

	/**
	 * The headers of @Link(TableHeaderAnnotation) to define the columns of the table
	 */
	headers?: TableHeaderAnnotation<T>[] = [];

	/**
	 * Defines the header rows, could be used for multiple headers
	 */
	headerRows?: TableHeaderRowAnnotation[] = [];

	/**
	 * Settings for the Table
	 */
	layout?: TableLayoutAnnotation;

	actions?: TableAction[];
}
