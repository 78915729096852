import { TableDataCell }      from './table-data-cell.model';
import { TableDataDescribed } from './table-data-described.model';
import { TableDataRowLayout } from './table-data-row.model';
import {
	isNullOrUndefined,
	isEmptyObject,
	LoggerUtil,
	findByIdObject
}                             from '@cs/core/utils';
import { DataGroup }          from './data-described-data-group.model';

export function generateIdObject(dataRow: any, keyNames: Array<string>): { [key: string]: string | number } {
	try {
		return keyNames.reduce((previousValue, currentValue) => {
			const obj         = {};
			obj[currentValue] = dataRow[currentValue];
			return Object.assign(previousValue, obj);
		}, {});

	} catch (ex) {
		if (isNullOrUndefined(keyNames) || keyNames.length === 0) {
			LoggerUtil.error('There is no keyNames provided');
			return {};
		}
		if (isNullOrUndefined(dataRow)) {
			LoggerUtil.error('There is no dataRow provided');
			return {};
		} else if (Array.isArray(dataRow)) {
			LoggerUtil.error('DataRow provided is an array, should be an single object');
			return {};
		}
	}

}

/**
 * generate a model with values from the row definitions
 */
export function generateObject<T>(row: TableDataRowLayout, dataDescribed: TableDataDescribed<any>,
																	key: keyof TableDataCell       = 'value',
																	keymapper: Map<string, number> = null): T {
	try {

		let data = dataDescribed.data;
		if (Array.isArray(dataDescribed.data)) {
			if (isEmptyObject(row.id))
				data = dataDescribed.data[keymapper.get(row.rowId)];
			else if (!isNullOrUndefined(row.id.group))
				data = findByGroupObject(dataDescribed.dataGroups, row.id);
			else
				data = findByIdObject(dataDescribed.data, row.id);
		}
		return <T>dataDescribed.dataAnnotation.fields.reduce((previousValue, currentValue) => {
			const obj            = <T>{};
			// Find the cell for this field
			const found          = row.cells.find(c => c.id === currentValue.id);
			// Get the value from the cells. If not there use the data object from described
			obj[currentValue.id] = isNullOrUndefined(found)
														 ? isNullOrUndefined(data)
															 ? currentValue.defaultCellValue
															 : data[currentValue.id]
														 : found[key];
			return Object.assign(previousValue, obj);
		}, {});

	} catch (ex) {
		if (isNullOrUndefined(dataDescribed)) {
			LoggerUtil.error('There is no DataDescribed provided');
			return null;
		}
		if (isNullOrUndefined(row)) {
			LoggerUtil.error('There is no dataRow provided');
			return null;
		}
	}

}


export function findByGroupObject(groups: DataGroup<any>[], id: { [key: string]: string | number }) {
	const group = groups.find(value => value.groupBy === id.group);
	return group.values.find(value => value[id.group] === id[id.group]);
}
