import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {isNullOrUndefined} from '@cs/core';

@Component({
  selector: 'cs-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (!isNullOrUndefined(this.data)) {
      this.data.message = (<string>this.data.message).replace(/\n/g, '<br />');
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }

}
