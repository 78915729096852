import {
	AfterViewInit,
	ChangeDetectionStrategy, ChangeDetectorRef, Component,
	EventEmitter, Input, OnChanges, OnInit, Output, QueryList, ViewChildren
}                                             from '@angular/core';
import { WizardStepComponent }                from '../wizard-step/wizard-step.component';
import { ComponentChanges, gv, whenChanging } from '@cs/core';
import { Step, StepState }                    from '../../models/step';

@Component({
						 selector:        'cs-wizard-stepper',
						 templateUrl:     './wizard-stepper.component.html',
						 styleUrls:       ['./wizard-stepper.component.scss'],
						 changeDetection: ChangeDetectionStrategy.OnPush,
						 exportAs:        'wizardStepper'
					 })
export class WizardStepperComponent implements OnInit,
																							 AfterViewInit,
																							 OnChanges {

	@Input() steps: Array<Step>;
	@Output() stepChanged: EventEmitter<Step> = new EventEmitter<Step>();
	@ViewChildren(WizardStepComponent) stepComponents: QueryList<WizardStepComponent>;


	get isFirstStep(): boolean {

		return gv(() => this.activeStep.index) === 1;
	}

	get isLastStep(): boolean {

		return gv(() => this.activeStep.index) === this.steps.length;
	}

	constructor(private readonly changeRef: ChangeDetectorRef) {
	}

	ngAfterViewInit(): void {

	}

	ngOnInit(): void {
	}

	ngOnChanges(changes: ComponentChanges<WizardStepperComponent>): void {

		whenChanging(changes.steps, true)
			.execute(value => {
				this.steps = value.currentValue
													.map((step, index) => {
														step.index = index + 1;
														return step;
													});

				this.setFirstStepActive();
			});

	}

	setStepActive(step: Step) {

		this.activeStep       = this.steps.find(value => step.name === value.name);
		this.activeStep.state = 'isActive';

		if (this.currentStepIndex > 0)
			this.steps[this.previousStepIndex].state = 'isChecked' as StepState;

		if (this.nextStepIndex < this.steps.length)
			this.steps[this.nextStepIndex].state = 'isDisabled' as StepState;

		this.stepChanged.emit(this.activeStep);
	}

	navigateBack() {
		this.setStepActive(this.steps[this.previousStepIndex]);
	}

	navigateNext() {
		this.setStepActive(this.steps[this.nextStepIndex]);
	}

	identify(index: number, item: Step) {
		return item.name;
	}

	private get currentStepIndex(): number {
		return this.steps.findIndex(value => value.name === this.activeStep.name);
	}

	private get nextStepIndex(): number {
		return this.currentStepIndex + 1 > this.steps.length
					 ? this.steps.length
					 : this.currentStepIndex + 1;
	}

	private get previousStepIndex(): number {
		return this.currentStepIndex - 1 < 0
					 ? 0
					 : this.currentStepIndex - 1;
	}

	private activeStep: Step;

	private setFirstStepActive() {
		if (this.steps && this.steps.length > 0)
			this.setStepActive(this.steps[0]);
	}
}
