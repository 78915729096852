import { animate, state, style, transition, trigger }                                  from '@angular/animations';
import { Component, EventEmitter, Inject, Injector, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DashboardPanelInfoIcon }                                                      from '../models/dashboard-panel-info-icon';
import { AllDashboardPanelType }                                                       from '../models/dashboard-models';
import { ComponentChanges, PropertyAnnotation, whenChanging }                          from '@cs/core';
import { IDashboardPanel }                                                             from '../models/i-dashboard-panel';
import {
	DashboardPanelSettingEventArgs
}                                                                                      from '../models/dashboard-panel-setting-event-args';

import { IDashboardPanelComponent }          from '../models/i-dashboard-panel-component';
import { FormatProviderService }             from '@cs/common';
import { DashboardEventHub }                 from '../dashboard-event-hub.service';
import { DashboardPanelOptions }             from '../models/dashboard-panel-options';
import { DashboardComponent }                from '../dashboard.component';
import { TranslateService }                  from '@ngx-translate/core';
import { getUIPlaceholder, isUIPlaceholder } from '@cs/performance-manager/shared';

@Component({
						 selector:    'cs-dashboard-panel',
						 templateUrl: './dashboard-panel.component.html',
						 styleUrls:   ['./dashboard-panel.component.scss'],
						 animations:  [
							 trigger('loadingIconState', [
								 state('clicked', style({
																					filter:        'blur(3px)',
																					opacity:       0.66,
																					pointerEvents: 'none'
																				})),
								 state('done', style({
																			 filter:  'blur(0)',
																			 opacity: 1
																		 })),
								 transition('clicked <=> done',
														animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)')),

								 state('loader', style({
																				 opacity: 1
																			 })),

								 transition('* => loader', [
									 style({opacity: 0}),
									 animate('400ms 100ms cubic-bezier(0.55, 0, 0.55, 0.2)')
								 ]),

								 transition('loader => done', [
									 style({opacity: 0}),
									 animate('400ms 100ms cubic-bezier(0.55, 0, 0.55, 0.2)')
								 ])

							 ])
						 ]
					 })
export class DashboardPanelComponent implements OnInit,
																								OnChanges {

	@Input() panel: AllDashboardPanelType;
	@Input() panels: Array<AllDashboardPanelType>;

	/**
	 * A panel could have multiple views that could be selected by the PanelViewOption
	 */
	@Output() panelOptionSelected = new EventEmitter<DashboardPanelSettingEventArgs>();

	constructor(private formatService: FormatProviderService,
							private eventHub: DashboardEventHub,
							private injector: Injector) {
	}

	ngOnChanges(changes: ComponentChanges<DashboardPanelComponent>): void {
		whenChanging(changes.panels, true)
			.execute(value => {
				const panels = value.currentValue;
				this.panel   = panels[0];
			});
	}

	ngOnInit(): void {
	}

	/**
	 * Detects if the header is truncated and if so add an tooltip
	 */
	detectTruncatedField($event: MouseEvent, panel: AllDashboardPanelType) {
		const element = $event.currentTarget as HTMLElement;

		function isEllipsisActive(e) {
			return (e.offsetWidth < e.scrollWidth);
		}


		panel.isTruncated = isEllipsisActive(element);


	}

	public replaceSpaces(tooltip: string): string {
		return tooltip.replace(/<br[^>]*>/g, '\n');
	}

	public verifyTranslation(text: string): string {
		const i18nApp = this.injector.get(TranslateService, null);
		return isUIPlaceholder(text)
					 ? getUIPlaceholder(text, i18nApp)
					 : text;
	}

	getIcon(icon: DashboardPanelInfoIcon) {

		let output = '';

		switch (icon.icon.toLowerCase()) {
			case 'FilterWarning'.toLowerCase():
				output = 'cs-icon-18px cs-icon-filter-warning info-icon ';
				break;
			case 'CertaintyWarning'.toLowerCase():
				output = 'mdi mdi-18px mdi-alert-outline info-icon warning ';
				break;
			case 'Info'.toLowerCase():
				output = 'mdi mdi-18px mdi-information-outline info-icon ';
				break;
			case 'DownloadAsExcel'.toLowerCase():
				output = 'mdi mdi-18px mdi-download info-icon ';
				break;
			case 'ShowDetails'.toLowerCase():
				output = 'mdi mdi-18px mdi-magnify-plus-outline info-icon ';
				break;
			case 'AddNew'.toLowerCase():
				output = 'mdi mdi-18px mdi-plus-circle-outline info-icon ';
				break;
			case 'ExpandPanel'.toLowerCase():
				output = 'mdi mdi-18px mdi-arrow-expand-horizontal info-icon ';
				break;
			case 'Print'.toLowerCase():
				output = 'mdi mdi-printer-outline hover-pointer ';
				break;
		}
		return output + this.getIconType(icon.type);
	}


	getButtonIcon(iconBtn: DashboardPanelInfoIcon) {
		let output = '';

		switch (iconBtn.icon.toLowerCase()) {
			case 'FilterWarning'.toLowerCase():
				output = 'cs-icon-filter-warning hover-help ';
				break;
			case 'CertaintyWarning'.toLowerCase():
				output = 'mdi  mdi-alert-outline hover-help warning';
				break;
			case 'Info'.toLowerCase():
				output = 'mdi mdi-information-outline hover-help ';
				break;
			case 'DownloadAsExcel'.toLowerCase():
				output = 'mdi mdi-download hover-pointer ';
				break;
			case 'ShowDetails'.toLowerCase():
				output = 'mdi mdi-magnify-plus-outline hover-pointer ';
				break;
			case 'AddNew'.toLowerCase():
				output = 'mdi mdi-plus hover-pointer ';
				break;
			case 'Print'.toLowerCase():
				output = 'mdi mdi-printer-outline hover-pointer ';
				break;
			case 'Check'.toLowerCase():
				output = 'mdi mdi-checkbox-marked-outline hover-pointer ';
				break;
		}
		return output;
	}

	getIconType(type: string) {
		switch (type) {
			case 'warning':
				return 'text-warning';
			case 'danger':
				return 'text-danger';
			case 'info':
				return 'text-info';
			case 'download':
				return 'download';
			case 'primary':
				return 'primary';
			default:
				return 'default';
		}
	}

	/**
	 * Return the class for the button type
	 * @param type the type of the button
	 */
	getButtonType(type: string) {
		switch (type) {
			case 'primary':
				return 'primary';
			case 'small':
				return 'default btn--sm';
			default:
				return 'default';
		}
	}


	getCurrentValue(panel: IDashboardPanel, optionList: PropertyAnnotation<unknown>) {

		let result;
		if (!optionList)
			return;

		if (optionList.lookup) {
			// @ts-ignore
			result = panel.options.resolveValueWithLookup(panel.options.data[optionList.id], optionList.lookup);
			if (result === null) {
				return '';
			}
		} else {
			result = (<any>panel.options).data[optionList.id];
		}

		result = this.formatService.formatByPropertyAnnotation(optionList, result);

		return result;
	}

	getLookup(panel: IDashboardPanel, lookup: string) {
		try {
			return panel.options.getLookup(lookup).values;
		} catch (e) {
			return [];
		}
	}


	panelOptionClicked(key: string | number | string | Date | ArrayBufferView | ArrayBuffer | IDBArrayKey | ((index: number) => (string | null)),
										 panel: AllDashboardPanelType, optionList: PropertyAnnotation<unknown>) {
		const host = this.eventHub.getParent() as DashboardComponent;
		host.panelOptionClicked(key, panel, optionList);
	}

	trackComponent(component: IDashboardPanelComponent<any>, panel: IDashboardPanel) {
		// is depricated in favor for the DashboardOptions
		component.name = panel.name;

		// TODO: Move to dashboard manager
		this.eventHub.registerPanelComponent(component);
	}

	metaButtonClicked(iconBtn: DashboardPanelInfoIcon, panel: AllDashboardPanelType) {
		const host = this.eventHub.getParent() as DashboardComponent;
		host.metaButtonClicked(iconBtn, panel);
	}

	switchPanel(panel: AllDashboardPanelType) {
		this.panel = panel;
	}
}
