import { Size, SizeUnit }                                                    from '@cs/core/generate';
import { DataEntryVisualState }                                              from './models/data-entry-state-visual-state';
import {
	DataDescribedArray,
	DataDescribedConverter,
	DataStructure,
	DataStructureGroup,
	DataStructureGroups,
	DataStructureRow,
	DataViewColumn,
	DataViewRow,
	generateQuickGuid, gv,
	IDataConverterOptions,
	isObject
}                                                                            from '@cs/core';
import {
	DataEntryStateColumn,
	DataEntryStateColumnType,
	DataEntryStateHeaderColumn,
	DataEntryStateHeaderPlaceholderColumn,
	DataEntryStateHeaderVerticalColumn,
	DataEntryStateIndicator,
	DataEntryStateIndicatorColumn,
	DataEntryStateLabelColumn,
	DataEntryStateRow,
	DataEntryStateStatus,
	DataEntryStateStatusColumn, DataEntryStateStatusWithLabelColumn,
	DataEntryStateTextColumn
}                                                                            from './models';
import { DataEntryStateStructure, DataEntryHeaderTypes, DataEntryDataTypes } from './models/data-entry-state-structure';

export interface DataEntryStateDataDescribedLayoutMapping {
	badges: string;
	text: string;
	tooltip: string;
}

export interface DataEntryStateDataDescribedLayoutMappingStickyHeader {
	left: SizeUnit<number, string>;
	name: string;
}

export class DataEntryStateDataDescribedLayoutHeader {
	name: string;
	sticky?: boolean;
	left?: SizeUnit<number, string>;
	alignment?: 'center' | 'left' | 'right' = 'left';
}

export class DataEntryStateDataDescribedLayout {
	mapping: DataEntryStateDataDescribedLayoutMapping;
	headers: Array<DataEntryStateDataDescribedLayoutHeader>;
}

export type DataEntryStateDataDescribed = DataDescribedArray<any, DataEntryStateDataDescribedLayout>;


/**
 * Parser and converter for based on the DataDescribed method. allows to render component specific structures
 */
export class DataEntryStateDataDescribedConverter extends DataDescribedConverter<any, DataEntryStateDataDescribedLayout> {

	private static _instance: DataEntryStateDataDescribedConverter;
	private column_keys_cache: Array<string>;
	private column_matched_filter_cache: Map<string, Array<string>> = new Map<string, Array<string>>();

	static convert(data: DataEntryStateDataDescribed,
								 options?: IDataConverterOptions): DataEntryStateStructure {
		if (this._instance == null)
			this._instance = new DataEntryStateDataDescribedConverter();

		return this._instance.convert(data, options);
	}

	convert(data: DataEntryStateDataDescribed,
					options: IDataConverterOptions): DataEntryStateStructure {

		return super.convert(data, options) as DataEntryStateStructure;
	}

	protected createStructure(data: DataDescribedArray<any, DataEntryStateDataDescribedLayout, any>,
														options: IDataConverterOptions): DataStructure<IDataConverterOptions> {
		return new DataEntryStateStructure({
																				 groupOrderColumns: this.getColumnGroupOrder(data),
																				 groupOrderRows:    this.getRowGroupOrder(data)
																			 }, options);
	}

	protected createHeaderRows(structure: Readonly<DataStructure>,
														 groupOrderColumns: string[],
														 data: DataDescribedArray<any, DataEntryStateDataDescribedLayout, any>
	): DataViewRow<DataViewColumn<unknown, unknown, unknown, string>, unknown>[] {
		const headerRows                           = [];
		const headerStructure: DataStructureGroups = structure.headerStructure;

		const appendRow = (rows: Array<DataViewRow<DataViewColumn<unknown, unknown, unknown, string>, unknown>>,
											 pathSegments: DataStructureGroup[],
											 depth: number,
											 path: string[], isHeaderDepth: boolean) => {

			const columns = [];
			const nextRow = [];
			const rowId   = groupOrderColumns[depth];

			path.push(rowId);
			const countExpectedChildren = 0;

			for (const headerSegment of pathSegments) {

				// const headerKind = headerSegment.type === 'DataGridAutoGroupColumn'
				// 									 ? DataGridGroupHeaderCell
				// 									 : DataGridHeaderCell;

				const label = structure.lookups.get(headerSegment.levelKey.toString())
															 .values
															 .find(
																 value => value.key === headerSegment.levelValue.toString());

				const field = data.dataAnnotation.fields.find(value => value.id === headerSegment.levelValue);


				const defaultHeader = {
					value:      label.value.toString(),
					levelValue: headerSegment.levelValue,
					levelKey:   headerSegment.levelKey,
					valueType:  headerSegment.type,
					key:        headerSegment.key,
					path:       headerSegment.path
				};

				const defaultVisual: DataEntryVisualState = {
					headerType: 'horizontal',
					visible:    true
				};

				if (field) {
					const layoutHeader         = gv(() => data.layout.headers.find(value => value.name === field.id), null);
					defaultVisual.visible      = !(field.visible === false);
					defaultVisual.width        = gv(() => field.size.width, SizeUnit.Empty);
					defaultVisual.cellTemplate = this.parseCellTemplate(field.cellTemplate);
					defaultVisual.sticky       = gv(() => layoutHeader.sticky, false);
					defaultVisual.alignment    = gv(() => layoutHeader.alignment, 'left');
				}

				let column = new DataEntryStateHeaderColumn({...defaultHeader, visualState: defaultVisual});

				if (field && isHeaderDepth) {
					if (field.template) {
						switch (field.template as DataEntryHeaderTypes) {
							case 'vertical-header': {
								column = new DataEntryStateHeaderVerticalColumn(
									{
										...defaultHeader, visualState: {
											...defaultVisual,
											headerType: 'vertical'
										}
									});
								break;
							}
						}
					}
				} else {
					// create placeholders for the headers that fill the ungrouped cells
					if (headerSegment.type === 'DataStructurePlaceholder')
						column = new DataEntryStateHeaderPlaceholderColumn({...defaultHeader, visualState: {...defaultVisual, placeholder: true}});
				}

				if (column == null)
					throw new Error('No column created');

				columns.push(column);

			}

			const row = new DataEntryStateRow({
																					index:   depth,
																					columns: columns,
																					path:    path.concat([])
																				});

			rows.push(row);

		};

		const levels = structure.groupOrderColumns.length;

		const path = [];
		for (let depth = 0; depth < levels; depth++) {

			const isHeaderDepth = depth === levels - 1;
			const header        = isHeaderDepth
														? DataStructure.getHeadStructureLastNodes(headerStructure)
														: DataStructure.getHeadStructureAtDepth(headerStructure, depth, true);

			appendRow(headerRows, header, depth, path, isHeaderDepth);
		}

		return headerRows;
	}

	private parseCellTemplate(cellTemplate: string): DataEntryStateColumnType {
		switch (cellTemplate) {
			case 'data-entry-state-indicator': {
				return DataEntryStateColumnType.DataEntryState;
			}
			case 'data-entry-state-icon-label':
				return DataEntryStateColumnType.StatusWithLabel;
			case 'data-entry-state-icon': {
				return DataEntryStateColumnType.Status;
			}
			case 'label-expand': {
				return DataEntryStateColumnType.Label;
			}
			default: {
				return DataEntryStateColumnType.Text;
			}
		}
	}

	protected createDataRow(dataRow: DataStructureRow, path: string[], index: number, rowKey: { [p: string]: any },
													headerRow: DataViewRow<DataViewColumn<unknown, unknown, unknown>, unknown>,
													structure: Readonly<DataStructure>,
													data: Readonly<DataEntryStateDataDescribed>): DataViewRow<DataViewColumn<unknown, unknown, unknown>, unknown> {

		const columns                     = [];
		const groupOrderColumns: string[] = structure.groupOrderColumns;

		const mapping = {badges: null};

		rowKey = dataRow.keys;

		for (const header of headerRow.columns) {
			const dataEntryHeader: DataEntryStateHeaderColumn = header as DataEntryStateHeaderColumn;
			const field                                       = data.dataAnnotation.fields.find(fieldItem => fieldItem.id === header.levelValue);

			if (data.layout.mapping) {
				const isPropertyForBadges = field.id.toString()
																				 .match(data.layout.mapping.badges);
				if (isPropertyForBadges)
					mapping.badges = header.structureKey;
			}
			// when there is no data row provided create a empty one
			const dataColumn = dataRow.columns.get(header.structureKey);

			const defaultHeader = {

				id:         generateQuickGuid(),
				levelValue: header.levelValue,
				levelKey:   header.levelKey,
				valueType:  header.valueType,
				key:        dataColumn
										? dataColumn.keys
										: rowKey,
				path:       path
			};

			const defaultVisual: DataEntryVisualState = {
				visible: true
			};
			const columnValue                         = dataColumn
																									? dataColumn.value
																									: null;
			if (field) {

				defaultVisual.visible   = !(field.visible === false);
				defaultVisual.sticky    = dataEntryHeader.visualState.sticky;
				defaultVisual.alignment = dataEntryHeader.visualState.alignment;
			}
			let column: DataEntryStateColumn = new DataEntryStateTextColumn({
																																				...defaultHeader,
																																				value:       columnValue
																																										 ? columnValue.toString()
																																										 : '',
																																				visualState: {...defaultVisual}
																																			});

			if (field) {


				let lookedUpValue = columnValue;

				if (field.lookup) {
					const found = structure.lookups.get(column.structureKey)
																 .values
																 .find(
																	 value => value.key === columnValue);

					lookedUpValue = found
													? found.value
													: columnValue;
				}


				if (field.cellTemplate) {
					switch (field.cellTemplate as DataEntryDataTypes) {
						case 'data-entry-state-indicator': {
							const indicator = Object.assign({}, lookedUpValue) as DataEntryStateIndicator;

							if (isObject(lookedUpValue)) {
								indicator.text  = this.getValueFromDataRow(column.structureKey, data.layout.mapping.text, dataRow);
								indicator.label = this.getValueFromDataRow(column.structureKey, data.layout.mapping.tooltip, dataRow);
							}

							column = new DataEntryStateIndicatorColumn(
								{
									...defaultHeader,
									visualState: {...defaultVisual},
									value:       indicator
								});
							break;
						}
						case DataEntryDataTypes.DATA_ENTRY_STATE_ICON_LABEL: {
							const indicator = Object.assign({}, lookedUpValue) as DataEntryStateStatus;

							if (isObject(lookedUpValue)) {
								indicator.text  = this.getValueFromDataRow(column.structureKey, data.layout.mapping.text, dataRow);
								indicator.label = this.getValueFromDataRow(column.structureKey, data.layout.mapping.tooltip, dataRow);
							}
							column = new DataEntryStateStatusWithLabelColumn(
								{
									...defaultHeader,
									visualState: {...defaultVisual},
									value:       indicator
								});
							break;
						}
						case DataEntryDataTypes.DATA_ENTRY_STATE_ICON: {
							const indicator = Object.assign({}, lookedUpValue) as DataEntryStateStatus;

							if (isObject(lookedUpValue)) {
								indicator.label = this.getValueFromDataRow(column.structureKey, data.layout.mapping.tooltip, dataRow);
							}
							column = new DataEntryStateStatusColumn(
								{
									...defaultHeader,
									visualState: {...defaultVisual},
									value:       indicator
								});
							break;
						}
						case 'label-expand': {
							column = new DataEntryStateLabelColumn(
								{
									...defaultHeader,
									visualState: {...defaultVisual},
									value:       lookedUpValue.toString()
								});
							break;
						}
						default: {
							column = new DataEntryStateTextColumn(
								{
									...defaultHeader,
									visualState: {...defaultVisual},
									value:       lookedUpValue.toString()
								});
							break;
						}
					}
				}

				// Default type of column
				if (column === null)
					column = new DataEntryStateTextColumn(
						{
							...defaultHeader,
							visualState: {...defaultVisual},
							value:       lookedUpValue.toString()
						});

			}

			if (column == null)
				throw new Error('No column created');

			columns.push(column);
		}


		const row = new DataEntryStateRow({
																				badges:  dataRow.columns.has(mapping.badges)
																								 ? dataRow.columns.get(mapping.badges).value as string[]
																								 : [],
																				index:   index,
																				columns: columns,
																				path:    path,
																				key:     rowKey
																			});

		return row;
	}

	private getValueFromDataRow<T>(field: string, match: string, dataRow: DataStructureRow): T {
		if (this.column_keys_cache == null)
			this.column_keys_cache = Array.from(dataRow.columns.keys());

		let columnKey = [];

		if (this.column_matched_filter_cache.has(match)) {
			columnKey = this.column_matched_filter_cache.get(match);
		} else {
			columnKey = this.column_keys_cache
											.filter(value => value.match(match));
			this.column_matched_filter_cache.set(match, columnKey);
		}

		const propertyStructureKey = columnKey.find(value => value.startsWith(field));

		return dataRow.columns.has(propertyStructureKey)
					 ? dataRow.columns.get(propertyStructureKey)
						 .value as T
					 : null;
	}


}
