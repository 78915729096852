export class AdvancedDropdownItem {
	label: string                         = null;
	identifier: string                    = null;
	params: any                           = null;
	selected: boolean                     = false;
	displayType: 'Default' | 'DatePicker' = 'Default';
	disabled: boolean                     = false;
	isLast                                = false;
	isTruncated: boolean                  = false;
	/**
	 *  indicates item is highlighted by keyboard navigation
	 */
	highlighted: boolean                  = false;
	/**
	 * Shows a description about the item
	 */
	description: string;
	/**
	 * Flag indicating that the feature is a teaser.
	 */
	isTeaser: boolean;

	constructor(data: Partial<AdvancedDropdownItem>) {
		Object.assign(this, data);
	}

	clone() {
		return new AdvancedDropdownItem(this);
	}
}
