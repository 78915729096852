import {
	Component, OnInit, OnDestroy,
	ChangeDetectorRef,
	ChangeDetectionStrategy,
	AfterViewInit,
	ViewChild
}                                                                     from '@angular/core';
import { Subscription }                                               from 'rxjs';
import {
	FilterCompareBarQuery,
	FilterCompareBarService, CsToastManagerService
}                                                                     from '@cs/components';
import { isNullOrUndefined }                                          from '@cs/core';
import { TranslateService }                                           from '@ngx-translate/core';
import { filter as filter$, map }                                     from 'rxjs/operators';
import { UntilDestroy, untilDestroyed }                               from '@ngneat/until-destroy';
import { FilterAndNavbarShellConfigService }                          from '@cs/performance-manager/filter-and-navbar-shell';
import { DomSanitizer, SafeHtml } 																		from '@angular/platform-browser';
import { ShellConfigService } 																				from '@cs/performance-manager/shell';
import { HttpClient } 																								from '@angular/common/http';
import { SafeMethods } 																								from '@cs/common';



@UntilDestroy()
@Component({
	selector:        'pmc-iframe-teaser',
	templateUrl:     './iframe-teaser.component.html',
	styleUrls:       ['./iframe-teaser.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IframeTeaserComponent implements OnInit, OnDestroy, AfterViewInit {

	@ViewChild('iframe', {static: false}) iframe: HTMLIFrameElement;

	private navbarChangeSubscription: Subscription;

	private bodyClasses: Array<string> = ['bg-app-background'];

	srcUrl: SafeHtml;

	constructor(protected readonly toastService: CsToastManagerService,
							readonly filterCompareBarQuery: FilterCompareBarQuery,
							readonly filterCompareBarService: FilterCompareBarService,
							private readonly filterAndNavbarShellConfigService: FilterAndNavbarShellConfigService,
							public readonly changeRef: ChangeDetectorRef,
							protected readonly i8n: TranslateService,
							private sanitizer: DomSanitizer,
							private readonly shellConfig: ShellConfigService,
							private http: HttpClient) {
	}

	ngOnInit() {
		this.filterAndNavbarShellConfigService.setBodyClasses(this.bodyClasses);
		this.shellConfig.enableFooter = false;

		//Avoid some error on load
		this.srcUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');

		this.navbarChangeSubscription = this.filterCompareBarService.filterbarNavigationRequested.subscribe(value => {
			const {pageType, pageName, url} = value;
			if(pageType === "TeaserPage") {
				this.srcUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
				SafeMethods.detectChanges(this.changeRef);
			}
		});
	}

	ngAfterViewInit(): void {
		this.filterCompareBarQuery.select(store => store.mainbarResultParams)
				.pipe(
					untilDestroyed(this),
					filter$(value => !isNullOrUndefined(value))
				)
				.subscribe((value: any) => {
					const {pageType, pageName, url} = value;
					if(pageType === "TeaserPage") {
						var test = url;
						this.srcUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
						SafeMethods.detectChanges(this.changeRef);
					}
				});
	}

	ngOnDestroy() {
		if (!isNullOrUndefined(this.navbarChangeSubscription))
			this.navbarChangeSubscription.unsubscribe();

		this.filterAndNavbarShellConfigService.cleanBodyClasses(this.bodyClasses);
		this.shellConfig.enableFooter = true;
	}


}

