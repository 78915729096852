import {Type} from '@angular/core';

export class ErrorMessage {

  static MAPPING_BUSINESS_MODEL_TO_APPLICATION_MODEL_FAILED<T>(businessModelName: Type<T>, propertyNotFoundError: Error) {
    return `⛔ The mapping from business model to the application model: ${businessModelName.name}
     failed, because ${propertyNotFoundError.message}`;
  }

  static NOT_FOUND(objectDesrc: string) {
    return `${objectDesrc} is not found, please check`;
  }
}
