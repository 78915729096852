import { getPropertyOf }              from '../../../utils';
import { DataKey, generateQuickGuid } from '../../../generate';
import { DataViewColumn }             from './data-view-column';

export type DataViewRowInit<TColumn extends DataViewColumn<unknown, unknown, unknown>, TRowType> =
	Pick<DataViewRow<TColumn, TRowType>, 'columns' | 'index' | 'path' | 'key'>;

export abstract class DataViewRow<TColumn extends DataViewColumn<unknown, unknown, unknown>, TRowType> {
	abstract type: TRowType;
	id: string;
	index: number;
	width: number;
	columns: Array<TColumn>;
	path: string[];
	key?: DataKey;

	constructor(init: DataViewRowInit<TColumn, TRowType>) {
		this.columns = init.columns;
		this.id      = generateQuickGuid();
		this.path    = init.path;
		this.index   = init.index;
		this.key     = getPropertyOf(init, 'key', {});

	}

}
