import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { Repository } from './models/Repository';
import { GitGraphConfigService } from './git-graph-config.service';
import { Commit } from './models/Commit';

import { CommitOptions, createGitgraph } from "@gitgraph/js"; // import javascript library
import { GitgraphUserApi, BranchUserApi, GitgraphBranchOptions } from '@gitgraph/core';
import { createForeignObject, createG, createText } from '@gitgraph/js/lib/svg-elements'

import { UnexpectedResponseException } from 'pdfjs-dist';

import { CsHttpRequestOptions, isNull, isNullOrUndefined, Result } from '@cs/core';

import { CsToastManagerService, FilterBarResultParamsSelection, FilterCompareBarQuery, FilterCompareBarStore } from '@cs/components';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { interval, Observable, of } from 'rxjs';
import { filter as filter$ } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ProcessProgressInfo } from './models/ProcessProgressInfo';
import { formatDate } from '@angular/common';
import { SafeMethods } from '@cs/common';


@UntilDestroy()
@Component({
  selector: 'pmc-git-graph',
  templateUrl: './git-graph.component.html',
  styleUrls: ['./git-graph.component.scss']
})
export class GitGraphComponent implements OnInit, AfterViewInit {


  //TODO: use Renderer2 instead of ViewChild
  @ViewChild('graphOutput', { static: false }) graphOutput: ElementRef<HTMLElement>;
  @ViewChild('graphTestOutput', { static: false }) graphTestOutput: ElementRef<HTMLElement>;

  public statusLog: string = "StatusLog...";


  public progressInfo?: ProcessProgressInfo = new ProcessProgressInfo();

  constructor(@Inject(GitGraphConfigService) private service: GitGraphConfigService,
    @Inject(FilterCompareBarQuery) private filterCompareBarQuery: FilterCompareBarQuery,
    @Inject(FilterCompareBarStore) private filterCompareBarStore: FilterCompareBarStore,
    @Inject(CsToastManagerService) private toastService: CsToastManagerService,
    private changeRef: ChangeDetectorRef,
    @Inject(LOCALE_ID) private _locale: string
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    // this.filterCompareBarStore.reset();
    this.filterCompareBarQuery.select(store => store.mainbarResultParams)
      .pipe(
        untilDestroyed(this),
        filter$(value => !isNullOrUndefined(value))
      )
      .subscribe((resultParams) => {
        console.log(resultParams);
        this.UpdateGraph(resultParams.selection);
      });


      interval(5000).subscribe( () => {
        this.UpdateProgressInfo();
      })
  }

  private UpdateGraph(resultParams: FilterBarResultParamsSelection) {
    const mainFilter = 'submoduleIdentifier';
    var mainFilterValue = resultParams[mainFilter];

    if (isNull(mainFilterValue) || mainFilterValue.length == 0)
      return;

    // 418 status code is handled in module loader
    this.service.getAnnotatedSubmoduleTree(mainFilterValue).subscribe(
      data => {
        if (data.isSuccess)
          this.drawGraph(this.graphOutput.nativeElement, data.value);
        else
          this.drawGraph(this.graphOutput.nativeElement, null);
      }
    )
  }

  private UpdateProgressInfo() {
    this.service.GetProcessProgressInfo().subscribe(
      data => {
        if (data.isSuccess)
          this.progressInfo = data.value;
        else
          this.progressInfo = null;

        SafeMethods.detectChanges(this.changeRef);
      }
    )
  }


  /**
   * Draws the git graph using the gitGraphJS UserApi
   * @param element
   * @param repository
   */
  private drawGraph(element: HTMLElement, repository: Repository) {

    //clear placeholder text
    element.innerText = '';

    //Note: horizontal orientation is only supported WITHOUT commit messages
    var gitgraph = createGitgraph(element, {
      author: "none",
      branchLabelOnEveryCommit: false
    });

    // Quirck: Main branch "MUST" have a name for branching from parentHash to work
    const mainBranch = gitgraph.branch("A");

    //Keep track of all hashes and commits and branches
    //GitJS has limited user API...
    var hashMap = new Map<string, CommitReference>();

    // The last commit is the root node
    for (var commit of repository.commits.reverse()) {
      if (hashMap.size == 0) {
        //First commit
        //we do not have the parent hashes in the hashMap yet, just add it
        this.AddCommitToBranch(mainBranch, commit, repository.branch);

        hashMap.set(commit.hashOrBranch, { branch: mainBranch, hascommit: false });
      } else {

        //get the first valid parent, sometimes a merge parent is missing because we are not pulling in the entire history
        var existingParentHashes = commit.parentHash.filter(x => hashMap.has(x));       

        //assert that parent exists
        if (existingParentHashes.length == 0)
          throw new UnexpectedResponseException(`Commit ${commit.hashOrBranch} is missing a parenthash. Expected to find one or more of ${commit.parentHash} in hashMap`, "error");

       
        var isMerge = existingParentHashes.length > 1;    

        var parentHash = existingParentHashes[0];
        var parentRef = hashMap.get(parentHash);

        if (!isMerge) {
          // Regular commit
          if (parentRef.hascommit) {
            //branching commit (fork)

            console.debug(`Branch from ${parentHash}`);

            var newBranch = gitgraph.branch({
              name: "B",
              from: parentHash
            });

            this.AddCommitToBranch(newBranch, commit, repository.branch);

            //Add it to the hashMap so we can lookup the branch later
            hashMap.set(commit.hashOrBranch, { branch: newBranch, hascommit: false });

          } else {
            //regular commit
            this.AddCommitToBranch(parentRef.branch, commit, repository.branch);

            parentRef.hascommit = true;
            //Add it to the hashMap so we can lookup the branch later
            hashMap.set(commit.hashOrBranch, { branch: parentRef.branch, hascommit: false });
          }
        } else {
          //Merge commit

          //start counting at 1 and keep merging into the targetBranch
          for (var i = 1; i < existingParentHashes.length; i++) {
            var mergeRef = hashMap.get(existingParentHashes[i]);
            this.AddMergeCommitToBranch(parentRef, mergeRef, commit);
          }
          //Add it to the hashMap so we can lookup the branch later
          hashMap.set(commit.hashOrBranch, { branch: parentRef.branch, hascommit: false });
        }

      }
    }


  }



  /**
   * Returns list of merge target that exist in hashMap
   * @param commit
   * @param existingParentHash
   * @param hashMap
   */
  FindMergeTargets(commit: Commit, existingParentHash: string, hashMap: Map<string, CommitReference>): CommitReference[] {
    var output = [];

    for (var i = 0; i < commit.parentHash.length; i++) {
      if (commit.parentHash[i] == existingParentHash)
        continue;

      var mergeTarget = hashMap.get(commit.parentHash[i]);

      // merge into merge target
      if (mergeTarget != undefined)
      {
        output.push(mergeTarget);
      }
    }

    return output;
  }


  /**
   * Add Commit to BranchUserApi
   * @param mainBranch
   * @param commit
   * @param branchName optional branch name of the super project
   */
  private AddCommitToBranch(mainBranch: BranchUserApi<SVGElement>, commit: Commit, branchName?: string) {

    // Commit with special renderer
    var branch = mainBranch.commit({
      subject: `${commit.message}`,
      hash: commit.hashOrBranch,
      author: commit.author,
      renderMessage: this.renderMessageFunction(commit),
      renderTooltip: this.renderToolTipFunction(commit)
    });


    // Add refs (branch names) to commit.
    // Could not find a way to add branch names after rendering commits
    for(var ref of commit.refNames)
    {
      branch.tag(ref);
    }

  }

  /**
   * Merges parent commit into mergeTarget
   * @param parentCommit
   * @param mergeTarget
   * @param commit
   */
  private AddMergeCommitToBranch(parentCommit: CommitReference, mergeTarget: CommitReference, commit: Commit) {
    var branch = parentCommit.branch.merge(
      {
        branch: mergeTarget.branch,
        commitOptions: {
          hash: commit.hashOrBranch,
          subject: commit.message,
          author: commit.author,
          renderMessage: this.renderMessageFunction(commit),
          renderTooltip: this.renderToolTipFunction(commit)
        }
      });

    // Add refs (branch names) to commit.
    // Could not find a way to add branch names after rendering commits
    for(var ref of commit.refNames)
    {
      branch.tag(ref);
    }

  }

  /**
   * Returns a function that gitgraph uses to render the commit
   * @param commitData
   * @returns
   */
  private renderMessageFunction(commitData: Commit): CommitOptions["renderMessage"] {
    //NOTE: the space available seems fixed per commit, text might overlap
    var elements = this.RepositoryDetails(commitData.repositories);

    // Type is a gitgraphjs/Commit
    return (commit: any) => {

      return createG({
        translate: { x: 0, y: commit.style.dot.size*2 },
        children: [
          createText({
            fill: commit.style.dot.color,
            content: `${commit.hashAbbrev} ${formatDate(commitData.date, 'longDate', this._locale)} - ${commit.subject}`,
          }),
          createG({
            translate: { x: commit.style.dot.size, y: commit.style.dot.size*2 },
            children: elements
          }),
        ],
      });
    };
  }


  /**
   * Returns a function that GitGraphJS uses internally to render the commit tooltip
   * Still WIP, does not show...
   * @param commitData
   * @returns
   */
  private renderToolTipFunction(commitData: Commit): CommitOptions["renderTooltip"] {
    // Type is a gitgraphjs/Commit
    return (commit: any) => {
      const commitSize = commit.style.dot.size * 2;

      return createG({
        translate: { x: commitSize + 10, y: commitSize / 2 },
        children: [
          createText({
            translate: { x: 40, y: 15 },
            fill: commit.style.dot.color,
            content: `${commit.hashAbbrev} - ${commit.subject}`,
          }),
        ],
      });
    };
  }


  /**
   * Renders repositories as multiline text
   * @param repositories
   * @param indent
   * @returns
   */
  private RepositoryDetails(repositories: Repository[], indent: number = 0): SVGElement[] {

    const showCommitDetails = true;
    var elements: SVGElement[] = [];

    const indentOffset: number = 25;
    const textHeight: number = 17; // WHY do we need to set this manually??
    const groupVOffset: number = 7;
    const groupIndent: number = 17;

    const font: string = 'normal 16pt Calibri';
    const fontHeader: string = 'bold 16pt Calibri';

    for (var repository of repositories) {
      // Repository: name (branch)
      var contentStr = `+ ${repository.name} ${repository.branch ? `(${repository.branch})` : ""}`;
      elements.push(
        createText({ translate: { x: 0, y: textHeight * elements.length }, content: contentStr, font: fontHeader })
      );

      // Commits
      for (var commit of repository.commits) {
        if (showCommitDetails) {
          // Commit
          var commitsBehindStr = this.commitsBehindToStr(commit.commitsBehind);
          var commitStr = `- ${commitsBehindStr}: ${commit.message} ${formatDate(commit.date, 'longDate', this._locale)}`;
          elements.push(
            createText({ translate: { x: indentOffset, y: textHeight * elements.length }, content: commitStr, font: font })
          );
        }

        // Recurse into next repository
        if (commit.repositories.length > 0)
        {
          var repoElements = this.RepositoryDetails(commit.repositories, indent + 1);
          elements.push(
            createG({ translate: { x: groupIndent, y: textHeight * elements.length + groupVOffset }, children: repoElements })
          );
        }
      }
    }

    return elements;
  }

  private commitsBehindToStr(commitsBehind: { [key:string]: number | null }): string
  {
    //Example: 10↓M

    var behind: string[] = [];
    for(let [key, value] of Object.entries(commitsBehind)) {
        behind.push(`${value}↓${key[0].toUpperCase()}`);
    }

    return behind.join(" ");
  }


  private drawTestGraph(element: HTMLElement) {
    //clear placeholder text
    element.innerText = '';
    var gitgraph = createGitgraph(element);

    //const master = gitgraph.branch("master").commit("Initial commit");

    const master = gitgraph.branch("master").commit(
      {
        hash: "test1",
        subject: "hash test 1"
      }
    );

    master.commit({
      hash: "inBetween",
      subject: "hash in between"
    });

    // gitgraph.tag({
    //   name: "inBetween"
    //   , ref: "master"
    // });


    var newBranch = gitgraph.branch({
      from: "test1",
      name: "another branch"
    });

    newBranch.commit("my new commit");

    // const develop = gitgraph.branch("develop");
    // var c1 = develop.commit("one");
    // master.commit("two");
    // develop.commit("three");
    // master.merge(develop);

  }


}

export class CommitReference {
  branch: BranchUserApi<SVGElement>;
  hascommit: boolean;
};


