import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CommonModule }                        from '@angular/common';

import { ResetPasswordRoutingModule }                         from './reset-password-routing.module';
import { StepOneComponent }                                   from './step-one/step-one.component';
import { FormsModule }                                        from '@angular/forms';
import { MatTooltipModule }                                   from '@angular/material/tooltip';
import { PasswordChangeComponent }                            from './password-change/password-change.component';
import { CsApplicationSettings }                              from '@cs/performance-manager/shared';
import { ResetPasswordConfigService }                         from './reset-password-config.service';
import { HttpClient }                                         from '@angular/common/http';
import { IConfigService }                                     from '@cs/performance-manager/shared';
import { CsTranslationLoader }                                from '@cs/performance-manager/shared';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { LoggerUtil }                             from '@cs/core';
import { CsCommonPipesModule, CsCultureProvider } from '@cs/common';
import { CsSharedModule }                         from '@cs/components';

export function resetPasswordTranslationLoader(client: HttpClient,
																							 pmAppSettings: CsApplicationSettings,
																							 service: IConfigService) {
	return new CsTranslationLoader(`${pmAppSettings.translationLocation}/${service.moduleName}`, client, null,
		pmAppSettings.deploymentDetails.buildDate);
}

@NgModule({
	imports:      [
		CommonModule,
		FormsModule,
		TranslateModule.forChild({
			loader:  {
				provide:    TranslateLoader,
				useFactory: resetPasswordTranslationLoader,
				deps:       [HttpClient, CsApplicationSettings, ResetPasswordConfigService]
			},
			isolate: true
		}),
		MatTooltipModule,
		ResetPasswordRoutingModule,
		CsCommonPipesModule,
		CsSharedModule
	],
	declarations: [StepOneComponent, PasswordChangeComponent]
})
export class PmResetPasswordModule {
	static forRoot(moduleConfig: Type<ResetPasswordConfigService>,
								 appSetting: CsApplicationSettings): ModuleWithProviders<PmResetPasswordModule> {
		return {
			ngModule:  PmResetPasswordModule,
			providers: [
				{
					provide:  ResetPasswordConfigService,
					useClass: moduleConfig
				},
				{
					provide:  CsApplicationSettings,
					useValue: appSetting
				}
			]
		};
	}

	constructor(translate: TranslateService, culture: CsCultureProvider) {

		if (!translate) {
			LoggerUtil.warn('Translation service is not set-up');
			return;
		}

		// this language will be used as a fallback when a translation isn't found in the current language for now hardcoded en
		translate.setDefaultLang(culture.getDefaultCulture());

		// the lang to use, if the lang isn't available, it will use the current loader to get them for now hardcoded en
		translate.use(culture.getCulture());
	}
}
