import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild }   from '@angular/core';
import { CsFormGeneratorComponent, CsFormGeneratorDataSource, CsToastManagerService } from '@cs/components';
import { UserProfileConfigService }                                                   from './user-profile-config.service';
import { HttpErrorResponse }                                                          from '@angular/common/http';
import { ToastService }                                                               from '@cs/performance-manager/shared';
import { CsHttpRequestOptions }                                                       from '@cs/core';
import { UserPersonalProfile }                                                        from './model/user-personal-profile';
import { UserLocationInfo }                                                           from './model/user-location-info';
import { simpleFadeInOut }                                                            from '@cs/common';
import { AuthenticationConfigService }                                                from '@cs/performance-manager/shared';

@Component({
	selector:        'pmc-user-profile',
	templateUrl:     './user-profile.component.html',
	styleUrls:       ['./user-profile.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations:      [
		simpleFadeInOut('isLoadingContent')
	]
})
export class UserProfileComponent implements OnInit {

	profileForm: CsFormGeneratorDataSource  = null;
	accountForm: CsFormGeneratorDataSource  = null;
	locationForm: CsFormGeneratorDataSource = null;

	isLoadingContent        = false;
	changePasswordAvailable = false;

	constructor(private config: UserProfileConfigService,
							private changeRef: ChangeDetectorRef,
							private toast: CsToastManagerService,
							public readonly authenticationConfig: AuthenticationConfigService) {
	}

	ngOnInit() {
		this.hasPlatformPasswordRecoveryAvailable();
		this.getAccountInfo();
		this.getPersonalInfo();
		this.getLocationInfo();
	}

	postAccountInfo(data: any): void {
		const accountData            = Object.assign({}, this.accountForm.data, data);
		const options                = new CsHttpRequestOptions();
		options.errorResponseHandler = (error) => this.handleSaveFactsFailure(error);

	}

	postPersonalInfo(data: UserPersonalProfile): void {
		this.config.updatePersonalInfo(data)
				.subscribe((x) => this.toast.show({type: 'success', content: 'Profile updated successfully.'}));
	}

	postLocationInfo(data: UserLocationInfo): void {
		this.config.updateLocationInfo(data)
				.subscribe((x) => this.toast.show({type: 'success', content: 'Location info updated successfully'}));
	}

	/**
	 * Handles the error response returned by dataEntryApi.dataentrygridsNameSavefactsPost
	 * @param response The response containing the failure reason
	 */
	handleSaveFactsFailure(response: HttpErrorResponse): boolean {
		if (response.status === 403) {

			const obj: any = JSON.parse(response.message);

			// Display warning about invalid input (as soon as toasts work)
			this.toast.show({type: 'warning', title: response.statusText, content: obj.messages.join('. ')});

			return true; // Response is regarded as handled
		}
		return false;
	}

	/**
	 * Handles the error response
	 * @param response The response containing the failure reason
	 */
	handlGetAccountInfoFailure(response: HttpErrorResponse): boolean {
		if (response.status === 405) {
			this.changePasswordAvailable = false;
			return true; // Response is regarded as handled
		}
		return false;
	}

	private getAccountInfo() {
		this.isLoadingContent        = true;
		const options                = new CsHttpRequestOptions();
		options.errorResponseHandler = (error) => this.handlGetAccountInfoFailure(error);

		this.config.getAccountInfo(options).subscribe(result => {
			const data = result.value;

			this.accountForm      = data;
			this.isLoadingContent = false;
			this.changeRef.markForCheck();
		});
	}

	private getPersonalInfo() {
		this.config.getPersonalInfo().subscribe(result => {
			this.profileForm = result.value;
			this.changeRef.markForCheck();
		});
	}

	private getLocationInfo() {
		this.config.getLocationInfo().subscribe(result => {
			this.locationForm = result.value;
			this.changeRef.markForCheck();
		});
	}

	private hasPlatformPasswordRecoveryAvailable() {
		const options                = new CsHttpRequestOptions();
		options.errorResponseHandler = (error) => this.handlGetAccountInfoFailure(error);
		this.config.hasPlatformPasswordRecoveryAvailableForCurrentAccount(options)
				.subscribe(value => this.changePasswordAvailable = value.value);
	}
}
