import { Component, Input, OnChanges, OnInit }      from '@angular/core';
import { ComponentChanges, whenChanging }           from '@cs/core';
import { AllDashboardPanelType, RenderOrientation } from '../models/dashboard-models';


@Component({
						 selector:    'cs-dashboard-panel-renderer',
						 templateUrl: './dashboard-panel-renderer.component.html',
						 styleUrls:   ['./dashboard-panel-renderer.component.css']
					 })
export class DashboardPanelRendererComponent implements OnInit,
																												OnChanges {
	/**
	 * Renders the panels in a specific way
	 */
	@Input() renderOrientation: RenderOrientation = 'STACKED';
	/**
	 * The panel that needs to be rendered
	 */
	@Input() panels: Array<AllDashboardPanelType>;
	trackByPanelName                              = (index: number, item: AllDashboardPanelType) => item.name;

	constructor() {

	}

	ngOnInit(): void {
	}

	ngOnChanges(changes: ComponentChanges<DashboardPanelRendererComponent>): void {

	}

}
