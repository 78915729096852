import { Component }                from '@angular/core';
import { DomSanitizer, SafeHtml }   from '@angular/platform-browser';
import { DashboardHtml }            from '../../models/dashboard-html';
import { IDashboardPanelComponent } from '../../models/i-dashboard-panel-component';
import { ArrayUtils }               from '@cs/core';
import { DashboardEventHub }        from '../../dashboard-event-hub.service';

@Component({
	selector:    'cs-dashboard-empty',
	templateUrl: './dashboard-empty.component.html',
	styleUrls:   ['./dashboard-empty.component.scss']

})
export class DashboardEmptyComponent implements IDashboardPanelComponent<DashboardHtml> {

	name: string;

	set data(value: DashboardHtml) {
		this._data = value;
		this.setData();
	}

	/**
	 * The data source that contains the values of the statistics panel.
	 */
	_data: DashboardHtml;

	safeHtml: SafeHtml;

	constructor(private sanitizer: DomSanitizer, private hub: DashboardEventHub) {
	}

	setData(): void {
		this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(this._data.html);
	}

	update(data: DashboardHtml): void {
		if (this.data && ArrayUtils.isEqual(this.data, data))
			return;

		this.data = data;
		this.setData();
	}
}
