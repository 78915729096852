import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CsHttpRequestOptions, LoggerUtil }        from '@cs/core';
import { MatTooltip }                              from '@angular/material/tooltip';
import { LoginConfigService }                      from '../login-config.service';
import { isNullOrUndefined }                       from '@cs/core';
import { LoginService }                            from '../state/login.service';
import { LoginQuery }                              from '../state/login.query';
import { merge, Observable, Subject }              from 'rxjs';
import { ActivatedRoute }                          from '@angular/router';
import { AuthenticationQuery }                     from '@cs/performance-manager/shared';
import { UntilDestroy, untilDestroyed }            from '@ngneat/until-destroy';
import { CsToastManagerService }                   from '@cs/components';
import { TranslateService }                        from '@ngx-translate/core';
import { LoginModel }                              from '../models/accountInfo';


@UntilDestroy()
@Component({
	selector:    'pmc-default',
	templateUrl: './default.component.html'
})
export class DefaultComponent implements OnInit, OnDestroy {

	/**
	 * Remember the username
	 */
	rememberMe = false;

	$inProgress: Observable<boolean>;
	$isErrorState: Observable<boolean>;
	$turnOnHandler: Observable<boolean>;

	loginModel: LoginModel = {googleCaptchaResponse: '', username: '', password: ''};

	get showResetPassword() {
		return this.loginModel.forgotPasswordAvailable;
	}

	@ViewChild(MatTooltip) tooltip: MatTooltip;

	turnLoginButtonSubject: Subject<boolean> = new Subject<boolean>();
	tooltipText: string;

	constructor(
		protected loginService: LoginService,
		protected loginQuery: LoginQuery,
		public loginConfig: LoginConfigService,
		private authQuery: AuthenticationQuery,
		protected route: ActivatedRoute,
		protected toastService: CsToastManagerService,
		protected l8n: TranslateService
	) {

		loginService.setTitleMessage(loginConfig.loginWelcomeMessage);
		loginService.setUnderTitleMessage('Please log in using your account.');
		loginQuery.select(store => store.rememberMe)
							.pipe(untilDestroyed(this))
							.subscribe(value => {
								this.rememberMe = value;
							});
		this.$inProgress    = loginQuery.select(store => store.inProgress);
		this.$isErrorState  = loginQuery.select(store => store.inErrorState);
		this.$turnOnHandler = merge(
			this.turnLoginButtonSubject.asObservable(),
			authQuery.select(store => store.userProfile === null))
			.pipe(untilDestroyed(this));
	}

	ngOnInit() {
		const loginState = this.loginQuery.getValue();
		this.loginService.setInProgress(false);
		const rememberMe = loginState.rememberMe;
		const username   = loginState.username;

		if (rememberMe) {
			this.loginModel.username = username;
			this.loginService.setRememberMe(true);
		}
	}

	login() {
		if (this.loginQuery.getValue().inProgress) {
			LoggerUtil.log('Already in progress');
			return;
		}

		this.loginService.setErrorState(false);
		this.loginService.setInProgress(true);

		const errorHandler                = new CsHttpRequestOptions();
		errorHandler.errorResponseHandler = error => {
			this.loginService.setInProgress(false);
			this.turnLoginButtonSubject.next(false);

			switch (error.status) {
				case 401:
				case 500:
					let message = '';
					try {
						// we might received more useful messages in the body
						const result = JSON.parse(error.message);
						if (result.hasOwnProperty('messages')) {
							message = (<string[]>result.messages).join(' ');
						}
					} catch (e) {
						if (error.error && error.error.hasOwnProperty('messages'))
							message = error.error.messages.join(' ');
						else if (error.error)
							message = error.error;
						else if (error.status === 401) {
							this.loginService.setErrorState(true);
							setTimeout(() => {
								this.tooltip.show();
							}, 200);
							return true;
						} else if (error.status === 500) {
							return false;
						}

					}
					this.toastService.show({
						type:            'warning',
						content:         message,
						showProgressBar: false,
						clickToClose:    true
					});
					return true;
			}

			return false;
		};

		this.loginConfig.login(this.loginModel.username, this.loginModel.password, errorHandler).subscribe(value => {
			// stop animation
			this.loginService.setInProgress(false);
			this.loginConfig.loginSuccessHandler(value, this.route);
		});

		this.loginService.setUserName(this.loginModel.username);
		this.loginService.setRememberMe(this.rememberMe);

	}

	resetErrorState() {
		if (this.loginQuery.getValue().inErrorState) {
			this.loginService.setErrorState(false);
		}
	}


	/**
	 * Error show on login fail
	 */
	setTooltipText() {
		let output = '';

		if (this.loginModel == null)
			return;

		if (!this.loginModel.username || this.loginModel.username.length < 1)
			output = 'Please provide username';
		else if (this.loginModel.requiresPassword && (!this.loginModel.password && this.loginModel.password.length < 1))
			output = 'Please provide password';

		this.tooltipText = output;
	}

	ngOnDestroy(): void {
	}
}
