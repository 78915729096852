import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { DashboardCombiData }                    from '../../models/dashboard-combi-data';
import { IDashboardPanelComponent }              from '../../models/i-dashboard-panel-component';
import { PopoverRef }                            from '@cs/components/popover';
import { ArrayUtils }                            from '@cs/core';


@Component({
	selector:    'cs-dashboard-legend',
	templateUrl: './dashboard-legend.component.html',
	styles:      [
		` ul {
		padding-left: 0;
		margin-bottom: 0;
	}

	li {
		list-style: none;
		margin-bottom: 0.5em;
	}

	li a {
		margin-right: 16px;
	}

	.datablock-with-content {
		height: 25px;
		width: 25px;
		border-radius: 6px;
		font-size: 14px;
	}

	.header {
		text-align: left;
		border-right: none;
		font-weight: 700;
		color: inherit;
	}
		`
	]
})
export class DashboardLegendComponent implements OnInit, IDashboardPanelComponent<DashboardCombiData> {

	name: string;
	data: DashboardCombiData;

	legendData: DashboardCombiData;

	constructor(@Inject(forwardRef(() => PopoverRef)) popover: PopoverRef<DashboardCombiData>) {
		this.legendData = popover.data;
	}

	ngOnInit() {
	}

	update(data: DashboardCombiData): void {
		if (this.data && ArrayUtils.isEqual(this.data, data))
			return;

		this.data = data;
	}

}
