import { Component }         from '@angular/core';
import { FileControlWidget } from '../models/file-control-widget.directive';

@Component({
	selector: 'file-widget',
	template: `
				<div class="form-group">
					<input [name]="name" class="form-control"
						   type="file"
						   (change)="onFileChange($event)"
						   [formControl]="control"
						   [attr.id]="id"
						   [disabled]="readOnly"
						   [class.is-valid]="isValid"
						   [class.is-invalid]="isInValid"
					>
					<div class="pm-form-element-invalid-feedback" *ngIf="errorMessages?.length> 0 ">
						<small class="{{error.type}}" *ngFor="let error of errorMessages">{{error.errorMessage}}</small>
					</div>
				</div>
						`
})

export class FileWidgetComponent<T> extends FileControlWidget<T> {


}
