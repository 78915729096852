import { LoginComponent }              from './login.component';
import { DefaultComponent }            from './default/default.component';
import { CsRoute }                     from '@cs/common';
import { DataDescribedLoginComponent } from './data-described/data-described-login.component';
import { AutoLoginComponent }          from './auto-login';
import { IsLoggedInGuard }             from '@cs/performance-manager/shared';


export class LoginRoutingLoader {
	static getRoutes(children: CsRoute[]): CsRoute[] {
		return [
			{
				path:      '',
				component: LoginComponent,
				children:  [
					{
						path:      '',
						component: DefaultComponent
					},
					...children
				]
			}
		];
	}

	static getRoutesDataDescribed(children: CsRoute[]): CsRoute[] {
		return [
			{
				path:      '',
				component: LoginComponent,
				children:  [
					{
						path:      '',
						component: DataDescribedLoginComponent
					},
					{
						path:        'external',
						component:   AutoLoginComponent,
						pathMatch:   'full'
					},
					...children
				]
			}
		];
	}
}
