import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output, Type
}                                                         from '@angular/core';
import { CsValidatorRegistry, IButton }                   from '@cs/components';
import { animate, state, style, transition, trigger }     from '@angular/animations';
import { BottomBarRequestActions, BottomBarService }      from './state/bottom-bar.service';
import { BottomBarQuery }                                 from './state/bottom-bar.query';
import { Observable }                                     from 'rxjs';
import { DynamicButton }                                  from '../pm-dynamic-button-bar/models/dynamic-button';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DynamicButtonRegistration, DynamicButtonsAgent } from '../pm-dynamic-button-bar/dynamic-button-bar.agent';
import { isNullOrUndefined }                              from '@cs/core';
import { DynamicButtonBarClickedEventArgs }               from '../pm-dynamic-button-bar/dynamic-button-bar.event-args';
import { MatDialog } from '@angular/material/dialog';
import { CsButtonBarInputOptions }                        from './cs-button-bar.models';
import { FormControl, ValidatorFn }                       from '@angular/forms';

@UntilDestroy()
@Component({
  selector:    'cs-button-bar',
  templateUrl: './cs-button-bar.component.html',
  styleUrls:   ['./cs-button-bar.component.scss'],
  animations:  [
    trigger('slideInOut', [
      state('false', style({
        transform: 'translateY(80px)'
      })),
      state('true', style({
        transform: 'translateY(0)'
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class CsButtonBarComponent implements OnInit, OnDestroy, OnChanges {

  /**
   * The data source that contains the values of the statistics panel.
   */
  @Input() dynamicButtons: Array<IButton> = [];

  @Input() useRowLabel = false;

  /**
   * Number of unsaved cells
   */
  @Input() nrUnsavedCells = 0;

  /**
   * Flag to disable the save button
   */
  @Input() disableSave = false;

  /**
   * flag that the save has a explanation
   */
  @Input() hasInputOptions: null | CsButtonBarInputOptions = null;

  @Output() onDynamicButtonClicked: EventEmitter<DynamicButtonBarClickedEventArgs> = new EventEmitter<DynamicButtonBarClickedEventArgs>();

  registeredDynamicButtons: DynamicButtonRegistration[];
  isOpen$: Observable<boolean>;
  canSave$: Observable<boolean>;
  inputRequired$: Observable<boolean> = this.bottomBarQuery.select(store => store.inputRequired);

  private disableEnter     = false;
  reasonField: FormControl = new FormControl('');

  constructor(private bottomBarService: BottomBarService,
              private bottomBarQuery: BottomBarQuery,
              @Optional() @Inject(DynamicButtonsAgent)
              private dynamicPageButtonAgent: DynamicButtonsAgent,
              private csValidatorRegistry: CsValidatorRegistry,
              public injector: Injector,
              private dialog: MatDialog) {

    this.setupNumberOfChangesListener();
    this.setupRowLabelListener();
    this.setupInputOptionAndValidatorListeners();
    this.setupRequestActionListener();
  }

  /**
   * Setup a subscription that listens for various @Link(BottomBarRequestActions)
   */
  private setupRequestActionListener() {
    this.bottomBarService.requestAction
        .pipe(untilDestroyed(this))
        .subscribe(value => {
          switch (value.action) {
            case BottomBarRequestActions.RESET_BAR:
              this.resetButtonBar();
              break;

          }
        });
  }

  /**
   * Setup  a subscription that listens if the changes needs or could to be save with a comment,
   * and which validations there should be applied
   */
  private setupInputOptionAndValidatorListeners() {
    this.bottomBarQuery.select(store => store.inputOptions)
        .pipe(untilDestroyed(this))
        .subscribe(value => {
          this.hasInputOptions = value;
        });

    this.bottomBarQuery.select(store => store.inputValidators).subscribe(value => {
      value ? this.reasonField.setValidators(value.reduce((previousValue, currentValue) => {
        previousValue.push(...currentValue.validatorFunc());
        return previousValue;
      }, []) as ValidatorFn[]) : this.reasonField.clearValidators();
    });

  }

  private setupRowLabelListener() {
    this.bottomBarQuery.select(store => store.isRowLabel)
        .pipe(untilDestroyed(this))
        .subscribe(value => {
          this.useRowLabel = value;
        });
  }

  private setupNumberOfChangesListener() {
    this.bottomBarQuery.select(store => store.unsavedChanges)
        .pipe(untilDestroyed(this))
        .subscribe(value => {
          this.nrUnsavedCells = value;
        });
  }

  ngOnChanges(changes: any) {
    this.registeredDynamicButtons =
      this.dynamicPageButtonAgent.getButtons();

    if (changes.hasOwnProperty('dynamicButtons')) {
      if (!isNullOrUndefined(this.dynamicButtons)) {
        this.registeredDynamicButtons = [
          ...this.dynamicButtons.map(x => {
            const btn = new DynamicButton(x);
            return this.dynamicPageButtonAgent.createServerProvidedButton(btn, this.dialog);
          }), ...this.dynamicPageButtonAgent.getButtons()];
      }
    }
  }

  ngOnInit() {
    this.isOpen$  = this.bottomBarQuery.select(store => store.isOpen);
    this.canSave$ = this.bottomBarQuery.select(store => store.dataIsValid);

    this.bottomBarQuery.select(store => store.useEnterToSave)
        .pipe(untilDestroyed(this)).subscribe(value => this.disableEnter = value);

    this.reasonField.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.bottomBarService.inputValue(value);
    });
  }


  @HostListener('document:keydown', ['$event'])
  onKeyUp(ev: KeyboardEvent) {

    // pressed enter
    if (ev.which !== 13 || this.disableEnter)
      return;


    if (this.nrUnsavedCells > 0) {
      this.dynamicButtonClicked(this.registeredDynamicButtons.find(x => x.buttonId === 'Save'), null);
    }
  }

  dynamicButtonClicked(dynamicButton: DynamicButtonRegistration, event: any) {
    if (this.dynamicPageButtonAgent)
      this.dynamicPageButtonAgent.goNewState(dynamicButton, this.injector);
    else {
      this.onDynamicButtonClicked.emit(new DynamicButtonBarClickedEventArgs(dynamicButton));
      this.bottomBarService.onButtonClicked.next(new DynamicButtonBarClickedEventArgs(dynamicButton));
    }
  }

  ngOnDestroy(): void {
  }

  public resetButtonBar() {
    this.reasonField.reset('');
  }
}
