import { AdvancedDropdownItem }        from '../models/advanced-dropdown-item';
import { CsAdvancedDropdownComponent } from '../advanced-dropdown.component';

export class SelectionChangedEventArgs {
	item: Array<AdvancedDropdownItem>;
	dropdown: any;
	newApiParams: { [key: string]: any };
	dropdownType: 'navElement' | 'filterElement';

	constructor(
		dropdown: {
			identifier: string,
			label: string,
			selectMultiple: boolean
		},
		item: Array<AdvancedDropdownItem>,
		newApiParams: { [key: string]: any },
		dropdownType: 'navElement' | 'filterElement'
	) {
		this.dropdown     = dropdown;
		this.item         = item;
		this.newApiParams = newApiParams;
		this.dropdownType = dropdownType;
	}
}

export class FilterSelectionChangedEventArgs extends SelectionChangedEventArgs {
	isCompareRow;
	dropdownType: 'navElement' | 'filterElement';

	constructor(
		dropdown: {
			identifier: string,
			label: string,
			selectMultiple: boolean
		},
		item: Array<AdvancedDropdownItem>,
		newApiParams: { [key: string]: any },
		isCompareRow = false,
		dropdownType: 'navElement' | 'filterElement'
	) {
		super(dropdown, item, newApiParams, dropdownType);
		this.isCompareRow = isCompareRow;
	}
}

export class DropdownOpenStateEventArgs {
	constructor(
		public dropdown: CsAdvancedDropdownComponent,
		public state: boolean
	) {
	}
}
