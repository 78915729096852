import { NgModule }                         from '@angular/core';
import { DataGridAddMemberDialogComponent }      from './data-grid-add-member-dialog.component';
import { CsFormGeneratorModule, CsSharedModule } from '@cs/components';
import { MatDialogModule }                       from '@angular/material/dialog';
import { CommonModule }                     from '@angular/common';

@NgModule({
  imports: [CommonModule,
            CsFormGeneratorModule,
            MatDialogModule, CsSharedModule],
  exports:         [DataGridAddMemberDialogComponent],
  declarations:    [DataGridAddMemberDialogComponent],
  entryComponents: [DataGridAddMemberDialogComponent]
})
export class DataGridAddMemberDialogModule {
}
