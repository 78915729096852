import { isEmpty }            from '@cs/core/utils';
import { TableDataCellState } from './table-data-cell-state.model';


export class TableDataCell {
	truncate: boolean;

	get displayValue(): string {
		return this._displayValue;
	}

	set displayValue(value: string) {
		this._displayValue = value;
		this._value        = value;
	}

	classList: string[]       = [];
	parentRowId: string;
	state: TableDataCellState = new TableDataCellState();


	get value(): any {
		return this._value;
	}

	set value(value: any) {
		this._value        = value;
		this._displayValue = value;
	}

	/**
	 * Identification for the data, most of the time it's the {@link TableHeader.id}
	 */
	id: string;
	/**
	 * use this template for rendering
	 */
	template: string;
	/**
	 * value that is used to display the value in the UI
	 */
	private _displayValue: string;

	/**
	 * the value we manipulate
	 */
	private _value: any;
	private _originalValue: any;

	/**
	 * Collapsible icon
	 */
	collapseIcon: boolean;

	constructor(id: string, value: any, template: string = null) {
		this.id             = id;
		this.value          = value;
		this._originalValue = value;
		this.template       = template;
	}

	isChanged() {
		return this.value + '' !== this._originalValue + '';
	}

	isEmpty() {
		return isEmpty(this.value);
	}
}
