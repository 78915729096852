import { LayoutAnnotation }                 from '../data-described/layout-annotation.model';
import { isNullOrUndefined, hasPropertyOf } from '@cs/core/utils';
import { TableAnnotation }                  from './table-annotation.model';
import { TableLayoutAnnotation }            from './table-layout-annotation.model';

export class LayoutAnnotationTable<T> extends LayoutAnnotation<T> {
	constructor(layoutObject: Partial<LayoutAnnotationTable<T>>) {
		super(layoutObject);

		this.table = !isNullOrUndefined(layoutObject) && hasPropertyOf(layoutObject, 'table')
			? new TableAnnotation<T>(layoutObject.table)
			: new TableAnnotation<T>({
				id:     'default', label: '',
				layout: new TableLayoutAnnotation({
					isSelectable:   false,
					ignoreIdColumn: true,
					asForm: false
				})
			});
	}

	table?: TableAnnotation<T>;
}
