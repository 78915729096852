import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isNumeric, isString }                            from '@cs/core/utils';


function isEmptyInputValue(value: any): boolean {
	// we don't check for string here so it also works with arrays
	return value == null || value.length === 0;
}


export class CsValidatorsUtils {
	static range(min: number, max: number): ValidatorFn {
		function execute(control: AbstractControl): ValidationErrors | null {
			if (isEmptyInputValue(control.value) || isEmptyInputValue(min) || isEmptyInputValue(max)) {
				return null;  // don't validate empty values to allow optional controls
			}
			const value = parseFloat(control.value);
			// Controls with NaN values after parsing should be treated as not having a
			// minimum, per the HTML forms spec: https://www.w3.org/TR/html5/forms.html#attr-input-min
			return !isNaN(value) && (value < min || value > max) ? {'range': {'range': `${min} <-> ${max}`, 'actual': control.value}} : null;
		}

		return execute;
	}

	static dateRange(min: number, max: number): ValidatorFn {
		function execute(control: AbstractControl): ValidationErrors | null {
			if (isEmptyInputValue(control.value) || isEmptyInputValue(min) || isEmptyInputValue(max)) {
				return null;  // don't validate empty values to allow optional controls
			}

			const value = new Date(control.value).getTime() / 1000.0;
			// Controls with NaN values after parsing should be treated as not having a
			// minimum, per the HTML forms spec: https://www.w3.org/TR/html5/forms.html#attr-input-min
			return !isNaN(value) && (value < min || value > max) ? {'range': {'range': `${min} <-> ${max}`, 'actual': control.value}} : null;
		}

		return execute;
	}

	static awbNumber(): ValidatorFn {
		function execute(control: AbstractControl): ValidationErrors | null {
			const awbnumber: unknown    = control.value;
			let awbnumberString: string = null;

			// pass on to build-in validator
			if (awbnumber == null)
				return null;

			// check if number, than convert to string
			if (isNumeric(awbnumber)) {
				awbnumberString = awbnumber.toString();
			} else if (isString(awbnumber)) {
				// check if not empty string
				if (awbnumber.length === 0)
					return null;
				else
					awbnumberString = awbnumber;
			}

			/** Source: Wikipedia */
			const prefix = Number(awbnumberString.slice(0, 3));
			const serial = Number(awbnumberString.slice(3, 3 + 7));
			const check  = Number(awbnumberString.slice(10));

			console.log(`validating ${prefix} ${serial} ${check}`);

			/* calculate remainder */
			const rem = serial % 7;

			/* compare remainder with check digit */
			if (rem !== check) {
				return {
					'awbnumber': {rem, serial, check}
				};
			}
			return null;
		}

		return execute;
	}
}
