import { Injector }                         from '@angular/core';
import { getPropertyOf, Result }            from '@cs/core';
import { DynamicButton, DynamicFileButton } from './models/dynamic-button';
import { MatDialog, MatDialogConfig }       from '@angular/material/dialog';
import { DialogBasicOptions, DialogType }   from '../dialog-basic/dialog-basic.models';
import { DialogBasicComponent }             from '../dialog-basic/dialog-basic.component';
import { isNullOrUndefined }                from '@cs/core';


export interface IDynamicButtonState extends IDynamicButtonStateExecution {
	from: string;
	to: string;
}

export interface IDynamicButtonStateExecution {
	shouldExecute?: (dynamicButtonName: string) => boolean;
	isVisible?: (button: DynamicButtonRegistration, injector: Injector, pageComponent: unknown, payload?: unknown) => boolean;
	executeTransition: (button: DynamicButtonRegistration, injector: Injector, pageComponent: unknown, payload: unknown) => void;
}

interface IDynamicButtonRegistration {
	/**
	 * Identifier for the button
	 */
	buttonId: string;
	/**
	 * The icon used when no server icon is provided
	 */
	icon?: string;
	/**
	 * Expression to check if button should be disabled
	 * @param button the button that is checking the state
	 * @param injector Depenecy injector
	 * @param pageComponent the current page or component that is relevant for the button
	 */
	isDisabled?: (button: DynamicButtonRegistration, injector: Injector, pageComponent?: unknown) => boolean;
	/**
	 * Collection of possible button states of the button
	 */
	readonly buttons: Array<Partial<DynamicButton>>;
	readonly buttonsStates?: Array<IDynamicButtonState>;

}

interface IDynamicServerButtonRegistration extends IDynamicButtonStateExecution {
	buttonId: string;
	/**
	 * The icon used when no server icon is provided
	 */
	icon?: string;
}

function isServerButton(btn): btn is IDynamicServerButtonRegistration {
	return btn.buttons == null;
}

/**
 * A registration object that set's up a button and a action so it's usable in the {@link }
 */
export class DynamicButtonRegistration implements IDynamicButtonRegistration {

	//#region UI binding properties

	/**
	 * The current visual state of the button
	 */
	displayInstance: Partial<DynamicButton | DynamicFileButton>;
	/**
	 * the condition that the button should met to be disabled
	 */
	isDisabled: (button: DynamicButtonRegistration, injector: Injector, pageComponent: unknown, payload?: unknown) => boolean;
	/**
	 * Messages for the buttons explaining the state
	 */
	messages: string[];// = ['test', 'aasd adas asd'];

	get isVisible(): boolean {
		return this.buttons && this.buttons.length > 0;
	}


	//#endregion

	/**
	 * flag indicating the action triggerd by the button is still in progress
	 */
	isLoading = false;

	buttonId: string;

	/**
	 * A list of buttons when the button has multiple states.
	 *
	 */
	readonly buttons: Array<Partial<DynamicButton>>;
	readonly buttonsStates?: Array<IDynamicButtonState>;

	/**
	 * icon set by the client, when registering the button
	 */
	icon: string;

	static createClientButton(init: IDynamicButtonRegistration) {
		const buttonId = getPropertyOf(init, 'buttonId');

		const btn      = new DynamicButtonRegistration(buttonId, init.buttons, init.buttonsStates);
		btn.isDisabled = init.isDisabled;
		return btn;
	}

	static createServerButton(init: IDynamicServerButtonRegistration) {

		const buttonId = getPropertyOf(init, 'buttonId');

		const {executeTransition, shouldExecute} = init;
		const buttonsStates                      = [{
			executeTransition,
			shouldExecute,
			from: buttonId,
			to:   buttonId
		}] as Array<IDynamicButtonState>;
		const btn                                = new DynamicButtonRegistration(buttonId, [], buttonsStates);

		// when there is an icon set by the client set it for further use
		if (init.icon)
			btn.icon = init.icon;

		return btn;
	}

	static createServerProvidedButton(name: string, dynamicButton: DynamicButton,
																		buttonsStates: Array<IDynamicButtonState>) {
		return new DynamicButtonRegistration(name, [dynamicButton], buttonsStates);
	}

	protected constructor(buttonId: string,
												buttons: Array<Partial<DynamicButton>>,
												buttonsStates?: Array<IDynamicButtonState>) {

		if (buttons == null)
			throw new Error(DynamicPageButtonsAgentFailureResults.NO_BUTTONS_PROVIDED.toString());

		this.buttonId      = buttonId;
		this.buttons       = buttons;
		this.buttonsStates = buttonsStates;

		if (this.buttons.length > 0) {
			this.displayInstance = this.buttons[0];
			this.buttons.forEach(value => value.type = buttonId);
		}

	}

	checkIfDisabled(dynamicButton: DynamicButtonRegistration, injector: Injector, component: unknown): boolean {
		return (this.isDisabled ? this.isDisabled(dynamicButton, injector, component) : false);
	}

	/**
	 * Get the current state based on the {@link DynamicButtonState.to} equals {@link DynamicButtonRegistration.displayInstance.name}
	 */
	getCurrentState() {

		if (this.buttons.length <= 1 && this.buttonsStates.length === 1) {
			return this.buttonsStates[0];
		} else if (this.buttons.length > 0) {
			return this.buttonsStates.find(value => value.shouldExecute(this.displayInstance.name));
		}

	}

	/**
	 * Update the button to show a new state after it's clicked
	 * @param to The state to navigate to. Should be the same as {@link DynamicButton.name}
	 */
	setNewState(to: string): Result<boolean, DynamicPageButtonsAgentFailureResults> {
		if (to == null)
			throw new Error('No state provided');

		// Only update the button when it has more than one possible state
		if (this.buttons.length === 1)
			return Result.success();

		const found = this.buttons.find(value => value.name === to);

		if (found == null) {
			return Result.failure(DynamicPageButtonsAgentFailureResults.IS_NOT_REGISTERED);
		}

		this.displayInstance = found;

		return Result.success();
	}

	disable() {
		this.displayInstance.disabled = true;
	}

	enable() {
		this.displayInstance.disabled = false;
	}

	async confirm(injector: Injector) {
		if (this.displayInstance.confirm) {
			const dialog = injector.get(MatDialog);
			return this.getUserConfirmation(this.displayInstance.confirm, dialog);
		} else
			return true;
	}

	getUserConfirmation(msg: string, dialog: MatDialog): Promise<any> {
		const confirmDialogRef = dialog.open(DialogBasicComponent, {
			data:       {
				dialogTitle: '',
				type:        DialogType.info,
				message:     msg
			},
			maxWidth:   '30vw',
			minWidth:   350,
			panelClass: 'confirmation-dialog'
		} as MatDialogConfig<DialogBasicOptions>);
		return confirmDialogRef.afterClosed().toPromise();
	}

	getIcon() {
		return this.icon;
	}
}


export enum DynamicPageButtonsAgentFailureResults {
	ALREADY_ADDED_SAME_NAME = 'ALREADY_ADDED_SAME_NAME',
	NO_BUTTONS_PROVIDED     = 'NO_BUTTONS_PROVIDED',
	IS_NOT_REGISTERED       = 'IS_NOT_REGISTERED'
}

export abstract class DynamicButtonsAgent {

	private _buttons: Map<string, DynamicButtonRegistration> = new Map();
	/**
	 * Use this id to resolve buttons that are not registered
	 */
	private _defaultButtonId: string;

	private _payloadTypeMappings: Map<string, string> = new Map<string, string>();

	get buttons(): ReadonlyMap<string, DynamicButtonRegistration> {
		return this._buttons;
	}

	constructor() {
		this.setupButtons();
	}

	setPayloadTypeMapper(payloadTypeMappings: Map<string, string>) {
		this._payloadTypeMappings = payloadTypeMappings;
	}

	/**
	 * Get the registered buttons that are {@link DynamicButtonRegistration.isVisible}
	 */
	getButtons(): Array<DynamicButtonRegistration> {
		return Array.from(this._buttons.values()).filter(value => value.isVisible);
	}

	/**
	 * Clear the registered buttons
	 */
	resetButtons(): ReadonlyMap<string, DynamicButtonRegistration> {
		this._buttons.clear();
		return this._buttons;
	}

	/**
	 * Register the button. The {@link PmDynamicButtonBarComponent} will render the registered buttons
	 * @param btn The registration object that defines the button look and functionality
	 */
	addButton(btn: DynamicButtonRegistration, setAsDefault = false): Result<boolean, DynamicPageButtonsAgentFailureResults> {
		if (this._buttons.has(btn.buttonId))
			return Result.failure(DynamicPageButtonsAgentFailureResults.ALREADY_ADDED_SAME_NAME);

		this._buttons.set(btn.buttonId, btn);

		if (setAsDefault)
			this._defaultButtonId = btn.buttonId;

		return Result.success();

	}

	protected abstract setupButtons(): void;

	/**
	 * Check if the {@link DynamicButton.type} is registered in the {@link DynamicButtonsAgent.buttons}
	 * @param buttonId The button identifier usual {@link DynamicButton.type}
	 */
	isRegistered(buttonId: string): Result<DynamicButtonRegistration, DynamicPageButtonsAgentFailureResults> {
		if (!this._buttons.has(buttonId)) {
			return Result.failure(DynamicPageButtonsAgentFailureResults.IS_NOT_REGISTERED);
		}

		return Result.success(this._buttons.get(buttonId));

	}

	/**
	 * Execute the injected function provided by {@link IDynamicButtonStateExecution.executeTransition}
	 * added to the registered {@link DynamicButtonRegistration}
	 * @param registeredButton The clicked button
	 * @param injector The Dependency Injector of the module
	 * @param pageComponent The component that registered the button clicked
	 * @param payload When the clicked button has extra information injected by the {@link PmDynamicButtonBarComponent.clicked} method
	 */
	goNewState(registeredButton: DynamicButtonRegistration, injector: Injector, pageComponent: unknown = null, payload: unknown = null) {

		const newState = registeredButton.getCurrentState();
		newState.executeTransition(registeredButton, injector, pageComponent, payload);

		if (newState.to)
			registeredButton.setNewState(newState.to);
	}

	/**
	 * Creates a new button provided by the server. The method searches for a
	 * registered button in {@link DynamicButtonsAgent._buttons}. If found it updates the server button
	 * with the found button state, this way it's able to execute the function for that registered {@link DynamicButton.type}.
	 * @param btn the button that is provided by the server
	 */
	createServerProvidedButton(btn: DynamicButton, dialog: MatDialog) {
		const result = this.isRegistered(btn.type);
		if (result.value) {
			return DynamicButtonRegistration.createServerProvidedButton(btn.type, btn, result.value.buttonsStates);
		} else if (result.failureReason === DynamicPageButtonsAgentFailureResults.IS_NOT_REGISTERED
			&& btn.payload
			&& this._payloadTypeMappings
			&& this._payloadTypeMappings.get(btn.payload.type)
			&& this._buttons.has(this._payloadTypeMappings.get(btn.payload.type))) {

			// When there is a payload found check if the type is registered use that button that is mapped to that type
			const found = this._buttons.get(this._payloadTypeMappings.get(btn.payload.type));
			// patch the icon
			if (isNullOrUndefined(btn.iconClass))
				btn.iconClass = found.getIcon();
			return DynamicButtonRegistration.createServerProvidedButton(btn.type, btn, found.buttonsStates);
		} else if (result.failureReason === DynamicPageButtonsAgentFailureResults.IS_NOT_REGISTERED
			&& this._defaultButtonId
			&& this._buttons.has(this._defaultButtonId)) {

			// When there is a default button registered use that one for unregistered buttons
			const found = this._buttons.get(this._defaultButtonId);
			return DynamicButtonRegistration.createServerProvidedButton(btn.type, btn, found.buttonsStates);
		} else {

			throw new Error(DynamicPageButtonsAgentFailureResults.IS_NOT_REGISTERED.toString());
		}
	}
}
