import { NgModule }             from '@angular/core';
import { DialogBasicComponent } from './dialog-basic.component';
import { CommonModule }         from '@angular/common';
import { MatDialogModule }      from '@angular/material/dialog';
import { TranslateModule }      from '@ngx-translate/core';
import { CsCommonPipesModule }       from '@cs/common';


const COMPONENTS = [DialogBasicComponent];

@NgModule({
  imports:         [CommonModule,
                    MatDialogModule, TranslateModule, CsCommonPipesModule],
  exports:         [...COMPONENTS],
  declarations:    [...COMPONENTS],
  entryComponents: [...COMPONENTS]
})
export class DialogBasicModule {
}
