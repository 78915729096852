/**
 * HB Returnable transport solutions | Cohelion
 * API documentation for HB Returnable transport solutions | Cohelion
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
  HttpResponse,
  HttpEvent
}                                                            from '@angular/common/http';
import { CsHttpClient, CsHttpRequestOptions }                from '@cs/core';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable } from 'rxjs';


import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AppService {

    protected basePath = ''; // No default basepath
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: CsHttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Returns change log
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appChangelogGet(csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public appChangelogGet(csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public appChangelogGet(csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public appChangelogGet(csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.get<any>(`${this.basePath}/app/changelog`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Returns members per level key, for the most common levels, and a minimal date range. To complete the set, use /app/extralevelmembers
     * Returns members per level key, for the most common levels, and a minimal date range. To complete the set, use /app/extralevelmembers
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appCommonlevelmembersGet(csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public appCommonlevelmembersGet(csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public appCommonlevelmembersGet(csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public appCommonlevelmembersGet(csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.get<any>(`${this.basePath}/app/commonlevelmembers`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Returns the remaining data that wasn&#39;t included in /app/commonlevelmembers
     * Returns the remaining data that wasn&#39;t included in /app/commonlevelmembers
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appExtralevelmembersGet(csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public appExtralevelmembersGet(csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public appExtralevelmembersGet(csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public appExtralevelmembersGet(csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.get<any>(`${this.basePath}/app/extralevelmembers`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * 
     * 
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appKeepAliveGet(csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public appKeepAliveGet(csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public appKeepAliveGet(csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public appKeepAliveGet(csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.get<any>(`${this.basePath}/app/keep-alive`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Returns all members per level key
     * Returns all members per level key
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appLevelmembersGet(csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public appLevelmembersGet(csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public appLevelmembersGet(csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public appLevelmembersGet(csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.get<any>(`${this.basePath}/app/levelmembers`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Logs client-side error details. Returns unique id of logged error.
     * Logs client-side error details. Returns unique id of logged error.
     * @param message Error message
     * @param type Error type/category
     * @param detail Summary of error
     * @param errorCode HTTP status code of error
     * @param extendedInfo Stack trace or extra information about the error
     * @param stackTrace URIEncoded Plaintext stackTrace
     * @param forceMail Forces an email to be send.
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appLogErrorPost(message: string, type?: string, detail?: string, errorCode?: string, extendedInfo?: string, stackTrace?: string, forceMail?: boolean, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public appLogErrorPost(message: string, type?: string, detail?: string, errorCode?: string, extendedInfo?: string, stackTrace?: string, forceMail?: boolean, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public appLogErrorPost(message: string, type?: string, detail?: string, errorCode?: string, extendedInfo?: string, stackTrace?: string, forceMail?: boolean, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public appLogErrorPost(message: string, type?: string, detail?: string, errorCode?: string, extendedInfo?: string, stackTrace?: string, forceMail?: boolean, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (message === null || message === undefined) {
            throw new Error('Required parameter message was null or undefined when calling appLogErrorPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (message !== undefined) {
            formParams = formParams.append('message', <any>message) || formParams;
        }
        if (type !== undefined) {
            formParams = formParams.append('type', <any>type) || formParams;
        }
        if (detail !== undefined) {
            formParams = formParams.append('detail', <any>detail) || formParams;
        }
        if (errorCode !== undefined) {
            formParams = formParams.append('errorCode', <any>errorCode) || formParams;
        }
        if (extendedInfo !== undefined) {
            formParams = formParams.append('extendedInfo', <any>extendedInfo) || formParams;
        }
        if (stackTrace !== undefined) {
            formParams = formParams.append('stackTrace', <any>stackTrace) || formParams;
        }
        if (forceMail !== undefined) {
            formParams = formParams.append('forceMail', <any>forceMail) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<string>(`${this.basePath}/app/log-error`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

}
