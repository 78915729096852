/**
 * HB Returnable transport solutions | Cohelion
 * API documentation for HB Returnable transport solutions | Cohelion
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
  HttpResponse,
  HttpEvent
}                                                            from '@angular/common/http';
import { CsHttpClient, CsHttpRequestOptions }                from '@cs/core';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable } from 'rxjs';


import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MdmprofilesService {

    protected basePath = ''; // No default basepath
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: CsHttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get page content for context
     * Get page content for context
     * @param scope Scope name
     * @param memberId Member ID
     * @param pageName Page name
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mdmprofilesScopeMemberIdPagePageNameGet(scope: string, memberId: string, pageName: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public mdmprofilesScopeMemberIdPagePageNameGet(scope: string, memberId: string, pageName: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public mdmprofilesScopeMemberIdPagePageNameGet(scope: string, memberId: string, pageName: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public mdmprofilesScopeMemberIdPagePageNameGet(scope: string, memberId: string, pageName: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (scope === null || scope === undefined) {
            throw new Error('Required parameter scope was null or undefined when calling mdmprofilesScopeMemberIdPagePageNameGet.');
        }
        if (memberId === null || memberId === undefined) {
            throw new Error('Required parameter memberId was null or undefined when calling mdmprofilesScopeMemberIdPagePageNameGet.');
        }
        if (pageName === null || pageName === undefined) {
            throw new Error('Required parameter pageName was null or undefined when calling mdmprofilesScopeMemberIdPagePageNameGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.get<any>(`${this.basePath}/mdmprofiles/${encodeURIComponent(String(scope))}/${encodeURIComponent(String(memberId))}/page/${encodeURIComponent(String(pageName))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Get list of profile pages for context
     * Get list of profile pages for context
     * @param scope Scope name
     * @param memberId Member ID
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mdmprofilesScopeMemberIdPagesGet(scope: string, memberId: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public mdmprofilesScopeMemberIdPagesGet(scope: string, memberId: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public mdmprofilesScopeMemberIdPagesGet(scope: string, memberId: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public mdmprofilesScopeMemberIdPagesGet(scope: string, memberId: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (scope === null || scope === undefined) {
            throw new Error('Required parameter scope was null or undefined when calling mdmprofilesScopeMemberIdPagesGet.');
        }
        if (memberId === null || memberId === undefined) {
            throw new Error('Required parameter memberId was null or undefined when calling mdmprofilesScopeMemberIdPagesGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.get<any>(`${this.basePath}/mdmprofiles/${encodeURIComponent(String(scope))}/${encodeURIComponent(String(memberId))}/pages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Save properties for context
     * Save properties for context
     * @param scope Scope name
     * @param memberId Member ID
     * @param data Array of data objects with property keys
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mdmprofilesScopeMemberIdPropertiesPost(scope: string, memberId: string, data?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public mdmprofilesScopeMemberIdPropertiesPost(scope: string, memberId: string, data?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public mdmprofilesScopeMemberIdPropertiesPost(scope: string, memberId: string, data?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public mdmprofilesScopeMemberIdPropertiesPost(scope: string, memberId: string, data?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (scope === null || scope === undefined) {
            throw new Error('Required parameter scope was null or undefined when calling mdmprofilesScopeMemberIdPropertiesPost.');
        }
        if (memberId === null || memberId === undefined) {
            throw new Error('Required parameter memberId was null or undefined when calling mdmprofilesScopeMemberIdPropertiesPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (data !== undefined) {
            formParams = formParams.append('data', <any>data) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<any>(`${this.basePath}/mdmprofiles/${encodeURIComponent(String(scope))}/${encodeURIComponent(String(memberId))}/properties`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

}
