<div class="data-entry-state flex-column">
	<div csWidthRuler></div>
	<div #heightRef="csLengthRulerRef" style="position: absolute; height: 100%" csLengthRuler></div>
	<div class="vertical-curtain"
		 [ngStyle]="{
	height: heightCurtain+'px',
	width: widthCurtain+'px'
	}"></div>

	<div class="flex flex-column">
		<ng-container *ngFor="let row of headerRows; ">
			<div class="data-entry-state-header-row flex flex-column depth-{{row.path.length}}">
				<div class="data-entry-state-header-row__content">
					<ng-container *ngFor="let column of row?.columns; trackBy: trackByStructureKey">
						<ng-container *ngIf="column?.visualState?.visible">
							<cs-data-entry-state-header [column]="column"
														[depth]="row.path.length"></cs-data-entry-state-header>
							<div style="min-width: 2px;"></div>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</ng-container>
	</div>
	<div class="flex flex-column">
		<ng-container *ngFor="let row of dataRows;trackBy:trackByRowStructureKey">
			<cs-data-entry-state-row [rowContext]="row"
									 [rowPosition]="renderSchema?.headerPositions[renderSchema?.headerPositions?.length - 1]"
									 [badges]="row?.badges">

			</cs-data-entry-state-row>
		</ng-container>
	</div>
</div>
