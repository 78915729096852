import { DataGridAction }                 from './data-grid-action';
import { DataGridCellType, GridItemType } from '../enums/data-grid.enum';
import { DataGridRuleMatch }              from './data-grid-rule-match';
import { GridActions }                    from '../enums/data-grid.enum';

import { isNullOrUndefined } from '@cs/core';

export class DataGridValidatorAction implements DataGridAction {
  readonly type: GridActions = GridActions.AddValidator;

  /**
   * Scope to which to limit the match (e.g. row of the cell)
   */
  matchScope: GridItemType = GridItemType.Cell;

  /**
   * Filter by cell type (default: Data).
   */
  celltype: DataGridCellType = DataGridCellType.Data;

  /**
   * LevelKeys or factProperties to Match on (see LevelKeys for more info)
   */
  match: DataGridRuleMatch;

  // target: GridItemType.Cell; // fixed property, only validated against cell values

  constructor(initial: Partial<DataGridValidatorAction> = null) {
    if (!isNullOrUndefined(initial))
      Object.assign(this, initial);
  }
}
