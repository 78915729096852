import { CsDropEvent }                                                         from '@cs/components/dragdrop';
import { Component, Input, Output, EventEmitter, forwardRef, TemplateRef, ContentChild, AfterViewInit }
																																							 from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NG_VALIDATORS, FormControl } from '@angular/forms';
import { CsValuePanelButton }                                                  from './value-panel-button.model';
import { CsValuePanelEventArgs }                                               from './value-panel-event-args.model';

const CS_VALUEPANEL_CONTROL_VALUE_ACCESSOR: any =
				[
					{
						provide:     NG_VALUE_ACCESSOR,
						useExisting: forwardRef(() => CsValuePanelComponent),
						multi:       true
					},
					{
						provide:     NG_VALIDATORS,
						useExisting: forwardRef(() => CsValuePanelComponent),
						multi:       true
					}
				];

/**
 * Ui value panel component.
 *

 */
@Component({
						 selector:    'cs-value-panel',
						 templateUrl: './value-panel.component.html',
						 providers:   [CS_VALUEPANEL_CONTROL_VALUE_ACCESSOR]
					 })
export class CsValuePanelComponent implements ControlValueAccessor,
																							AfterViewInit {
	public defaultTamplate: TemplateRef<any>;

	/**
	 * Model to be bind.
	 */
	@Input() ngModel: string; // ngModel

	/**
	 * A template to show on top of handler.
	 */
	@ContentChild(TemplateRef) inlineTemplate: TemplateRef<any>;
	@Input() template: TemplateRef<any>;

	/**
	 * Drag and drop enabled flag
	 *

	 */
	@Input() dragAndDrop: Boolean = false;

	/**
	 * Panel buttons array of CsValuePanelButton
	 *

	 */
	@Input() actions: CsValuePanelButton[];

	/**
	 * On action triggered event!
	 *

	 */
	@Output() actionClickedEvent = new EventEmitter<CsValuePanelEventArgs>();

	/**
	 * On value panle droped event!
	 *

	 */
	@Output() onDropped = new EventEmitter<CsDropEvent>();


	ngAfterViewInit(): void {
		this.template = this.template || this.inlineTemplate || this.defaultTamplate;
	}

	actionClicked(action: CsValuePanelButton) {
		this.actionClickedEvent.emit(new CsValuePanelEventArgs(action.name, this.ngModel));
	}

	writeValue(obj: any): void {
		this.ngModel = obj;
	}

	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	setDisabledState(isDisabled: boolean): void {

	}

	validate(c: FormControl) {
		return this.validateFn(c);
	}


	private onTouchedCallback = (v: any) => {
	};
	private onChangeCallback  = (v: any) => {
	};
	private validateFn: any   = () => {
	};

}
