import {
	ChangeDetectionStrategy, Component,
	OnDestroy, OnInit, Optional, ViewChild
}                                              from '@angular/core';
import { Location }                            from '@angular/common';
import { BrandingService, BrandingQuery }      from '@cs/performance-manager/shared';
import { BrandingConfigService }               from '@cs/performance-manager/shared';
import { ShellConfigService }                  from './shell-config.service';
import { AuthenticationService, PmNavbarItem } from '@cs/performance-manager/shared';
import { AppQuery }                            from '@cs/performance-manager/shared';
import { AppService }                          from '@cs/performance-manager/shared';
import {
	ActivatedRoute, NavigationEnd,
	ResolveEnd, Router, RouterOutlet
}                                              from '@angular/router';
import { UntilDestroy, untilDestroyed }        from '@ngneat/until-destroy';

import { AuthenticationQuery }                                         from '@cs/performance-manager/shared';
import { CsHttpRequestOptions, FileUtil, isNullOrUndefined, isObject } from '@cs/core';
import { AuthenticationConfigService }                                 from '@cs/performance-manager/shared';
import { DataDescribed, isEmptyObject, LoaderService, LoggerUtil }     from '@cs/core';
import { CsApplicationSettings }                                       from '@cs/performance-manager/shared';
import {
	Environment,
	getAllUrlParamsFromActiveComponents, getModulePath,
	getRoutesFromCurrentLoadedComponents,
	routerFadeTransition
}                                                                      from '@cs/common';
import { DeploymentDetails }                                           from '@cs/performance-manager/shared';
import { PmNavbarSectionChangedEventArgs }                             from '@cs/performance-manager/shared';
import { findNavigationItemBasedOnUrl }                                from '@cs/performance-manager/shared';
import { PmNavbarComponent }                                           from '@cs/performance-manager/shared';
import { Subscription }                                                from 'rxjs';
import { debounceTime, filter }                                        from 'rxjs/operators';
import { AppNavigationService }                                        from '@cs/common';
import { PmNavbarModuleChangedEventArgs }                              from '@cs/performance-manager/shared';
import { HttpErrorResponse }                                           from '@angular/common/http';


@UntilDestroy()
@Component({
			   selector:        'pmc-shell',
			   templateUrl:     './shell.component.html',
			   changeDetection: ChangeDetectionStrategy.OnPush,
			   animations:      [routerFadeTransition('fadeAnimation')]
		   })
export class ShellComponent implements OnInit,
									   OnDestroy {
	/**
	 * Navbar instance
	 */
	@ViewChild(PmNavbarComponent, {static: true}) navbar: PmNavbarComponent;

	/**
	 * List of available modules
	 */
	modules: Array<PmNavbarItem>;
	/**
	 * List of Sections items for selected module
	 */
	sections: Array<PmNavbarItem>;
	/**
	 * List of module dropdowns that could have extra functionality like logo changes
	 */
	moduleDropdowns: DataDescribed<any>;
	/**
	 * Menu items beneath the avatar block
	 */
	avatarMenu: Array<PmNavbarItem>;

	$isLoading = this.loaderService.isLoading$.pipe(debounceTime(50));

	/**
	 * Flag indicating that the user is impersonating a other user
	 */
	$isImpersonated = this.authenticationQuery.select(store => store.isImpersonated);
	/**
	 * Name of the active section
	 */
	$activeSection  = this.appStateQuery.select(store => store.activeSection);
	/**
	 * Show username into the Avatar
	 */
	$userName       = this.authenticationQuery
						  .select(store => !isNullOrUndefined(store.userProfile)
										   ? `${store.userProfile.firstName} ${store.userProfile.lastName}`
										   : '');
	/**
	 * Builded environment
	 */
	environment: Environment;
	/**
	 * Information from the deployment
	 */
	deploymentDetails: DeploymentDetails;


	showDeploymentInfo = false;
	poweredByBarItems: Array<PmNavbarItem>;

	private routeChangeListener$: Subscription;
	brandingLogo: string | ArrayBuffer;


	constructor(private readonly shellConfig: ShellConfigService,
				private readonly appStateQuery: AppQuery,
				private readonly appStateService: AppService,
				private readonly authenticationService: AuthenticationService,
				private readonly authenticationQuery: AuthenticationQuery,
				private readonly loaderService: LoaderService,
				private readonly authenticationConfig: AuthenticationConfigService,
				private readonly activeRoute: ActivatedRoute,
				private readonly location: Location,
				private readonly router: Router,
				private readonly pmAppSettings: CsApplicationSettings,
				private readonly appNavigationService: AppNavigationService,
				private readonly brandingService: BrandingService,
				private readonly brandingQuery: BrandingQuery,
				@Optional() private readonly brandingConfigService: BrandingConfigService) {

		this.setupLogoChangeHandler();
	}

	ngOnInit() {

		this.environment = this.pmAppSettings.environment;

		this.shellConfig.getNavigationBarItems()
			.subscribe(async navBarBody => {
				this.modules         = navBarBody.value.modules || [];
				this.sections        = navBarBody.value.sections || [];
				this.avatarMenu      = navBarBody.value.avatarMenu || [];
				this.moduleDropdowns = navBarBody.value.moduleDropdowns || null;

				this.listenToRouteChangesForActiveNavbarItem();
				const foundNavItem = this.getActiveNavbarItem();

				const moduleParams = {};
				if (this.moduleDropdowns) {
					// Check the route if it has a module value in the url
					const activeModules = getAllUrlParamsFromActiveComponents(this.activeRoute);
					// loop over the module selectors and search for the field id.
					this.moduleDropdowns.dataAnnotation.fields.forEach(value => {
						if (activeModules.hasOwnProperty(value.id)) {
							const currentUrlModuleValue = activeModules[value.id.toString()];
							// if the url contains the module key than set that value as the default
							if (currentUrlModuleValue !== 'default') {
								// check if the module param is an option, if not than it's gibberish and let the server provided value intact
								const hasModuleValueInLookup = this.moduleDropdowns
																   .resolveValueWithLookupByPropertyName(activeModules[value.id.toString()],
																										 value.id);
								// If value is found in the lookup it's a valid value and usable
								if (hasModuleValueInLookup != null)
									this.moduleDropdowns.data[value.id] = activeModules[value.id.toString()];
							}

							const lookup = this.moduleDropdowns.resolveValueWithLookupByPropertyName(this.moduleDropdowns.data[value.id],
																									 value.id);
							if (lookup != null && isObject(lookup))
								this.brandingService.update({
																backDropImage: lookup.backgroundImage,
																personalLogo:  lookup.logoImage
															});
						}
					});

					for (const field of this.moduleDropdowns.dataAnnotation.fields) {
						moduleParams[field.id] = this.moduleDropdowns.data[field.id];
					}
				}

				if (foundNavItem) {
					this.navbar.activeSection = foundNavItem;
					this.appStateService.update({currentAppParamsScope: {...foundNavItem.appParams, ...moduleParams}});
				} else {
					// kickstart applications
					this.appStateService.update({currentAppParamsScope: moduleParams});
				}


			});

		this.shellConfig.getDeploymentDetails()
			.subscribe(value => {
				this.deploymentDetails = value.value;

				if (this.environment !== Environment.PRODUCTION
					&& this.environment !== Environment.QA) {
					this.showDeploymentInfo = true;
				}

			});

		this.shellConfig.getPoweredByBarItems()
			.subscribe(value => this.poweredByBarItems = value.value);
	}

	/**
	 * Click handler for a section change
	 */
	sectionChanged($event: PmNavbarSectionChangedEventArgs) {

		if (!this.appNavigationService.canNavigate()) {
			LoggerUtil.debug('Navigation canceled');
			return;
		}

		const eventArgs = this.patchUrlWithAppScopeVariables(this.activeRoute, $event);

		this.router.navigate(eventArgs.paths, {
			relativeTo:          this.activeRoute.root,
			queryParams:         eventArgs.params,
			queryParamsHandling: eventArgs.options.preserveQueryParams
								 ? 'preserve'
								 : '',
			preserveFragment:    true
		})
			.then(value => {
				this.appStateService.update({
												currentAppParamsScope: Object.assign({},
																					 this.appStateQuery.getValue().currentAppParamsScope,
																					 eventArgs.appParams)
											});
			});

	}

	/**
	 * Patch the route variables with the appscopevariable when resolving the path
	 * @param route
	 * @param $event
	 * @private
	 */
	private patchUrlWithAppScopeVariables(route: ActivatedRoute, $event: PmNavbarSectionChangedEventArgs) {
		let currentUrlParams = getAllUrlParamsFromActiveComponents(route);

		if (isEmptyObject(currentUrlParams))
			currentUrlParams = this.appStateQuery.getValue().currentAppParamsScope;

		const $eventClone = JSON.parse(JSON.stringify($event));

		// Check if the requested url is root
		const isRoot = $eventClone.paths && $eventClone.paths.length === 1 && $eventClone.paths[0] === '/';

		if (!isRoot) {
			for (let i = 0; i < $eventClone.paths.length; i++) {
				const path = $eventClone.paths[i];

				// split if it contains extra levels
				const splitted = path.split('/');

				for (let i1 = 0; i1 < splitted.length; i1++) {
					const sPath = splitted[i1];
					if (sPath.startsWith(':')) {
						const paramName = sPath.replace(':', '');
						splitted[i1]    = currentUrlParams.hasOwnProperty(paramName)
										  ? currentUrlParams[paramName]
										  : 'default';
					}
				}

				// reconstruct the path
				$eventClone.paths[i] = splitted.join('/');
			}
		}

		// check if the last item is not root
		if ($eventClone.paths.length === 1 && $eventClone.paths[0] === '/' && this.shellConfig.patchRootPathWIthModule) {
			const moduleRoutes = getModulePath(route);
			// check if the current loaded route has module dependant pages, if not set the original  path
			$eventClone.paths  = moduleRoutes.length > 0
								 ? moduleRoutes.map(value => value.path)
								 : $eventClone.paths;
		}

		return $eventClone;

	}

	/**
	 * Start listening for route changes and update the active navbar item
	 */
	private listenToRouteChangesForActiveNavbarItem() {
		if (this.routeChangeListener$)
			return;

		this.routeChangeListener$ = this.router.events
										.pipe(untilDestroyed(this),
											  // Also on the resolveEnd because of some extra navigation that is triggering NavigationCanceled
											  filter(event => event instanceof ResolveEnd || event instanceof NavigationEnd),
											  debounceTime(100)
										)
										.subscribe((event: ResolveEnd | NavigationEnd) => {

											const foundNavItem = this.getActiveNavbarItem(event.urlAfterRedirects);
											if (foundNavItem)
												this.navbar.activeSection = foundNavItem;
											else
												LoggerUtil.error('No navitem found for ' + event.urlAfterRedirects);

										});
	}

	/**
	 * Will return the active navbar item based on the url
	 */
	private getActiveNavbarItem(url?: string) {
		if (!url)
			url = this.router.url;

		function convertUrlParamToString(parameters: { [p: string]: string }) {
			const key = Object.keys(parameters)[0];
			return `:${key}`;
		}

		try {
			let urlFragments: string[] = [];
			// 😣 Needed to make a switch between the PM and CP, because of the different navigation implementation.
			// This is now done by this implicit flag used only by the CP
			if (this.shellConfig.patchRootPathWIthModule) {
				urlFragments = getRoutesFromCurrentLoadedComponents(this.activeRoute)
					.map(value => value.parameters != null && !isEmptyObject(value.parameters)
								  ? convertUrlParamToString(value.parameters)
								  : value.path);
			} else {
				urlFragments = this.router.parseUrl(url)
								   .root
								   .children
								   .primary
								   .segments
								   .map(value => value.path);
			}
			return findNavigationItemBasedOnUrl(urlFragments, [
				...this.modules,
				...this.sections,
				...this.avatarMenu
			]);

		} catch (e) {
			return null;
		}

	}

	public getRouterOutletState(outlet: RouterOutlet) {
		return outlet.isActivated
			   ? outlet.activatedRoute
			   : '';
	}

	ngOnDestroy(): void {
	}

	navigateToWebsite() {
		const p = this.poweredByBarItems.find(value => value.name === 'POWERED_BY_WEBSITE');
		if (p) {
			this.navbar.sectionClicked(p);
		} else {
			alert('No "POWERED_BY_WEBSITE" item found');
		}
	}

	poweredByBarItemClicked(navItem: PmNavbarItem) {
		this.navbar.sectionClicked(navItem);
	}

	moduleChanged($event: PmNavbarModuleChangedEventArgs) {

		if (!this.appNavigationService.canNavigate()) {
			LoggerUtil.debug('Navigation canceled');
			return;
		}

		const currentPath = getRoutesFromCurrentLoadedComponents(this.activeRoute);
		const eventArgs   = this.shellConfig.resolveNewModulePath($event, currentPath);

		this.brandingService.update({
										backDropImage: $event.params.backgroundImage,
										personalLogo:  $event.params.logoImage
									});

		this.router.navigate([...eventArgs], {
			relativeTo:          this.activeRoute.root,
			queryParamsHandling: 'merge'
		})
			.then(value => {
				this.appStateService.update({
												currentAppParamsScope: Object.assign({},
																					 this.appStateQuery.getValue().currentAppParamsScope,
																					 $event.appParams)
											});
			});
	}

	private setupLogoChangeHandler() {

		if (this.brandingConfigService == null) {
			LoggerUtil.log('No branding module defined');
			return;
		}

		this.brandingQuery
			.select(store => store.personalLogo)
			.pipe(
				untilDestroyed(this),
				filter(value => value != null)
			)
			.subscribe(value => {
				this.setBrandingLogo(value);
			});
	}

	private setBrandingLogo(image: string | undefined) {
		if (image == null) {
			// Restore default image
			this.brandingLogo = null;
		}
		const options                = new CsHttpRequestOptions();
		options.errorResponseHandler = (response: HttpErrorResponse) => {
			// Restore default image
			this.brandingLogo = null;
			return true;
		};

		// Download the backdrop, This is done async and replaced when the image is loaded
		this.brandingConfigService.getBrandingLogo(image, {}, options)
			.subscribe(value => {
				if (value == null) {
					this.brandingLogo = null;
					return;
				}

				FileUtil.createFileUrl(value)
						.subscribe(dataUrl => {
							this.brandingLogo = dataUrl == null
												? null
												: dataUrl;
						});
			});

	}

	isFooterEnable() {
		return this.shellConfig.enableFooter;
	}
}

