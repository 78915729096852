import { isNullOrUndefined } from '@cs/core';
import { ToastService }      from '@cs/performance-manager/shared';
import { Injector }          from '@angular/core';
import {
	CsHttpRequestOptions,
	FileUtils, LoggerUtil, Result
}                            from '@cs/core';
import { Observable }        from 'rxjs';
import { HttpResponse }      from '@angular/common/http';
import { DataGridUtils }     from './data-grid.utils';
import { DialogType }        from '@cs/performance-manager/shared';
import {
	ImportResultMessage,
	CSImportResultMessage
}                            from '@cs/performance-manager/shared';
import { IButton }           from '@cs/components';


export interface IDataGridExportConfig {
	getExportForGrid(gridName: string,
									 exportName: string,
									 selection?: { [key: string]: string | number },
									 params?: { [key: string]: any },
									 csHttpRequestOptions?: CsHttpRequestOptions): Observable<Result<HttpResponse<Blob>>>;
}

export interface IDataGridImportConfig {
	uploadImportForGrid(gridName: string,
											exportName: string,
											file: File,
											selection?: { [key: string]: string | number },
											params?: { [key: string]: any },
											csHttpRequestOptions?: CsHttpRequestOptions): Observable<Result<ImportResultMessage>>;
}


export class ImportExportAgent {

	static importButtonClicked(gridName: string,
														 injector: Injector,
														 config: IDataGridImportConfig,
														 button: IButton,
														 selection: { [key: string]: string | number },
														 files: FileList = null,
														 params          = {}) {
		const obs = new Observable<CSImportResultMessage>(subscriber => {
			const toastService = injector.get(ToastService);

			if (!isNullOrUndefined(files) && files.length > 0) {

				// Handle documented responses
				const options: CsHttpRequestOptions = {
					errorResponseHandler: (error): boolean => {
						if (!error) {
							return false;
						}
						if (error.status === 400) { // Handle Bad requests
							if (CSImportResultMessage.isImportResultMessage(error.message)) {
								const result = new CSImportResultMessage(JSON.parse(error.message));
								DataGridUtils.displayMultilineUserMessage(injector, result.messages, 'Error', DialogType.danger, 0);
							} else {
								// assume that the server response is in plain text
								toastService.error('Upload unsuccessful', error.statusText + ': ' + error.message);
							}
							// Some failure/warning during actual processing of the data
							return true;
						}
						return false;
					}
				};


				config.uploadImportForGrid(
					gridName,
					button.name,
					files[0],
					selection,
					params,
					options
				).subscribe(response => {
					const data = response.value;

					if (!CSImportResultMessage.isImportResultMessage(data)) {
						LoggerUtil.error('Import: server returned unexpected response', true);
					}

					const result = new CSImportResultMessage(data);
					if (result.success) {
						if (result.haswarning) {
							DataGridUtils.displayMultilineUserMessage(injector, result.messages, 'Warning', DialogType.warning, 0);
						} else {
							// Import usually takes some time to process and user will switch to different tab/screen.
							// Keep success notification on the screen for when the user returns.
							DataGridUtils.displayMultilineUserMessage(injector, result.messages, 'Import Successful', DialogType.success, 0);
						}
					} else {
						DataGridUtils.displayMultilineUserMessage(injector, result.messages, 'Error', DialogType.danger, 0);
					}
					// Set the import name to the result
					result.name = button.name;
					subscriber.next(result);
					subscriber.complete();
				});
			}
		});
		return obs;
	}

	static exportButtonClicked(gridName: string,
														 injector: Injector,
														 config: IDataGridExportConfig,
														 button: IButton,
														 selection: { [key: string]: string | number },
														 params = {}) {

		const obs = new Observable<boolean>(subscriber => {
			const toastService = injector.get(ToastService);
			config.getExportForGrid(gridName, button.name, selection, params).subscribe(response => {
				if (!isNullOrUndefined(response)) {
					FileUtils.downloadFile(response.value);
					subscriber.next(true);
				} else {
					toastService.warning('No data', 'Export does not have data. No file created.');
					subscriber.next(true);
				}
				subscriber.complete();
			});
		});

		return obs;
	}
}
