import { NgModule, ModuleWithProviders, Type }                from '@angular/core';
import { CommonModule }                                       from '@angular/common';
import { IframeTeaserRoutingModule }                         	from './iframe-teaser-routing.module';
import { IframeTeaserComponent }                              from './iframe-teaser.component';
import { CsApplicationSettings }                              from '@cs/performance-manager/shared';
import { IframeTeaserConfigService }                          from './iframe-teaser-config.service';
import { HttpClient }                                         from '@angular/common/http';
import { IConfigService }                                       from '@cs/performance-manager/shared';
import { ComponentTranslationLoaderService, CsCultureProvider } from '@cs/common';
import { CsTranslationLoader }                                  from '@cs/performance-manager/shared';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { LoggerUtil }                                         from '@cs/core';
import { CsSharedModule } 																		from '@cs/components';

export function iframeTeaserTranslationLoader(client: HttpClient,
  pmAppSettings: CsApplicationSettings,
  service: IConfigService,
  componentLoader: ComponentTranslationLoaderService) {
return new CsTranslationLoader(`${pmAppSettings.translationLocation}/${service.moduleName}`,
client, componentLoader);
}

@NgModule({
  declarations: [IframeTeaserComponent],
  imports: [
    CommonModule,
    CsSharedModule,
    TranslateModule.forChild({
      loader:  {
        provide:    TranslateLoader,
        useFactory: iframeTeaserTranslationLoader,
        deps:       [HttpClient, CsApplicationSettings, IframeTeaserConfigService, ComponentTranslationLoaderService]
      },
      isolate: true
    }),
    IframeTeaserRoutingModule
  ]
})
export class PmIframeTeaserModule {
  static forRoot(moduleConfig: Type<IframeTeaserConfigService>,
                 appSetting: CsApplicationSettings): ModuleWithProviders<PmIframeTeaserModule> {
    return {
        ngModule:  PmIframeTeaserModule,
        providers: [
          {
            provide:  IframeTeaserConfigService,
            useClass: moduleConfig
          },
          {
            provide:  CsApplicationSettings,
            useValue: appSetting
          }
        ]
      };
    }

    constructor(translate: TranslateService, culture: CsCultureProvider) {

      if (!translate) {
        LoggerUtil.warn('Translation service is not set-up');
        return;
      }

      // this language will be used as a fallback when a translation isn't found in the current language
      translate.setDefaultLang(culture.getDefaultCulture());

      // the lang to use, if the lang isn't available, it will use the current loader to get them
      translate.use(culture.getCulture());
    }
 }
