import { Logger }                                   from '@cs/components/util';
import { createToObjectWithLowerCaseKeys, filter }  from '@cs/components/util';
import { GridOptions, ChangedDataPackage }          from '../classes';
import { GridHeaderRow, GridSheet, GridGroup }      from '../models';
import { isNullOrUndefined }                        from '@cs/core';
import { GridDataRow }                              from '../models';
import { GridDataCell }                             from '../models';
import { DataGridCellType, GridItemType, RowState } from '../enums/data-grid.enum';
import { GridHeaderCell }                           from '../models';
import zipObject                                    from 'lodash/zipObject';
import { IChoiceSet }                               from '../interfaces';
import { FormatProviderService }                    from '@cs/common';
import { Injector }                                 from '@angular/core';


/**
 * Created by alex on 28-7-2017.
 */


export class DataGridHelpers {
	static createKeysObject(rowData: any, rowKeys: string[]) {
		const output = {};
		for (const key of rowKeys) {
			output[key] = rowData[key];
		}
		return output;
	}

	static createKeysString(keys: any): string {
		const output = [];
		// preserve key order: use Object.getOwnPropertyNames instead of Object.keys
		for (const key of Object.getOwnPropertyNames(keys)) {
			if (keys.hasOwnProperty(key)) {
				output.push(keys[key]);
			}
		}
		return output.join('_');
	}

	static cleanBaseKeys(options: GridOptions, keys: { [key: string]: any }, keepInjectedKey = false, extraKeysToIgnore: Array<string> = []) {
		const cleanKeys = Object.assign({}, keys);
		// remove the injected Key that is only used for injected columns
		if (!keepInjectedKey)
			delete cleanKeys.injectedkey;

		const properties = Object.getOwnPropertyNames(createToObjectWithLowerCaseKeys(options.baseKeys));
		properties.push(...extraKeysToIgnore);

		// Remove base keys because these are not in the returned data
		for (const key of properties) {
			if (cleanKeys.hasOwnProperty(key))
				delete cleanKeys[key];
		}

		return cleanKeys;
	}

	static flattenRows(sheet: GridSheet) {
		const rows = [];
		for (const group of sheet.groups) {
			for (const row of group.dataRows) {
				rows.push(row);
			}
		}
		return rows as GridDataRow[];
	}

	static findGroupByRow(rowId: string, sheets: Array<GridSheet>): { group: GridGroup, rowIndex: number } {
		for (const sheet of sheets) {
			for (const group of sheet.groups) {
				for (let i = 0; i < group.dataRows.length; i++) {
					const row = group.dataRows[i];
					if (row.id === rowId)
						return {group: group, rowIndex: i};
				}
			}
		}
		return null;
	}

	static findExpansionRow(rowId: string, sheets: Array<GridSheet>): { group: GridGroup, expansionRow: GridDataRow, rowIndex: number } {
		for (const sheet of sheets) {
			for (const group of sheet.groups) {
				for (let i = 0; i < group.dataRows.length; i++) {
					const row = group.dataRows[i];
					if (!isNullOrUndefined(row.parent) && row.parent === rowId)
						return {group: group, rowIndex: i, expansionRow: row};
				}
			}
		}
		return null;
	}

	static findHeaderByCell(cell: GridDataCell, gridRow: GridDataRow, sheets: GridSheet[]): any {
		let row = gridRow;

		if (isNullOrUndefined(row))
			row = this.findRowByCell(cell, sheets);

		const found = this.findGroupByRow(row.id, sheets);

		if (isNullOrUndefined(found))
			return null;

		const last  = found.group.columsRows[found.group.columsRows.length - 1];
		const index = row.values.findIndex(c => c.keys === cell.keys);
		return last.columns[index];
	}

	/**
	 * Returns Cell keys for each column in row that matches matches the 'unique' string property of IChoiceSet.
	 * For example the ChoiceSet AcType might refer to IChoiceSet IdCarrier by setting unique property to that name ('IdCarrier').
	 */
	static findKeyByRowSet(row: GridDataRow, sheets: GridSheet[], foundSets: IChoiceSet[]): any {
		const found = this.findGroupByRow(row.id, sheets);
		if (isNullOrUndefined(found))
			return null;

		const last     = found.group.columsRows[found.group.columsRows.length - 1];
		const foundKey = {};
		for (const sets of foundSets) {
			const indexHeader = last.columns.findIndex(c => !isNullOrUndefined(sets.unique) && c.key === sets.unique.toLowerCase());
			if (indexHeader === -1)
				continue;

			const foundValue = row.values[indexHeader];
			Object.assign(foundKey, foundValue.keys);
		}

		return foundKey;
	}

	static findKeysByRowSet(row: GridDataRow, sheets: GridSheet[], foundSets: IChoiceSet[]): any {
		const found = this.findGroupByRow(row.id, sheets);
		if (isNullOrUndefined(found))
			return null;

		const last     = found.group.columsRows[found.group.columsRows.length - 1];
		const foundKey = {};
		for (const sets of foundSets) {
			const indexHeader = last.columns.findIndex(c => c.key === sets.name.toLowerCase());
			if (indexHeader === -1)
				continue;

			const foundValue = row.values[indexHeader];
			Object.assign(foundKey, foundValue.keys);
		}

		return foundKey;
	}

	static filterCells(sheet: GridSheet,
										 dataGridCellType: DataGridCellType = DataGridCellType.Data,
										 dataGridRow: RowState              = RowState.Default,
										 scope: GridItemType                = GridItemType.Sheet,
										 scopeCell?: GridDataCell): Array<GridDataCell> {
		let cells = [];


		if (scope === GridItemType.Group) {
			// const group = DataGridHelpers.findGroupByCell(scopeCell, [sheet]);
			const row   = DataGridHelpers.findRowByCell(scopeCell, [sheet]);
			const group = DataGridHelpers.findGroupByRow(row.id, [sheet]);
			if (!isNullOrUndefined(group))
				cells = DataGridHelpers.extractCellFromDataGridGroup(group.group, dataGridCellType, dataGridRow);
		} else {
			for (const group of sheet.groups) {
				cells.push(...DataGridHelpers.extractCellFromDataGridGroup(group, dataGridCellType, dataGridRow));
			}
		}
		return cells;
	}

	static filterHeaderCells(sheet: GridSheet,
													 dataGridCellType: DataGridCellType = DataGridCellType.Data,
													 dataGridRow: RowState              = RowState.Default,
													 scope: GridItemType                = GridItemType.Sheet,
													 scopeCell?: GridDataCell): Array<GridHeaderCell> {
		let cells = [];

		if (scope === GridItemType.Group) {
			// const group = DataGridHelpers.findGroupByCell(scopeCell, [sheet]);
			const row   = DataGridHelpers.findRowByCell(scopeCell, [sheet]);
			const group = DataGridHelpers.findGroupByRow(row.id, [sheet]);
			if (!isNullOrUndefined(group))
				cells = DataGridHelpers.extractHeaderCellFromDataGridGroup(group.group, dataGridCellType, dataGridRow);
		} else {
			for (const group of sheet.groups) {
				cells.push(...DataGridHelpers.extractHeaderCellFromDataGridGroup(group, dataGridCellType, dataGridRow));
			}
		}

		return cells;
	}

	private static findGroupByCell(cell: GridDataCell, sheets: GridSheet[]) {
		for (const sheet of sheets) {
			const group = filter(sheet.groups, cell.keys, 'keys', 'partial');
			if (group.length === 1)
				return group[0] as GridGroup;
			else if (group.length > 1) {
				Logger.ThrowError('Something is wrong, i found more than one group');
			}
		}
		return null;
	}

	static findSheetByGroup(group: GridGroup, sheets: GridSheet[]): GridSheet {
		for (const sheet of sheets) {
			for (const gridgroup of sheet.groups) {
				if (gridgroup === group) {
					return sheet;
				}
			}
		}
		return undefined;
	}

	static findSheetByCell(cell: GridDataCell, sheets: GridSheet[]): GridSheet {
		for (const sheet of sheets) {
			for (const group of sheet.groups) {
				for (const row of group.dataRows) {
					if (row.values.some(rowCell => rowCell === cell)) {
						return sheet;
					}
				}
			}
		}
		return null;
	}

	static extractCellFromDataGridGroup(group: GridGroup,
																			dataGridCellType: DataGridCellType = DataGridCellType.Data,
																			dataGridRow: RowState              = RowState.Default) {
		let cells = [];
		for (const row of group.dataRows) {
			if (row.rowState !== dataGridRow && dataGridRow !== RowState.All)
				continue;

			cells = cells.concat(row.values.filter(x => dataGridCellType === DataGridCellType.All || x.cellType === dataGridCellType));
		}
		return cells;
	}

	static extractHeaderCellFromDataGridGroup(group: GridGroup,
																						dataGridCellType: DataGridCellType = DataGridCellType.Data,
																						dataGridRow: RowState              = RowState.Default) {
		let cells = [];
		for (const row of group.columsRows) {

			cells = cells.concat(row.columns.filter(x => dataGridCellType === DataGridCellType.All || x.cellType === dataGridCellType));
		}
		return cells;
	}

	static findRows(sheet: GridSheet, rowKeys: { [key: string]: any }, dataGridRow: RowState = RowState.Default): GridDataRow[] {

		const foundRows = [];
		for (const group of sheet.groups) {
			const found = filter(group.dataRows, rowKeys, 'keys');
			if (found) {
				foundRows.push(...found);
			}
		}
		return foundRows;
	}

	static findCellsInEditMode(sheets: Array<GridSheet>): Array<GridDataCell> {
		let output = [];
		for (const sheet of sheets) {
			for (const group of sheet.groups) {
				for (let i = 0; i < group.dataRows.length; i++) {
					const row   = group.dataRows[i];
					const found = row.values.filter(x => x.cellState.editable);
					if (found.length > 0)
						output = output.concat(found);
				}
			}
		}
		return output;
	}

	static combineAllKeys(cell: GridDataCell, row: GridDataRow, group: GridGroup, sheet: GridSheet, options: GridOptions): {} {
		// Combine al keys from the row, and sheet so we can send it to the server
		const combinedKey = Object.assign({}, options.baseKeys, sheet.keys);
		if (!isNullOrUndefined(group)) Object.assign(combinedKey, group.keys);
		if (!isNullOrUndefined(row)) Object.assign(combinedKey, row.keys);
		if (!isNullOrUndefined(cell)) Object.assign(combinedKey, cell.keys);

		return DataGridHelpers.createKeysObject(combinedKey, options.dataKeyParts);
	}

	static findRowByCell(cell: GridDataCell, sheets: GridSheet[]) {
		for (const sheet of sheets) {
			for (const group of sheet.groups) {
				for (const row of group.dataRows) {
					const index = row.values.findIndex(c => c.keys === cell.keys);
					if (index > -1)
						return row;
				}
			}
		}
		return null;
	}

	/**
	 * Returns the index within row which exactly matches the columnkeys (ignoring rowkeys)
	 */
	static findColumnIndexByKeys(columnKeys: any, row: GridHeaderRow,
															 ignoreKeys: string[]       = [],
															 ignoreRowIndices: number[] = [],
															 options: GridOptions): number {
		const columnkeys = DataGridHelpers.cleanBaseKeys(options, columnKeys, true);

		function findRowIndex(cell, index): boolean {
			if (ignoreRowIndices.indexOf(index) !== -1) {
				return false;
			}
			const cellkeys      = {};
			const cellOnRowKeys = DataGridHelpers.cleanBaseKeys(options, cell.keys, true);
			for (const key of Object.keys(cellOnRowKeys)) {
				if (ignoreKeys.indexOf(key) === -1) {
					cellkeys[key] = cellOnRowKeys[key];
				}
			}
			if (Object.keys(cellkeys).length !== Object.keys(columnkeys).length)
				return false;
			let same = true;
			for (const key of Object.keys(cellkeys)) {
				same = columnkeys.hasOwnProperty(key) && cellkeys[key] === columnkeys[key];
				if (!same)
					return same;
			}
			return same;
		}

		return row.columns.findIndex(findRowIndex);
	}


	static findGroupKeys(group: GridGroup) {
		let keys;

		const labelCol = group.columsRows[group.columsRows.length - 1].columns.find(item => item.isLabel);

		return labelCol.keys;
	}

	/**
	 * the data already setup for sending to the server, it uses by default the dataKeyParts
	 */
	static getChangedCellsReadyForApi(changedCells: Array<GridDataCell>,
																		options: GridOptions,
																		addBaseKeys     = false,
																		useLegacyFormat = false,
																		injector: Injector): ChangedDataPackage {
		const formatService = injector.get(FormatProviderService);

		const idDatasourceManual     = 1;
		const idDatasourceSnapshot   = 4;
		const idDatasourceActualCopy = 6;

		let saveValueAsDataSource = true;
		if (options.config.hasOwnProperty('saveAsManualDataSource') && !options.config.saveAsManualDataSource)
			saveValueAsDataSource = options.config.saveAsManualDataSource;

		const columns = [...options.dataKeyParts];

		if (addBaseKeys) {
			for (const key of Object.getOwnPropertyNames(createToObjectWithLowerCaseKeys(options.baseKeys))) {
				if (columns.indexOf(key) === -1)
					columns.push(key);
			}
		}

		// add value column
		columns.push('value');

		const cleanedData = [];

		changedCells.forEach((changedCell) => {
			const cell = new GridDataCell(changedCell, formatService);

			// check if we can save the datasource
			// by definition the client can only modify/save the manual datasource
			if (!isNullOrUndefined(cell.keys['iddatasource']) && options.allowSaveDatasources.indexOf(cell.keys['iddatasource']) === -1) {
				// not sure how to display a message to the user
				Logger.ThrowError('You are not allowed to save selected datasource.');
			}

			// Set the iddatasource explicitly (1 = Manual datasource)
			if (isNullOrUndefined(cell.keys['iddatasource'])) {
				cell.keys['iddatasource'] = idDatasourceManual;
			}

			const cellValues = [];
			for (const key of columns) {
				if (cell.keys.hasOwnProperty(key)) {
					cellValues.push(cell.keys[key]);
				} else if (key !== 'value') {
					cellValues.push(null);
				}
			}

			// find manual entry
			const manualEntry = cell.cellData.find(x => options.allowSaveDatasources.indexOf(x.iddatasource) !== -1);
			let valueToSave   = cell.value;
			if (saveValueAsDataSource && manualEntry && !useLegacyFormat) {
				valueToSave = manualEntry.value;
			}

			// when there is snapshot or actualcopy data, save the sum, unless value is empty
			const snapshotData = cell.cellData.find(x => x.iddatasource === idDatasourceSnapshot || x.iddatasource === idDatasourceActualCopy);
			if (snapshotData && manualEntry && manualEntry.value !== '') {
				valueToSave = snapshotData.value + manualEntry.value;
			}

			// add value
			cellValues.push(valueToSave);
			cleanedData.push(cellValues);
		});

		// Create data object for server
		const dataObject = {
			data: {
				columns: columns,
				data:    cleanedData
			}
		};
		return dataObject;
	}

	static getChangedCellsReadyForApiLegacy(changedCells: Array<GridDataCell>,
																					options: GridOptions, addBaseKeys: boolean = false, injector: Injector) {
		const changes = DataGridHelpers.getChangedCellsReadyForApi(changedCells, options, addBaseKeys, true, injector);
		const output  = [];
		for (const change of changes.data.data) {
			const item = zipObject(changes.data.columns, change);
			Object.assign(item, options.client.cargoDataEntryTool.addLegacyKeys || {});
			output.push(item);
		}
		return output;
	}

	static compareDataWithStructure(dataKeyParts: Array<string>, keys: Array<string>, included = false) {
		const bk            = keys.map(value => value.toLowerCase());
		const realDataParts = [];

		// Remove base keys because these are not in the returned data
		for (const key of dataKeyParts) {
			// Check if the key exits in the DataKeyPart array
			if (included) {
				if (bk.indexOf(key.toLowerCase()) > -1)
					realDataParts.push(key);

				// Check if the key is NOT in the DataKeyPart array
			} else if (bk.indexOf(key.toLowerCase()) === -1)
				realDataParts.push(key);
		}

		return realDataParts;
	}
}
