import { NgModule }                          from '@angular/core';
import { CommonModule }                      from '@angular/common';
import { CsButtonBarComponent }              from './cs-button-bar.component';
import { BottomBarQuery }                    from './state/bottom-bar.query';
import { BottomBarService }                  from './state/bottom-bar.service';
import { BottomBarStore }                    from './state/bottom-bar.store';
import { MatTooltipModule }                  from '@angular/material/tooltip';
import { TranslateModule }                   from '@ngx-translate/core';
import { ComponentTranslationLoaderService } from '@cs/common';
import { CsSharedModule }                    from '@cs/components/shared';
import { ReactiveFormsModule }               from '@angular/forms';
import { MatDialogModule }                   from '@angular/material/dialog';


@NgModule({
  declarations: [
    CsButtonBarComponent
  ],
  imports:      [
    CommonModule,
    TranslateModule.forChild(),
    MatTooltipModule,
    MatDialogModule,
    CsSharedModule,
    ReactiveFormsModule
  ],
  providers:    [
    BottomBarQuery,
    BottomBarService,
    BottomBarStore
  ], exports:   [
    CsButtonBarComponent
  ]
})
export class CsBottomButtonBarModule {
  constructor(loader: ComponentTranslationLoaderService) {
    loader.registerComponentModule('pm-bottom-button-bar');
  }
}

