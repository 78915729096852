import { Component, Input, OnInit } from '@angular/core';
import { DeploymentDetails }        from './pm-deploy-info.models';
import { Environment }              from '@cs/common';
import { TranslateService }         from '@ngx-translate/core';

@Component({
  selector: 'pmc-pm-deploy-info',
  templateUrl: './pm-deploy-info.component.html',
  styleUrls: ['./pm-deploy-info.component.scss']
})
export class PmDeployInfoComponent implements OnInit {

  @Input() deploymentDetails: DeploymentDetails;
  @Input() environment: Environment;

  badge: string;

  constructor(private l8n: TranslateService) { }

  ngOnInit() {
    switch (this.environment) {
      case Environment.PREVIEW:
        this.badge = 'primary';
        break;
      case Environment.LOCAL:
        this.badge = 'success';
        break;
      case Environment.DEVELOPMENT:
        this.badge = 'warning';
        break;
      case Environment.TESTING:
        this.badge = 'secondary';
        break;
    }
  }
}
