import { Component, OnInit, Inject, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { TableDataDescribed }                                                     from '@cs/core';

@Component({
	// tslint:disable-next-line: component-selector
	selector:    'pm-audit-trail-changes',
	templateUrl: './audit-trail-changes.component.html'
})
export class AuditTrailChangesComponent implements AfterViewInit, OnDestroy {

	/**
	 *  Get/set meta data of audit changes. For example columns that have the same value throughout.
	 */
	public set metaData(value: TableDataDescribed<[]>) {
		this._metaData = value;

		this.changeRef.detectChanges();
	}

	public get metaData(): TableDataDescribed<[]> {
		return this._metaData;
	}

	_metaData: TableDataDescribed<[]>;

	/**
	 *  Get/Set data of audit changes.
	 */
	public set data(value: TableDataDescribed<[]>) {
		this._data = value;
		this.changeRef.detectChanges();
	}

	public get data(): TableDataDescribed<[]> {
		return this._data;
	}

	_data: TableDataDescribed<[]>;

	constructor(private changeRef: ChangeDetectorRef) {
	}


	ngAfterViewInit() {
	}

	ngOnDestroy() {

	}
}
