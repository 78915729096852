import { isNullOrUndefined, isNumber, isString } from '@cs/core';
import { GridDataCell }                          from '../models/grid-data-cell.model';
import { DataGridCellType }                      from '../enums/data-grid.enum';


export class Calculations {

	private static injectedCalculations: Map<string, Function> = new Map<string, Function>();


	static defaultCalculation(values: Array<GridDataCell>) {
		let total    = 0;
		let hasValue = false;
		values.forEach(x => {
			if (x.cellType !== DataGridCellType.Data)
				return;

			let val = 0;

			// check if value is a string if so try to parse it as a string because it could be manual input
			if (!isNullOrUndefined(x.value) && x.value !== '') {
				val      = isString(x.value) ? parseFloat(x.value) : x.value;
				total += (val * x.calculation.aggregationFactor);
				hasValue = true;
			}
		});

		if (!hasValue)
			total = null;

		return total;

	}

	static getOffsetCalculation(values: Array<GridDataCell>) {
		const totalCell = values.find(x => x.cellType === DataGridCellType.Total);
		return isNullOrUndefined(totalCell) || isNullOrUndefined(totalCell.compareCell) ? '-' : totalCell.metaValues.relDifference - 1;
	}

	/**
	 * Return a dynamically set function, or undefined.
	 */
	static getCalculationMethod(functionName: string) {
		return Calculations.injectedCalculations.get(functionName);
	}

	/**
	 * Dynamically set custom function.
	 */
	static addCalculationMethod(functionName: string, func: Function) {
		Calculations.injectedCalculations.set(functionName, func);
	}
}
