import { IConfigService }               from '@cs/performance-manager/shared';
import { CsHttpRequestOptions, Result } from '@cs/core';
import { Observable }                   from 'rxjs';
import { FormDefinitionBundle }         from '@cs/performance-manager/shared';
import { CreateItemMessage }            from './models/createItemMessage';
import { Column }                       from '@cs/components';
import { ResourceModel }                from './models/resourceModel';

export abstract class DataListConfigService<T> implements IConfigService {

  moduleName = 'data-list';
  columns: Array<Column>;

  abstract getList(idCountry: number,
    csHttpRequestOptions?: CsHttpRequestOptions): Observable<Result<Array<T>>>;

  abstract getData(idCountry: number,
    idCla: number,
    csHttpRequestOptions?: CsHttpRequestOptions): Observable<Result<T>>;

  abstract getFormDefition(idCountry: number,
    csHttpRequestOptions?: CsHttpRequestOptions): Observable<Result<FormDefinitionBundle>>;

  abstract saveNewData(idCountry: number,
    claStruct: string,

    csHttpRequestOptions?: CsHttpRequestOptions): Observable<Result<CreateItemMessage>>;

  abstract saveExistingData(idCountry: number,
    idCla: number,
    claStruct: string,
    csHttpRequestOptions?: CsHttpRequestOptions): Observable<Result<CreateItemMessage>>;

  abstract deleteData(idCountry: number,
    idCla: number,
    csHttpRequestOptions?: CsHttpRequestOptions): Observable<Result<{}>>;

  abstract getResourceAccess(idCountry: number): Observable<Result<ResourceModel>>;
}
