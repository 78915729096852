import { TableDataCell }     from './table-data-cell.model';
import { generateQuickGuid } from '../data-described/generate.utils';

export enum TableDataRowType {
	Data,
	Group,
	Total
}

export class TableDataRowLayout {
	/**
	 * Collection of all the cells for the row
	 */
	cells: TableDataCell[] = [];

	/**
	 * Identification for the data row
	 */
	id: { [key: string]: string | number };

	/**
	 * Flag indicating a row is selected
	 */
	selected      = false;
	/**
	 * Id reference for containing row
	 */
	rowId: string;
	/**
	 * Check if the row is collapsible
	 */
	isCollapsible = false;

	rowType: TableDataRowType;

	classList: string[] = [];

	constructor(id: { [key: string]: string | number }, rowType: TableDataRowType) {
		this.id      = id;
		this.rowId   = generateQuickGuid();
		this.rowType = rowType;
	}

}
