<div class="mt-4 user-profile">
  <div class="row">

    <div class="cs-panel-header">
      <div class="cs-panel-header--header flex flex-row">
        <label>{{'ACCOUNT' | translate | titlecase}}</label>
        <div [@isLoadingContent]="isLoadingContent" class="mr-2 loader"></div>
        <button type="button" class="btn btn--primary btn--md"
                *ngIf="changePasswordAvailable"
        [routerLink]="authenticationConfig.expiredPasswordUrl">
        {{'CHANGE_PASSWORD' | translate | titlecase}}
      </button>
      </div>

      <div class="cs-panel-header--content">
        <cs-form-generator
          #accountFormElement
          [dataSource]="accountForm"
          [showRevert]="false"
          [layout]="'horizontal'"
          [editMode]="'all'"
          [keepFormInEditMode]="true"
          [readOnly]="true"
          [isInEditMode]="true"
        >
          <!--  [readOnly]="accountForm?.data.isLDAP"-->
        </cs-form-generator>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="cs-panel-header">
      <div class="cs-panel-header--header flex flex-row">
        <label>{{'PERSONAL_INFORMATION' | translate | titlecase}}</label>
      </div>

      <div class="cs-panel-header--content" [@isLoadingContent]="!isLoadingContent">
        <cs-form-generator
          [dataSource]="profileForm"
          [layout]="'horizontal'"
          [showRevert]="false"
          [editMode]="'all'"
          [keepFormInEditMode]="true"
          [isInEditMode]="true"
          (saveForm)="postPersonalInfo($event)"
        >
        </cs-form-generator>
      </div>
    </div>
    <div class="cs-panel-header">
      <div class="cs-panel-header--header flex flex-row">
        <label>{{'LOCATION' | translate | titlecase}}</label>
      </div>
      <div class="cs-panel-header--content" [@isLoadingContent]="!isLoadingContent">
        <cs-form-generator
          [dataSource]="locationForm"
          [layout]="'horizontal'"
          [showRevert]="false"
          [editMode]="'all'"
          [isInEditMode]="true"
          [keepFormInEditMode]="true"
          (saveForm)="postLocationInfo($event)"
        >
        </cs-form-generator>
      </div>
    </div>
  </div>
</div>
