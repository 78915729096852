import { ModuleWithProviders, NgModule, Type }                        from '@angular/core';
import { LoginComponent }                                             from './login.component';
import { LoginConfigService }                                         from './login-config.service';
import { TranslateLoader, TranslateModule, TranslateService }         from '@ngx-translate/core';
import { CsApplicationSettings, IConfigService, CsTranslationLoader } from '@cs/performance-manager/shared';
import { LoggerUtil }                                                 from '@cs/core';
import { FormsModule }                                                from '@angular/forms';
import { MatTooltipModule }                                           from '@angular/material/tooltip';
import { FormGeneratorNxtModule }                                     from '@cs/components/form-generator-nxt';
import { CsSharedModule }                                             from '@cs/components/shared';
import { HttpClient }                                                 from '@angular/common/http';
import { DefaultComponent }                                           from './default/default.component';
import { RouterModule }                                                              from '@angular/router';
import { CsCommonPipesModule, ComponentTranslationLoaderService, CsCultureProvider } from '@cs/common';
import { LoginService }                                                              from './state/login.service';
import { LoginStore }                                                 from './state/login.store';
import { LoginQuery }                                                 from './state/login.query';
import { DataDescribedLoginComponent }                                from './data-described/data-described-login.component';

export function loginTranslationLoader(client: HttpClient,
																			 pmAppSettings: CsApplicationSettings,
																			 service: IConfigService,
																			 componentLoader: ComponentTranslationLoaderService) {
	return new CsTranslationLoader(`${pmAppSettings.translationLocation}/${service.moduleName}`,
		client, componentLoader, pmAppSettings.deploymentDetails.buildDate);
}

const providers = [LoginService, LoginStore, LoginQuery];

@NgModule({
	imports:      [
		CsCommonPipesModule,
		RouterModule,
		FormsModule,
		MatTooltipModule,
		FormGeneratorNxtModule,
		TranslateModule.forChild({
			loader:  {
				provide:    TranslateLoader,
				useFactory: loginTranslationLoader,
				deps:       [HttpClient, CsApplicationSettings, LoginConfigService, ComponentTranslationLoaderService]
			},
			isolate: true
		}),
		CsSharedModule
	],
	declarations: [LoginComponent, DefaultComponent, DataDescribedLoginComponent],
	providers:    [...providers]
})
export class PmLoginModule {
	static forRoot(moduleConfig: Type<LoginConfigService>,
								 appSetting: CsApplicationSettings): ModuleWithProviders<PmLoginModule> {
		return {
			ngModule:  PmLoginModule,
			providers: [
				{
					provide:  LoginConfigService,
					useClass: moduleConfig
				},
				{
					provide:  CsApplicationSettings,
					useValue: appSetting
				},
				...providers
			]
		};
	}

	constructor(translate: TranslateService, culture: CsCultureProvider) {

		if (!translate) {
			LoggerUtil.warn('Translation service is not setup');
			return;
		}

		// this language will be used as a fallback when a translation isn't found in the current language
		translate.setDefaultLang(culture.getDefaultCulture());

		// the lang to use, if the lang isn't available, it will use the current loader to get them
		translate.use(culture.getCulture());
	}
}
