<div class="stepper-item"
	 [class.is-active]="state === 'isActive'"
	 [class.is-disabled]="state === 'isDisabled'"
	 [class.is-checked]="state === 'isChecked'">
	<cs-wizard-step-icon [icon]="step.icon"
						 [showConnector]="!isLast"
						 [state]="state"
	></cs-wizard-step-icon>
	<div class="stepper-item--content flex flex-column">
		<div class="stepper-item--label">{{step.label}}</div>
		<div class="stepper-item--step-label"> {{"STEP"| translate}} {{step.index}}</div>
	</div>
</div>
