import { IButton } from '@cs/components';

export class DynamicButtonClickedEventArgs {
	data: IButton;
	files: FileList;
	handled = false;

	constructor(data: IButton, files?: FileList) {
		this.data  = data;
		this.files = files;
	}
}
