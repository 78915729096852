import { IConfigService }               from '@cs/performance-manager/shared';
import { Observable }                   from 'rxjs';
import { CsFormGeneratorDataSource }    from '@cs/components';
import { CsHttpRequestOptions, Result } from '@cs/core';
import { UserPersonalProfile }          from './model/user-personal-profile';
import { UserLocationInfo }             from './model/user-location-info';

export abstract class UserProfileConfigService implements IConfigService {
	moduleName = 'user-profile';

	abstract hasPlatformPasswordRecoveryAvailableForCurrentAccount(options?: CsHttpRequestOptions): Observable<Result<boolean>>;

	abstract getPersonalInfo(): Observable<Result<CsFormGeneratorDataSource>>;

	abstract getLocationInfo(): Observable<Result<CsFormGeneratorDataSource>>;

	abstract getAccountInfo(options?: CsHttpRequestOptions): Observable<Result<CsFormGeneratorDataSource>>;

	abstract updateLocationInfo(data: UserLocationInfo): Observable<Result<any>>;

	abstract updatePersonalInfo(data: UserPersonalProfile): Observable<Result<any>>;
}
