import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CommonModule }                        from '@angular/common';
import { TabbedPageComponent }                 from './tabbed-page.component';
import { RouterModule }                        from '@angular/router';
import { CsApplicationSettings }               from '@cs/performance-manager/shared';
import { TabbedPageConfigService }             from './tabbed-page-config.service';
import { CsSharedModule }                      from '@cs/components/shared';
import { TabService }                          from './state/tab.service';
import { TabStore }                            from './state/tab.store';
import { TabQuery }                            from './state/tap.query';

const providers = [TabService, TabStore, TabQuery];

@NgModule({
	declarations: [TabbedPageComponent],
	imports:      [
		CommonModule,
		RouterModule,
		CsSharedModule
	],
	providers:    [...providers]
})
export class PmTabbedPageModule {
	static forRoot(moduleConfig: Type<TabbedPageConfigService>,
								 appSetting: CsApplicationSettings): ModuleWithProviders<PmTabbedPageModule> {
		return {
			ngModule:  PmTabbedPageModule,
			providers: [
				{
					provide:  TabbedPageConfigService,
					useClass: moduleConfig
				},
				{
					provide:  CsApplicationSettings,
					useValue: appSetting
				},
				...providers
			]
		};
	}
}
