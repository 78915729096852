/**
 * Indicates status information about the current process
 */
export interface IProcessProgressInfo { 
    /**
     * Flag indicating process is running
     */
    readonly isRunning: boolean;
    /**
     * Informational message
     */
    message: string;
    /**
     * Number of items processed (including skipped and warnings), DependencyReaderWeb.Models.ProcessProgressInfo.TotalItems
     */
    processed: number;
    /**
     * Number of items skipped
     */
    skipped: number;
    /**
     * Number of warnings
     */
    warnings: number;
    /**
     * Total number of items to be processed DependencyReaderWeb.Models.ProcessProgressInfo.Processed
     */
    totalItems: number;
}


export class ProcessProgressInfo implements IProcessProgressInfo {
    isRunning: boolean = false;
    message: string = "";
    processed: number = 0;
    skipped: number = 0;
    warnings: number = 0;
    totalItems: number = 0;

    /**
     * Constructor
     * @param partial 
     */
    public constructor(partial?: Partial<IProcessProgressInfo>) {
        Object.assign(this, partial);
    }
    
}