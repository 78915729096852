import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { IDashboardPanelComponent }                              from '../../models/i-dashboard-panel-component';
import { GaugeDataDescribed }                                    from '@cs/components/gauge';
import { ArrayUtils }                                            from '@cs/core';
import { isNullOrUndefined }                                     from '@cs/core';
import { SafeMethods }                                           from '@cs/common';

@Component({
	selector:        'cs-dashboard-gauge',
	templateUrl:     './dashboard-gauge.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardGaugeComponent implements IDashboardPanelComponent<GaugeDataDescribed> {

	name: string;

	data: GaugeDataDescribed;

	constructor(private changeRef: ChangeDetectorRef) {
	}

	update(data: GaugeDataDescribed): void {
		if (!isNullOrUndefined(this.data) && !isNullOrUndefined(data) &&
			(this.data && ArrayUtils.isEqual(this.data.data, data.data)))
			return;

		this.data = data;
		SafeMethods.detectChanges(this.changeRef);
	}

}
