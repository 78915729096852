import {
	ChangeDetectorRef,
	ComponentFactoryResolver,
	ComponentRef,
	Directive,
	EventEmitter, Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	ViewContainerRef
}                                from '@angular/core';
import { PortalInjector }        from '@angular/cdk/portal';
import { DashboardPanelOptions } from './models/dashboard-panel-options';

@Directive({
	selector: '[csDashboardComponentLoader]'
})
export class DashboardComponentLoaderDirective implements OnChanges, OnInit, OnDestroy {

	@Input('csDashboardComponentLoader') component: any;
	@Input() componentData: any;
	@Input() componentName: string;
	@Input() componentPanel: any;
	@Input() componentHeight: string;

	@Output() componentInstantiated = new EventEmitter<any>();
	private ref: ComponentRef<any>;

	constructor(private cdr: ChangeDetectorRef,
							private container: ViewContainerRef,
							private resolver: ComponentFactoryResolver,
							private injector: Injector) {
	}

	ngOnInit() {

	}

	ngOnChanges() {
		this.container.clear();
		const componentFactory = this.resolver.resolveComponentFactory(this.component);
		const tokens           = new WeakMap(
			[
				[DashboardPanelOptions, new DashboardPanelOptions({
					name:   this.componentName,
					panel:  this.componentPanel,
					height: this.componentHeight
				})]
			]);

		this.ref               = this.container.createComponent(componentFactory, 0, new PortalInjector(this.injector, tokens));
		this.ref.instance.data = this.componentData;


		this.componentInstantiated.emit(this.ref.instance);
		this.cdr.detectChanges();
	}

	ngOnDestroy(): void {
		if (this.ref) {
			this.ref.destroy();
		}
	}

}
