import { ResizeSensor }                   from 'css-element-queries';
import { ChangeDetectorRef, ElementRef }  from '@angular/core';
import {
	convertColor,
	CsDataDescribedClickEventArgs,
	isNullOrUndefined, LoggerUtil, updateTargetSources
} from '@cs/core';
import { DashboardEventHub }              from '../../dashboard-event-hub.service';
import { DashboardChartNxtDataDescribed } from './dashboard-chart-nxt-data-described.model';
import {
	CsChartLoaderDirective,
	CsDataDescribedChartLoaderSetup
}                                         from '@cs/components/cs-chart-loader';

export abstract class DashboardChartNxtBase {
	name: string;
	isClickable           = false;
	clickTypeClass        = '';
	isWholeChartClickable = false;
	series                = [];

	protected oldWidth: number;
	protected oldHeight: number;
	protected resizeSensor: ResizeSensor;


	abstract chartDir: CsChartLoaderDirective;

	protected constructor(protected chartSetup: CsDataDescribedChartLoaderSetup,
												protected dashboardEventHub: DashboardEventHub,
												protected changeDetectorRef: ChangeDetectorRef,
												protected readonly element: ElementRef) {

	}

	protected hasRightEdgeData(data: DashboardChartNxtDataDescribed): boolean {
		if (data && data.layout && data.layout.options && data.layout.options.series)
			return Object.keys(data.layout.options.series).some(key => {
				const value = data.layout.options.series[key];
				return value && value.hasOwnProperty('targetAxisIndex');
			});

		return false;
	}

	/**
	 * Check and set options
	 * @returns default options
	 */
	protected checkOptions(data: DashboardChartNxtDataDescribed) {

		// Check color property
		if (!isNullOrUndefined(data.layout.options) && !isNullOrUndefined(data.layout.options.colors)) {
			const colors = [...data.layout.options.colors];

			const legendLength = data.layout.chartType !== 'PieChart' ? data.dataAnnotation.fields.length - 1 :
				data.data.length;
			const colorLegth   = colors.length;
			if (colorLegth < legendLength) {
				let aux = 0, alpha = 0.5;
				for (let i = 0; i < legendLength - colorLegth; i++) {

					if (aux >= colorLegth) {
						aux -= colorLegth;
						alpha = (alpha - 0.2 === 0) ? 0.5 : alpha - 0.2;
					}

					const color = this.shadeColor(colors[aux], alpha);
					data.layout.options.colors.push(color);
					aux++;
				}
			}
		}

		return {
			backgroundColor:   '',
			chartArea:         {
				width:  data.layout.chartType === 'PieChart' ? '100%' : '',
				height: data.layout.chartType === 'PieChart' ? '95%' : '',
				right:  data.layout.chartType !== 'PieChart' ? (this.hasRightEdgeData(data) ? '6%' : 0) : '',
				left:   data.layout.chartType !== 'PieChart' ? '6%' : ''
			},
			legend:            {
				position:  'bottom',
				textStyle: {
					fontSize: 12
					, color:  '555'
				}
			},
			hAxis:             {
				textStyle: {
					fontSize: 11,
					color:    '777'
				},
				gridlines: {
					color: 'DDD',
					count: 3
				}
			},
			vAxis:             {
				textStyle:      {
					fontSize: 11,
					color:    '888'
				},
				minorGridlines: {
					count: 0
				}
			},
			tooltip:           {
				textStyle: {
					fontSize: 12
				}
			},
			pieSliceTextStyle: {
				fontSize: 13
			},
			// https://developers.google.com/chart/interactive/docs/gallery/linechart See focusTarget.
			// If the data has at least one property with a selection trigger, set the focusTarget to 'datum' because the
			// 'select' event for google chart will not set the columnIndex anymore when set to 'category'. Therefore IPA functionality is broken
			focusTarget: data.dataAnnotation.fields.some(value => value.selectionTrigger === 'Property') ? 'datum' : 'category',
			lineWidth:   3,
			animation:   {
				duration: 300,
				easing:   'inAndOut',
				startup:  true
			}
		};
	}

	/**
	 * Change shade of a color
	 * @returns coverted color
	 */
	private shadeColor(color: string, percent: number): string {
		let r, g, b;
		// convert hex color rgb
		r = parseInt(color.substring(1, 3), 16);
		g = parseInt(color.substring(3, 5), 16);
		b = parseInt(color.substring(5, 7), 16);

		const rgba      = 'rgba(' + r + ',' + g + ',' + b + ',' + percent + ')';
		const resultHex = convertColor(rgba);

		return resultHex.hex;
	}

	onChartIsClicked($event: CsDataDescribedClickEventArgs, data: DashboardChartNxtDataDescribed) {

		const result = updateTargetSources($event, data, this.name);

		if (result === null || !this.isClickable)
			return;

		this.dashboardEventHub.isDashboardEntryIsClicked.next(result);
	}

	protected isResized() {
		const newWidth  = this.element.nativeElement.parentElement.clientWidth;
		const newHeight = this.element.nativeElement.parentElement.clientHeight;

		if (newWidth === this.oldWidth && newHeight === this.oldHeight) {
			return false;
		}

		this.oldWidth  = this.element.nativeElement.parentElement.clientWidth;
		this.oldHeight = this.element.nativeElement.clientHeight;
		return true;

	}

	chartClicked($event: MouseEvent, data: DashboardChartNxtDataDescribed) {

		if ($event.ctrlKey) {
			LoggerUtil.log(this.chartDir.getDebugInfo());
			return;
		}

		if (!this.isWholeChartClickable) {
			LoggerUtil.debug('Whole chart is not clickable marked, has no selectionValue');
			return;
		}

		$event.preventDefault();
		$event.cancelBubble = true;
		const result        = updateTargetSources({
			column: null,
			row:    null
		} as CsDataDescribedClickEventArgs, data, this.name);

		if (result === null)
			return;

		this.dashboardEventHub.isDashboardEntryIsClicked.next(result);

	}


	/**
	 * The chart is clickable when the following criteria is met:
	 * - there is no field with a {@link PropertyAnnotation.selectionValueSource}
	 * - there is at least one field that:
	 *    & has {@link PropertyAnnotation.selectionTrigger} set to {@link SelectionTriggerEnum.Entity}
	 *    & has a {@link PropertyAnnotation.selectionValue}
	 *
	 * @param value The data that should be analyzed for the chart capabilities
	 */
	checkIfWholeChartClickable(value: DashboardChartNxtDataDescribed) {
		// check if some of the field have a selectionValueSource
		if (value.dataAnnotation.fields.some(iss => !isNullOrUndefined(iss.selectionValueSource)))
			return false;

		// The chart is not selectable when selectionValue is not set. Because it doesn't know what to do
		if (value.dataAnnotation.fields.some(iss => iss.selectionTrigger === 'Entity' && !isNullOrUndefined(iss.selectionValue)))
			return true;

		// if not matching the previous statement it is not clickable
		return false;

	}

	/**
	 * The chart series are clickable when the following criteria is met:
	 * - there is at least one field with a {@link PropertyAnnotation.selectionValueSource}
	 *
	 * @param value The data that should be analyzed for the chart capabilities
	 */
	checkIfClickable(value: DashboardChartNxtDataDescribed) {
		if (isNullOrUndefined(value.dataAnnotation))
			return false;

		return value.dataAnnotation.fields.some(iss => !isNullOrUndefined(iss.selectionTrigger));
	}


	protected OnDestroy(): void {
		this.resizeSensor.detach();
	}
}
