import {
	ChangeDetectionStrategy, ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	ViewChild
} from '@angular/core';

import { DynamicButtonClickedEventArgs } from './event-args/dynamic-button-clicked-event-args';

import { isNullOrUndefined } from '@cs/core';
import { DynamicButton }     from '@cs/performance-manager/shared';
import {
	IButton,
	IChartDataSet,
	CsChartLoaderDirective, CsChartLoaderSetup
}                            from '@cs/components';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { ComponentChanges, whenChanging }             from '@cs/core';
import { SafeMethods }                                from '@cs/common';
import { StatisticsPanelData }                        from '../statistics-panel/models/statistics-models';
import { Observable, Subject }                        from 'rxjs';
import { IDataSource }                                from '../statistics-panel/interfaces/interfaces';


@Component({
	selector:        'cs-chart-panel',
	templateUrl:     './chart-panel.component.html',
	styles:          [
		`
		:host .events, :host .event-form {
			width: 100%;
		}

		.events-container, .event-form-container {
			padding: 20px;
		}

		.incomplete-data-message {
			background-color: rgb(192, 0, 0);
			font-size: 0.8em;
			color: #ffffff;
			padding: 2px;
		}
		`
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations:      [
		trigger('isCollapsed', [
			state('false', style({height: '*', opacity: 1})),
			state('true', style({height: 0, opacity: 0, padding: 0, pointerEvents: 'none'})),
			transition('true <=> false', [
				animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)')])
		]),
		trigger('loadingPanelState', [
			state('true', style({
				filter:        'blur(3px)',
				opacity:       0.3,
				pointerEvents: 'none'
			})),
			state('false', style({
				filter:  'blur(0)',
				opacity: 1
			})),
			transition('true <=> false',
				animate('200ms ease-out'))
		]),
		trigger('chartPanelState', [

			transition(':leave', [ // each time the binding value changes

				style({opacity: 1}),
				animate('200ms ease-in',
					style({
						opacity: 0
					}))

			]), transition(':enter', [ // each time the binding value changes

				style({opacity: 0}),
				animate('375ms 150ms ease-out',
					style({
						opacity: 1
					}))

			])
		])
	]
})
export class CsChartPanelComponent implements OnChanges {
	@ViewChild('chartPanelWrapper') chartPanelWrapper: ElementRef;
	@ViewChild(CsChartLoaderDirective) chartDir: CsChartLoaderDirective;
	@ViewChild('fileinput') fileInputElement: ElementRef;

	private prevWidth;
	dataIncomplete = false;

	/**
	 * String that contains the title of the chart panel.
	 */
	@Input() title: string;

	/**
	 * The preset name of the serie that should have a visible area.
	 */
	@Input() activeSerie: string;

	/**
	 * Boolean value for collapsing the panel.
	 */
	@Input() collapsed: boolean;

	/**
	 * The data source that contains the values of the chart panel.
	 */
	@Input() chartPanelData: IChartDataSet;

	/**
	 * The options of the google chart.
	 */
	@Input() options: any;

	/**
	 * The data source that contains the values of the statistics panel.
	 */
	@Input() statsData: StatisticsPanelData[];

	/**
	 * The data source that contains the values of the statistics panel.
	 */
	@Input() dynamicButtons: Array<IButton>;

	/**
	 * A list of events that must be shown.
	 */
	@Input() events: any;

	/**
	 * A list of selected items in the datagrid, that must be shown in the event form.
	 */
	@Input() eventFormItems: any;

	/**
	 * Whether the event form should be displayed or not.
	 */
	@Input() eventFormVisible: any;

	/**
	 * Whether the events panel should be displayed or not.
	 */
	@Input() eventsPanelVisible: boolean;
	/**
	 * flag indicating that the chart panel show be in loading state
	 */
	@Input() isStatsLoading: boolean;
	/**
	 * flag indicating that the chart panel show be in loading state
	 */
	@Input() isChartLoading: boolean;

	@Input() activeTab: 'stats' | 'workflow'                       = 'stats';
	/**
	 * Event that is triggered when an dynamic button is clicked.
	 */
	@Output() clicked: EventEmitter<DynamicButtonClickedEventArgs> = new EventEmitter<DynamicButtonClickedEventArgs>();

	@Output() isClosed: EventEmitter<any>               = new EventEmitter<any>();
	@Output() isOpened: EventEmitter<any>               = new EventEmitter<any>();
	@Output() eventFormSubmitted: EventEmitter<any>     = new EventEmitter<any>();
	@Output() eventFormCancelled: EventEmitter<any>     = new EventEmitter<any>();
	@Output() eventMarkedAsRead: EventEmitter<any>      = new EventEmitter<any>();
	@Output() eventMarkedAsConfirmed: EventEmitter<any> = new EventEmitter<any>();
	@Output() eventDeleted: EventEmitter<any>           = new EventEmitter<any>();
	@Output() eventsOpened: EventEmitter<any>           = new EventEmitter<any>();
	@Output() eventsClosed: EventEmitter<any>           = new EventEmitter<any>();
	@Output() tabSwitched: EventEmitter<IDataSource>    = new EventEmitter<IDataSource>();

	primaryStatisticsData: StatisticsPanelData[];
	secondStatisticsData: StatisticsPanelData[];
	chartOptions: any;
	chartDataTable: any;
	chartType: string;

	private isLoadingLongerEmitter: Subject<boolean> = new Subject<boolean>();
	isLoadingLonger$: Observable<boolean>            = this.isLoadingLongerEmitter;
	private timeout: any | null                      = null;

	constructor(private elementRef: ElementRef,
							private chartSetup: CsChartLoaderSetup,
							public changeRef: ChangeDetectorRef) {
	}

	ngOnChanges(changes: ComponentChanges<CsChartPanelComponent>) {


		whenChanging(changes.isChartLoading, true).execute(value => {
			const loading = value.currentValue;
			if (loading && this.timeout === null) {
				this.timeout = window.setTimeout(() => {
					this.isLoadingLongerEmitter.next(loading);
					this.timeout = null;
				}, 300);

			} else if (!loading && this.timeout) {
				clearTimeout(this.timeout);
				this.isLoadingLongerEmitter.next(loading);
				this.timeout = null;
			} else if (!loading && this.timeout === null) {
				this.isLoadingLongerEmitter.next(loading);
			}
		});

		if (changes.hasOwnProperty('activeSerie')) {
			if (!isNullOrUndefined(this.chartDir))
				this.chartDir.resize();
		}

		if (changes.hasOwnProperty('dynamicButtons')) {
			if (!isNullOrUndefined(this.dynamicButtons)) {
				this.dynamicButtons = this.dynamicButtons.map(x => new DynamicButton(x));
			}
		}

		// if (changes.hasOwnProperty('isStatsLoading')) {
		//   if (changes.isStatsLoading.currentValue && !isNullOrUndefined(this.statisticsPanel)) {
		//     this.statisticsPanel.loadingState = 'isLoading';
		//   } else if (!changes.isStatsLoading.currentValue && !isNullOrUndefined(this.statisticsPanel)) {
		//     this.statisticsPanel.loadingState = 'isLoaded';
		//   }
		//   if (!isNullOrUndefined(this.statisticsPanel))
		//     this.statisticsPanel.markAsChanged();
		//
		//   this.changeRef.detectChanges();
		// }

		whenChanging(changes.statsData, true).execute(value => {
			this.parseDataSource(value.currentValue);
			if (!isNullOrUndefined(this.chartDir))
				this.chartDir.resize();

		});

		whenChanging(changes.chartPanelData, true).execute(value => {
			this.chartSetup.mapToDataTable(value.currentValue, null).then(value1 => {
				this.setChart(value.currentValue, value1.dataTable);
			});

		});

	}

	setChart(chartData: IChartDataSet, data: any) {

		this.chartType      = chartData.chartType || 'LineChart';
		this.chartDataTable = data;
		this.chartOptions   = this.mergeOptions(this.options, chartData.series);
		SafeMethods.detectChanges(this.changeRef);
	}

	getClientRect(): ClientRect {
		return this.elementRef.nativeElement.getBoundingClientRect();
	}

	mergeOptions(options, series) {
		if (!options.series) {
			options.series      = [];
			options.areaOpacity = 0.065;
		}

		for (const i of Object.keys(series)) {
			const serieData = series[i];
			let data: any   = {};

			if (serieData.preset && serieData.preset.chartStyle) {
				data = serieData.preset.chartStyle;
			}

			if (serieData.presetName !== this.activeSerie) {
				data.areaOpacity = 0;
			}
			// use the serie settings for overrides
			if (serieData.hasOwnProperty('serieOptions'))
				Object.assign(data, serieData.serieOptions);

			options.series[i] = data;
		}

		return options;
	}

	toggleMenuState(e) {
		this.collapsed = !this.collapsed;

		if (this.collapsed) {
			this.isClosed.emit();
		} else {
			this.isOpened.emit();
		}
		e.stopPropagation();
	}

	toggleEvents() {
		if (this.eventsPanelVisible)
			this.eventsOpened.emit(this.eventsPanelVisible);
		else
			this.eventsClosed.emit(this.eventsPanelVisible);
	}

	dynamicButtonClicked(dynamicButton, files?: FileList) {
		const eventArgs = new DynamicButtonClickedEventArgs(dynamicButton, files || null);
		this.clicked.emit(eventArgs);
	}

	onEventFormSubmitted(e) {
		this.eventFormSubmitted.emit(e);
	}

	onEventFormCancelled(e) {
		this.eventFormCancelled.emit(e);
	}

	onEventMarkedAsRead(e) {
		this.eventMarkedAsRead.emit(e);
	}

	onEventMarkedAsConfirmed(e: any) {
		this.eventMarkedAsConfirmed.emit(e);
	}

	onEventDeleted(e) {
		this.eventDeleted.emit(e);
	}

	/**
	 * Clear the Input type=file element, to allow uploading of the same file
	 */
	clearInputElementValue(event: Event) {
		this.fileInputElement.nativeElement.value = '';
	}

	private parseDataSource(data: StatisticsPanelData[]) {

		const primaryData   = [];
		const secondaryData = [];

		if (data.length > 1) {
			this.primaryStatisticsData = data.filter((value, index) => index !== data.length - 1);
			this.secondStatisticsData  = [data[data.length - 1]];
		} else {
			this.primaryStatisticsData = data;
			this.secondStatisticsData  = [];
		}

		this.changeRef.detectChanges();
	}
}
