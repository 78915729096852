import { Injectable }                                                                            from '@angular/core';
import { BottomBarStore }                                                                        from './bottom-bar.store';
import { Subject }                                                                               from 'rxjs';
import { DynamicButtonBarClickedEventArgs }                                                      from '../../pm-dynamic-button-bar/dynamic-button-bar.event-args';
import { CsButtonBarInputOptions }                                                               from '../cs-button-bar.models';
import { BottomBarQuery }                                                                        from './bottom-bar.query';
import { MinlengthValidationAnnotation, RequiredValidationAnnotation, ValidationAnnotationType } from '@cs/core';
import { CsValidatorRegistry, FormGeneratorNxtParser }                                           from '@cs/components';
import { TranslateService }                                                                      from '@ngx-translate/core';

export enum BottomBarRequestActions {
  DISABLE_ALL_BUTTONS,
  RESET_BAR
}

export interface BottomBarRequestAction<T> {
  payload?: T;
  action: BottomBarRequestActions;
}

@Injectable({providedIn: 'root'})
export class BottomBarService {

  requestAction: Subject<BottomBarRequestAction<unknown>>    = new Subject();
  onButtonClicked: Subject<DynamicButtonBarClickedEventArgs> = new Subject();

  constructor(private bottomBarStore: BottomBarStore,
              private csValidatorRegistry: CsValidatorRegistry,
              private i8n: TranslateService,
              private bottomBarQuery: BottomBarQuery) {
  }

  disableAllButtons() {
    this.requestAction.next({action: BottomBarRequestActions.DISABLE_ALL_BUTTONS, payload: true});
  }

  enableAllButtons() {
    this.requestAction.next({action: BottomBarRequestActions.DISABLE_ALL_BUTTONS, payload: false});
  }

  toggleBottomBar(isOpen: boolean) {
    this.bottomBarStore.update({
      isOpen: isOpen
    });
  }

  isDataValid(stateOfData: boolean) {
    this.bottomBarStore.update(
      {dataIsValid: stateOfData}
    );
  }

  registerChange(change: number) {
    this.bottomBarStore.update(
      {unsavedChanges: change}
    );
  }

  useRowLabel(change: boolean) {
    this.bottomBarStore.update(
      {isRowLabel: change}
    );
  }

  /**
   * Configures the input with options
   */
  inputOptions(inputOptions: CsButtonBarInputOptions) {
    this.bottomBarStore.update(
      {inputOptions: inputOptions}
    );
  }

  /**
   * Current value of the input
   */
  inputValue(value: string) {
    this.bottomBarStore.update({inputValue: value});
  }

  /**
   * Makes input required
   */
  inputRequired(required: boolean) {
    this.bottomBarStore.update({inputRequired: required});
    // test case
    this.setInputValidators(required);
  }

  /**
   * Set validators for the reason
   * @param required is required to supply a reason for the changes
   */
  private setInputValidators(required: boolean) {
    const options: CsButtonBarInputOptions = Object.assign({}, this.bottomBarQuery.getValue().inputOptions);
    let validators                         = [];

    if (!options.validations)
      options.validations = [];

    if (required && options.validations && !options.validations.find(value => value.type === ValidationAnnotationType.Required)) {
      validators = [new RequiredValidationAnnotation({errorMessage: this.i8n.instant('REQUIRED_MESSAGE')}),
                    new MinlengthValidationAnnotation({
                      value:        5,
                      errorMessage: this.i8n.instant('REQUIRED_MIN_LENGTH_MESSAGE', {value: 5})
                    }),
                    ...options.validations];
    } else if (options.validations) {
      validators = options.validations.filter(annotation => annotation.type !== ValidationAnnotationType.Required);
    }

    this.bottomBarStore.update({
      inputValidators: FormGeneratorNxtParser.parseValidatorsList(validators, this.csValidatorRegistry)
    });
  }

  /**
   * Reset the button bar to a clean state
   */
  resetButtonBar() {
    this.requestAction.next({action: BottomBarRequestActions.RESET_BAR});
  }
}
