import { Component, OnChanges, Input }                       from '@angular/core';
import {
	ComponentChanges, whenChanging, hasPropertyOf,
	DataAnnotation
}                                                            from '@cs/core';
import { SingleIndicatorData, SingleIndicatorDataDescribed } from './models/single-indicator-data-described';
import { TranslateService }                                  from '@ngx-translate/core';
import { isNullOrUndefined }                                 from '@cs/core';

@Component({
	selector:    'cs-single-indicator',
	templateUrl: './single-indicator.component.html'
})
export class SingleIndicatorComponent implements OnChanges {

	@Input() dataSource: SingleIndicatorDataDescribed;
	@Input() dataAnnotation: DataAnnotation<any>;

	/**
	 * The value for the comparison
	 */
	value: string;
	/**
	 * Label for the value
	 */
	label: string;
	/**
	 * A reason to be shown when the value is null
	 */
	emptyDataReason: string;
	/**
	 * A boolean that will tell if the emptyDataReason is null or undefined
	 */
	emptyDataReasonIsNull: boolean;

	formatValue  = '{0:P0}';
	formatOffset = '{0:P0}';
	formatGoal   = '{0:P0}';

	constructor(private readonly i8n: TranslateService) {
	}

	ngOnChanges(changes: ComponentChanges<SingleIndicatorComponent>) {
		whenChanging(changes.dataSource, true)
			.execute(changedvalue => {
				if (hasPropertyOf(changedvalue.currentValue, 'dataAnnotation')
					&& hasPropertyOf(changedvalue.currentValue.dataAnnotation, 'fields')) {
					const fields      = changedvalue.currentValue.dataAnnotation.fields;
					const value       = fields.find(val => val.id === 'value');
					const offset      = fields.find(val => val.id === 'offset');
					const goal        = fields.find(val => val.id === 'goal');
					this.formatValue  = (!isNullOrUndefined(value) && hasPropertyOf(value, 'format')) ?
						value.format : this.formatValue;
					this.formatOffset = (!isNullOrUndefined(offset) && hasPropertyOf(offset, 'format')) ?
						offset.format : this.formatOffset;
					this.formatGoal   = (!isNullOrUndefined(goal) && hasPropertyOf(goal, 'format')) ?
						goal.format : this.formatGoal;

					this.value = changedvalue.currentValue.data[0].value;
					this.label = changedvalue.currentValue.data[0].label;

					this.emptyDataReasonIsNull = isNullOrUndefined(changedvalue.currentValue.data[0].emptyDataReason);
					this.emptyDataReason       = changedvalue.currentValue.data[0].emptyDataReason;
				}
			});
	}

	getOffsetMessage(indicator: SingleIndicatorData) {

		switch (indicator.offsetStyle) {
			case 'good':
				return this.i8n.instant('BETTER_THAN_AVERAGE');
			case 'bad':
				return this.i8n.instant('WORSE_THAN_AVERAGE');
		}

	}

}
