import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder, ValidatorFn }   from '@angular/forms';

import {
	CsFormGeneratorSaveField, CsFormGeneratorActionEvent, CsFormGeneratorAction,
	CsFormGeneratorDataSourceFormAction, CsFormGeneratorDataSourceLookup, IDownloadRequestEventArgs, CSFormGeneratorDataSourceTree,
	CSFormGeneratorDataSourceTreeMember, CSFormGeneratorDataSourceLookupValues
} from './form-generator.models';

import {
	CsFormGeneratorEditMode,
	CsFormGeneratorLayout,
	CsFormGeneratorDataSourceFormField,
	CsFormGeneratorDataSource,
	CsFormGeneratorDataSourceFormValidation
}                                                           from './form-generator.models';
import { isNull, isNullOrUndefined, isString, isUndefined } from '@cs/core';
import { isNumeric }                                        from '@cs/core';
import { Logger }                                           from '@cs/components/util';

@Component({
	selector:    'cs-form-generator',
	templateUrl: 'form-generator.component.html'
})

export class CsFormGeneratorComponent implements OnChanges {
	/**
	 * The full dataSource of for filling and layouting the Form.
	 */
	@Input() dataSource: CsFormGeneratorDataSource;

	/**
	 * Enables or disables the translation of Placeholders and Labels.
	 */
	@Input() translate = false;

	/**
	 * Kind of Edit Mode.
	 */
	@Input() editMode: CsFormGeneratorEditMode = 'all';

	/**
	 * Type Layout used for the Form.
	 */
	@Input() layout: CsFormGeneratorLayout = 'vertical';

	/**
	 * Enables or disables Edit Mode.
	 * Also used for opening the Form already in Edit Mode.
	 */
	@Input() isInEditMode       = false;
	/**
	 * Keep the form in edit mode after saving
	 */
	@Input() keepFormInEditMode = false;
	/**
	 * Read Only Form.
	 */
	@Input() readOnly           = false;

	/**
	 * Show revert button

	 */
	@Input() showRevert = false;

	/**
	 * Dateformat for the date picker: iddate | ISO

	 */
	@Input() dateFormat = 'iddate';
	/**
	 * Set label for the edit button if required
	 */
	@Input() editBtnLabel: string;
	/**
	 * Saves the full Form.
	 */
	@Output() saveForm  = new EventEmitter<Object>();

	/**
	 * Saves just a Form Field.
	 */
	@Output() saveField = new EventEmitter<CsFormGeneratorSaveField>();

	/**
	 * Outputs an action for a field.
	 */
	@Output() fieldAction = new EventEmitter<any>(); // TODO: Add interface.

	/**
	 * Outputs a Custom Validation rule.
	 */
	@Output() customValidation = new EventEmitter<any>(); // TODO: Add interface.

	/**
	 * Outputs user changes to form
	 */
	@Output() formGroupChanges = new EventEmitter<any>(); // TODO: Add interface

	@Output() cancelForm = new EventEmitter();

	@Output() downloadRequest = new EventEmitter<IDownloadRequestEventArgs>();
	/**
	 * Indicates the change in edit mode

	 */
	@Output() editModeChanged = new EventEmitter<boolean>();

	activeSaveFieldName: string;
	formGroup: FormGroup;
	fields: Array<CsFormGeneratorDataSourceFormField> = [];
	showFieldButtons                                  = false; // Not completely implemented and interferes with CSS
	fieldValuesAsString: { [key: string]: string };
	currentFormGroupValue: any;
	private submitted                                 = false;
	private disabledFormFields: Array<string>;
	private isInit                                    = false;

	/**
	 * Save the state of the form(data) for cancel and revert
	 */
	private dataSource_beforeEdit: CsFormGeneratorDataSource;
	private formValues_beforeEdit: any;

	/**
	 * Save the prepared form data of the advanced dropdown components
	 */
	advancedDropDownDataSources = {};

	/**
	 * Save the prepared data for the combobox components
	 */
	comboboxOptions = {};


	// TODO: Add manual Change Detection for improving performance.
	// TODO: Other performance improvements.
	constructor(private fb: FormBuilder) {
	}

	/**
	 * Returns visible display values
	 * @param (boolean) onlyVisible, default = true
	 */
	public getCurrentDisplayValues(onlyVisible = true, asHtml = true, prefixLabel = true) {
		const item = {};
		for (const key of Object.keys(this.formGroup.value)) {
			if (!onlyVisible || this.fields.find((x) => {
				return x.name === key && !x.hidden;
			})) {
				let val      = this.formGroup.value[key];
				const field  = this.fields.filter((x) => {
					return x.name === key;
				})[0];
				const lookup = isUndefined(field) ? null : this.getLookupValues(field.lookup);
				if (isNullOrUndefined(lookup)) {
					item[key] = val;
				} else {
					const found = lookup.filter(x => {
						// There are two types of lookups, multiselect with "id" and single with "key".
						// lookup key can be both number and string
						if (field.uiType === 'checkboxlist' || field.uiType === 'radiolist') {
							// tslint:disable-next-line:triple-equals
							return val.toString().split(',').some(v => x.hasOwnProperty('id') && x.id == v);
						} else if (field.uiType === 'combobox' && !isNullOrUndefined(val)) {
							return val.toString().split(',').some(v => x.hasOwnProperty('key') && x.key == v);
						} else {
							// tslint:disable-next-line:triple-equals
							return x.key == val;
						}
					});
					if (!found.length) {
						Logger.Warning(`Display values: Value ${val} is not found in lookup: ${key}`);
						if (field.uiType === 'checkboxlist' || field.uiType === 'radiolist') {

						} else if (field.uiType === 'checkboxtree') {
							item[key] = lookup.slice(0, lookup.length > 2 ? 2 : lookup.length).map(po => po.value).join(', ') + ` (${lookup.length})`;
						} else {
							item[key] = lookup.length > 0 ? lookup[0].value : val;
							// Show empty string if val is null when UI is a simple-select, with a lookup (not valid for reporting)
							// or if it's a combobox with default value set to none
							if ((lookup.length > 0 && field.uiType === 'simple-select' && val === null) || (field.uiType === 'combobox' &&
								!isNullOrUndefined(field.elementParameters.default) && field.elementParameters.default === 'none'))
								item[key] = '';
						}
					} else {
						item[key] = found.map(x => x.value).join(', ');
					}
				}
				// Show empty string if null or undefined
				let itemValue = isNullOrUndefined(item[key]) ? '' : item[key];

				// special case
				if (!isNullOrUndefined(field) && field.uiType === 'password')
					itemValue = '';

				// special case: file-select it's own readonly output
				if (asHtml) {
					if (prefixLabel) {
						item[key] = `<span class="reporting-filter-label">${field.label}</span>: <span class="reporting-filter-value">${itemValue}</span>`;
					} else {
						item[key] = `<span class="reporting-filter-value">${itemValue}</span>`;
					}
				} else {
					if (prefixLabel) {
						item[key] = `${field.label}: ${itemValue}`;
					} else {
						item[key] = `${itemValue}`;
					}
				}
			}
		}
		return item;
	}

	/**
	 * Hides fields that have the onEditMode event, when the edit mode is true
	 */
	fieldsEditMode() {
		this.fields.forEach((field) => {
			if (!isNullOrUndefined(field.actions) && field.actions.length) {
				// Apply actions for onEditMode Events
				const onEditModeFields = field.actions.filter(x => x.event === CsFormGeneratorActionEvent.onEditMode.toString());
				onEditModeFields.forEach((actionEvent) => {
					if (this.isInEditMode) {
						actionEvent.actions[0] = 'hideField';
					} else {
						actionEvent.actions[0] = 'showField';
					}

					actionEvent.actions.forEach((action) => {
						this.executeActionEvent(actionEvent.eventSource || field.name, field.name, actionEvent, null);
					});
				});
			}
		});
	}

	/**
	 * React to changes
	 */
	ngOnChanges(changes?: any) {
		if (this.validateInputs()) {
			if (!this.isInEditMode || (!isNullOrUndefined(changes) && !isNullOrUndefined(changes.dataSource) && isNullOrUndefined(changes.dataSource.previousValue))) {
				this.dataSource_beforeEdit = Object.assign({}, this.dataSource);
				this.formValues_beforeEdit = Object.assign({}, this.dataSource.data);
			}
		}
		// When formGroup is not initalized as empty here, the ExpressionChangedAfterItHasBeenCheckedError exception after onRevert goes away --jv
		this.buildForm();
	}

	/**
	 * Validate input parameters

	 */
	validateInputs() {
		if (isNullOrUndefined(this.dataSource)) {
			return false;
		}
		return true;
	}

	/**
	 * Rebuild form when dataSource changes
	 */
	buildForm() {
		if (this.validateInputs()) {
			// Flatten list of fields
			this.fields = this.dataSource.form.elements.reduce(function (a, b) {
				return a.concat(b.elements);
			}, []);

			// TODO:REMOVE this when CF decimal issue is fixed
			this.fixLookup();

			// Prepare Custom Dropdown/Combobox DataSources
			this.preprocessDataSource();

			// Form methods.
			const initial = this.getInitialData();
			this.setForm(initial);
			this.disabledFormFields = [];
			this.changeDisableFormgroup();
			this.applyActionEvents();
			this.fieldsEditMode();
			this.fieldValuesAsString = this.getCurrentDisplayValues(false, false, false);
			this.subscribeCustomChangeDetector();
		} else {
			this.formGroup = this.fb.group({});
		}
	}

	/**
	 * Toggles Edit Mode.
	 *

	 */
	toggleIsInEditMode() {
		if (!this.keepFormInEditMode)
			this.isInEditMode = !this.isInEditMode;

		if (this.isInEditMode) {
			this.dataSource_beforeEdit = this.dataSource;
			this.formValues_beforeEdit = this.formGroup.value;

			// create copy of form group value to detect changes
			this.currentFormGroupValue = this.formGroup.value;
			// for form validation when entering edit mode
			const tempvar              = this.formGroup.valid;
		}
		if (!this.keepFormInEditMode)
			this.fieldsEditMode();
		else {
			this.formGroup.markAsPristine();
		}

		this.fieldValuesAsString = this.getCurrentDisplayValues(false, false, false);
		this.changeDisableFormgroup();

		this.editModeChanged.emit(this.isInEditMode);
	}

	/**
	 * Returns if the layout is 'horizontal'.
	 *
	 * @returns

	 */
	isLayoutHorizontal(): boolean {
		return this.layout === 'horizontal';
	}

	fixLookup(): any {
		if (this.dataSource.lookups !== undefined) {
			for (let a = 0; a < this.dataSource.lookups.length; a++) {
				// TODO: Remove temp fix for CF decimal issue
				if (!isNullOrUndefined(this.dataSource.lookups[a].values)) {
					this.dataSource.lookups[a].values.forEach(x => {
						// e.g. 2001.0
						if (x.key.toString().match(/\.0$/)) {
							x.key = isNaN(parseFloat(x.key)) ? x.key : parseFloat(x.key);
						}
					});
				}
			}
		}
	}

	getLookup(lookupName: string): CsFormGeneratorDataSourceLookup {
		if (this.dataSource.lookups !== undefined) {
			for (let a = 0; a < this.dataSource.lookups.length; a++) {
				if (this.dataSource.lookups[a].name === lookupName) {
					return this.dataSource.lookups[a];
				}
			}
		}
	}

	/**
	 * Get the lookupValues for the Field Type.
	 */
	getLookupValues(lookupName: string): Array<any> {
		const lookup = this.getLookup(lookupName);
		if (!isNullOrUndefined(lookup)) {
			if (lookup.tree)
				return this.flattenTree(lookup.tree.members).filter(val => val.group === lookup.tree.levels.length);
			else if (lookup.values)
				return lookup.values;
		}
		return [];
	}

	flattenTree(members: Array<CSFormGeneratorDataSourceTreeMember>, output = []): CSFormGeneratorDataSourceLookupValues[] {

		for (const member of members) {
			if (member.members)
				this.flattenTree(member.members, output);
			else if (member.selected) {
				output.push({
					id:    member.id,
					key:   member.lookupKey,
					group: member.depth,
					value: member.label
				} as CSFormGeneratorDataSourceLookupValues);
			}
		}
		return output;
	}

	/**
	 * Get the tree for the Field Type.
	 */
	getTree(lookupName: string): CSFormGeneratorDataSourceTree {
		const lookup = this.getLookup(lookupName);
		if (!isNullOrUndefined(lookup)) {
			return lookup.tree;
		}
		return null;
	}

	/**
	 * Cancels current edits
	 */
	onCancelForm() {
		if (!this.keepFormInEditMode)
			this.toggleIsInEditMode();

		this.onRevertForm();
		this.cancelForm.emit();
	}

	/**
	 * Reverts form to original state
	 */
	public onRevertForm() {
		this.dataSource      = Object.assign({}, this.dataSource_beforeEdit);
		this.dataSource.data = Object.assign({}, this.formValues_beforeEdit);
		this.buildForm();
	}

	/**
	 * Saves the full form.
	 */
	onSaveForm(valid: boolean) {
		this.submitted = true;
		if (valid) {
			this.saveForm.emit(this.formGroup.value);
			this.dataSource_beforeEdit = Object.assign({}, this.dataSource);
			this.toggleIsInEditMode();

		}

		// TODO: Show validation?
		// TODO: Custom change detection hook.
	}

	/**
	 * Emits a event of a single field to save it and closes it.
	 */
	onSaveField(field: CsFormGeneratorDataSourceFormField, event: MouseEvent) {
		if (this.editMode !== 'single') {
			return;
		}

		this.saveField.emit({
			data:  this.formGroup.value[field.name],
			field: field
		});
		this.onToggleSaveField(event);
	}

	/**
	 * Toggles the save field buttons.
	 */
	onToggleSaveField(event: MouseEvent, fieldName?: string): void {
		if (this.editMode !== 'single') {
			return;
		}

		if (fieldName === undefined) {
			// Means closing it with the button group button, so having no active field for saving.
			this.formGroup.get(this.activeSaveFieldName).disable();
			this.activeSaveFieldName = undefined;
			event.stopPropagation();
		} else if (this.activeSaveFieldName !== fieldName) {
			let control = this.formGroup.get(this.activeSaveFieldName);
			if (control !== null) {
				control.disable();
			}
			this.activeSaveFieldName = fieldName;
			this.formGroup.get(fieldName).enable();
		}
	}

	/**
	 * Emits a Field Action to the Parent Component.
	 */
	onFieldAction(action: string, field: CsFormGeneratorDataSourceFormField, event: MouseEvent) {
		event.stopPropagation();
		this.fieldAction.emit({
			action: action,
			field:  field
		});
	}

	/**
	 * Show a validation for an item or not depending on the field with it's type.
	 */
	showValidation(fieldName: string, type: string): boolean {
		if (!this.formGroup.controls.hasOwnProperty(fieldName) || (this.formGroup.controls[fieldName].pristine && !this.submitted)) {
			return false;
		}

		let errors: CsFormGeneratorDataSourceFormValidation | null = <any>this.formGroup.controls[fieldName].errors;
		// Return no validation because there is no error found.
		if (errors === null) {
			return false;
		}

		switch (type) {
			case 'required':
				return errors.required !== undefined;
			case 'pattern':
				return errors.pattern !== undefined;
			default:
				return false;
		}
	}

	hasVisibleMembers(elements: Array<CsFormGeneratorDataSourceFormField>): boolean {
		return elements.some((element) => !element.hidden);
	}

	/**
	 * Toggles the full form is disabled or enabled.
	 * Prevents emition of valueChanged on each element
	 */
	private changeDisableFormgroup() {
		Object.keys(this.formGroup.controls).forEach((fieldName) => {
			if (this.disabledFormFields.indexOf(fieldName) === -1) {
				if (!this.isInEditMode) {
					this.formGroup.get(fieldName).disable({emitEvent: false});
				} else {
					this.formGroup.get(fieldName).enable({emitEvent: false});
				}
			}
		});
	}

	/**
	 * Preprocess dataSource for custom components
	 */
	private preprocessDataSource(): void {
		this.advancedDropDownDataSources = {};
		this.comboboxOptions             = {};
		this.fields.forEach((field) => {
			if (field.uiType === 'select') {
				this.advancedDropDownDataSources[field.name] = this.prepareAdvancedDropDownDataset(field);
			} else if (field.uiType === 'combobox') {
				const values                     = this.getLookupValues(field.lookup);
				this.comboboxOptions[field.name] = values.map(item => {
					return {value: item.key + '', label: item.value};
				}) || {};
			}
		});
	}

	/**
	 * Set all form controls, overwriting existing ones
	 */
	private setForm(initial: any): void {
		let config = {};
		this.fields.forEach((field) => {
			// Create an Array of Validators which need to be applied to the FormControl.
			let validators: Array<ValidatorFn> = [];
			if (!isNullOrUndefined(field.validation)) {
				if (field.validation.required) {
					validators.push(Validators.required);
				}
				if (!isNullOrUndefined(field.validation.pattern)) {
					validators.push(Validators.pattern(field.validation.pattern));
				}
			}
			config[field.name] = [initial[field.name], validators];
		});

		// overwrite the old formGroup as the updated dataSource could contain same-name control with different validators
		this.formGroup = this.fb.group(config);
	}

	/**
	 * Check OnInit Event state to do stuff.
	 * Called after the creation and after the data has been filled.
	 * Method has the right position to do anything with the Form.
	 */
	private applyActionEvents() {
		this.fields.forEach((field) => {
			if (!isNullOrUndefined(field.actions) && field.actions.length) {
				// Apply actions for onInit Events
				const onInitFields = field.actions.filter(x => x.event === CsFormGeneratorActionEvent.onInit.toString());
				onInitFields.forEach((actionEvent) => {
					actionEvent.actions.forEach((action) => {
						this.executeActionEvent(actionEvent.eventSource || field.name, field.name, actionEvent, null);
					});
				});
				// Set callback actions for onSelectionChanged Event
				const onChangedFields = field.actions.filter(x => x.event === CsFormGeneratorActionEvent.onSelectionChanged.toString());
				onChangedFields.forEach((action) => {
					this.setFormFieldActionCallbacks(field.name, action);
				});
			}
		});
	}

	/**
	 * Toggles from field status
	 */
	private toggleFormFieldDisabled(fieldName: string) {
		if (this.disabledFormFields.indexOf(fieldName) === -1) {
			this.formFieldDisable(fieldName);
		} else {
			this.formFieldEnable(fieldName);
		}
	}

	/**
	 * Sets form field to enabled, updates list of disabled fields
	 */
	private formFieldEnable(fieldName: string) {
		if (this.disabledFormFields.indexOf(fieldName) !== -1) {
			this.disabledFormFields = this.disabledFormFields.filter(x => x !== fieldName);
			this.formGroup.get(fieldName).enable({emitEvent: false});
		}
	}

	/**
	 * Sets form field to disabled, updates list of disabled fields
	 */
	private formFieldDisable(fieldName: string) {
		if (this.disabledFormFields.indexOf(fieldName) === -1) {
			this.disabledFormFields.push(fieldName);
			this.formGroup.get(fieldName).disable({emitEvent: false});
		}
	}

	/**
	 * Visually hides the field, value remains in form output
	 */
	formFieldHide(fieldName: string) {
		const foundField  = this.fields.find(x => x.name === fieldName);
		foundField.hidden = true;
	}

	/**
	 * Visually shows the field
	 */
	formFieldShow(fieldName: string) {
		const foundField  = this.fields.find(x => x.name === fieldName);
		foundField.hidden = false;
	}

	/**
	 * Subscribe actionEvent for fieldName (onSelectionChanged)
	 */
	private setFormFieldActionCallbacks(fieldName: string, actionEvent: CsFormGeneratorDataSourceFormAction) {
		if (actionEvent.event === CsFormGeneratorActionEvent.onSelectionChanged.toString()) {
			const fieldSource = actionEvent.eventSource || fieldName;
			if (isNullOrUndefined(this.formGroup.get(fieldSource))) {
				Logger.Warning(`fieldname "${fieldSource}" is undefined for "${fieldName}". Skipping callback actions: ` + actionEvent.event);
			} else {
				this.formGroup.get(fieldSource).valueChanges
						.subscribe((x) => {
							this.executeActionEvent(fieldSource, fieldName, actionEvent, x);
						});
			}
		}
	}


	private executeActionEvent(fieldSource: string, fieldTarget: string,
														 actionEvent: CsFormGeneratorDataSourceFormAction,
														 onValueChangesEvent: any) {

		// current value of the field that triggered that action (source)
		const fieldValue = this.formGroup.get(fieldSource).value;

		// TODO: check dependency type
		console.log(actionEvent.event + ': ' + fieldSource + '->' + fieldTarget + '.' + actionEvent.actions);
		// If all validators return true, or no validators are defined: execute Actions, otherwise ActionsElse are executed
		const doActions = this.validateActionEvents(fieldValue, actionEvent.validators);
		if (doActions) {
			actionEvent.actions.forEach((action) => {
				this.applyActionEventAction(fieldTarget, action);
			});
		} else {
			if (!isNullOrUndefined(actionEvent.actionsElse)) {
				actionEvent.actionsElse.forEach((action) => {
					this.applyActionEventAction(fieldTarget, action);
				});
			}
		}
	}

	private validateActionEvents(fieldValue: any, validators: Array<any>): Boolean {
		if (isNullOrUndefined(validators) || validators.length === 0) {
			return true;
		}
		{
			const valid: Array<Boolean> = validators.map((validator) => {
				if (validator.type === 'regEx') {
					if (!isNullOrUndefined(validator.params)) {
						const regex = new RegExp(validator.params[0]);
						return !isNullOrUndefined(fieldValue) && (!isNull(fieldValue.toString().match(regex)));
					} else {
						// Logger.Warning('regEx Event action not implemented');
						Logger.Warning('Invalid regex for action event validator: ' + validator.params[0]);
						return false;
					}
				} else if (validator.type === 'greaterThen') {
					return isNumeric(fieldValue) && validator.params.length && fieldValue > validator.params[0];
				} else if (validator.type === 'greaterThenEqual') {
					return isNumeric(fieldValue) && validator.params.length && fieldValue >= validator.params[0];
				} else if (validator.type === 'lessThen') {
					return isNumeric(fieldValue) && validator.params.length && fieldValue < validator.params[0];
				} else if (validator.type === 'lessThenEquals') {
					return isNumeric(fieldValue) && validator.params.length && fieldValue <= validator.params[0];
				} else if (validator.type === 'equals') {
					return isNumeric(fieldValue) && validator.params.length && fieldValue === validator.params[0];
				} else if (validator.type === 'isDefined') {
					return !(isNullOrUndefined(fieldValue) || (isString(fieldValue) && fieldValue.length === 0));
				} else if (validator.type === 'isUndefined') {
					return isNullOrUndefined(fieldValue) || (isString(fieldValue) && fieldValue.length === 0);
				} else {
					Logger.Warning('No such validator: ' + validator.type);
					return true;
				}
			});
			return valid.length ? valid.reduce((a, b) => {
				return a && b;
			}, true) : false;
		}
	}

	/**
	 * Call mapped function "action" on fieldName
	 */
	private applyActionEventAction(fieldName: string, action: string) {
		switch (action) {
			case CsFormGeneratorAction.enableField.toString():
				this.formFieldEnable(fieldName);
				break;
			case CsFormGeneratorAction.disableField.toString():
				this.formFieldDisable(fieldName);
				break;
			case CsFormGeneratorAction.showField.toString():
				this.formFieldShow(fieldName);
				break;
			case CsFormGeneratorAction.hideField.toString():
				this.formFieldHide(fieldName);
				break;
			default:
				Logger.Warning(`Action "${action}" is not supported`);
		}
	}

	/**
	 * Sets only the initial data of the component.
	 * This method is not for populating the 'formGroup'.
	 */
	private getInitialData(): any {
		let initialData = {};

		this.fields.forEach((field) => {
			const lookup = this.getLookup(field.lookup);
			let value    = null;
			// get value from dataSource
			if (this.dataSource.data[field.name] !== undefined) {
				value = this.dataSource.data[field.name];
			}
			// check lookup for neutral values
			if (!isNullOrUndefined(lookup) && !isNullOrUndefined(lookup.neutralValue)) {
				let isInLookup = true;
				if (!isNullOrUndefined(lookup.values)) {
					// check if specified value exists in lookupvalues
					// tslint:disable-next-line:triple-equals
					if (isNullOrUndefined(lookup.values.find((x) => x.key == value))) {
						isInLookup = false;
						Logger.Warning(`Value "${value}" not found in lookup ${field.lookup}`);
					}
				}
				if ((!isInLookup || isNull(value))) {
					// apply neutral value or first item
					if (!isNullOrUndefined(lookup.neutralValue)) {
						Logger.Warning('Setting neutral value for: ' + field.name);
						value = lookup.neutralValue;
					} else if (!isNullOrUndefined(lookup.values) && lookup.values.length) {
						// This conflicts with checkbox lists, which can be empty
						value = lookup.values[0].key;
						Logger.Warning('Form-generator: no neutral value found, setting to first item of: ' + field.name);
					} else {
						Logger.Warning('Form-generator: no neutral value or lookups found: ' + field.name);
					}
				}
			}
			initialData[field.name] = value;
		});

		// Set initial data.
		return initialData;
	}

	private subscribeCustomChangeDetector(): void {
		// implement own FormGroup valueChanges detection due to Angular bug
		// in the form field disable and enable functions:
		// https://github.com/angular/angular/issues/12366
		this.currentFormGroupValue = this.formGroup.value;
		this.formGroup.valueChanges.subscribe((x) => {
			// this gets called by all the children of the FormGroup when enabled/disabled
			let changedKeys = [];
			for (let key in this.formGroup.value) {
				if (this.currentFormGroupValue.hasOwnProperty(key)) {
					if (this.currentFormGroupValue[key] !== this.formGroup.value[key]) {
						changedKeys.push(key);
					}
				}
			}
			if (changedKeys.length) {
				this.currentFormGroupValue = this.formGroup.value;
				this.formGroupChanges.emit(changedKeys);
			}
		});
	}


	/**
	 * Converts Select field lookup to the CsAdvancedDropdownComponent data format
	 */
	prepareAdvancedDropDownDataset(field: CsFormGeneratorDataSourceFormField): any {
		const lookups: Array<any> = this.getLookupValues(field.lookup);
		let data                  = [];
		if (!isNullOrUndefined(lookups)) {
			data = lookups.map((lookup) => {
				return {identifier: lookup.key, label: lookup.value};
			});
		}

		const fieldSearchOptions = {
			hasSearchBar:   false,
			searchEndpoint: ''
		};

		if (!isNullOrUndefined(field.elementParameters) && !isNullOrUndefined(field.elementParameters.search)) {
			Object.assign(fieldSearchOptions, field.elementParameters.search);
		}

		let dataSource = {
			identifier:    field.name,
			label:         field.label,
			search:        fieldSearchOptions,
			requireReload: false,
			values:        [{
				data: data
			}]
		};
		// apply element options
		if (!isNullOrUndefined(field.options)) {
			Object.assign(dataSource, field.options);
		}
		return dataSource;
	}

	/**
	 * Dropdown values could be string or number, and lookup keys are string. So, strict comparison is not required
	 */
	selectCompare(c1: any, c2: any): boolean {
		// tslint:disable-next-line:triple-equals
		return c1 == c2;
	}

	fileDownloadRequested(event: IDownloadRequestEventArgs) {
		this.downloadRequest.emit(event);
	}
}
