import {
	AfterViewInit, ChangeDetectorRef, Component, ElementRef,
	forwardRef, Inject, OnDestroy, ViewChild
}                                          from '@angular/core';
import { CsChartLoaderSetup }              from '@cs/components/cs-chart-loader';
import { IDashboardPanelComponent }        from '../../models/i-dashboard-panel-component';
import { DashboardChartNxtDataDescribed }  from './dashboard-chart-nxt-data-described.model';
import {
	ArrayUtils,
	LoggerUtil, mergeDeep, pathChecked, isNullOrUndefined
}                                          from '@cs/core';
import { CsDataDescribedChartLoaderSetup } from '@cs/components/cs-chart-loader';
import { DashboardEventHub }               from '../../dashboard-event-hub.service';
import { SafeMethods }                     from '@cs/common';
import { CsChartLoaderDirective }          from '@cs/components/cs-chart-loader';
import { ResizeSensor }                    from 'css-element-queries';
import { getSelectionTargetClass }         from '../../dashboard-helpers';
import { ChartLegendSeriesEventArgs }      from '@cs/components/chart-legend';
import { DashboardChartNxtBase }           from './dashboard-chart-nxt.base';

@Component({
	selector:    'cs-dashboard-chart-nxt',
	templateUrl: './dashboard-chart-nxt.component.html',
	styleUrls:   ['./dashboard-chart-nxt.component.scss']
})
export class DashboardChartNxtComponent extends DashboardChartNxtBase
	implements AfterViewInit, OnDestroy, IDashboardPanelComponent<DashboardChartNxtDataDescribed> {


	@ViewChild('csChartLoaderDirective') chartDir: CsChartLoaderDirective;


	set data(value: DashboardChartNxtDataDescribed) {
		this._data    = value;
		const options = pathChecked(this._data, ['layout', 'options'], {});

		this.isClickable           = this.checkIfClickable(value);
		this.isWholeChartClickable = this.checkIfWholeChartClickable(value);

		this.clickTypeClass = getSelectionTargetClass(value);
		this.chartSetup.mapToDataTable(this._data, null).then(value1 => {
			this.chartData    = value1.dataTable;
			this.chartOptions = mergeDeep(this.checkOptions(this._data), options);
			this.series       = this.chartSetup.patchLegend(value1.legendItems, this.chartOptions, this._data);
			SafeMethods.detectChanges(this.changeDetectorRef);
		});
	}

	get data(): DashboardChartNxtDataDescribed {
		return this._data;
	}

	private _data: DashboardChartNxtDataDescribed;

	chartData: any;

	chartOptions: any;

	constructor(@Inject(forwardRef(() => CsChartLoaderSetup))
								chartSetup: CsDataDescribedChartLoaderSetup,
							@Inject(forwardRef(() => DashboardEventHub))
								dashboardEventHub: DashboardEventHub,
							changeDetectorRef: ChangeDetectorRef,
							readonly element: ElementRef) {
		super(chartSetup, dashboardEventHub, changeDetectorRef, element);
	}


	ngOnDestroy(): void {
		this.OnDestroy();
	}


	update(data: DashboardChartNxtDataDescribed): void {
		if (!isNullOrUndefined(this.data) && !isNullOrUndefined(data) &&
			this.data && ArrayUtils.isEqual(this.data.data, data.data))
			return;

		this.data = data;
	}


	async refreshData() {
		if (this.chartDir)
			await this.chartDir.refreshData();
	}

	ngAfterViewInit(): void {

		const element = this.element.nativeElement.parentElement as HTMLDivElement;

		this.resizeSensor = new ResizeSensor(element, () => {
			if (this.isResized()) {
				LoggerUtil.debug(`Change of size detected: {old}:${this.oldHeight} => {new}: ${this.element.nativeElement.clientHeight}`);
				if (typeof (Event) === 'function') {
					// modern browsers
					window.dispatchEvent(new Event('resize'));
				} else {
					// for IE and other old browsers
					// causes deprecation warning on modern browsers
					const evt = window.document.createEvent('UIEvents');
					(<any>evt).initUIEvent('resize', true, false, window, 0);
					window.dispatchEvent(evt);
				}

				this.resizeSensor.detach();
			}
		});
	}


	onLegendMouseOver($event: ChartLegendSeriesEventArgs) {
		const legendGoogle = this.element.nativeElement
														 .querySelector(`g[column-id="${$event.series.label}"]`);

		if (legendGoogle != null)
			legendGoogle.dispatchEvent(new MouseEvent('mouseover', {'bubbles': true}));
	}

	onLegendMouseLeave($event: ChartLegendSeriesEventArgs) {
		const legendGoogle = this.element.nativeElement
														 .querySelector(`g[column-id="${$event.series.label}"]`);

		if (legendGoogle != null)
			legendGoogle.dispatchEvent(new MouseEvent('mouseout', {'bubbles': true}));
	}

	onLegendClicked($event: ChartLegendSeriesEventArgs) {
		// const legendGoogle = this.element.nativeElement
		// 												 .querySelector(`g[column-id="${$event.serie.label}"]`) as SVGGElement;
		// legendGoogle.attributes.setNamedItem(document.createAttribute('filter-active'));
		//
		// if (legendGoogle != null)
		// 	legendGoogle.dispatchEvent(new MouseEvent('click', {'bubbles': true}));

		this.chartDir.hideColumn($event.series.column);
		this.chartDir.hideLegend();
	}
}

