import {
	DataViewColumn, DataViewColumnPosition, DataViewRow,
	DataViewRowPosition
}                                                                                    from '../view';
import { isEmptyObject, LoggerUtil }                                                 from '../../../utils';
import { DataStructureGroup, DataStructureGroups }                                   from './data-structure-group';
import { DataLookup }                                                                from './data-structure-lookup';
import { DataStructureRow }                                                          from './data-structure-row';
import { convertDataStructureGroupToArray, DataFieldDefinition, UntypedDataViewRow } from '../utils';

export interface IDataConverterOptions {
	/**
	 * Flag indicating the field that should be used to replaced the AutoGroupColumn value,
	 * this field would also be hidden as and header
	 * ONLY FOR DATA BASED RENDERING
	 */
	useFieldAsValueAutoGroupColumn: boolean;
	/**
	 * Use the field specified by this property when the autoGroupColumn cell needs a value
	 * ONLY FOR DATA BASED RENDERING
	 */
	fieldToUseAsValueAutoGroupColumn?: string;

	/**
	 * Add extra lookups that are not provided by the data format.
	 */
	externalLookups: Map<string, DataLookup<string | number>>;

	/**
	 * Registers the lookup to the converter, so it could be used to resolve values not provided by the data format
	 * @param name The original lookup name
	 * @param lookup The lookup values
	 */
	addLookup(name: string, lookup: DataLookup<string | number>);

	/**
	 * Search and return an external added lookup
	 * @param name The original lookup name
	 */
	getLookup(name: string): DataLookup<string | number>;
}

export abstract class DataStructure<TOptions extends IDataConverterOptions = IDataConverterOptions> {
	headerDefinitions: Array<DataFieldDefinition>;


	static getHeadStructureLastNodes(headerGroups: DataStructureGroups) {
		if (headerGroups == null)
			return null;

		const lastNodes: Array<DataStructureGroup> = [];

		function checkForChildren(groups: DataStructureGroups, nodes: Array<DataStructureGroup>) {
			for (const groupName of Object.keys(groups)) {
				const groupStructure = groups[groupName];

				if (groupStructure.isLastNode)
					nodes.push(groupStructure);
				else {
					checkForChildren(groupStructure.children, nodes);
				}
			}
		}

		checkForChildren(headerGroups, lastNodes);

		return lastNodes.sort((a, b) => a.index - b.index);
	}

	/**
	 * gets the nodes at the requested depth that are not the last node. Because these are not groups
	 * @param headerGroups the haystack
	 * @param requestedDepth take a look at given depth
	 */
	static getHeadStructureAtDepth(headerGroups: DataStructureGroups, requestedDepth: number, includeLastNodes = false) {
		if (headerGroups == null)
			return null;

		const lastNodes: Array<DataStructureGroup> = [];

		function checkForChildren(groups: DataStructureGroups, nodes: Array<DataStructureGroup>, currentDepth: number) {

			for (const groupStructure of convertDataStructureGroupToArray(groups)) {

				// Only take the nodes that are not the last node. Because these are not groups
				if (currentDepth === requestedDepth && !groupStructure.isLastNode)
					nodes.push(groupStructure);
				// Check if we need to add the root nodes (ungrouped nodes) aswell, usefull for column rendering
				else if (includeLastNodes && groupStructure.isLastNode) {
					// TODO CLONE FUNCIONS
					const placeholder = new DataStructureGroup({...groupStructure});
					Object.assign(placeholder, groupStructure);
					placeholder.type = 'DataStructurePlaceholder';

					nodes.push(placeholder);
				}

				if (currentDepth !== requestedDepth && !isEmptyObject(groupStructure.children)) {
					checkForChildren(groupStructure.children, nodes, currentDepth + 1);
				}
			}
		}

		checkForChildren(headerGroups, lastNodes, 0);

		return lastNodes;
	}

	headerPositions: Array<DataViewRowPosition>;

	rowStructure: DataStructureGroups;
	dataRows: Array<UntypedDataViewRow>;
	groupedDataRows: Map<string, Array<UntypedDataViewRow>>;


	headerRows: Array<DataViewRow<DataViewColumn<unknown, unknown, unknown>, unknown>>;

	/**
	 * Array of Id's identifying the hierarchy of the Columns
	 */
	groupOrderColumns: Array<string>;
	/**
	 * Array of Id's identifying the hierarchy of the Data
	 */
	groupOrderRows: Array<string>;
	/**
	 * What is the field id used to create a column for group labels
	 */
	fieldIdAutoGroupColumn: string | null = null;
	/**
	 * Option that the data converter needs to know to convert data
	 */
	options: IDataConverterOptions | null;
	/**
	 * All lookups that are available in the data structure
	 */
	lookups: Map<string, DataLookup>      = new Map<string, DataLookup>();

	get headerRoot(): DataViewRow<DataViewColumn<unknown, unknown, unknown>, unknown> {
		if (this.headerRows == null)
			return null;

		return this.headerRows[this.headerRows.length - 1];
	}

	get headerStructure(): DataStructureGroups {
		return this._headerStructure;
	}

	set headerStructure(value: DataStructureGroups) {
		this._headerStructure = value;
		DataStructureGroups.createParentReferences(this._headerStructure);
	}


	get headerStructureLastNodes() {
		return DataStructure.getHeadStructureLastNodes(this.headerStructure);
	}

	constructor(init: Pick<DataStructure, 'groupOrderColumns' | 'groupOrderRows'>, options?: IDataConverterOptions) {
		this.groupOrderRows    = init.groupOrderRows;
		this.groupOrderColumns = init.groupOrderColumns;


		this.options = options || null;
	}

	findOrCreateEmptyLookup(key: string): DataLookup {
		// If not found create one
		if (!this.lookups.has(key))
			this.lookups.set(key, new DataLookup<unknown, unknown>({
																															 id:     key,
																															 values: []
																														 }));
		return this.lookups.get(key);
	}

	calculateDataColumnPositions(availableWidth: number) {
		// calculate the the cells based on the available space
		this.updateHeaderRootCellsWidth(availableWidth);
		// calculate the header positions and update the headerStructureGroup with that cell widths
		this.calculateHeaderPositions();
		// Apply the calculated positions to the the header row
		this.applyHeaderPositions();

	}

	applyHeaderPositions(): void {

		for (let depth = 0; depth < this.headerPositions.length; depth++) {
			const positionRow = this.headerPositions[depth];
			const headerRow   = this.headerRows[depth];

			for (let index = 0; index < headerRow.columns.length; index++) {
				const cell = headerRow.columns[index];
				// Get position for the cell

				const position = positionRow.getColumn(cell.structureKey);

				cell.position = position;
			}
		}
	}

	calculateHeaderPositions() {
		const headerRows: Array<DataViewRowPosition> = [];

		const appendRow = (rows: Array<DataViewRowPosition>,
											 pathSegments: DataStructureGroup[],
											 depth: number,
											 rowPath: string[]) => {

			const cells = [];
			const rowId = this.groupOrderColumns[depth];

			for (const headerSegment of pathSegments) {

				const cellWidth = headerSegment.getWidth();
				const pos       = new DataViewColumnPosition({
																											 width:         cellWidth,
																											 path:          headerSegment.path,
																											 index:         headerSegment.index,
																											 field:         headerSegment.structureKey,
																											 totalChildren: headerSegment.totalNumOfChildren,
																											 children:      headerSegment.numOfChildren,
																											 position:      0
																										 });
				if (headerSegment.isLastNode) {
					const field = this.headerDefinitions.find(value => value.structureKey === headerSegment.structureKey);

					if (field == null)
						LoggerUtil.error(`${headerSegment.structureKey} is not found as field`);

					if (!field.visible) {
						pos.width = 0;
					}
				}

				cells.push(pos);


				// if (!headerSegment.isLastNode)
				// 	nextRow.push(...convertDataStructureGroupToArray(headerSegment.children));
			}

			const row = new DataViewRowPosition({
																						cellPositions: cells,
																						path:          rowPath,
																						index:         depth,
																						id:            rowId
																					});
			rows.push(row);

			row.calculateCellPositionsAndRowWidth();
		};

		const levels = this.groupOrderColumns.length;
		const path   = [];
		for (let depth = 0; depth < levels; depth++) {
			path.push(this.groupOrderColumns[depth]);
			const header = depth === levels - 1
										 ? DataStructure.getHeadStructureLastNodes(this.headerStructure)
										 : DataStructure.getHeadStructureAtDepth(this.headerStructure, depth, true);
			appendRow(headerRows, header, depth, path.concat([]));
		}
		//	appendRow(headerRows, convertDataStructureGroupToArray(this.headerStructure), 0, []);
		this.headerPositions = headerRows;

		return this.headerPositions;
	}

	/**
	 * calculate the the cells based on the available space
	 * @param availableWidth provided width in pixels
	 * @protected
	 */
	protected abstract updateHeaderRootCellsWidth(availableWidth: number);


	private _headerStructure: DataStructureGroups;
}


