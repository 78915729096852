import { DataEntryStateIndicator, DataEntryStateStatus }     from './data-entry-state.indicator';
import { DataViewColumn, DataViewColumnInit, getPropertyOf } from '@cs/core';
import { DataEntryVisualState }                              from './data-entry-state-visual-state';


export enum DataEntryStateColumnType {
	DataEntryState  = 'DataEntryState',
	Text            = 'Text',
	Status          = 'Status',
	Label           = 'Label',
	Header          = 'Header',
	HeaderVertical  = 'HeaderVertical',
	Placeholder     = 'Placeholder',
	StatusWithLabel = 'StatusWithLabel'
}


export type DataEntryStateColumn =
	DataEntryStateTextColumn
	| DataEntryStateStatusColumn
	| DataEntryStateLabelColumn
	| DataEntryStateIndicatorColumn
	| DataEntryStateHeaderColumn
	| DataEntryStateHeaderVerticalColumn;

export class DataEntryStateTextColumn extends DataViewColumn<string, DataEntryStateColumnType, DataEntryVisualState> {
	type: DataEntryStateColumnType = DataEntryStateColumnType.Text;

	constructor(init: DataViewColumnInit<string, DataEntryStateColumnType>) {super(init);}
}

export class DataEntryStateStatusColumn extends DataViewColumn<DataEntryStateStatus, DataEntryStateColumnType, DataEntryVisualState> {
	type: DataEntryStateColumnType = DataEntryStateColumnType.Status;

	constructor(init: DataViewColumnInit<DataEntryStateStatus, DataEntryStateColumnType>) {super(init);}
}

export class DataEntryStateStatusWithLabelColumn extends DataViewColumn<DataEntryStateStatus, DataEntryStateColumnType, DataEntryVisualState> {
	type: DataEntryStateColumnType = DataEntryStateColumnType.StatusWithLabel;

	constructor(init: DataViewColumnInit<DataEntryStateStatus, DataEntryStateColumnType>) {super(init);}
}

export class DataEntryStateIndicatorColumn extends DataViewColumn<DataEntryStateIndicator, DataEntryStateColumnType, DataEntryVisualState> {
	type: DataEntryStateColumnType = DataEntryStateColumnType.DataEntryState;

	constructor(init: DataViewColumnInit<DataEntryStateIndicator, DataEntryStateColumnType>) {super(init);}
}

export class DataEntryStateLabelColumn extends DataViewColumn<string, DataEntryStateColumnType, DataEntryVisualState> {
	type: DataEntryStateColumnType = DataEntryStateColumnType.Label;

	constructor(init: DataViewColumnInit<string, DataEntryStateColumnType>) {super(init);}
}


export class DataEntryStateHeaderColumn extends DataViewColumn<string, DataEntryStateColumnType, DataEntryVisualState> {
	type: DataEntryStateColumnType = DataEntryStateColumnType.Header;

	constructor(init: DataViewColumnInit<string, DataEntryStateColumnType, DataEntryVisualState>) {
		super(init);

		this.visualState = getPropertyOf(init, 'visualState', new DataEntryVisualState());
	}
}

export class DataEntryStateHeaderPlaceholderColumn extends DataEntryStateHeaderColumn {
	type: DataEntryStateColumnType = DataEntryStateColumnType.Placeholder;

	constructor(init: DataViewColumnInit<string, DataEntryStateColumnType, DataEntryVisualState>) {
		super(init);

		this.visualState = getPropertyOf(init, 'visualState', new DataEntryVisualState());
	}
}

export class DataEntryStateHeaderVerticalColumn extends DataEntryStateHeaderColumn {
	type: DataEntryStateColumnType = DataEntryStateColumnType.HeaderVertical;

	constructor(init: DataViewColumnInit<string, DataEntryStateColumnType, DataEntryVisualState>) {
		super(init);

		this.visualState = getPropertyOf(init, 'visualState', new DataEntryVisualState());
	}
}

