<style>
    
</style>

<div class="progressInfo">
    <div *ngIf="progressInfo?.isRunning">
        Refreshing: {{progressInfo.processed}} / {{progressInfo.totalItems}}
    </div>
    <div *ngIf="!progressInfo?.isRunning">
        Ready!
    </div>
</div>

<div style="display: flex">

    <div #graphOutput class="item">
        No data.
    </div>

    <!-- <div #graphTestOutput class="item">
        Nothing here yet.
    </div> -->

</div>


<div class="statuslog">
    
</div>