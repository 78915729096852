export type StepState = 'isActive' | 'ísChecked' | 'isDisabled' | 'isChecked' | 'isError' | 'isWarning';

export class Step {
	name: string;
	label: string;
	description: string;
	content: string;
	canNavigateBack: boolean;
	updateSteps: boolean;
	validateWithServer: boolean;
	index: number;
	icon: string;
	data?: unknown;
	state: StepState = 'isDisabled';
	updateOn?: 'change' | 'blur' | 'submit';

	constructor(init: Step) {
		Object.assign(this, init);
	}
}
