import { IConfigService }               from '@cs/performance-manager/shared';
import { Observable }                   from 'rxjs';
import { CsHttpRequestOptions, Result } from '@cs/core';
import { PageList }                     from './models/page-list-item.model';
import { CfResultResponse }             from '@cs/performance-manager/shared';
import { FilterBarResultParams }        from '@cs/components';
import { HttpResponse }                 from '@angular/common/http';
import { MdmProfileContext }            from './models/mdm-properties-result-params';

export abstract class MdmPropertiesViewerConfigService implements IConfigService {

  moduleName = 'mdm-properties-viewer';
  /**
   * Fill the name so the server knows what data it should get from MDM
   * @Depricated
   */
  abstract mdmPageName: string;

  /**
   * Get a list of subpages that contains the properties
   * @param pageName the given @Link(mdmPageName)
   * @param params the selection keys for filtering MDM data
   */
  abstract getPageList(pageName: string, params: { [key: string]: any }): Observable<Result<PageList>>;

  /**
   * Get the properties for the given subpage
   * @param pageName the given @Link(mdmPageName)
   * @param params the selection keys for filtering MDM data
   */
  abstract getProperties(pageName: string, params: MdmProfileContext): Observable<Result<PageList>>;

  /**
   * Saves the changed properties
   * @param params the selection keys for filtering MDM data
   * @param changedItems change properties
   */
  abstract saveProperties(context: MdmProfileContext, changedItems: any[], options?: CsHttpRequestOptions): Observable<Result<CfResultResponse>>;

  /**
   * Get list with lookup values
   * @param lookupId the property name
   */
  abstract getLookup(lookupId: string): Observable<Result<any>>;

  /**
   * Get the id used to identify the current viewed property, like stationId
   * @param value the returned value or the filterbar
   */
  abstract getIdentifierObject(value: FilterBarResultParams): MdmProfileContext;

  /**
   * Get the title used for human readable identification of the current page
   * @param value the returned value or the filterbar
   */
  abstract getPageTitle(value: FilterBarResultParams): string;

  /**
   * Get export based on the clicked button and selected @Link(PageNode)
   * @param pageName the given @Link(mdmPageName)
   * @param pageNodeName the current page of properties
   * @param button the clicked button
   * @param options for handling error codes
   */
  abstract requestExport(pageName: string,
                         pageNodeName: string,
                         buttonName: string,
                         params: { [key: string]: any },
                         options: CsHttpRequestOptions): Observable<Result<HttpResponse<Blob>>>;
}
