import { HttpErrorResponse }                             from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component, ElementRef, forwardRef,
	Host, Inject, NgZone, OnDestroy, Optional, ViewChild, ViewEncapsulation
}                                                        from '@angular/core';
import { TranslateService }                              from '@ngx-translate/core';
import {
	DashboardPanelOptions
}                                                        from '../../models/dashboard-panel-options';
import {
	IDashboardComponent
}                                                        from '../../models/i-dashboard-component';
import {
	IDashboardPanel
}                                                        from '../../models/i-dashboard-panel';
import {
	IDashboardPanelComponent
}                                                        from '../../models/i-dashboard-panel-component';
import {
	ArrayUtils,
	DataDescribed,
	TableDataDescribed,
	updateTargetSources,
	ServerSidePaging, ServerSideFilter, PropertyAnnotation,
	ServerSideSorting, ServerSideNewPage, pathChecked, CsHttpRequestOptions, ValidationResult, isArray, gv
}                                                        from '@cs/core';
import {
	DashboardEventHub
}                                                        from '../../dashboard-event-hub.service';
import { UntilDestroy, untilDestroyed }                  from '@ngneat/until-destroy';
import { debounceTime, filter, take }                    from 'rxjs/operators';
import {
	TableRowClickEventArgs,
	TableCellClickEventArgs,
	TableMenuClickEventArgs,
	ValueChangedEventArgs
}                                                        from '@cs/components/table-nxt';
import { isNullOrUndefined }                             from '@cs/core';
import { getSelectionTargetClass }                       from '../../dashboard-helpers';
import { CsTableNxtComponent }                           from '@cs/components/table-nxt';
import { BehaviorSubject, Subject }                      from 'rxjs';
import { NotifyServerForChangesDashboardPanelEventArgs } from '../../models';
import { CsToastManagerService }                         from '@cs/components/toast-manager';
import { FilterCompareBarQuery }                         from '@cs/components/filter-and-compare-bar';

export enum DashboardGenericTableActions {
	NewRow = 'NewRow'
}

@UntilDestroy()
@Component({
						 selector:      'cs-dashboard-generic-table',
						 templateUrl:   './dashboard-generic-table.component.html',
						 styleUrls:     ['./dashboard-generic-table.component.scss'],
						 encapsulation: ViewEncapsulation.None
					 })
export class DashboardGenericTableComponent implements OnDestroy,
																											 IDashboardPanelComponent<TableDataDescribed<[]>> {

	@ViewChild('grid', {static: true}) grid: CsTableNxtComponent<any>;
	requestInProgress$: BehaviorSubject<boolean>                                             = new BehaviorSubject<boolean>(false);
	private lastPanelOptions: DataDescribed<any>;
	filterServerSideDebounced$: Subject<{ event: ServerSideFilter, panel: IDashboardPanel }> = new Subject<{ event: ServerSideFilter, panel: IDashboardPanel }>();
	isEntityClickable                                                                        = false;

	get data(): TableDataDescribed<[]> {
		return this._data;
	}

	set data(value: TableDataDescribed<[]>) {
		this.isClickable       = value == null
														 ? false
														 : value.dataAnnotation.fields.some(iss => !isNullOrUndefined(iss.selectionTrigger));
		this.isEntityClickable = value == null
														 ? false
														 : value.dataAnnotation.fields.some(
				iss => !isNullOrUndefined(iss.selectionTrigger) && iss.selectionTrigger === 'Entity');
		this.clickTypeClass    = getSelectionTargetClass(value);
		this._data             = this.applyPanelOptions(value);
	}

	name: string;

	private _data: TableDataDescribed<[]>;
	private dashboardParent: IDashboardComponent;

	lastHeight: string;
	height: string;

	clickTypeClass   = '';
	isClickable      = false;
	isSortable       = false;
	isLoading        = false;
	selectedPageSize = 25;
	forceDisable: boolean;

	constructor(@Inject(forwardRef(() => DashboardEventHub)) private dashboardEventHub: DashboardEventHub,
							@Inject(DashboardPanelOptions) private dashboardOptions: DashboardPanelOptions,
							private elementRef: ElementRef,
							private changeRef: ChangeDetectorRef,
							private toastManageer: CsToastManagerService,
							private i8n: TranslateService,
							private ngZone: NgZone,
							@Optional() private filterCompareBarQuery: FilterCompareBarQuery) {

		// Removing the need for host injection
		this.dashboardParent = this.dashboardEventHub.getParent();

		this.dashboardEventHub.getActionsHook<any, DashboardGenericTableActions>()
				.pipe(
					untilDestroyed(this),
					filter(value => value.panelName === this.dashboardOptions.name))
				.subscribe(value => {
					switch (value.action) {
						case 'IsLoading':
							this.isLoading = value.data as boolean;
							break;
						case DashboardGenericTableActions.NewRow:
							this.grid.createNewRow();
					}
				});

		this.filterServerSideDebounced$
				.pipe(untilDestroyed(this), debounceTime(800))
				.subscribe(value => this.dashboardParent
																.panelOptionClicked(value.event.filter, value.panel, {id: 'columnFilter'} as PropertyAnnotation<any>));
		this.height = this.dashboardOptions.height;

	}

	cellClicked($event: TableCellClickEventArgs<[]>) {
		const result = updateTargetSources({row: $event.row, column: $event.item}, this._data, this.name);

		if (result === null)
			return;

		this.dashboardEventHub.isDashboardEntryIsClicked.next(result);
	}

	rowClicked($event: TableRowClickEventArgs<any>) {
		const result = updateTargetSources({row: $event.item, column: null}, this._data, this.name);

		if (result === null)
			return;

		this.dashboardEventHub.isDashboardEntryIsClicked.next(result);
	}

	update(data: TableDataDescribed<[]>): void {
		if (!isNullOrUndefined(this._data) && !isNullOrUndefined(data) &&
			(this._data && ArrayUtils.isEqual(this._data.data, data.data))) {
			this.applyPanelOptions(this._data);
			return;
		}

		this.data = data;

	}

	updateView() {
		// this.updateTableHeight('');
		// if (!this.isSet) {
		//   this.ngZone.onStable.pipe(
		//     untilDestroyed(this),
		//     debounceTime(100)).subscribe(() => {
		//     this.updateTableHeight();
		//   });
		//   this.isSet = true;
		// }

	}

	private updateTableHeight(setHeight: string = null) {
		if (isNullOrUndefined(this._data))
			return;

		const isCollapsible = !isNullOrUndefined(this._data.dataGroups)
													? this._data.dataGroups
																.find(value => value.isCollapsible)
													: false;
		const div           = this.elementRef.nativeElement as HTMLDivElement;
		const response      = div.querySelector('.table-responsive') as HTMLDivElement;

		const dashboardBlockDiv = div.parentElement.parentElement as HTMLDivElement;
		const header            = dashboardBlockDiv.querySelector('.header') as HTMLDivElement;
		const content           = dashboardBlockDiv.querySelector('.content') as HTMLDivElement;
		if (!isNullOrUndefined(header)) {
			const headerHeight   = header.getBoundingClientRect().height;
			content.style.height = `calc(100% - ${headerHeight}px)`;
		}


		if (!isCollapsible)
			return;

		const computed        = window.getComputedStyle(response.firstElementChild);
		const height          = computed.height;
		this.lastHeight       = `${height}`;
		response.style.height = setHeight === null
														? this.lastHeight
														: setHeight;
		console.log(response.style.height);
	}

	ngOnDestroy(): void {
	}

	private applyPanelOptions(_data: TableDataDescribed<[]>) {
		const panel = this.dashboardParent.dashboardGrid.getPanel(this.dashboardOptions.name);
		if (panel.options && (<any>panel.options.data) && (<any>panel.options.data).pageIndex != null) {
			const layout                        = _data.layout;
			layout.table.enableServerSidePaging = new ServerSidePaging(panel as { options: DataDescribed<any> }, this.lastPanelOptions);
			layout.table.serverSideFilter       = ServerSideFilter.createFilter(panel);
			layout.table.serverSideSorting      = ServerSideSorting.createSorting(panel as { options: DataDescribed<any> });
			this.isSortable                     = true;
			panel.options.dataAnnotation.fields
					 .filter(value => value.id === 'pageIndex' || value.id === 'pageSize' || value.id === 'recordCount')
					 .forEach(value => value.visible = false);
			this.lastPanelOptions       = panel.options;
			this.grid.currentFilter     = layout.table.serverSideFilter.filter; //why is this passed in here an not rely on cs-table-nxt::TableLayoutParse()?  --jv
			this.grid.currentSorting    = layout.table.serverSideSorting.filter;
			this.grid.neutralSortOption = layout.table.serverSideSorting.neutralSortOption;
		} else {
			this.isSortable = pathChecked(_data, ['layout', 'table', 'isSortable'], null, false);
		}
		this.selectedPageSize = pathChecked(_data, ['layout', 'table', 'pageSize'], 25, false);

		return _data;

	}

	filterServerSideDateRequest($event: ServerSideFilter) {
		const panel                            = this.dashboardParent.dashboardGrid.getPanel(this.dashboardOptions.name);
		(<any>panel.options.data).performCount = true;
		this.filterServerSideDebounced$.next({
																					 event: $event,
																					 panel: panel
																				 });
	}

	sortingServerSideDateRequest($event: ServerSideSorting) {
		const panel = this.dashboardParent.dashboardGrid.getPanel(this.dashboardOptions.name);
		this.dashboardParent.panelOptionClicked($event.filter, panel, {id: 'columnSorting'} as PropertyAnnotation<any>);

	}

	newPageServerSideDateRequest($event: ServerSideNewPage) {
		const panel = this.dashboardParent.dashboardGrid.getPanel(this.dashboardOptions.name);
		if ($event.pageIndex !== null)
			this.dashboardParent.panelOptionClicked($event.pageIndex, panel, {id: 'pageIndex'} as PropertyAnnotation<any>);
		else if ($event.pageSize !== null)
			this.dashboardParent.panelOptionClicked($event.pageSize, panel, {id: 'pageSize'} as PropertyAnnotation<any>);
	}

	/**
	 * This handler is listening for IPA activity triggered by a menuitem in the row.
	 * @param $event
	 */
	entityMenuItemClicked($event: TableMenuClickEventArgs) {
		if ($event.selectedMenuItem.selectionTarget !== 'RegisteredAction') {

			const result = updateTargetSources({
																					 row:    $event.row,
																					 column: $event.selectedMenuItem as unknown as PropertyAnnotation<any>
																				 }, this._data, this.name);

			if (result === null)
				return;

			result.triggerId = $event.selectedMenuItem.name;
			this.dashboardEventHub.isDashboardEntryIsClicked.next(result);
		}
	}

	valueChanged($event: ValueChangedEventArgs) {

	}

	notifyChangesToServer(closeAfterNotify = false) {
		this.requestInProgress$.next(true);


		const options                = new CsHttpRequestOptions();
		options.errorResponseHandler = (response: HttpErrorResponse) => {
			this.requestInProgress$.next(false);
			switch (response.status) {
				case 400:
					// this.formGenerator.showErrorResponse(response.error.map(e => new ValidationResult(e)));
					this.changeRef.detectChanges();
					return true;
			}
		};

		const data      = this.grid.getAllData();
		const dashboard = this.dashboardEventHub.getParent();

		const selectionObject = {
			//...this.data.getSelectionObject(),
			dashboard: dashboard.data.name, //Depricated
			...gv(() => this.filterCompareBarQuery.getValue().mainbarResultParams, {} as any)
		};

		this.dashboardEventHub.notifyChangesToServer(new NotifyServerForChangesDashboardPanelEventArgs<any>({
																																																					data:                 data,
																																																					panelName:            this.name,
																																																					selection:            selectionObject,
																																																					csHttpRequestOptions: options,
																																																					requestInProgress:    this.requestInProgress$,
																																																					callback:             value => {
																																																						this.toastManageer.show({
																																																																			type:    'success',
																																																																			content: this.i8n.instant(
																																																																				'CHANGES_ARE_SAVED')
																																																																		});

																																																						const result = value.value;

																																																						if (isArray(
																																																							result) && result.length > 0) {
																																																							const message = result.reduce(
																																																								(prev, current, index) => {
																																																									const r = new ValidationResult(
																																																										current);
																																																									if (r.type === 'warning')
																																																										prev.push(
																																																											r.errorMessage);

																																																									return prev;
																																																								}, []);
																																																							this.toastManageer.show({
																																																																				type:         'warning',
																																																																				content:      message.join(
																																																																					'\n'),
																																																																				clickToClose: true
																																																																			});
																																																						}


																																																					}
																																																				}));
	}


}
