import {
	ChangeDetectionStrategy, Component,
	EventEmitter, Input, OnChanges, Output
}                                                                          from '@angular/core';
import { PmNavbarItem, PmNavbarItemType }                                  from './models/pm-navbar-item.model';
import { isNullOrUndefined, LoggerUtil, whenChanging, ComponentChanges }   from '@cs/core/utils';
import { DataDescribed }                                                   from '@cs/core/generate';
import { ErrorMessage }                                                    from '../../app/error-messages';
import { PmNavbarSectionChangedEventArgs }                                 from './models/pm-navbar-section-changed-event-args';
import { findPmNavItem }                                                   from './pm-navbar.utils';
import { dataDescribedToNavbar, FilterBarItem, SelectionChangedEventArgs } from '@cs/components';
import { PmNavbarModuleChangedEventArgs }                                  from './models/pm-navbar-module-changed-event-args';


@Component({
	selector:        'pmc-pm-navbar',
	templateUrl:     './pm-navbar.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PmNavbarComponent implements OnChanges {

	/**
	 * Used for displaying the dropdowns
	 */
	moduleDropdownsDisplay: Array<FilterBarItem> = [];


	logoStyling: {
		backgroundImage: string
	} = null;

	@Input() logo: string | ArrayBuffer = null;

	get getAllItems(): Array<PmNavbarItem> {
		return [...this.modules, ...this.sections, ...this.avatarMenu];
	}

	get activeSection(): PmNavbarItem {
		return this._activeSection;
	}

	set activeSection(value: PmNavbarItem) {
		if (this._activeSection)
			this.resetActiveItems();

		if (value)
			value.active = true;
		this._activeSection = value;
	}

	get activeModule(): PmNavbarItem {
		return this._activeModule;
	}

	set activeModule(value: PmNavbarItem) {
		if (this._activeModule)
			this._activeModule.active = false;
		if (value)
			value.active = true;

		this._activeModule = value;
	}

	/**
	 * Show the modules in the navbar. This could be a department or Business Unit
	 */
	@Input() modules: Array<PmNavbarItem>;
	/**
	 * List of the available application modules
	 */
	@Input() sections: Array<PmNavbarItem>;
	/**
	 * List of navigation items as the avatar dropdown list
	 */
	@Input() avatarMenu: Array<PmNavbarItem>;
	/**
	 * List of navigation items that have a special behaviour like changing the logo and/or header image
	 */
	@Input() moduleDropdowns: DataDescribed<any>;
	/**
	 * Set the name of the active Module, this will be resolved to a @Link(PmNavbarItem)
	 */
	@Input() activeModuleName: string;
	/**
	 * Set the name of the active Section, this will be resolved to a @Link(PmNavbarItem)
	 */
	@Input() activeSectionName: string;
	/**
	 * Number of module buttons shown before collapsing to a dropdown
	 */
	@Input() nrOfVisibleModules = 4;
	/**
	 * Flag indicating if the use is impersonating a other user
	 */
	@Input() isImpersonated     = false;
	/**
	 * Use this name for the avatar
	 */
	@Input() userFullName: string;

	@Input() isLoading = false;

	@Output() sectionChanged: EventEmitter<PmNavbarSectionChangedEventArgs>        = new EventEmitter();
	@Output() moduleChanged: EventEmitter<string | PmNavbarModuleChangedEventArgs> = new EventEmitter();

	private _activeModule: PmNavbarItem;
	private _activeSection: PmNavbarItem;
	homeItem: PmNavbarItem = new PmNavbarItem({
		name:                    '/',
		preserveQueryParams:     true,
		preserveModuleUrlParams: true
	});


	moduleClicked(module: PmNavbarItem) {
		this.activeModule = module;
		this.moduleChanged.emit(this.activeModule.name);
	}

	sectionClicked(section: PmNavbarItem, parent: PmNavbarItem = null) {

		switch (section.type) {
			case PmNavbarItemType.REMOTE_LINK:
				window.open(section.path, '_blank');
				break;
			case PmNavbarItemType.APP_LINK:
				const output = this.resolvePath(section, parent);
				this.sectionChanged.emit(Object.freeze({
					paths:     output,
					appParams: section.appParams,
					params:    section.params,
					options:   {
						preserveQueryParams: section.preserveQueryParams
					}
				}));
				break;
			case PmNavbarItemType.APP_ACTION:
				section.action(section);
				break;
		}

	}

	hasActiveChildren(list: Array<PmNavbarItem>) {
		if (isNullOrUndefined(list))
			return false;

		return !isNullOrUndefined(list.find(value => value.active || value.hasActiveChildren));
	}

	ngOnChanges(changes: ComponentChanges<PmNavbarComponent>): void {
		if (changes.hasOwnProperty('activeModuleName') && !isNullOrUndefined(changes.activeModuleName.currentValue) && !isNullOrUndefined(this.modules)) {
			const found = this.modules.find(value => value.name.toLowerCase() === changes.activeModuleName.currentValue.toString().toLowerCase());

			if (isNullOrUndefined(found))
				LoggerUtil.error(ErrorMessage.NOT_FOUND(`Navbar module: ${changes.activeModuleName.currentValue}`));
			this.activeModule = found;
		}

		if (changes.hasOwnProperty('activeSectionName') && !isNullOrUndefined(changes.activeSectionName.currentValue) && !isNullOrUndefined(this.sections)) {
			const found = this.sections.find(value => value.name === changes.activeSectionName.currentValue);
			if (isNullOrUndefined(found))
				LoggerUtil.error(ErrorMessage.NOT_FOUND(`Navbar section: ${changes.activeSectionName.currentValue}`));

			this.activeSection = found;
		}

		whenChanging(changes.moduleDropdowns, true).execute(async (value) => {
			this.moduleDropdownsDisplay = await dataDescribedToNavbar(value.currentValue);
		});

		whenChanging(changes.logo, false).execute(async (value) => {
			this.logoStyling = value.currentValue == null ? null : {
				backgroundImage: `url(${value.currentValue})`
			};
		});

	}

	private resolvePathLegacy(section: PmNavbarItem, parent: PmNavbarItem) {
		this.activeSection = section;
		const output       = [section.resolveFromModule + '',
													!isNullOrUndefined(this.activeSection.path) ? this.activeSection.path : this.activeSection.name.toLowerCase()];
		if (parent !== null)
			output.splice(1, 0, parent.name.toLowerCase());

		// convert to kebab case : MANAGED_BY_ME to managed-by-me
		const convertedToKebabCase = output.map(value => value.replace(new RegExp('_', 'g'), '-'));

		// Check if the first path element is a '' if so than we need to resolve the path from the current selected module
		if (convertedToKebabCase[0] === '' && this.activeModule)
			convertedToKebabCase[0] = this.activeModule.name.toLowerCase();

		return convertedToKebabCase;
	}

	private resolvePath(section: PmNavbarItem, parent: PmNavbarItem) {
		this.activeSection = section;
		const output       = section.routeToParent.concat(); // make a deep clone

		if (!section.ignoreNameForRouting)
			output.push(section.name);

		// convert to kebab case : MANAGED_BY_ME to managed-by-me
		const convertedToKebabCase = output.map(value => value.replace(new RegExp('_', 'g'), '-'));

		// Check if the first path element is a '' if so than we need to resolve the path from the current selected module
		if (convertedToKebabCase[0] === '' && this.activeModule)
			convertedToKebabCase[0] = this.activeModule.name.toLowerCase();

		return convertedToKebabCase;
	}

	resetActiveItems() {
		findPmNavItem(this.getAllItems, item => {
			if (item.active)
				item.active = false;
			return false;
		});
	}

	onSelectionChanged($event: SelectionChangedEventArgs, dropdown: FilterBarItem) {
		const item                     = $event.item[0];
		const appParams                = {};
		// Putting the key of the dropdown and the selected value into one object
		appParams[dropdown.identifier] = item.identifier;
		this.moduleChanged.emit({appParams: appParams, params: item.params});
	}


}


