import { Injectable }        from '@angular/core';
import { HttpClient }        from '@angular/common/http';
import { CsCultureProvider } from './culture-provider';

import { catchError, tap } from 'rxjs/operators';
import { of, Subject }     from 'rxjs';
import { LoggerUtil }      from '@cs/core';

@Injectable({
	providedIn: 'root'
})
export class ComponentTranslationLoaderService {
	private cache: Map<string, { [key: string]: string }>              = new Map();
	private isLoading: Map<string, Subject<{ [key: string]: string }>> = new Map();
	private componentModuleStore: Map<string, null>                    = new Map();

	constructor(private http: HttpClient, private culture: CsCultureProvider) {
	}

	registerComponentModule(module: string) {
		this.componentModuleStore.set(module, null);
	}

	getComponentModuleNames() {
		return this.componentModuleStore.keys();
	}

	setupComponentModule(module: string) {
		if (module == null)
			return of({});

		const url = `${this.culture.getTranslationLocation()}/components/${module}/${this.culture.getCulture()}.json?version=`
			+ this.culture.getCacheBusterHash();
		if (this.cache.has(url)) {
			const value = this.cache.get(url);
			LoggerUtil.debug(`${module} translation file is already loaded, added from cache. @${url}`);
			return of(value);
		}
		if (this.isLoading.has(url)) {
			LoggerUtil.debug(`Loading ${module} translation file is in progress, waiting for @${url}`);
			return this.isLoading.get(url);
		}
		this.isLoading.set(url, new Subject());
		return this.http.get(url).pipe(
			catchError(err => of({})),
			tap((x: { [key: string]: string }) => {
				const loaderHandler$ = this.isLoading.get(url);
				loaderHandler$.next(x);
				loaderHandler$.complete();
				this.isLoading.delete(url);
				this.cache.set(url, x);
			})
		);
	}
}
