import { Component, Input, Output, EventEmitter, HostListener, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor }                          from '@angular/forms';


const CS_SWITCH_CONTROL_VALUE_ACCESSOR: any = {
	provide:     NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CsSwitchComponent),
	multi:       true
};

/**
 * Ui switch component.
 *

 */
@Component({
						 selector:    'cs-switch',
						 templateUrl: './switch.component.html',
						 providers:   [CS_SWITCH_CONTROL_VALUE_ACCESSOR]
					 })
export class CsSwitchComponent implements ControlValueAccessor {

	/**
	 * Size of switch.
	 */
	@Input() size: 'large' | 'medium' | 'small' = 'medium';

	/**
	 * Add's an clickable label behind the switch if provided.
	 */
	@Input() label: string;

	/**
	 * On change event.
	 */
	@Output() change = new EventEmitter<boolean>();


	/**
	 * Set's the checked state.
	 */
	@Input() set checked(v: boolean) {
		this._checked = v !== false;
	}

	get checked() {
		return this._checked;
	}

	@Input() set disabled(v: boolean) {
		this._disabled = v !== false;
	}

	get disabled() {
		return this._disabled;
	}

	@HostListener('click', ['$event'])
	onToggle(event) {
		if (this.disabled) {
			return;
		}
		this.checked = !this.checked;
		this.change.emit(this.checked);
		this.onChangeCallback(this.checked);
		this.onTouchedCallback(this.checked);
	}

	writeValue(obj: any): void {
		if (obj !== this.checked) {
			this.checked = !!obj;
		}
	}

	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	private _checked: boolean;
	private _disabled: boolean;

	private onTouchedCallback = (v: any) => {
	};
	private onChangeCallback  = (v: any) => {
	};
}
