import {
  CellActionClickEventArgs,
  CellClickedType, FilterCompareBarQuery,
  PopoverTypes
}                                   from '@cs/components';
import { DataEntryComponent }       from '../data-entry.component';
import { Injector }                 from '@angular/core';
import { DataEntryResultParams }    from '../models/data-entry-result-params';
import { MatDialog } from '@angular/material/dialog';
import { SliderEditModalComponent } from '../prediction/slider-edit.modal/slider-edit.modal.component';
import { ISliderEditModalData }     from '../prediction/slider-edit.modal/prediction-data.described';

export class CellClickedHandler {
  type: PopoverTypes | string;
}

export abstract class CellClickedHandlerService {

  private readonly registeredCellClickedHandlers = new Map<string, CellClickedHandler>();

  abstract executeCellAction(args: CellActionClickEventArgs, dataEntry: DataEntryComponent);

  registerCellClickedHandler(handler: CellClickedHandler) {
    this.registeredCellClickedHandlers.set(handler.type, handler);
  }
}

export class DefaultCellClickedHandlerService extends CellClickedHandlerService {

  constructor(private injector: Injector) {
    super();
  }

  executeCellAction(args: CellActionClickEventArgs, dataEntry: DataEntryComponent) {
    if (args.gridItem.cellUIState.hasPopoverClick === CellClickedType.SliderEdit
      || args.gridItem.cellUIState.hasPopoverClick === CellClickedType.Edit) {
      this.openDialog(args, dataEntry, args.gridItem.cellUIState.hasPopoverClick === CellClickedType.SliderEdit);
    }
  }

  openDialog(args: CellActionClickEventArgs, dataEntry: DataEntryComponent, enableSliders = false): void {
    const dialog                = this.injector.get(MatDialog);
    const filterCompareBarQuery = this.injector.get(FilterCompareBarQuery);

    const {mainbarResultParams} = filterCompareBarQuery.getValue();
    const dataEntrygrid         = (mainbarResultParams as DataEntryResultParams).dataEntryGrid;

    const setupModal: ISliderEditModalData = {
      factTableName: dataEntry.dataEntryGrid.data.config.facttableName.toString(),
      params:        {scenario: dataEntry.dataEntryGrid.options.client.cargoDataEntryTool.scenario},
      dataEntryGrid: dataEntrygrid,
      // cellEventArgs:     args,
      changedCells:  [args.gridItem],
      sliderData:    []
    };

    const dialogRef = dialog.open(SliderEditModalComponent, {
      width: '850px',
      data:  setupModal
    });

    dialogRef.beforeClosed().subscribe(result => {
      // Turn the checkboxes back on
      // this.toggleDisableState(false);
      // this.selectedRow = null;
      //
      // if (result === '') {
      //   cellGrid.resetAllChanges(cellSheets[0], true);
      //   cellGrid.updateCells(DataGridCellType.Data);
      //   SafeMethods.detectChanges(cellGrid.changeRef);
      //   return;
      // }
      //
      // //this.getDataEventsIndicators();
      // cellGrid.updateParentRowValues();
      // this.grid.updateCells(DataGridCellType.Data);
      // this.grid.updateCells(DataGridCellType.Total);
      // this.grid.updateCells(DataGridCellType.Offset);
      //
      // cellGrid.calculateSheets(cellSheets);
      //
      // cellGrid.updateCells(DataGridCellType.Data);
      // cellGrid.updateCells(DataGridCellType.Total);
      // cellGrid.updateCells(DataGridCellType.Offset);
      // SafeMethods.detectChanges(this.grid.changeRef);
      //
      // this.postSaveActions();
    });
  }
}
