import { Component, OnInit, Type }     from '@angular/core';
import { DashboardDataEntryTable }     from '../../models/dashboard-data-entry-table';
import { DashboardDataEntryTableCell } from '../../models/dashboard-data-entry-table-cell';
import { IDashboardPanelComponent }    from '../../models/i-dashboard-panel-component';
import { Router }                      from '@angular/router';
import { DashboardCombiListComponent } from '../dashboard-list/dashboard-list.component';
import { ArrayUtils }                  from '@cs/core';

@Component({
	selector:    'cs-dashboard-table',
	templateUrl: './dashboard-table.component.html'
})
export class DashboardTableComponent implements OnInit, IDashboardPanelComponent<DashboardDataEntryTable> {

	name: string;

	get marginTop(): string {
		const length = this.data.columns
											 .map(c => c.headerLabel.length)
											 .reduce((previousValue, currentValue) =>
												 previousValue > currentValue ? previousValue : currentValue);
		return `${(length * 7) - 50}px`;
	}

	listComponent: Type<DashboardCombiListComponent> = DashboardCombiListComponent;

	data: DashboardDataEntryTable;

	private _marginTop: string;

	constructor(private router: Router) {
	}

	ngOnInit() {
	}

	navigateTo(cell: DashboardDataEntryTableCell) {

		console.log(cell);
		this.router.navigateByUrl(cell.href);
	}

	update(data: DashboardDataEntryTable): void {
		if (this.data && ArrayUtils.isEqual(this.data, data))
			return;

		this.data = data;
	}

}
