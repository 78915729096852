import { Component }                from '@angular/core';
import { DomSanitizer }             from '@angular/platform-browser';
import { IDashboardPanelComponent } from '../../models/i-dashboard-panel-component';
import { ArrayUtils }               from '@cs/core';

@Component({
	selector:    'cs-dashboard-statistics',
	templateUrl: './dashboard-statistics.component.html'
})
export class DashboardStatisticsComponent implements IDashboardPanelComponent<any> {

	name: string;

	/**
	 * The data source that contains the values of the statistics panel.
	 */
	data: any;


	constructor(private sanitizer: DomSanitizer) {
	}

	calculateWidth(size) {
		return this.sanitizer.bypassSecurityTrustStyle('width: ' + (size * 100) + '%');
	}

	update(data: any): void {
		if (this.data && ArrayUtils.isEqual(this.data, data))
			return;

		this.data = data;
	}
}
