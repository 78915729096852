<div class="inner-page-container">
	<div class="full-width d-flex flex-column justify-content-center">
		<div class="full-width hide-in-print filter-and-navbar-shell--filter-container">
			<cs-filter-and-compare-bar [mDataSource]="mainbarDataSource"
									   [cDataSource]="comparebarDataSource"
									   (selectionChanged)="filterBarSelectionChanged($event)"
									   [hasCompare]="showCompareButton$ | async"
									   [SyncUrlParameters]="true"
									   #filterBar></cs-filter-and-compare-bar>
		</div>
		<div class="full-width"
			 *ngIf="mainbarDataSource?.subFilterItems?.length > 0 || mainbarDataSource?.viewSelectionItems?.length > 0">
			<div class="flex flex-row filter-badge justify-content-start align-items-center">
				<div class="filter-badge-child" *ngIf="mainbarDataSource?.subFilterItems?.length > 0">
					<div
							class="badge badge--secondary badge--padding is-clickable hover-pointer flex flex-row justify-content-between align-items-center mb-0"
							*ngFor="let filter of mainbarDataSource.subFilterItems"
							(click)="dismissSubFilter(filter)">
						<label class="mr-1">{{filter.label}}</label> <i class="mdi mdi-close-circle"
																		*ngIf="filter.isDismissible"></i>
					</div>
				</div>
				<div class="filter-badge-child" *ngIf="mainbarDataSource?.viewSelectionItems?.length > 0">
					<mat-button-toggle-group *ngFor="let item of mainbarDataSource?.viewSelectionItems"
											 [value]="item.selected"
											 matTooltip="{{item.description}}"
											 matTooltipPosition="after">
						<mat-button-toggle *ngFor="let option of item.lookup.values"
										   (click)="pageViewSelectionChanged(item, option)"
										   [value]="option.key" [disableRipple]="true">
							{{option.value}}
						</mat-button-toggle>
					</mat-button-toggle-group>
				</div>
				<div class="flex-pull-right last-refresh-align filter-badge-child"
					 *ngIf="(informationLabel$| async)!=null">
					<div class="cs-panel flex">

						<span class="f-body-s text-muted mr-2">{{'LAST_REFRESH' | translate}}</span>
						<span class="f-body-s font-weight-bold">{{informationLabel$ | async  | format:'{0:dd MMM yyyy H:mm}:iddate'}}</span>

					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="showNavigationWarning"
		 class="page-description--container overflow-page-container mb-0 mt-3">
		<div class="cs-panel flex flex-align-items-center justify-content-start">
			<div class="mr-2">
				<i class="mdi mdi-account-alert"></i>
			</div>
			<div>{{'MSSG_WARNING_NAVIGATION_NOT_POSSIBLE' | translate}}</div>

		</div>
	</div>
	<div class="navbar-loaded-content-container" [ngClass]="filterbarShellClasses$ | async"
		 [@fadeAnimation]="getRouterOutletState(o)">
		<router-outlet #o="outlet"></router-outlet>
	</div>

	<cs-button-bar [dynamicButtons]="dynamicButtons"></cs-button-bar>

</div>

