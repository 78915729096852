import { IValidationAnnotation } from '../data-described/validators/validation-annotations';
import { PropertyAnnotation }    from '../data-described/property-annotation.model';
import { ValidationResult }      from '../data-described/http/validation-result.model';
import { replacePlaceholders }   from '@cs/core/utils';
import { AbstractControl }       from '@angular/forms';


export function getErrorMessages(control: AbstractControl, propertyAnnotation: PropertyAnnotation<any>) {
	return getErrorMessagesFromValidators(control, propertyAnnotation.validators);
}

export function getErrorMessagesFromValidators(control: AbstractControl, validators: Array<IValidationAnnotation>) {
	// always clean the errormessages otherwise there will be multiple error messages
	const errorMessages: ValidationResult[] = [];

	if (!control.errors) {
		// if no error messages just stop
		return [];
	}

	// Match the keys of the errors to the type of the validatorAnnotations
	for (const key of Object.keys(control.errors)) {
		// If the correct validator is found, get the error message.
		const foundValidator = validators.find(pa => pa.type === key);

		// Check if warningMessage is defined, if so treat the error as warning
		if (foundValidator.warningMessage != null) {
			errorMessages.push(new ValidationResult({
				errorMessage: replacePlaceholders(control.errors[key], foundValidator.warningMessage),
				memberNames:  [key],
				type:         'warning'
			}));

			// Set the control to valid by removing the error 😎
			control.setErrors(null);

		} else {
			errorMessages.push(new ValidationResult({
				errorMessage: replacePlaceholders(control.errors[key], foundValidator.errorMessage),
				memberNames:  [key],
				type:         'invalid'
			}));
		}
	}

	return errorMessages;
}
