import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { DataViewRowPosition }                                           from '@cs/core';
import { DataEntryStateColumn, DataEntryStateRow }                       from '../../models';

export abstract class DataEntryStateParentRow {}

@Component({
						 selector:        'cs-data-entry-state-row',
						 templateUrl:     './data-entry-state-row.component.html',
						 changeDetection: ChangeDetectionStrategy.OnPush,
						 providers:       [
							 {provide: DataEntryStateParentRow, useExisting: forwardRef(() => CsDataEntryStateRowComponent)}
						 ]
					 })
export class CsDataEntryStateRowComponent implements OnInit {

	@Input() rowContext: DataEntryStateRow;
	@Input() badges: string[] = [];
	@Input() isCollapsed      = true;
	@Input() rowPosition: DataViewRowPosition;
	trackByStructureKey       = (index: number, item: DataEntryStateColumn) => item.structureKey;


	// @Input() indicators: DataEntryStateIndicator[] = [];
	// @Input() statuses: string[]                    = [];
	// @Input() label: string;


	// @Input() textColumns: string[]                 = [];
	trackByBadge = (index: number, item: string) => item;

	constructor() { }

	ngOnInit(): void {
	}

	toggleExpansionRow() {
		if (this.badges.length > 0)
			this.isCollapsed = !this.isCollapsed;
	}
}
