import { getPropertyOf, hasPropertyOf, DataDescribed } from '@cs/core';
import { FilterBarResultParams }                       from '@cs/components';
import { DynamicButton }                               from '@cs/performance-manager/shared';

export class ApprovalLayoutAnnotation {
  factsToApprove: FactsToApproveItem[];
}

export class FactsToApproveItem {
  label: string;
  keys?: { [key: string]: any };
  id: number;
  options?: { [key: string]: any };
}

export interface ApprovalState {
  IdYear: number;
  StateLabel: string;
  DateStart: string;
  IdRound: number;
  IdMonthMin: number;
  IsStarted: number;
  IdDesignation: number;
  RoundNr: number;
  ScopeLabel: string;
  IsClosed: number;
  Label: number;
  IdMonthMax: number;
  LocalState: LocalState;
  DateEnd: string;
  IsAvailable: number;
}

export interface LocalState {
  BusinessUnitLabel: string;
  IdBusinessUnit: number;

  DateLastEdit: string;
  DateRequestApproval: string;
  DateApproval: string;
  DateRejection: string;
  DateUndoRequestApproval: string;
  DateUndoApproval: string;
  DateUndoRejection: string;

  IdAccountLastEdit: number;
  IdAccountRequestApproval: number;
  IdAccountApproval: number;
  IdAccountRejection: number;
  IdAccountUndoRequestApproval: number;
  IdAccountUndoApproval: number;
  IdAccountUndoRejection: number;

  IsInProgress: number;
  IsReadyForApproval: number;
  IsApproved: number;
  IsRejected: number;
  IsReopened: number;
  IsLocked: number;
  IsUndoReadyForApproval: number;
  IsUndoApproved: number;
  IsUndoRejected: number;
  IsModifiedAfterSubmit: boolean;
  IsModifiedAfterApproval: boolean;

  LocalStateName: string;
  LocalStateLabel: string;
  LocalStateUndoLabel: string;

  LastAction: LastAction;
  Labels: Labels;
}

export interface LastAction {
  User: string;
  Date: string;
  Label: string;
  IsCurrentState: boolean;
}

export interface Labels {
  LastEditUser: string;
  RequestApprovalUser: string;
  RejectionUser: string;
  ApprovalUser: string;
  UndoRequestApprovalUser: string;
  UndoRejectionUser: string;
  UndoApprovalUser: string;
}

export class ApprovalComment {
  message: string;
  timestamp: Date;
  userName: string;
  isSelf: boolean;
  factId: number;
  isNew: boolean;
  id: number;

  constructor(init: Partial<ApprovalComment>) {
    this.message = getPropertyOf(init, 'message');
    this.factId = getPropertyOf(init, 'factId');
    this.isSelf = getPropertyOf(init, 'isSelf');
    this.timestamp = hasPropertyOf(init, 'timestamp')
      ? new Date(getPropertyOf(init, 'timestamp'))
      : new Date();
    this.userName = getPropertyOf(init, 'userName');
    this.isNew = getPropertyOf(init, 'isNew', false);
    this.id = getPropertyOf(init, 'id', 0);
  }
}

export class ApprovalDeviationItem {
  value: number;
  format: string;
  status: 'positive' | 'veryPositive' | 'veryNegative' | 'negative' | 'neutral';

  get statusClass() {
    switch (this.status) {
      case 'negative':
        return 'danger--lighten';
      case 'positive':
        return 'success--lighten';
      case 'veryNegative':
        return 'danger';
      case 'veryPositive':
        return 'success';
    }
  }

  constructor(init: Partial<ApprovalDeviationItem>) {
    this.status = getPropertyOf(init, 'status', 'neutral');
    this.value = getPropertyOf(init, 'value', null);
    this.format = getPropertyOf(init, 'format', '');

  }
}

export class ApprovalDeviation {
  rowId: string;
  values: { [key: string]: ApprovalDeviationItem };

  constructor(init: Partial<ApprovalDeviation>) {
    this.rowId = getPropertyOf(init, 'rowId');
    this.values = hasPropertyOf(init, 'values') ? Object.keys(init.values).reduce((prev, key) => {
      prev[key] = new ApprovalDeviationItem(init.values[key]);
      return prev;
    }, {}) : {};
  }


}

export class ApprovalMetaDataSet {
  comments: Array<ApprovalComment>;
  deviations: Array<ApprovalDeviation>;
  pageButtons: Array<DynamicButton>;
  approvalState: ApprovalState;
  cellOptions: Array<ApprovalCellOptions>;
}

// tslint:disable-next-line
export type ApprovalData = Array<{ [key: string]: any }>;


export class ApprovalDataDescribed extends DataDescribed<ApprovalData, ApprovalLayoutAnnotation, ApprovalMetaDataSet> {

}

export class ApprovalCellOptions {
  rowId: string;
  values: { [key: string]: ApprovalCellOptionsItem };

  constructor(init: Partial<ApprovalCellOptions>) {
    this.rowId = getPropertyOf(init, 'rowId');
    this.values = hasPropertyOf(init, 'values') ? Object.keys(init.values).reduce((prev, key) => {
      prev[key] = new ApprovalDeviationItem(init.values[key]);
      return prev;
    }, {}) : {};
  }
}

export class ApprovalCellOptionsItem {
  format: string;
  status: 'positive' | 'veryPositive' | 'veryNegative' | 'negative' | 'neutral';

  get statusClass() {
    switch (this.status) {
      case 'negative':
        return 'danger--lighten';
      case 'positive':
        return 'success--lighten';
      case 'veryNegative':
        return 'danger';
      case 'veryPositive':
        return 'success';
    }
  }

  constructor(init: Partial<ApprovalCellOptionsItem>) {
    this.status = getPropertyOf(init, 'status', 'neutral');
    this.format = getPropertyOf(init, 'format', '');

  }
}

export interface CommentData {
  factId?: string;

  message?: string;

  timestamp?: string;

  userName?: string;

  id?: number;

  isSelf?: boolean;

}


export interface ApprovalResultParams extends FilterBarResultParams {
  /**
   * the current data-entry name that is selected
   */
  dataEntryRoundScopeName: string;
}

