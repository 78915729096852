import { isNullOrUndefined } from '@cs/core';
import { IButton }           from '@cs/components';

export class DynamicButton implements IButton {
  confirm: string;
  description: string;
  disabled: boolean;
  label: string;
  name: string;
  iconClass: string;
  btnClass: string;
  type: 'Import' | 'Export' | 'EntryGridButton' | any;
  payload: { [key: string]: any, type: string };

  constructor(initial?: Partial<IButton>) {
    Object.assign(this, initial);
  }

}

export class DynamicFileButton extends DynamicButton {

  allowedExtensions: Array<string>;
  allowedMimeTypes: Array<string>;
  accept: string;

  constructor(initial?: Partial<IButton>) {
    super(initial);

    Object.assign(this, initial);

    if (!isNullOrUndefined(this.allowedExtensions)) {
      this.accept = this.allowedExtensions.join(', ');
    }
    if (!isNullOrUndefined(this.allowedMimeTypes)) {
      this.accept += ', ' + this.allowedMimeTypes.join(', ');
    }
  }

}
