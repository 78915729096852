import { AdvancedDropdownItem } from './advanced-dropdown-item';

export class AdvancedDropdownGroup {
	isTruncated: boolean;

	get data(): Array<AdvancedDropdownItem> {
		if (this.isMultipleSelectable) {
			//Sort items in the group
			const out = this._data
											.sort((a, b) => a.label < b.label ? 1 : a.label === b.label ? 0 : -1) // sort by label
											.sort((a, b) => a.selected < b.selected ? 1 : -1);                    // show selected items first

			const active = out.filter(value => value.selected);
			if (active.length > 0) {
				const last  = active.pop();
				last.isLast = true;
			}
			return out;
		} else
			return this._data;
	}

	set data(value: Array<AdvancedDropdownItem>) {
		this._data = value;
	}

	label: string;
	isDefault: string;
	private _data: Array<AdvancedDropdownItem> = new Array<AdvancedDropdownItem>();

	constructor(data: any, private isMultipleSelectable = false) {
		this.label     = data.label || null;
		this.isDefault = data.isDefault || null;
	}

	clone() {
		return Object.assign(new AdvancedDropdownGroup({}), this, {
			isMultipleSelectable: this.isMultipleSelectable,
			data:                 this._data.map(d => d.clone())
		});
	}
}
