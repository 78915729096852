import {
	Component, OnInit, ChangeDetectionStrategy,
	Input, ViewChild, ElementRef, OnChanges, NgZone, OnDestroy, ChangeDetectorRef
}                                                        from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, take }           from 'rxjs/operators';
import { DataEntryStateHeaderColumn }   from './models';
import { DataEntryStateStructure }                       from './models/data-entry-state-structure';
import { CsLengthRulerDirective, CsWidthRulerDirective } from '@cs/components/shared';
import { ComponentChanges, whenChanging }                from '@cs/core';
import { DataEntryStateHeaderRow, DataEntryStateRow }    from './models/data-entry-state-row';

@UntilDestroy()
@Component({
						 selector:        'cs-data-entry-state',
						 templateUrl:     './data-entry-state.component.html',
						 changeDetection: ChangeDetectionStrategy.OnPush
					 })
export class CsDataEntryStateComponent implements OnInit,
																									OnDestroy,
																									OnChanges {
	static TYPE_NAME = 'data-entry-state';

	@Input() dataRows: DataEntryStateRow[];

	@Input() headerRows: DataEntryStateHeaderRow[];
	@Input() renderSchema: DataEntryStateStructure;

	@ViewChild(CsLengthRulerDirective) heightDirective: CsLengthRulerDirective;

	heightCurtain: number;
	widthCurtain: number;

	constructor(private ngZone: NgZone, private changeRef: ChangeDetectorRef) { }

	ngOnInit(): void {
	}

	ngOnChanges(changes: ComponentChanges<CsDataEntryStateComponent>): void {

		whenChanging(changes.renderSchema, true)
			.execute(value => {
				this.init(value.currentValue);
			});

		this.ngZone.onStable.pipe(
			untilDestroyed(this),
			take(1)
		)
				.subscribe(value => {
					this.heightDirective.update();
					this.heightCurtain = this.heightDirective.currentHeight;
					this.widthCurtain  = this.renderSchema.headerRoot.columns.filter((value: DataEntryStateHeaderColumn) => value.visualState.sticky)
																	 .reduce((previousValue: number, currentValue: DataEntryStateHeaderColumn) => {
																		 return previousValue + currentValue.position.width + 2;
																	 }, 0) + 24;
					this.changeRef.detectChanges();
				});
	}

	@ViewChild(CsWidthRulerDirective, {static: true}) private widthRuler: CsWidthRulerDirective;
	trackByStructureKey    = (index: number, item: DataEntryStateHeaderColumn) => item.structureKey;
	trackByRowStructureKey = (index: number, item: DataEntryStateRow) => item.structureKey;


	private init(structure: CsDataEntryStateComponent['renderSchema']) {
		if (structure.headerRows != null)
			this.headerRows = structure.headerRows as DataEntryStateHeaderRow[];

		if (structure.lookups.size > 0) {
			// 	this.dataGridLookupService.registerLookups(structure.lookups);
		}

		if (structure.dataRows != null && structure.rowStructure != null) {
			this.calculateRowPositions(structure, this.widthRuler.currentWidth);

			this.dataRows = structure.dataRows as DataEntryStateRow[];
		}
	}

	private calculateRowPositions(structure: DataEntryStateStructure, availableWidth: number) {
		return structure.calculateDataColumnPositions(availableWidth);
	}

	ngOnDestroy(): void {
	}
}
