/**
 * Created by alex on 11-8-2017.
 */

import { isNullOrUndefined }                        from '@cs/core';
import { GridDataCell }                             from '../models/grid-data-cell.model';
import { DataGridCellType, GridItemType, RowState } from '../enums/data-grid.enum';
import { filter }                                   from '@cs/components/util';
import { GridSheet }                                from '../models/grid-sheet.model';
import { DataGridHelpers }                          from './data-grid-helpers';
import { Calculations }                             from './calculations';
import { Observable }                               from 'rxjs';

/**
 * The calculator dedicated for this @link(GridSheet)
 */
export class DataGridSheetCalculator {

	private calculationCells: Array<GridDataCell> = [];
	sheet: GridSheet;
	private _disableCalculations                  = false;

	constructor(sheet: GridSheet) {
		this.sheet = sheet;
	}


	/**
	 * Add a cell that has a calculation attached or gets a default calculation
	 */
	addCalculationCell(cell: GridDataCell, scope?: GridItemType) {

		if (cell.cellType === DataGridCellType.Offset)
			cell.calculation.calcFunction = Calculations.getOffsetCalculation;
		else
			cell.calculation.calcFunction = Calculations.defaultCalculation;

		if (!isNullOrUndefined(cell.calculation.useFunctionWithName)) {
			// not sure this ever gets called after the applying the rules
			cell.calculation.calcFunction = Calculations.getCalculationMethod(cell.calculation.useFunctionWithName);
		}


		if (!isNullOrUndefined(scope))
			cell.calculation.scope = scope;

		this.calculationCells.push(cell);
	}


	/**
	 * Calculates all cells in the calculationCells
	 */
	calculateAll() {
		const obs = new Observable(subscriber => {
			this.calculateCells(DataGridCellType.All, this.calculationCells);
			this.calculateCells(DataGridCellType.Total,
				this.calculationCells.filter(
					item => item.cellType === DataGridCellType.Offset)
				, null,
				true);

			subscriber.next();
			subscriber.complete();
		});
		return obs;
	}

	/**
	 * Clean the list of cells that needs calculation
	 */
	clean(): void {
		this.calculationCells = [];
	}

	/**
	 * Function used to calculate the cells based on the keys
	 * @param type Filter the cell based on the cell type
	 * @param calculationCells The collection of cells we want to calculate
	 * @param calculation When this function is called outside the grid, the cells could not have any calculations, if not use
	 *                   use this calculation instead
	 */
	calculateCells(type: DataGridCellType, calculationCells: Array<GridDataCell>, calculation: Function = Calculations.defaultCalculation, forOffset = false) {
		if (this._disableCalculations)
			return;

		let foundCells     = DataGridHelpers.filterCells(this.sheet, type, RowState.Default);
		// Added Spinner row for calculation fix DCM-3002
		const spinnerCells = DataGridHelpers.filterCells(this.sheet, type, RowState.Spinner);

		if (spinnerCells.length > 0) {
			foundCells = foundCells.concat(spinnerCells);
		}

		// Prepend cells with RowState.Total to calculate the overall offset (top-right corner)
		if (forOffset) {
			foundCells.unshift(...DataGridHelpers.filterCells(this.sheet, type, RowState.Total));
		}

		for (const cell of calculationCells) {
			let cells = foundCells;

			if (cell.calculation.scope !== GridItemType.Sheet)
				cells = DataGridHelpers.filterCells(this.sheet, type, RowState.Default, cell.calculation.scope, cell);

			const filtered = filter(cells, cell.keys, 'keys');//TODO: PERFORMANCE GAIN BY REMOVE ALREADY FOUND VALUES?
			if (filtered.length > 0) {
			}
			cell.updateValue(cell.calculation.calcFunction ? cell.calculation.calcFunction(filtered) : calculation(filtered));
			cell.formatValue();
		}
	}

	disableCalculations() {
		this._disableCalculations = true;
	}
}
