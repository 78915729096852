import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule }                  from '@angular/common';
import { CsChartPanelComponent }         from './chart-panel.component';
import { GoogleChartTransformerPipe }    from './google-chart-transformer.pipe';
import { MatTooltipModule }              from '@angular/material/tooltip';
import {
	CsChartLoaderModule, CsLegacyPMChartLoaderSetup,
	CsSharedModule
}                                        from '@cs/components';
import { CsStatisticsPanelModule }       from '../statistics-panel/statistics-panel.module';
import { TranslateModule }               from '@ngx-translate/core';

@NgModule({
	imports:      [
		CommonModule,
		CsStatisticsPanelModule,
		MatTooltipModule,
		CsSharedModule,
		CsChartLoaderModule.forChild(CsLegacyPMChartLoaderSetup),
		TranslateModule.forChild()
	],
	declarations: [
		CsChartPanelComponent,
		GoogleChartTransformerPipe
	],
	exports:      [
		CsChartPanelComponent
	],
	providers:    [GoogleChartTransformerPipe]
})
export class CsChartPanelModule {
	static forRoot(): ModuleWithProviders<CsChartPanelModule> {
		return {ngModule: CsChartPanelModule};
	}
}
