import { Component, OnInit }        from '@angular/core';
import { DashboardDataLinks }       from '../../models/dashboard-data-links';
import { DashboardDataLinksAction } from '../../models/dashboard-data-links-action';
import { DashboardDataLinksItem }   from '../../models/dashboard-data-links-item';
import { IDashboardPanelComponent } from '../../models/i-dashboard-panel-component';
import { Router }                   from '@angular/router';
import { isNullOrUndefined }        from '@cs/core';
import { LoggerUtil, ArrayUtils }   from '@cs/core';

@Component({
	selector:    'cs-hyperlink-list',
	templateUrl: './hyperlink-list.component.html'
})
export class HyperlinkListComponent implements OnInit, IDashboardPanelComponent<DashboardDataLinks> {

	name: string;


	data: DashboardDataLinks;

	constructor(private router: Router) {
	}

	ngOnInit() {
	}

	/**
	 * Handler for clicking a link. Based on the action the link will trigger the corresponding behaviour
	 * @param link The clicked link
	 */
	linkClicked(link: DashboardDataLinksItem) {
		if (isNullOrUndefined(link.action)) {
			LoggerUtil.error(`No action provided for this link ${link.label}`);
			return;
		}
		switch (link.action.toLowerCase()) {
			case DashboardDataLinksAction.NavigateTo:
				this.router.navigateByUrl(link.payload);
				return;
			case DashboardDataLinksAction.RefreshDashboard:
				//this.dashboardComponent.getDashboardData(link.payload);
				return;
			default:
				LoggerUtil.error(`Action: '${link.action}' is not found, USE: RefreshDashboard or NavigateTo`);
		}

	}

	update(data: DashboardDataLinks): void {
		if (this.data && ArrayUtils.isEqual(this.data, data))
			return;

		this.data = data;
	}

}
