import { animate, state, style, transition, trigger } from '@angular/animations';

export function opacityAndBlur(triggerName: string) {
  return trigger(triggerName, [
    state('true', style({
      filter:        'blur(3px)',
      opacity:       0.3,
      pointerEvents: 'none'
    })),
    state('false', style({
      filter:  'blur(0)',
      opacity: 1
    })),
    transition('true <=> false',
      animate('400ms ease-out'))
  ]);

}
