import { DataDescribed, Result }    from '@cs/core';
import { Observable }               from 'rxjs';

import { RequestStepDataEventArgs } from '../models/request-step-data-event-args';

export abstract class WizardConfigService {

	abstract getStepData(eventArgs: RequestStepDataEventArgs): Observable<Result<DataDescribed<any>>>;

	abstract sendUserData(eventArgs: {}): Observable<Result<boolean>>;

}
