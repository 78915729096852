import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import { ComponentChanges, whenChanging } from '@cs/core';
import { Step, StepState }                from '../../models/step';

@Component({
						 selector:        'cs-wizard-step',
						 templateUrl:     './wizard-step.component.html',
						 styleUrls:       ['./wizard-step.component.scss'],
						 changeDetection: ChangeDetectionStrategy.OnPush
					 })
export class WizardStepComponent implements OnInit,
																						OnChanges {
	@Input() step: Step;
	@Input() isLast: boolean;
	/**
	 * flag indicating in what state the step is
	 */
	@Input() state: StepState = 'isDisabled';

	constructor(private readonly changeRef: ChangeDetectorRef) {
	}

	ngOnInit(): void {

	}

	ngOnChanges(changes: ComponentChanges<WizardStepComponent>): void {
		// whenChanging(changes.step, true).execute(value => {
		// 	const cValue = value.currentValue;
		// 	this.steps = value.currentValue.icon;
		// });
	}

	detectChanges() {
		this.changeRef.detectChanges();
	}
}
