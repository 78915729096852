import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	Inject,
	OnDestroy,
	ViewChild
}                                                         from '@angular/core';
import { CsChartLoaderSetup }                             from '@cs/components/cs-chart-loader';
import { IDashboardPanelComponent }                       from '../../models/i-dashboard-panel-component';
import { ArrayUtils, LoggerUtil, mergeDeep, pathChecked } from '@cs/core';
import { isNullOrUndefined }                              from '@cs/core';
import { CsDataDescribedChartLoaderSetup }                from '@cs/components/cs-chart-loader';
import { DashboardEventHub }                              from '../../dashboard-event-hub.service';
import { SafeMethods }                                    from '@cs/common';
import { CsChartLoaderDirective }                         from '@cs/components/cs-chart-loader';
import { ResizeSensor }                                   from 'css-element-queries';
import { getSelectionTargetClass }                        from '../../dashboard-helpers';
import {
	LabelType,
	Options
}                                                         from '@cs/components/slider-nxt';
import { DashboardChartNxtSliderDataDescribed }           from './dashboard-chart-nxt-data-described.model';
import { DashboardChartNxtDataDescribed }                 from '../dashboard-chart-nxt/dashboard-chart-nxt-data-described.model';
import { fromEvent }                                      from 'rxjs';
import { debounceTime }                                   from 'rxjs/operators';
import { UntilDestroy, untilDestroyed }                   from '@ngneat/until-destroy';
import { SliderComponent }                                from '@cs/components/slider-nxt';
import { DashboardChartNxtBase }                          from '../dashboard-chart-nxt/dashboard-chart-nxt.base';


@UntilDestroy()
@Component({
	selector:    'cs-dashboard-chart-nxt',
	templateUrl: './dashboard-chart-nxt-slider.component.html',
	styleUrls:   ['./dashboard-chart-nxt-slider.component.scss']
})
export class DashboardChartNxtSliderComponent extends DashboardChartNxtBase
	implements AfterViewInit, OnDestroy, IDashboardPanelComponent<DashboardChartNxtSliderDataDescribed> {
	private activeSlidersCount: number;

	get currentActiveOffset(): number {
		return this._currentActiveOffset;
	}

	set currentActiveOffset(value: number) {
		if (this._currentActiveOffset !== value
			|| this._data.slider.filter(slider => !slider.disabled).length !== this.activeSlidersCount) {
			this._currentActiveOffset = value;
			this.updateSlider();
		} else {
			LoggerUtil.debug(`currentActiveOffset: ${value} is same as previous: ${this._currentActiveOffset}`);
		}

	}

	@ViewChild('csChartLoaderDirective') chartDir: CsChartLoaderDirective;
	@ViewChild(SliderComponent) slider: SliderComponent;
	private lastIndex: number;
	private chartColumnTextPositions: number[];

	showSlider = true;
	private _data: DashboardChartNxtSliderDataDescribed;

	chartData: any;

	chartOptions: any;
	options: Options = {
		floor: 0,
		ceil:  0
	};

	currentChart: DashboardChartNxtDataDescribed;

	private _currentActiveOffset      = 0;
	manualRefresh: EventEmitter<void> = new EventEmitter<void>();

	set data(value: DashboardChartNxtSliderDataDescribed) {
		this._data = value;

		this.setActiveChart(value.charts[value.selected]);

	}

	private updateSlider() {
		this.activeSlidersCount = this._data.slider.filter(slider => !slider.disabled).length;

		this.showSlider = this.activeSlidersCount > 0;

		this.options = {
			floor:                 0,
			ceil:                  this.activeSlidersCount - 1,
			showTicks:             true,
			hideLimitLabels:       true,
			hidePointerLabels:     false,
			translate:             (index, label) => {
				if (label === LabelType.Low && index !== NaN) {
					return isNullOrUndefined(this._data.slider[index]) ? '' : this._data.slider[index].label;
				}
			},
			customValueToPosition: (val: number, minVal: number, maxVal: number): number => {

				const value = (((this.currentActiveOffset * val)
						+ ((this.currentActiveOffset * this._data.axisMargin) + (val * 4))))
					/ this.element.nativeElement.getBoundingClientRect().width;

				return value;
			},
			customPositionToValue: (percent: number, minVal: number, maxVal: number): number => {

				const position = (
					(percent *
						(this.element.nativeElement.getBoundingClientRect().width
							- ((4 * this.activeSlidersCount) * percent)
							- (this.currentActiveOffset * this._data.axisMargin)
						)
					)
					/ this.currentActiveOffset
				);

				if (position > maxVal)
					return maxVal;
				else if (position < minVal)
					return minVal;
				else
					return Math.floor(position);
			}
		};

	}

	get data(): DashboardChartNxtSliderDataDescribed {
		return this._data;
	}


	constructor(@Inject(forwardRef(() => CsChartLoaderSetup))
								chartSetup: CsDataDescribedChartLoaderSetup,
							@Inject(forwardRef(() => DashboardEventHub)) dashboardEventHub: DashboardEventHub,
							changeDetectorRef: ChangeDetectorRef,
							readonly element: ElementRef) {
		super(chartSetup, dashboardEventHub, changeDetectorRef, element);

	}

	ngOnDestroy(): void {
		this.OnDestroy();
	}

	update(data: DashboardChartNxtSliderDataDescribed): void {
		if (!isNullOrUndefined(this.data) && !isNullOrUndefined(data) &&
			this.data && ArrayUtils.isEqual(this.data.charts, data.charts))
			return;

		// make sure the last index is set instead of the server provided index
		// will position the correct chart

		data.selected = this.lastIndex ? this.lastIndex : data.selected;

		this.data = data;
	}

	async refreshData() {
		if (this.chartDir)
			await this.chartDir.refreshData();
	}

	ngAfterViewInit(): void {

		const element = this.element.nativeElement.parentElement as HTMLDivElement;

		fromEvent(window, 'resize').pipe(untilDestroyed(this), debounceTime(400)).subscribe(value => {
			this.calculateSpaceBetweenTicks();
			this.changeDetectorRef.detectChanges();
		});

		this.resizeSensor = new ResizeSensor(element, () => {
			if (this.isResized()) {
				LoggerUtil.debug(`Change of size detected: {old}:${this.oldHeight} => {new}: ${this.element.nativeElement.clientHeight}`);
				if (typeof (Event) === 'function') {
					// modern browsers
					window.dispatchEvent(new Event('resize'));
				} else {
					// for IE and other old browsers
					// causes deprecation warning on modern browsers
					const evt = window.document.createEvent('UIEvents');
					(<any>evt).initUIEvent('resize', true, false, window, 0);
					window.dispatchEvent(evt);
				}

				this.resizeSensor.detach();
			}
		});
	}

	private setActiveChart(data: DashboardChartNxtDataDescribed) {
		this.currentChart = data;

		const options = pathChecked(data, ['layout', 'options'], {});

		this.isClickable           = this.checkIfClickable(data);
		this.isWholeChartClickable = this.checkIfWholeChartClickable(data);

		this.clickTypeClass = getSelectionTargetClass(data);
		this.chartSetup.mapToDataTable(data, null).then(value1 => {
			this.chartData    = value1.dataTable;
			this.chartOptions = mergeDeep(this.checkOptions(data), options);
			SafeMethods.detectChanges(this.changeDetectorRef);
			this.calculateSpaceBetweenTicks();

		});
	}

	private calculateSpaceBetweenTicks() {
		const positions               = this.element.nativeElement.querySelectorAll('svg text[text-anchor="middle"]') as NodeList;
		this.chartColumnTextPositions = Array.from(positions).map((value: HTMLElement) => {
			const valX   = value.getAttribute('x');
			const parsed = Number.parseFloat(valX);
			//return (100 * parsed) / this.element.nativeElement.getBoundingClientRect().width;
			return parsed;
		});
		if (this.chartColumnTextPositions.length > 1)
			this.currentActiveOffset = this.chartColumnTextPositions[this.chartColumnTextPositions.length - 1] - this.chartColumnTextPositions[this.chartColumnTextPositions.length - 2] - 3;
	}

	changeActiveChart($event: number) {
		if ($event < 0)
			$event = 0;

		this.lastIndex = $event;
		this.setActiveChart(this._data.charts[$event]);
	}
}

