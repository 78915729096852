import { InjectionToken }                 from '@angular/core';
import { IConfigService }                 from '@cs/performance-manager/shared';
import { PmNavbarSelection }              from '@cs/performance-manager/shared';
import { Observable }                     from 'rxjs';
import { Result }                         from '@cs/core';
import { PmNavbarItem }                   from '@cs/performance-manager/shared';
import { DeploymentDetails }              from '@cs/performance-manager/shared';
import { PmNavbarBody }                   from '@cs/performance-manager/shared';
import { PmNavbarModuleChangedEventArgs } from '@cs/performance-manager/shared';
import { Route }                          from '@angular/router';

export function DefaultNavbarIconsFactory() {
	const iconsLib = new Map<string, string>();
	iconsLib.set('admin-webtop', 'mdi mdi-cog');
	iconsLib.set('user/managed-by-me', 'mdi mdi-briefcase');
	iconsLib.set('user/profile', 'mdi mdi-account');
	iconsLib.set('app/changelog', 'mdi mdi-history');
	iconsLib.set('developer', 'developer-logo-icon');
	iconsLib.set('app-reset', 'mdi mdi-application');
	iconsLib.set('refresh-page', 'mdi mdi-refresh');
	iconsLib.set('logout', 'mdi mdi-logout');
	iconsLib.set('about', 'mdi mdi-18px mdi-information-outline');
	return iconsLib;
}

export const SHELL_NAVBAR_ICONS_REPO = new InjectionToken('shell-navbar-icons-repo', {
	providedIn: 'root',
	factory:    DefaultNavbarIconsFactory
});

export abstract class ShellConfigService implements IConfigService {

	moduleName = 'shell';

	patchRootPathWIthModule = false;

	enableFooter = true;

	abstract getPoweredByBarItems(): Observable<Result<PmNavbarItem[]>>;

	abstract getNavigationBarItems(currentSelection?: PmNavbarSelection): Observable<Result<PmNavbarBody>>;

	abstract getDeploymentDetails(): Observable<Result<DeploymentDetails>>;

	abstract resolveNewModulePath($event: PmNavbarModuleChangedEventArgs, currentPath: Route[]): string[];
}
