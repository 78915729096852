import { LoggerUtil }                                            from '@cs/core';
import { Component, Directive, Injectable, Input, OnInit, Type } from '@angular/core';
import { toLowerCase }                                           from '@cs/components/util';
import { ComponentLoaderDataContext }                            from '../directives';
import { WizardParentContext, WizardDataDescribedParser }        from '@cs/components/wizard';
import { FormControl, FormGroup }                                from '@angular/forms';

/**
 * Use this key to wrap data that is not according the data described spec and to be used in the wizard formgroups
 */
export const DATA_WRAPPER_KEY = 'dataWrapper';

// tslint:disable-next-line:no-empty-interface
@Directive()
export abstract class WizardContentComponent<T, TData = T> implements OnInit {
	/**
	 * Extra context provided by the wizard, like step information or formgroups
	 * @protected
	 */
	parentContext: WizardParentContext;
	/**
	 * Store for the Wizard provided formgroup
	 * @protected
	 */
	formGroup: FormGroup;
	/**
	 * The data receiver for the component that is loaded. Allows for uniform handling of data changes when NgModel is implemented
	 * @protected
	 */
	formControl: FormControl = new FormControl();

	get dataContext(): T {
		return this._dataContext;
	}

	protected set dataContext(value: T) {
		this._dataContext = value;
	}

	protected constructor(protected readonly context: ComponentLoaderDataContext<TData, WizardParentContext>) {

	}

	ngOnInit() {
		this.parentContext = this.context.parentContext;
	}

	validate() {

	}

	private _dataContext: T;
}

export interface WizardRegisterItem {
	component: Type<WizardContentComponent<unknown, unknown>>;
	dataLoaderFn?: Function;
}


@Injectable({providedIn: 'root'})
export class WizardComponentRegistry {

	constructor() {
	}

	hasTemplate(type: string) {
		return this.components.has(toLowerCase(type));
	}

	register<T extends Type<WizardContentComponent<unknown, unknown>>>(type: string,
																																		 component: T
	) {
		this.components.set(toLowerCase(type), {component: component});
	}

	getTemplate(type: string): WizardRegisterItem {
		if (this.hasTemplate(type)) {
			return this.components.get(toLowerCase(type));
		}
		LoggerUtil.error(`${type} is not found in template registry`);
		return null;
	}

	private components: Map<string, WizardRegisterItem> = new Map<string, WizardRegisterItem>();

}

