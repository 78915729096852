import { ModuleWithProviders, NgModule, Type }                from '@angular/core';
import { CommonModule }                                       from '@angular/common';
import { CsApplicationSettings }                              from '@cs/performance-manager/shared';
import { CsTranslationLoader }                                from '@cs/performance-manager/shared';
import { IConfigService }                                     from '@cs/performance-manager/shared';
import { HttpClient }                                         from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { LoggerUtil }                                           from '@cs/core';
import { ComponentTranslationLoaderService, CsCultureProvider } from '@cs/common';
import { ManagedByMeComponent }                                 from './managed-by-me.component';
import { ManagedByMeConfigService }                           from './managed-by-me-config.service';
import { ManagedByMeRoutingModule }                           from './managed-by-me-routing.module';
import { CsTableNxtModule }        from '@cs/components';


export function managedByMeTranslationLoader(client: HttpClient,
                                             pmAppSettings: CsApplicationSettings,
                                             service: IConfigService,
                                             componentLoader: ComponentTranslationLoaderService) {
  return new CsTranslationLoader(`${pmAppSettings.translationLocation}/${service.moduleName}`,
    client, componentLoader, pmAppSettings.deploymentDetails.buildDate);
}

@NgModule({
  declarations: [ManagedByMeComponent],
  imports:      [
    CommonModule,
    TranslateModule.forChild({
      loader:  {
        provide:    TranslateLoader,
        useFactory: managedByMeTranslationLoader,
        deps:       [HttpClient, CsApplicationSettings, ManagedByMeConfigService, ComponentTranslationLoaderService]
      },
      isolate: true
    }),
    ManagedByMeRoutingModule,
    CsTableNxtModule
  ]
})
export class PmManagedByMeModule {
  static forRoot(moduleConfig: Type<ManagedByMeConfigService>,
                 appSetting: CsApplicationSettings): ModuleWithProviders<PmManagedByMeModule> {
    return {
      ngModule:  PmManagedByMeModule,
      providers: [
        {
          provide:  ManagedByMeConfigService,
          useClass: moduleConfig
        },
        {
          provide:  CsApplicationSettings,
          useValue: appSetting
        }
      ]
    };
  }

  constructor(translate: TranslateService, culture: CsCultureProvider) {

    if (!translate) {
      LoggerUtil.warn('Translation service is not set-up');
      return;
    }

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(culture.getDefaultCulture());

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use(culture.getCulture());
  }
}
