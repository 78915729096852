import { Component, OnInit }             from '@angular/core';
import { IDashboardPanelComponent }      from '../../models/i-dashboard-panel-component';
import { IndicatorDataDescribed }        from '@cs/components/indicator';
import { ArrayUtils, isNullOrUndefined } from '@cs/core';

@Component({
	selector:    'cs-dashboard-indicators',
	templateUrl: './dashboard-indicators.component.html',
	styleUrls:   ['./dashboard-indicators.component.scss']
})
export class DashboardIndicatorsComponent implements OnInit, IDashboardPanelComponent<IndicatorDataDescribed> {

	name: string;

	data: IndicatorDataDescribed;

	constructor() {
	}

	ngOnInit() {
	}

	update(data: IndicatorDataDescribed): void {
		if (!isNullOrUndefined(this.data) && !isNullOrUndefined(data) &&
			(this.data && ArrayUtils.isEqual(this.data.data, data.data)))
			return;

		this.data = data;
	}

}
