import { ChangeDetectorRef, Component }  from '@angular/core';
import { ArrayUtils, isNullOrUndefined } from '@cs/core/utils';
import { IDashboardPanelComponent }      from '../../models/i-dashboard-panel-component';
import { CsFileDataDescribed } 					 from '@cs/components/viewer';

@Component({
	selector:    'cs-dashboard-viewer',
	templateUrl: './dashboard-viewer.component.html',
	styleUrls:   ['./dashboard-viewer.component.scss']
})
export class DashboardViewerComponent implements IDashboardPanelComponent<CsFileDataDescribed> {

	name: string;

	data: CsFileDataDescribed;

	constructor(private changeRef: ChangeDetectorRef) {
	}

	update(data: CsFileDataDescribed): void {
		if (!isNullOrUndefined(this.data) && !isNullOrUndefined(data) &&
			(this.data && ArrayUtils.isEqual(this.data.data, data.data)))
			return;

		this.data = data;
	}

}
