import { forwardRef, Inject, ModuleWithProviders, NgModule, Optional } from '@angular/core';
import { CommonModule }                                                from '@angular/common';
import { FormsModule, ReactiveFormsModule }                            from '@angular/forms';
import { CsSharedModule, WizardComponentRegistry }                     from '@cs/components/shared';
import { DatePickerWidgetComponent }                                   from './widgets/date-picker-widget.component';
import { CsDatepickerModule }                                          from '@cs/components/datepicker';
import { CsComboboxModule }                                            from '@cs/components/combobox';
import { FormatProviderService }                                       from '@cs/common';
import { TranslateModule }                                             from '@ngx-translate/core';
import { MatTooltipModule }                                            from '@angular/material/tooltip';
import { CsMaskModule }                                                from '@cs/components/input-mask';
import { FormGeneratorNxtComponent }                                   from './form-generator-nxt.component';
import { WidgetFactory }                                               from './widget-factory.service';
import { FormElementComponent }                                        from './form-element.component';
import { WidgetSwitchComponent }                                       from './widget-switch.component';
import { WidgetRegistry }                                              from './widget-registry.util';
import { TerminatorService }                                           from './terminator.service';
import { StringWidgetComponent }                                       from './widgets/string-widget.component';
import { ButtonWidgetComponent }                                       from './widgets/button-widget.component';
import { InputMaskWidgetComponent }                                    from './widgets/input-mask-widget.component';
import { CsValidatorRegistry }                                         from './cs-validator-registry';
import { FormWidgetCollectionComponent }                               from './form-widget-collection.component';
import { SelectWidgetComponent }                                       from './widgets/select-widget.component';
import { DefaultWidgetRegistry }                                       from './widgets/default-widget-registry.service';
import { DefaultCsValidatorRegistry }                                  from './cs-validators';
import { LabelWidgetComponent }                                        from './widgets/label-widget.component';
import { FileWidgetComponent }                                         from './widgets/file-widget.component';
import { DateWidgetComponent }                                         from './widgets/date-widget.component';
import { CheckboxWidgetComponent }                                     from './widgets/checkbox-widget.component';
import { TextWidgetComponent }                                         from './widgets/text-widget.component';
import { WizardFormNxtComponent }                                      from './plugins/wizard-form-nxt/wizard-form-nxt.component';


const COMPONENTS = [
	FormGeneratorNxtComponent, FormElementComponent,
	WidgetSwitchComponent, FormWidgetCollectionComponent, WizardFormNxtComponent
];
const WIDGETS    = [
	StringWidgetComponent,
	DatePickerWidgetComponent,
	SelectWidgetComponent,
	LabelWidgetComponent,
	FileWidgetComponent,
	DateWidgetComponent,
	CheckboxWidgetComponent,
	ButtonWidgetComponent,
	InputMaskWidgetComponent,
	TextWidgetComponent
];

const moduleProviders = [
	{
		provide:  WidgetRegistry,
		useClass: DefaultWidgetRegistry
	},
	{
		provide:  CsValidatorRegistry,
		useClass: DefaultCsValidatorRegistry
	},
	{
		provide:  FormatProviderService,
		useClass: forwardRef(() => FormatProviderService)
	}
];

@NgModule({
						declarations:    [...COMPONENTS, ...WIDGETS],
						imports:         [
							CommonModule,
							CsSharedModule,
							FormsModule,
							ReactiveFormsModule,
							CsDatepickerModule,
							CsComboboxModule,
							TranslateModule,
							MatTooltipModule,
							CsMaskModule
						],
						exports:         [...COMPONENTS, ...WIDGETS],
						providers:       [WidgetFactory, TerminatorService],
						entryComponents: [...COMPONENTS, ...WIDGETS]
					})
export class FormGeneratorNxtModule {
	static forRoot(): ModuleWithProviders<FormGeneratorNxtModule> {
		return {
			ngModule:  FormGeneratorNxtModule,
			providers: [...moduleProviders]
		};
	}


}
