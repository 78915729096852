import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy, OnInit,
	Output,
	SimpleChange
} from '@angular/core';
import {
	FormGroup
} from '@angular/forms';

import { NodeClickedEventArgs } from '../../event-args/node-clicked-event-args';
import { PageNode }             from '../../models/node-types/page-node';

import { INode } from '../../interfaces/inode';

import { isNullOrUndefined, isUndefined }             from '@cs/core';
import { MdmPropertiesViewerConfigService }           from '../../mdm-properties-viewer-config.service';
import { ComponentChanges, whenChanging }             from '@cs/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SafeMethods, simpleFadeInOut }               from '@cs/common';
import { MdmProfileContext }                          from '../../models/mdm-properties-result-params';
import { UntilDestroy, untilDestroyed }               from '@ngneat/until-destroy';
import { MdmPropertiesViewerEventService }            from '../../mdm-properties-viewer-event.service';
import { ActivatedRoute, Router }                     from '@angular/router';
import { CsToastManagerService }                      from '@cs/components';
import { TranslateService }                           from '@ngx-translate/core';

import { MatDialog, MatDialogConfig }                           from '@angular/material/dialog';
import { DialogBasicComponent, DialogBasicOptions, DialogType } from '@cs/performance-manager/shared';


@UntilDestroy()
@Component({
	selector:    'pmc-properties-form',
	templateUrl: './properties-form.component.html',
	animations:  [
		simpleFadeInOut('showLoading'),
		trigger('isLoading', [
			state('loading',
				style({
					filter:        'blur(3px)',
					opacity:       0.5,
					pointerEvents: 'none'
				})
			),
			state('done',
				style({
					filter:        'blur(0px)',
					opacity:       1,
					pointerEvents: 'all'
				})
			),
			// When the element goes from 'selected' state to whatever...
			transition('loading <=> done', [
				animate('0.2s cubic-bezier(0.4, 0.0, 0.2, 1)')
			])
		])
	]
})
export class CsPropertiesFormComponent implements OnChanges, OnDestroy {
	get originalData(): any {
		return JSON.parse(this._originalData);
	}

	set originalData(value: any) {
		this._originalData = JSON.stringify(value);
	}

	form: FormGroup = new FormGroup({});
	nodes: Array<INode>;
	propertyNodes   = [];

	@Input() pageList: Array<PageNode> = [];
	@Input() context: MdmProfileContext;
	/**
	 * List of all Section nodes. This will show a subnavigation bar when there are more than one
	 */
	sectionNodes: Array<INode>         = [];
	/**
	 * Selected current sectionNode
	 */
	selectedSectionNode: INode         = null;
	/**
	 * Selected current page
	 */
	selectedPageNode: PageNode         = null;

	data;
	private _originalData: any;
	timezones: any;

	pageState: 'loading' | 'done' = 'loading';

	@Input() editMode    = false;
	@Input() showButtons = false;

	/**
	 * Flag indicating to return to a readonly state after saving
	 */
	@Input() revertToReadonlyAfterSave = true;

	/**
	 * Flag indicating to return to a readonly state after changin tabs
	 */
	@Input() revertToReadonlyAfterSwitchingTabs = true;
	/**
	 * Event that is triggered when a node is clicked.
	 */
	@Output() nodeClicked                       = new EventEmitter<NodeClickedEventArgs>();

	@Output() saveDone = new EventEmitter<NodeClickedEventArgs>();

	/**
	 * Callback function which returns true if form data must be discarded
	 */
	@Input() saveChangesConfirmed = (): Promise<boolean> => this.getUserConfirmation(this.i8n.instant('SAVE_UNSAVED_CHANGES'), this.dialog);


	public constructor(private propertiesService: MdmPropertiesViewerConfigService,
										 private mdmPropertiesViewerEventService: MdmPropertiesViewerEventService,
										 private router: Router,
										 private route: ActivatedRoute,
										 private toastManager: CsToastManagerService,
										 private i8n: TranslateService,
										 private dialog: MatDialog,
										 public changeRef: ChangeDetectorRef) {
		if (isUndefined(this.form)) {
			this.form = new FormGroup({});
		}

		this.mdmPropertiesViewerEventService.saveDataRequested
				.pipe(untilDestroyed(this))
				.subscribe(value => this.saveData(value));
		this.mdmPropertiesViewerEventService.cancelRequested
				.pipe(untilDestroyed(this))
				.subscribe(value => this.cancelForm(value));

	}

	getUserConfirmation(msg: string, dialog: MatDialog): Promise<any> {
		const confirmDialogRef = dialog.open(DialogBasicComponent, {
			data:       {
				dialogTitle: '',
				type:        DialogType.info,
				message:     msg
			},
			maxWidth:   '30vw',
			minWidth:   200,
			panelClass: 'confirmation-dialog'
		} as MatDialogConfig<DialogBasicOptions>);
		return confirmDialogRef.afterClosed().toPromise();
	}

	private refreshFormData() {
		if (isNullOrUndefined(this.selectedPageNode))
			return;

		this.pageState                  = 'loading';
		this.selectedPageNode.isLoading = true;
		// timeout is for animation purposes
		setTimeout(() => {
			this.propertiesService.getProperties(this.selectedPageNode.name, this.context)
				//.pipe(tap(WaitingForResponse.new(isLoading => this.selectedPageNode.isLoading = isLoading)))
					.subscribe(result => {
						if (this.selectedPageNode)
							this.router.navigate([],
								{queryParamsHandling: 'merge', queryParams: {PropertyPage: this.selectedPageNode.name}});

						this.nodes        = null;
						this.form         = new FormGroup({});
						this.data         = result.value;
						this.originalData = result.value;
						if (this.data == null || this.data.length === 0) {
							this.alertThatNoPropertiesAreProvided();
							return;
						}

						this.processData(this.data);
						this.selectedPageNode.isLoading = false;
						this.pageState                  = 'done';
					}, (results) => {
						this.pageState                  = 'done';
						this.selectedPageNode.isLoading = false;
						//this.processData(this.data);
					});
		}, 200);
	}


	private alertThatNoPropertiesAreProvided() {
		this.toastManager.show({
			type:    'warning',
			content: this.i8n.instant('NO_PROPERTIES_PROVIDED'),
			title:   this.i8n.instant('EMPTY_RESPONSE')
		});
	}

	private createForm(sectionNode: INode) {
		const group: any    = {};
		const propertyNodes = [];

		if (sectionNode == null || sectionNode.children == null || sectionNode.children.length === 0) {
			this.alertThatNoPropertiesAreProvided();
		}

		for (const groupNode of sectionNode.children) {
			for (const iNode of groupNode.children) {
				const propertyNode      = iNode;
				group[propertyNode.key] = propertyNode.input;
				propertyNodes.push(propertyNode);
			}
		}

		this.propertyNodes = propertyNodes;
		return new FormGroup(group);
	}

	private resetData(data, currentSection: string) {
		if (!isNullOrUndefined(data)) {
			const sectionNodes           = [];
			const pages: Array<PageNode> = [];

			for (const pageData of data) {
				pages.push(new PageNode(pageData));
			}
			pages.forEach(page => sectionNodes.push(...page.children));
			const selectedSectionNode = sectionNodes.find(page => page.name === currentSection);

			return this.resetForm(selectedSectionNode);
		}
	}

	private resetForm(sectionNode: INode) {

		const group: any = {};

		if (sectionNode == null || sectionNode.children == null || sectionNode.children.length === 0) {
			return;
		}

		for (const groupNode of sectionNode.children) {
			for (const iNode of groupNode.children) {
				const propertyNode      = iNode;
				group[propertyNode.key] = propertyNode.input;

			}
		}
		return new FormGroup(group);
	}

	ngOnChanges(changes: ComponentChanges<CsPropertiesFormComponent>) {

		if (changes.hasOwnProperty('timezones')) {
			this.setTimezones();
		}

		whenChanging(changes.pageList, true).execute(value => {
			if (isNullOrUndefined(this.selectedPageNode)
				&& !isNullOrUndefined(this.pageList)
				&& this.pageList.length > 0) {


				// Check if the page is in the URL otherwise that the first one
				if (this.route.snapshot.queryParamMap.has('PropertyPage'))
					this.selectedPageNode = this.pageList.find(page => page.name === this.route.snapshot.queryParamMap.get('PropertyPage'));

				// fallback
				if (isNullOrUndefined(this.selectedPageNode))
					this.selectedPageNode = this.pageList[0];

				this.refreshFormData();
			} else if (!isNullOrUndefined(this.selectedPageNode) && !isNullOrUndefined(this.pageList) && this.pageList.find(page => page.name === this.selectedPageNode.name) == null) {

				// Check if the page is in the URL otherwise that the first one
				if (this.route.snapshot.queryParamMap.has('PropertyPage'))
					this.selectedPageNode = this.pageList.find(page => page.name === this.route.snapshot.queryParamMap.get('PropertyPage'));

				// fallback
				if (isNullOrUndefined(this.selectedPageNode))
					this.selectedPageNode = this.pageList[0];

				this.refreshFormData();
			} else {
				this.refreshFormData();
			}
		});
	}

	private processData(data) {
		if (!isNullOrUndefined(data)) {
			this.sectionNodes            = [];
			const pages: Array<PageNode> = [];

			for (const pageData of data) {
				pages.push(new PageNode(pageData));
				if (this.selectedPageNode.name === pageData.name)
					this.selectedPageNode.meta = pageData.meta;
			}
			pages.forEach(page => this.sectionNodes.push(...page.children));
			let selectedSectionNode = this.sectionNodes[0];
			// Check if the page is in the URL otherwise take the first one
			if (this.route.snapshot.queryParamMap.has('PropertySectionPage'))
				selectedSectionNode = this.sectionNodes.find(page => page.name === this.route.snapshot.queryParamMap.get('PropertySectionPage'));

			// fallback
			if (isNullOrUndefined(selectedSectionNode))
				selectedSectionNode = this.sectionNodes[0];

			this.selectedSectionNode = selectedSectionNode;

			this.nodes = [this.selectedSectionNode];

			this.form = this.createForm(this.selectedSectionNode);
			this.setTimezones();
			this.changeRef.markForCheck();
			this.changeRef.detectChanges();
		}
	}

	private async setTimezones() {
		if (!this.nodes) {
			return;
		}

		for (const pageNode of this.nodes) {
			for (const sectionNode of pageNode.children) {
				for (const groupNode of sectionNode.children) {
					for (const iNode of groupNode.children) {
						const propertyNode = iNode;
						if (propertyNode.propertyType.name === 'TimeZone') {
							if (isNullOrUndefined(this.timezones)) {
								// should write a resolver. But just get the timezone data if we have a property with timezones.
								this.timezones = await this.propertiesService.getLookup('timezones').toPromise();
							}
							propertyNode.input.setMetaData('timezones', this.timezones);
						}
					}
				}
			}
		}
	}

	public onNodeClicked(node: INode) {
		const eventArgs = new NodeClickedEventArgs(node);
		this.nodeClicked.emit(eventArgs);
	}

	public toggleEditMode(editMode?: boolean, node?: INode) {

		const toggleChildren = (childNodes: Array<INode>, childEditMode: boolean) => {
			for (const childNode of childNodes) {
				childNode.editMode = childEditMode;
				if (childNode.children.length > 0) {
					toggleChildren(childNode.children, editMode);
				}
			}
		};

		if (isNullOrUndefined(editMode)) {
			this.editMode = !this.editMode;
		} else {
			this.editMode = editMode;
		}
		if (node != null) {
			node.editMode = editMode;
			toggleChildren(node.children, editMode);
		}
		return this.editMode;
	}

	public saveData(value: INode) {
		const formControls = this.form.controls;
		const changedItems = [];
		// if (value) {
		//   for (const iNode of value.children) {
		//     const propertyNode = iNode;
		//     const control      = formControls[propertyNode.key] as any;
		//     if (JSON.stringify(control.data) !== JSON.stringify(propertyNode.data)) {
		//       const copy = Object.assign({}, control.data);
		//       copy.key   = propertyNode.key;
		//       changedItems.push(copy);
		//     }
		//   }
		//
		// } else {
		const pageNode = this.nodes.find(x => x.name === this.selectedSectionNode.name);

		for (const groupNode of pageNode.children) {
			for (const iNode of groupNode.children) {
				const propertyNode = iNode;
				const control      = formControls[propertyNode.key] as any;
				if (JSON.stringify(control.data) !== JSON.stringify(propertyNode.data)) {
					const copy = Object.assign({}, control.data);
					copy.key   = propertyNode.key;
					changedItems.push(copy);
				}
			}
		}
		//}

		this.propertiesService.saveProperties(this.context, changedItems).subscribe(value => {
			this.toastManager.show({type: 'success', content: this.i8n.instant('CHANGES_ARE_SAVED')});
			this.refreshFormData();
			if (this.revertToReadonlyAfterSave) {
				this.toggleEditMode(false);
			}

		});
	}

	public async onSectionNavLinkClicked(node: INode) {
		let saveChanges = null;
		if (!isNullOrUndefined(this.form) && this.form.dirty) {
			saveChanges = await this.saveChangesConfirmed();
			if (saveChanges) {
				this.saveData(null);
				this.selectedSectionNode = this.sectionNodes.find(x => x.name === node.name);
				this.changeSectionPage(node);
				return;
			} else if (saveChanges === false) {
				this.cancelForm(this.selectedSectionNode);

				this.selectedSectionNode = this.sectionNodes.find(x => x.name === node.name);
				this.changeSectionPage(node);
			} else
				this.cancelForm();

		} else if (isNullOrUndefined(this.form) || this.form.pristine) {
			// null form to postpone rendering of nodes until all data has been refreshed
			// property-node-view will complain if the formControl is not yet set.
			this.form = new FormGroup({});

			this.selectedSectionNode = this.sectionNodes.find(x => x.name === node.name);
			this.changeSectionPage(node);
		}

		if (this.revertToReadonlyAfterSwitchingTabs)
			this.toggleEditMode(false);

		this.changeRef.detectChanges();
	}

	public async onNavLinkClicked(node: INode) {

		let saveChanges = null;

		if (!isNullOrUndefined(this.form) && this.form.dirty) {
			saveChanges = await this.saveChangesConfirmed();
			if (saveChanges) {
				this.selectedPageNode = this.pageList.find(x => x.name === node.name);
				this.saveData(null);
				return;
			} else if (saveChanges === false) {
				this.cancelForm(this.selectedPageNode);
				this.selectedPageNode = this.pageList.find(x => x.name === node.name);
				this.refreshFormData();
			} else
				this.cancelForm();

		} else if (isNullOrUndefined(this.form) || this.form.pristine) {
			// null form to postpone rendering of nodes until all data has been refreshed
			// property-node-view will complain if the formControl is not yet set.
			this.form = new FormGroup({});

			this.selectedPageNode = this.pageList.find(x => x.name === node.name);
			this.refreshFormData();
		}

		if (this.revertToReadonlyAfterSwitchingTabs)
			this.toggleEditMode(false);

	}

	public cancelForm(node?: INode) {
		if (node != null) {
			this.pageState = 'loading';
			this.nodes     = null;
			this.form      = new FormGroup({});
			this.data      = this.originalData;
			this.processData(this.data);
			this.pageState = 'done';
		}

	}

	private changeSectionPage(node: INode) {
		const selectedSectionNode          = this.selectedSectionNode;
		this.selectedSectionNode.isLoading = true;
		this.router.navigate([],
			{queryParamsHandling: 'merge', queryParams: {PropertySectionPage: node.name}});
		this.selectedSectionNode = node;
		this.nodes               = [this.selectedSectionNode];
		setTimeout(() => {
			this.form                     = this.createForm(this.selectedSectionNode);
			selectedSectionNode.isLoading = false;
			SafeMethods.detectChanges(this.changeRef);
		}, 200);

	}

	ngOnDestroy(): void {
	}


}
