import { isNull, isNullOrUndefined } from '@cs/core';

import {
  isGreaterAndEqualsThanValidator,
  isGreaterThanValidator,
  isLessAndEqualsThanValidator, isLessThanValidator
}                                    from '@cs/components/util';

export class DataGridRuleMatch {
  factProperties: { [key: string]: DataGridRuleValueFilter } = {};
  levelKeys: { [key: string]: DataGridRuleMatchFilter }      = {};
  state: { [key: string]: DataGridRuleValueFilter }          = {};
  values: { [key: string]: DataGridRuleValueFilter }         = {};

  constructor(initial: Partial<DataGridRuleMatch> = null) {
    if (initial !== null)
      Object.assign(this, initial);
  }
}

/**
 * The structure to match corresponding keys of a rule to the data-grid-items like Columns, Cells and Rows
 */
export class DataGridRuleMatchFilter {
  ids: Array<number>;
  notIds: Array<number>;
  minId: number = null;
  maxId: number = null;

  constructor(initial: Partial<DataGridRuleMatchFilter>) {
    Object.assign(this, initial);
  }
}


export class DataGridRuleValueFilter {
  is: Array<number | string>;
  isNot: Array<number | string>;
  isNull: boolean;
  isNotNull: boolean;
  gt: number;
  gte: number;
  lt: number;
  lte: number;
  isNullOrUndefined: boolean;
  isNotNullOrUndefined: boolean;

  /**
   * Evaluates value for given filter. Return true when value is valid for all (set) filter properties.
   * Undefined filter properties are ignored
   */
  static evaluate(filter: DataGridRuleValueFilter, value: number) {

    // If value is not
    if (!isNullOrUndefined(filter.isNot) && filter.isNot.indexOf(value) > -1)
      return false;

    // Check if the cell.value is IsNotNull
    if (!isNullOrUndefined(filter.isNotNull) && isNull(value))
      return false;

    // Check if the cell.value is IsNull
    if (!isNullOrUndefined(filter.isNull) && !isNull(value))
      return false;

    // Check if the cell.value is isNotNullOrUndefined
    if (!isNullOrUndefined(filter.isNotNullOrUndefined) && isNullOrUndefined(value))
      return false;

    // Check if the cell.value is isNullOrUndefined
    if (!isNullOrUndefined(filter.isNullOrUndefined) && !isNullOrUndefined(value))
      return false;

    // If value is greater or equals
    if (!isNullOrUndefined(filter.gte) && !isGreaterAndEqualsThanValidator(value, filter.gte))
      return false;

    // If value is greater then
    if (!isNullOrUndefined(filter.gt) && !isGreaterThanValidator(value, filter.gt))
      return false;

    // If value is less or equals
    if (!isNullOrUndefined(filter.lte) && !isLessAndEqualsThanValidator(value, filter.lte))
      return false;

    // If value is less or equals
    if (!isNullOrUndefined(filter.lt) && !isLessThanValidator(value, filter.lt))
      return false;

    // If value equals
    if (!isNullOrUndefined(filter.is) && filter.is.indexOf(value) === -1)
      return false;

    // Item is valid
    return true;
  }

  constructor(initial?: Partial<DataGridRuleValueFilter>) {
    if (!isNullOrUndefined(initial)) {
      Object.assign(this, initial);
    }
  }

}
