import {
	Component, OnInit, AfterViewInit, OnDestroy,
	ChangeDetectorRef
}                                                                 from '@angular/core';
import { opacityAndBlur, SafeMethods }                            from '@cs/common';
import { UntilDestroy, untilDestroyed }                           from '@ngneat/until-destroy';
import { FilterCompareBarQuery, FilterCompareBarService, Column } from '@cs/components';
import { DataListConfigService }                                  from './data-list-config.service';
import { Router, ActivatedRoute }                                 from '@angular/router';
import { isNullOrUndefined }                                      from '@cs/core';
import { filter as filter$, tap }                                 from 'rxjs/operators';
import { CSResourceModel }                                        from './models/resourceModel';

@UntilDestroy()
@Component({
	selector:    'pmc-data-list',
	templateUrl: './data-list.component.html',
	styleUrls:   ['./data-list.component.scss'],
	animations:  [
		opacityAndBlur('loadingPanelState')
	]
})
export class DataListComponent<T> implements OnInit, AfterViewInit, OnDestroy {

	idCountry: number;
	list: Array<T>;
	columns: Array<Column>;
	isLoadingDataGrids = true;

	// Resources access for controlling visiblity of for example buttons.
	resources: CSResourceModel = new CSResourceModel();

	constructor(private filterCompareBarQuery: FilterCompareBarQuery,
							private config: DataListConfigService<T>,
							private router: Router,
							private route: ActivatedRoute,
							public readonly changeRef: ChangeDetectorRef,
							private filterCompareBarService: FilterCompareBarService) {
		this.columns = config.columns;
	}

	ngOnInit() {
	}

	ngOnDestroy(): void {
	}

	ngAfterViewInit(): void {
		this.filterCompareBarService.filterbarSelectionChanged.pipe(
			untilDestroyed(this)
		).subscribe(value => {
			this.isLoadingDataGrids = true;
		});

		// Load the idCountry from the navbar selection
		this.filterCompareBarQuery.select(store => store.mainbarResultParams)
				.pipe(
					tap(x => console.log(x)),
					untilDestroyed(this),
					filter$(value => !isNullOrUndefined(value))
				)
				.subscribe((value) => {
					this.idCountry = value.selection.idCountry['id'];
					this.getResourceAccess(this.idCountry);
					this.getListData(this.idCountry);
				});

	}

	/**
	 * Get list of T for a given country
	 * @param idCountry number
	 */
	private getListData(idCountry: number): void {
		this.config.getList(idCountry).subscribe(result => {
			this.list               = result.value as Array<T>;
			this.isLoadingDataGrids = false;
			SafeMethods.detectChanges(this.changeRef);
		});
	}

	onRowClick(data: any) {
		this.isLoadingDataGrids = true;
		this.router.navigate([this.idCountry, 'detail', data.id], {relativeTo: this.route});
	}

	onClickNew() {
		this.isLoadingDataGrids = true;
		this.router.navigate([this.idCountry, 'detail', 'new'], {relativeTo: this.route});
	}

	/**
	 * Get resource access rights for country
	 * @param idcountry number
	 */
	getResourceAccess(idCountry: number) {
		this.config.getResourceAccess(idCountry).subscribe((data) => {
			this.resources = new CSResourceModel(data.value);
		});
	}

}
