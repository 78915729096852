import { DataKey }             from '../utils/data-key.model';
import { DataStructureColumn } from './data=structure-column';

/**
 * Model for uniform data handling
 */
export class DataStructureRow {
	columns: Map<string, DataStructureColumn>;
	id: string;
	keys: DataKey;

	constructor(init: DataStructureRow) {
		this.columns = init.columns;
		this.id      = init.id;
		this.keys  = init.keys;
	}

}
