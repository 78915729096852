import { Injectable }          from '@angular/core';
import { DataEntrySaveMethod } from '@cs/components';
import { Store, StoreConfig }  from '@datorama/akita';


export interface DataEntryState {
	saveMethod: DataEntrySaveMethod;
	isLoadingNewContent: boolean;
	enableScrollDetection: boolean;
	isChartCollapsed: boolean;
	showChart: boolean;
	showEventPanel: boolean;
	isStatisticsPanelLoading: boolean;
	isChartPanelLoading: boolean;
	isDataGridsLoading: boolean;
	cargoToolSettings: any;
	dataGridSubFilterParams: { [key: string]: any };
}

export function createInitialStateDataEntryState(): DataEntryState {
	return {
		isChartCollapsed:         false,
		showChart:                true,
		showEventPanel:           false,
		isStatisticsPanelLoading: true,
		isChartPanelLoading:      true,
		isDataGridsLoading:       true,
		cargoToolSettings:        null,
		enableScrollDetection:    false,
		isLoadingNewContent:      false,
		dataGridSubFilterParams:  {},
		saveMethod:               DataEntrySaveMethod.BottomBar
	};
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'data-entry-state'})
export class DataEntryStateStore extends Store<DataEntryState> {

	constructor() {
		super(createInitialStateDataEntryState());
	}

}

