import { ChangeDetectionStrategy, Component, OnInit }                         from '@angular/core';
import { DashboardPanelComponentBase }                                        from '@cs/components/shared';
import { ArrayUtils, DataDescribed, LayoutAnnotationForm, LayoutOrientation } from '@cs/core';

@Component({
						 selector:        'cs-dashboard-simple-form-nxt',
						 templateUrl:     './dashboard-simple-form-nxt.component.html',
						 styleUrls:       ['./dashboard-simple-form-nxt.component.scss'],
						 changeDetection: ChangeDetectionStrategy.OnPush
					 })
export class DashboardSimpleFormNxtComponent extends DashboardPanelComponentBase<DataDescribed<any, LayoutAnnotationForm<any>>> implements OnInit {

	static TYPE_NAME = 'simple-form-nxt';
	renderContext: DataDescribed<any, LayoutAnnotationForm<any>>;

	constructor() {
		super();
	}

	ngOnInit(): void {
	}

	dataChanged(value: DataDescribed<any, LayoutAnnotationForm<any>>) {

		value.layout = new LayoutAnnotationForm<any>({
																									 form: {
																										 id:     '',
																										 layout: {
																											 readOnly:         true,
																											 readOnlyAsText:   true,
																											 groupOrientation: LayoutOrientation.Vertical
																										 }
																									 }
																								 });

		this.renderContext = value;

	}

	update(data: DataDescribed<any, LayoutAnnotationForm<any>>): void {
		if (this.data && data && ArrayUtils.isEqual(this.data.data, data.data))
			return;

		this.data = data;
	}


}
