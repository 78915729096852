import { map }                         from 'rxjs/operators';
import { Inject, Injectable }          from '@angular/core';
import {
	CanActivate, ActivatedRouteSnapshot,
	RouterStateSnapshot, Router
}                                      from '@angular/router';
import { Observable }                  from 'rxjs';
import { AuthenticationConfigService } from '../authentication-config.service';
import {
	CsHttpErrorResponse,
	CsHttpRequestOptions, LoggerUtil
}                                      from '@cs/core';
import { AuthenticationService }       from '../state/authentication.service';
import { AuthenticationQuery }         from '../state/authentication.query';
import { CsToastManagerService }       from '@cs/components/toast-manager';
import { TranslateService }            from '@ngx-translate/core';
import { AuthLogin }                   from '../models/auth-login.model';
import { AppConstants }                from '../../app/application-constants';

function getErrorHandler(state: RouterStateSnapshot, router: Router, config: AuthenticationConfigService) {
	const handler: CsHttpRequestOptions = new CsHttpRequestOptions();
	handler.errorResponseHandler        = (error) => {
		if (error
			&& (
				error.status === 0
				|| error.status === 500
			)) {

			LoggerUtil.warn(`Handling error in extended request option args: ${error}, navigating to /app/error`);

			router.navigate([config.errorUrl]);

			return true;
		}
		return false;
	};

	return handler;
}


@Injectable()
export class AuthenticationGuard implements CanActivate {
	constructor(@Inject(AuthenticationConfigService) private config: AuthenticationConfigService,
							private authenticationService: AuthenticationService,
							private router: Router) {
	}

	canActivate(next: ActivatedRouteSnapshot,
							state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

		LoggerUtil.debug(`Checking authentication`);

		const handler: CsHttpRequestOptions = getErrorHandler(state, this.router, this.config);
		return this.config.isLoggedIn(handler).pipe(map(result => {
			if (!result.value.status) {
				LoggerUtil.debug(`Not authenticated`);
				const queryParams = {[AppConstants.RETURN_URL]: state.url};
				this.router.navigate([this.config.loginUrl], {queryParams: queryParams});
				return false;
			}
			this.authenticationService.startKeepAlive();
			this.authenticationService.getUserProfile();
			LoggerUtil.debug(`Is authenticated`);
			return true;
		}));
	}
}

@Injectable()
export class IsLoggedInGuard implements CanActivate {
	constructor(@Inject(AuthenticationConfigService) private config: AuthenticationConfigService,
							private authenticationService: AuthenticationService,
							private toastService: CsToastManagerService,
							private l8n: TranslateService,
							private router: Router) {
	}

	canActivate(next: ActivatedRouteSnapshot,
							state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

		const handler: CsHttpRequestOptions = new CsHttpRequestOptions();
		handler.errorResponseHandler        = (error) => {
			if (error
				&& (
					error.status === 0
					|| error.status === 500
				)) {

				LoggerUtil.warn(`Handling error in extended request option args: ${error}, navigating to /app/error`);

				this.router.navigate([this.config.errorUrl]);

				return true;
			} else if (error.status === 401 || error.status === 423 || error.status === 432 || error.status === 406 || error.status === 426 || error.status === 400) {
				// User is not authenticated so allow the login module route to be loaded
				return true;
			}
			return false;
		};

		return new Observable(observer => {

			if (this.isExternalLoginAttempt()) {
				if (next.queryParamMap.has('method')) {
					const method    = next.queryParamMap.get('method');
					const returnUrl = next.queryParamMap.has(AppConstants.RETURN_URL) ? next.queryParamMap.get(AppConstants.RETURN_URL) : '/';
					this.config.authLogin(method, next.queryParams as AuthLogin, handler).subscribe(value => {
						this.router
								.navigate([returnUrl])
								.then(value1 => {
								});

						observer.next(false);
						observer.complete();
						return;
					}, error => {
						if (error.status === 400)
							this.authenticationService.showInvalidAutoLoginMessage(this.l8n);
						else if (error.status === 401)
							this.authenticationService.showInvalidAutoLoginMessage(this.l8n);
					});
				}

			} else if (this.isAutoLoginAttempt(next)) {
				this.config.autoLogin(next.queryParamMap.get('ALC'), handler).subscribe(value => {
					this.router.navigate(['/']).then(value1 => {
						//this.authenticationService.showExpiredPasswordPageMessage(this.l8n);
					});
					observer.next(false);
					observer.complete();
					return;
				}, error => {
					if (error.status === 400)
						this.authenticationService.showInvalidAutoLoginMessage(this.l8n);
					else if (error.status === 401)
						this.authenticationService.showInvalidAutoLoginMessage(this.l8n);
				});
			} else if (this.isChangePasswordUrl()
				|| this.isSetPasswordUrl()) {
				observer.next(true);
				observer.complete();
				return;
			}

			this.config.isLoggedIn(handler).subscribe(value => {
				// user is logged-in
				if (value.value.status) {
					observer.next(false);
					this.router.navigate(['/']);
				} else {
					// user is not logged in, so allow the user to enter the login module
					observer.next(true);
				}
			}, (error: CsHttpErrorResponse) => {
				// check if we want to go to the reset page, if so then allow it
				if (error.status === 432) {
					if (this.navigatingToResetPasswordPage()) {
						observer.next(true);
						observer.complete();
						this.authenticationService.showResetPasswordPageMessage(this.l8n);
						return;
					} else {
						// We are not going to the reset page so we need to navigate there.
						this.authenticationService.goToResetPasswordPage(this.l8n);
					}
				} else if (error.status === 426) {
					// We want to go to the resetpage so allow it
					if (this.navigatingToSetPasswordPage()) {
						observer.next(true);
						observer.complete();
						this.authenticationService.showResetPasswordPageMessage(this.l8n);
						return;
					} else {
						// We are not going to the reset page so we need to navigate there.
						this.authenticationService.goToSetPasswordPage(this.l8n);
					}
				} else {
					observer.next(true);
				}
			}, () => observer.complete());
		});
	}

	/**
	 * Check if the url is the external auth-login url.
	 * @private
	 */
	private isExternalLoginAttempt() {
		return this.matchUrl(this.config.externalLoginUrl);
	}

	private matchUrl(url: string) {
		const regex    = new RegExp(`(${url})(\\/?\\?{0}|\\/?\\?{1}.*)`);
		const hasMatch = this.router.getCurrentNavigation().finalUrl.toString().match(regex);
		return hasMatch != null && hasMatch.length > 0;
	}

	private isAutoLoginAttempt(next: ActivatedRouteSnapshot) {
		return next.queryParamMap.has('ALC');
	}

	private isChangePasswordUrl() {
		return this.matchUrl(this.config.expiredPasswordUrl);
	}

	private isSetPasswordUrl() {
		return this.matchUrl(this.config.setPasswordUrl);
	}

	private navigatingToResetPasswordPage() {
		return this.matchUrl(this.config.resetPasswordUrl);
	}

	private navigatingToSetPasswordPage() {
		return this.matchUrl(this.config.setPasswordUrl);
	}
}

@Injectable()
export class IsResetPageGuard implements CanActivate {
	constructor(private authenticationQuery: AuthenticationQuery,
							@Inject(AuthenticationConfigService) private config: AuthenticationConfigService,
							private toastService: CsToastManagerService,
							private l8n: TranslateService,
							private authenticationService: AuthenticationService,
							private router: Router) {
	}

	canActivate(next: ActivatedRouteSnapshot,
							state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

		const handler: CsHttpRequestOptions = new CsHttpRequestOptions();
		handler.errorResponseHandler        = (error) => {
			if (error
				&& (
					error.status === 0
					|| error.status === 500
				)) {

				LoggerUtil.warn(`Handling error in extended request option args: ${error}, navigating to /app/error`);

				this.router.navigate([this.config.errorUrl]);

				return true;
			}

			return true;
		};

		return new Observable(observer => {
			this.config.isLoggedIn(handler).subscribe(value => {
				// is logged in and there is no error than the user don't has any issues to login
				observer.next(value.value.status);
			}, error => {
				// if not 423 return code then we assume the user is not logged-in.
				if (error.status !== 423) {
					observer.next(false);
					this.router.navigate([this.config.loginUrl]);
				} else {
					// if 423 return code then we assume the user is logged-in.
					observer.next(true);
					this.authenticationService.showExpiredPasswordPageMessage(this.l8n);
				}

			}, () => observer.complete());
		});

	}
}
