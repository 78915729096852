<ng-container [ngSwitch]="renderOrientation">

    <ng-container *ngSwitchCase="'TABS'">
        <cs-dashboard-panel class="dashboard-panel" [panels]="panels" ></cs-dashboard-panel>
    </ng-container>

    <ng-container *ngSwitchDefault>
        <ng-container *ngFor="let panel of panels;trackBy:trackByPanelName">
            <cs-dashboard-panel class="dashboard-panel" [panel]="panel" [ngStyle]="{height: panel.height}"></cs-dashboard-panel>
        </ng-container>


    </ng-container>
</ng-container>

