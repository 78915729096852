import { DataFieldDefinition }                                                                           from './data-field-definition';
import { DataAnnotation, DataDescribed, DataDescribedArray, DataKey, DataViewColumn, generateQuickGuid } from '../../../generate';
import {
	ArrayUtils,
	convertKeysToFnv32a,
	convertKeysToFnv32aPath,
	Dictionary, fnv32a,
	isEmptyObject
}                                                                                                        from '../../../utils';
import {
	DataStructureRow,
	DataStructure,
	DataPathInfo,
	DataLookup,
	DataStructureGroup,
	DataStructureGroups,
	IDataConverterOptions
}                                                                                                        from '../structure';
import { DataViewRow }                                                                                   from '../view';
import { HeaderDefinitionResult }                                                                        from './header-definition-result';

export enum DATA_CONSTANTS {
	AUTO_GROUP_COLUMN_ID = 'autoGroupColumn'
}

export type UntypedDataViewRow = DataViewRow<DataViewColumn<unknown, unknown, unknown>, unknown>;

export interface RowStructureResult {
	dataRows: Array<UntypedDataViewRow>;
	rowStructure: DataStructureGroups;
	groupedDataRows: Map<string, Array<UntypedDataViewRow>>;
}


export class DefaultDataConverterOptions implements IDataConverterOptions {
	readonly externalLookups: Map<string, DataLookup<string | number>> = new Map<string, DataLookup<string | number>>();

	useFieldAsValueAutoGroupColumn = true;
	fieldToUseAsValueAutoGroupColumn: string;

	addLookup(name: string, lookup: DataLookup<string | number>) {
		this.externalLookups.set(name, lookup);
	}

	getLookup(name: string): DataLookup<string | number> {
		return this.externalLookups.get(name);
	}

}

/**
 * Function that converts an DataScrutcure object to a sorted by index array of all the keys in the object.
 * @param root the DataStructureGroups object
 */
export function convertDataStructureGroupToArray(root: DataStructureGroups) {
	return Object.keys(root)
							 .map(value => root[value])
							 .sort((a, b) => a.index - b.index);
}


export abstract class DataConverter<TData = any, TStructure extends DataStructure = DataStructure, TDataKey = DataKey, TColumnType = string> {

	protected convert(data: TData, options: IDataConverterOptions = new DefaultDataConverterOptions()): TStructure {

		const structure = this.createStructure(data, options);


		const definitions           = this.createHeaderDefinitions(data, structure);
		structure.headerDefinitions = definitions.headerDefinitions[ArrayUtils.getLastElement(structure.groupOrderColumns)];

		structure.headerStructure = this.createHeaderStructureGroups(
			structure.headerDefinitions,
			definitions.headerStructure,
			structure.groupOrderColumns,
			structure.groupOrderRows, structure);

		structure.headerRows = this.createHeaderRows(structure, structure.groupOrderColumns, data);

		const dataObjects = this.getData(data, structure);

		const result = this.createRowStructureGroups(dataObjects, structure.groupOrderRows, structure, data);

		structure.rowStructure    = result.rowStructure;
		structure.dataRows        = result.dataRows;
		structure.groupedDataRows = result.groupedDataRows;

		// structure.groupRows = this.createGroupRows(structure);

		return structure;
	}

	/**
	 * Provide the dimension keys, for the headers. For example:
	 * Giving the current dataset. You have two header rows. the top row key is `IdGroup`, in this case, and the root header key is `IdHeader` then
	 * you return an {@link Array<string>} containing the header id's. ['IdGroup','IdHeader']. These keys wil then be used to group the headers.
	 * @param data The complete dataset
	 * @protected
	 */
	protected abstract getColumnGroupOrder(data: TData): string[];

	/**
	 * Provide the dimension keys, if applicable, for the row groups. For example:
	 * Giving the current dataset. You have one group. In this case, the group key is `IdGroup`,  then this will be used to create groups of data rows.
	 * you return an {@link Array<string>} containing the header id's. ['IdGroup']. These keys wil then be used to group the datarows.
	 * @param data The complete dataset
	 * @protected
	 */
	protected abstract getRowGroupOrder(data: TData): string[];

	/**
	 * Provide the data from the dataset as an {@link Array<DataStructureRow>}, this is uniform data structure between data formats.
	 * Given the uniform data input, the stitching of the dataRows and dataGroups and other structure defining work is reusable.
	 * @param data The complete dataset
	 * @param structure The current state of the renderSchema, will be used for decision making for the output
	 * @protected
	 */
	protected abstract getData(data: TData, structure: Readonly<TStructure>): Array<DataStructureRow>;

	/**
	 * The goal here is to create the {@link DataGridFieldDefinition}'s that will be used by the data-grid as headers.
	 * The field key is used to get the value from the property with the same name of the data row.
	 * The fieldGroupDefinitions are used to create an hierarchy of HeaderGroups and connect the fieldDefinitions to a group,
	 * this property is empty when the ${@link DataGridStructure.groupOrderColumns} > 1.
	 * @param data The complete dataset
	 * @param structure The current state of the renderSchema, will be used for decision making for the output
	 * @protected
	 */
	protected abstract createHeaderDefinitions(data: TData, structure: Readonly<TStructure>)
		: HeaderDefinitionResult;

	/**
	 * Calculates the keys and hashed column id's for the GroupColumn.
	 * @param groupOrderHeaders The dimension keys for the headers
	 * @protected
	 */
	protected calculateAutoGroupColumnKeys(groupOrderHeaders: string[]):
		DataPathInfo[] {
		const output: DataPathInfo[] = [];

		return this.calculateColumnKeys(groupOrderHeaders, DATA_CONSTANTS.AUTO_GROUP_COLUMN_ID);
	}

	/**
	 * Calculates the keys and hashed column id's for the parameter ID.
	 * @param groupOrderHeaders The dimension keys for the headers
	 * @param id The key that should be used to generate the paths
	 * @protected
	 */
	protected calculateColumnKeys(groupOrderHeaders: string[], id: string):
		DataPathInfo[] {
		const output: DataPathInfo[] = [];
		// Store the headers dimensions keys for the current depth
		const groupKeys              = {};
		// the headers dimensions for the current depth
		const groupPath: string[]    = [];
		// the hashed path to the current depth
		const path: string[]         = [];

		for (let depth = 0; depth < groupOrderHeaders.length; depth++) {
			// get the dimension key
			const headerGroup = groupOrderHeaders[depth];
			// add the dimension key to current depth
			groupPath.push(headerGroup);
			// create key object for current depth
			groupKeys[headerGroup] = id;
			// Hash the keys for column identification
			const groupId          = convertKeysToFnv32a(groupKeys, [headerGroup]);
			// add it to the current hashed path
			path.push(groupId);
			// add info to the result
			output.push({path: [...path], levels: [...groupPath], keys: {...groupKeys}, id: DataStructureGroup.getFullPath(path)});
		}

		return output;
	}

	/**
	 *
	 * @param fields
	 * @param fieldGroups
	 * @param groupOrderHeaders
	 * @param groupOrderRows
	 * @param structure
	 * @protected
	 */
	protected createHeaderStructureGroups(fields: Array<DataFieldDefinition>,
																				fieldGroups: DataStructureGroups,
																				groupOrderHeaders: Array<string>,
																				groupOrderRows: Array<string>,
																				structure: TStructure): DataStructureGroups {

		const rootHeaderDimension = ArrayUtils.getLastElement(groupOrderHeaders);
		// convert groupkey to hashed keys, so it's a match with the hashed columnId in the field definition
		const groupOrderKeys      = groupOrderRows.map(value => convertKeysToFnv32a({[rootHeaderDimension]: value}));
		const groups              = {} as DataStructureGroups;
		// show all fields that are visible and not found as a rowGroup
		const visibleHeaders      = fields.filter(value => groupOrderKeys.indexOf(value.structureKey) === -1);

		// get all headers without group
		const noGroupHeaders: DataFieldDefinition[] = visibleHeaders.filter(value => value.groupStructureKey == null)
																																.sort((a, b) => a.index - b.index)
																																.map((value, index) => {
																																	return new DataFieldDefinition({...value, index: index});
																																});
		let celIndexOffset                          = noGroupHeaders.length;
		const groupedHeaders: DataFieldDefinition[] = visibleHeaders.filter(value => value.groupStructureKey != null)
																																.sort((a, b) => a.index - b.index)
																																.map((value, index) => {
																																	return new DataFieldDefinition(
																																		{...value, index: value.index + (celIndexOffset)});
																																});


		const createStructureGroupHeader = (headerPropertyName: string,
																				header: DataFieldDefinition,
																				groupRoot: Dictionary): DataStructureGroup => {
			const result      = groupRoot;
			const pathSegment = header.structureKey;

			const headerGroup = new DataStructureGroup({
																									 levelKey:   header.levelKey,
																									 levelValue: header.levelValue,
																									 type:       'DataGridHeader'
																								 }).setIndex(header.index);

			result[pathSegment] = headerGroup;
			return headerGroup;
		};

		// check if there are row groups, therefore we need to add extra column that will contain the group labels
		// This cell could be empty
		if (groupOrderRows.length > 0 && !structure.fieldIdAutoGroupColumn) {

			let currentGroup = groups;
			const groupPaths = this.calculateAutoGroupColumnKeys(groupOrderHeaders);

			for (let depth = 0; depth < groupOrderHeaders.length; depth++) {
				const groupPathInfo = groupPaths[depth];

				const groupColumn = new DataStructureGroup({
																										 levelValue: '',
																										 levelKey:   '',
																										 type:       'DataGridAutoGroupColumn'
																									 }).setIndex(-1);

				currentGroup[groupPathInfo.id] = groupColumn;

				currentGroup = groupColumn.children;
			}
		}


		// No header groups specified so just create all visible headers
		for (const header of noGroupHeaders) {
			createStructureGroupHeader(header.levelKey, header, groups);
		}

		// starting a the top, working the way down the children
		for (let depth = 0; depth < groupOrderHeaders.length; depth++) {
			const headerPropertyName = groupOrderHeaders[depth];

			if (!isEmptyObject(fieldGroups)) {
				// Create Groups first so we could add the root headers to the corresponding groups
				for (const group of DataStructure.getHeadStructureLastNodes(fieldGroups)) {

					const headers = groupedHeaders.filter(value => value.groupStructureKey === group.structureKey);

					for (const header of headers) {
						createStructureGroupHeader(headerPropertyName, header, group.children);
					}

				}
			}
		}

		this.patchIndexWithNumberOfUngroupedHeaders(structure.groupOrderColumns, fieldGroups, celIndexOffset);

		// Merge the injected columns with the generated header groups
		return Object.assign(groups, fieldGroups);

	}

	protected createRowStructureGroups(dataRowDefinitions: Array<DataStructureRow>,
																		 groupOrderRows: Array<string>,
																		 structure: TStructure,
																		 data: TData): RowStructureResult {
		const headerRow: UntypedDataViewRow                                    = structure.headerRoot;
		const dataRows: Array<UntypedDataViewRow>                              = [];
		const groups                                                           = {} as DataStructureGroups;
		const distinctValuesTrackers: Array<Map<string | number, string>>      = [];
		const lastGroupNodeChildrenMap: Map<string, Array<UntypedDataViewRow>> = new Map<string, Array<UntypedDataViewRow>>();
		// pointer to last used pathSegment to avoid looping path property later on
		let lastGroupNodeFullPathSegment                                       = null;


		groupOrderRows.forEach((value, index) => distinctValuesTrackers[index] = new Map<string | number, string>());

		for (let rowIndex = 0; rowIndex < dataRowDefinitions.length; rowIndex++) {
			const dataRow                       = dataRowDefinitions[rowIndex];
			const path: Array<string>           = [];
			let currentRowGroupRoot: Dictionary = groups;
			const rowKey                        = {};

			for (let depth = 0; depth < groupOrderRows.length; depth++) {
				const groupKey   = groupOrderRows[depth];
				const keyTracker = distinctValuesTrackers[depth];
				const value      = dataRow.keys[groupKey];

				let pathSegment: string        = keyTracker.get(value);
				let result: DataStructureGroup = null;

				// append the current property name with the value as rowKey
				rowKey[groupKey] = value;

				// Check if value is already found by pathsegment not being NULL
				// Or if the value is already passed check if it is added to the current group
				if (pathSegment == null ||
					(pathSegment != null && !currentRowGroupRoot.hasOwnProperty(pathSegment))) {
					pathSegment = fnv32a(`${groupKey}=${value}`)
						.toString();
					keyTracker.set(value, pathSegment);
					// create and append the group to the groupStructure object
					result = this.createStructureGroupRow(groupKey, value, rowKey, currentRowGroupRoot, path, depth);
					// create an entry for the rowGroup so the corresponding data row instances could also added here
					lastGroupNodeChildrenMap.set(result.fullPath, []);
				} else {
					// the group is already created, set it as the currentGroup
					result = currentRowGroupRoot[pathSegment];
				}

				// Set the children as the groupRoot, so when the next groupOrder value
				// is used. It fills the children of the group
				if (depth < groupOrderRows.length - 1) {
					if (result.children == null)
						result.children = {};

					currentRowGroupRoot = result.children;
				}

				path.push(pathSegment);

				lastGroupNodeFullPathSegment = result.fullPath;
			}

			const row = this.createDataRow(dataRow, path, rowIndex, rowKey, headerRow, structure, data);

			if (groupOrderRows.length > 0) {
				const rowPointerRef = lastGroupNodeChildrenMap.get(lastGroupNodeFullPathSegment);

				if (rowPointerRef == null)
					throw new Error(`No entry found for ${lastGroupNodeFullPathSegment}`);

				rowPointerRef.push(row);
			}

			// Create the data rows because we're here already, in the correct context
			dataRows.push(row);
		}


		return {
			rowStructure:    groups,
			dataRows:        dataRows,
			groupedDataRows: lastGroupNodeChildrenMap
		};
	}

	protected abstract createDataRow(dataRow: DataStructureRow,
																	 path: string[],
																	 index: number,
																	 rowKey: { [p: string]: any; },
																	 headerRow: UntypedDataViewRow,
																	 structure: Readonly<TStructure>,
																	 data: Readonly<TData>): UntypedDataViewRow;

	protected createStructureGroupRow(levelKey: string | number,
																		levelValue: string | number,
																		key: DataKey,
																		groupRoot: Dictionary,
																		path: string[],
																		index: number): DataStructureGroup {
		const result   = groupRoot;
		const rowGroup = new DataStructureGroup({
																							levelKey:   levelKey,
																							levelValue: levelValue,
																							type:       'DataGridDataGroup'
																						}).setIndex(index);

		result[rowGroup.structureKey] = rowGroup;

		return rowGroup;
	}

	protected abstract createHeaderRows(structure: Readonly<TStructure>,
																			groupOrderColumns: string[],
																			data: TData): Array<UntypedDataViewRow>;


	protected abstract createStructure(data: TData, options: IDataConverterOptions): TStructure;

	/**
	 * When the data contains groups that are column based, there is a ungrouped headers and grouped headers. The grouped headers wil
	 * have indexes starting at 0 foreach child group. In order to maintain the original order we need to patch the data structures with
	 * corresponding indexes. At eacht depth of a DataStrucreGroup append the number of ungrouped headers to the index and then increment the
	 * offset by 1. repeat for each header.
	 * @param levels The amount of levels in the data
	 * @param headerStructure The structure to patch
	 * @param offset Number that needs be added to the index;
	 * @private
	 */
	protected patchIndexWithNumberOfUngroupedHeaders(levels: string[], headerStructure: DataStructureGroups, offset: number) {


		const numOfLevels = levels.length;

		for (let depth = 0; depth < numOfLevels - 1; depth++) {
			let startIndex = offset;

			const headers = DataStructure.getHeadStructureAtDepth(headerStructure, depth);

			for (const header of headers) {
				header.setIndex(header.index + startIndex++);
			}

		}

	}
}
