import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { CsHttpRequestOptions, DataDescribed, LoggerUtil }                    from '@cs/core';
import { DataDescribedLoginService, LoginConfigService }                      from '../login-config.service';
import { LoginService }                                                       from '../state/login.service';
import { LoginQuery }                                                         from '../state/login.query';
import { ActivatedRoute }                                                     from '@angular/router';
import { DefaultComponent }                                                   from '../default/default.component';
import { AuthenticationQuery }                                                from '@cs/performance-manager/shared';
import { TranslateService }                                                   from '@ngx-translate/core';
import { AuthMethod }                                                         from '../models/auth-method.model';
import { animate, style, transition, trigger }                                from '@angular/animations';
import { LoginModel }                                                         from '../models/accountInfo';
import { CsToastManagerService }                                              from '@cs/components';
import { DomSanitizer, SafeHtml }                                             from '@angular/platform-browser';

declare var grecaptcha: any;

@Component({
	selector:    'pmc-data-described-login',
	templateUrl: './data-described-login.component.html',
	animations:  [trigger(
		'inOutAnimation',
		[
			transition(
				':enter',
				[
					style({transform: 'translateX(-100%)', height: 48, marginTop: 0}),
					animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)',
						style({transform: 'translateX(0)', height: 48}))
				]
			),
			transition(
				':leave',
				[
					style({height: 0}),
					animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)',
						style({height: 0, opacity: 0, marginTop: -8}))
				]
			)
		]
	)
	]
})
export class DataDescribedLoginComponent extends DefaultComponent implements OnInit, OnDestroy {
	private availableAuthProviders: LoginModel[];
	private alreadyInjectedDependency: boolean = false;

	get activeMethod(): string {
		return this._activeMethod;
	}

	set activeMethod(value: string) {
		this._activeMethod = value;
	}

	get currentAuthMethod() {
		return this.loginOptions.find(value => value.active);
	}

	loginConfig: DataDescribedLoginService;
	formData: DataDescribed<LoginModel[]>;

	loginOptions: Array<AuthMethod>;

	loginModel: LoginModel = {googleCaptchaResponse: '', username: '', password: ''};

	/**
	 * Binding to the captcha field
	 */
	captcha      = '';
	/**
	 * The state of the form. Defaults to true because it's empty
	 */
	isErrorState = true;

	/**
	 * HTML of the Captcha
	 */
	captchaHtml: SafeHtml;

	/**
	 * Indicator for the loader
	 */
	isLoadingCaptcha = true;

	useGoogleCapthca = false;

	//#region Loaders

	/**
	 * Loader flag for the reset password button
	 */
	isWaitingForReset = false;

	//#endregion

	private _activeMethod = 'UsernamePassword';


	constructor(
		readonly loginService: LoginService,
		readonly loginQuery: LoginQuery,
		@Inject(LoginConfigService) loginConfig: DataDescribedLoginService,
		readonly route: ActivatedRoute,
		readonly authenticationQuery: AuthenticationQuery,
		readonly toastService: CsToastManagerService,
		readonly l8n: TranslateService,
		readonly renderer: Renderer2,
		readonly sanitizer: DomSanitizer,
		readonly changeRef: ChangeDetectorRef
	) {
		super(loginService, loginQuery, loginConfig, authenticationQuery, route, toastService, l8n);
		window['onloadRecaptchaCallback'] = () => this.refreshCaptcha();
	}

	ngOnInit() {
		super.ngOnInit();
		this.loginConfig.getLoginForm().subscribe(value => {

			this.formData               = value.value;
			this.availableAuthProviders = JSON.parse(JSON.stringify(this.formData.data));


			const loginState = this.loginQuery.getValue();

			this.activeMethod = loginState.method == null ? this.activeMethod : loginState.method;


			this.loginOptions = this.formData  // @ts-ignore
															.getLookupByProperty('method')
															.lookup
															.values
															.map(value1 => new AuthMethod({
																label:  value1.value,
																key:    value1.key,
																icon:   this.getAuthIcon(value1.key),
																active: this.activeMethod === value1.key
															}));

			// Save the login options for extenal login page label resolvement
			this.loginService.setLoginOptions(JSON.parse(JSON.stringify(this.loginOptions)));

			this.switchMethod(this.loginOptions.find(value1 => value1.active) || this.loginOptions[0]);
		});
	}

	private setAuthMethodMessage() {
		this.loginService.setUnderTitleMessage(`<i class="mr-2 ${this.getAuthIcon(this.activeMethod)}"></i> ${this.l8n.instant('MSG_LOGIN_METHOD', {method: this.currentAuthMethod.label})}`);
	}

	login() {
		if (this.loginQuery.getValue().inProgress) {
			LoggerUtil.log('Already in progress');
			return;
		}

		this.loginService.setErrorState(false);
		this.loginService.setInProgress(true);

		const errorHandler                = new CsHttpRequestOptions();
		errorHandler.errorResponseHandler = error => {
			this.loginService.setInProgress(false);

			this.refreshCaptcha();

			switch (error.status) {
				case 503:
					this.toastService.show({
						type:    'error',
						content: this.l8n.instant('AUTH_PROVIDER_NOT_AVAILABLE_MSG', {methodName: this.currentAuthMethod.label})
					});
					return true;
				case 309:
					if (error.error) {
						window.location = error.error;
						return true;
					} else {
						this.toastService.show({type: 'warning', content: this.l8n.instant('NO_AUTH_CALLBACK_LOGIN_MSG')});
					}
					return true;
				case 401:
					this.loginService.setErrorState(true);

					this.toastService.show({
						type:            'info',
						showProgressBar: true,
						showTitle:       false,
						content:         `${this.l8n.instant('INVALID_LOGIN_MSG')}`
					});

					return true;
			}
			return false;
		};

		this.loginConfig.loginWithProvider(this.loginModel, errorHandler).subscribe(value => {

			this.loginConfig.loginSuccessHandler(value, this.route);
			// stop animation
			this.loginService.setInProgress(false);
		});


		this.rememberMe ? this.loginService.setUserName(this.loginModel.username)
			: this.loginService.setUserName('');

		this.loginService.SetAuthMethod(this.loginModel.method);
		this.loginService.setRememberMe(this.rememberMe);

	}


	ngOnDestroy(): void {
		super.ngOnDestroy();
	}


	getAuthIcon(option: string) {

		const method = option.toLowerCase();

		if (method.startsWith('ldap'))
			return 'icon icon-microsoft-account';
		if (method === 'usernamepassword')
			return 'icon-cohelion';
		if (method === 'duo')
			return 'icon icon-duo-account';

	}

	switchMethod(authMethod: AuthMethod) {
		this.activeMethod = authMethod.key;
		for (const method of this.loginOptions) {
			method.active = this.activeMethod === method.key;
		}
		this.loginModel = this.availableAuthProviders.find(value => value.method === this.activeMethod);
		this.setAuthMethodMessage();

		this.useGoogleCapthca = this.loginModel.requiresGoogleCaptcha;
		if (this.useGoogleCapthca) {
			const injectedRootUrlHtml = this.loginModel.googleCaptchaChallenge;
			this.captchaHtml          = this.sanitizer.bypassSecurityTrustHtml(injectedRootUrlHtml);
			this.injectDependency();
		}
	}

	filterActive(loginOptions: Array<AuthMethod>) {
		if (loginOptions == null)
			return [];
		return loginOptions.filter(value => !value.active);
	}

	private injectDependency() {
		if (this.alreadyInjectedDependency) {
			this.refreshCaptcha();
			return;
		}

		this.alreadyInjectedDependency = true;
		const script                   = document.createElement('script');
		script.src                     = 'https://www.google.com/recaptcha/api.js?onload=onloadRecaptchaCallback&render=explicit';
		script.type                    = 'text/javascript';
		script.async                   = true;
		script.defer                   = true;
		script.charset                 = 'utf-8';
		this.renderer.appendChild(document.head, script);
	}

	refreshCaptcha() {
		if (!this.loginModel.requiresGoogleCaptcha)
			return;

		this.isLoadingCaptcha = false;
		this.changeRef.detectChanges();
		setTimeout(() => {
			const container = document.querySelector('.g-recaptcha');
			if (container && container.childElementCount > 0) {
				grecaptcha.reset();
			} else {
				grecaptcha.render(container, {
					'callback': (output) => {
						this.captcha                          = output;
						this.loginModel.googleCaptchaResponse = output;
					}
				});
			}
		}, 0);

	}

	// captchaHtmlRefresh() {
	// 	//Work around in case the user makes a mistake
	// 	this.loginConfig.getLoginForm().subscribe(value => {
	// 		const formData        = value.value;
	// 		this.useGoogleCapthca = this.loginModel.requiresGoogleCaptcha;
	// 		if (this.useGoogleCapthca) {
	// 			const injectedRootUrlHtml = this.loginModel.googleCaptchaChallenge;
	// 			this.captchaHtml          = this.sanitizer.bypassSecurityTrustHtml(injectedRootUrlHtml);
	// 			this.refreshCaptcha();
	// 		}
	// 	});
	// }

}
