import { Component }     from '@angular/core';
import { ControlWidget } from '../models/control-widget.directive';

@Component({
	selector: 'string-widget',
	template: `
				<div class="checkbox-form-group form-group">
					<label class="checkbox-container"
						   [class.disabled]="readOnly">
						<input [name]="name" class="form-control"
							   type="checkbox"
							   [formControl]="control"
							   [attr.id]="id"
							   [disabled]="readOnly"
							   [class.is-valid]="isValid"
							   [class.is-invalid]="isInValid"
						> <span class="checkmark"></span>
					</label>
					<div class="pm-form-element-invalid-feedback" *ngIf="errorMessages?.length> 0 ">
						<small class="{{error.type}}" *ngFor="let error of errorMessages">{{error.errorMessage}}</small>
					</div>
				</div>
						`
})

export class CheckboxWidgetComponent<T> extends ControlWidget<T> {

	/**
	 * Override to tell the parser that a readonly state is handeled by the widget
	 */
	static hasReadOnlyState = true;

}
