import { Component, Inject, OnInit }     from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef }  from '@angular/material/dialog';
import { DialogBasicOptions, DialogType } from './dialog-basic.models';
import { isNullOrUndefined }              from '@cs/core';


@Component({
  selector:    'csw-dialog-basic',
  templateUrl: './dialog-basic.component.html'
})
export class DialogBasicComponent implements OnInit {

  type: DialogType = DialogType.warning;
  showYes          = true;
  showCancel       = true;
  showNo           = false;
	showOk           = false;

  constructor(public dialogRef: MatDialogRef<DialogBasicComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogBasicOptions) {
  }

  ngOnInit() {
    if (!isNullOrUndefined(this.data.type)) {
      this.type = this.data.type;
    }
    this.showYes    = isNullOrUndefined(this.data.showYes) || this.data.showYes;
    this.showNo     = isNullOrUndefined(this.data.showNo) || this.data.showNo;
    this.showCancel = isNullOrUndefined(this.data.showCancel) || this.data.showCancel;
		this.showOk 		= !isNullOrUndefined(this.data.showOk) || this.data.showOk;

  }

  onNoClick() {
    this.dialogRef.close();
  }

}
