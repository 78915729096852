import { ModuleWithProviders, NgModule, Type } from '@angular/core';

import { ErrorRoutingModule } from './error-routing.module';
import { ErrorComponent }     from './error.component';

import { ErrorConfigService }    from './error-config.service';
import { CommonModule }          from '@angular/common';
import { CsApplicationSettings } from '@cs/performance-manager/shared';

@NgModule({
  imports:      [
    CommonModule,
    ErrorRoutingModule
  ],
  declarations: [ErrorComponent]
})
export class PmErrorModule {

  static forRoot(moduleConfig: Type<ErrorConfigService>,
                 appSetting: CsApplicationSettings): ModuleWithProviders<PmErrorModule> {
    return {
      ngModule:  PmErrorModule,
      providers: [
        {
          provide:  ErrorConfigService,
          useClass: moduleConfig
        },
        {
          provide:  CsApplicationSettings,
          useValue: appSetting
        }
      ]
    };
  }

}
