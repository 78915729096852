import { NgModule }              from '@angular/core';
import { CsScrollPageDirective } from './scroll-page.directive';


@NgModule({
    imports:      [],
    exports:      [CsScrollPageDirective],
    declarations: [CsScrollPageDirective]
  }
)
export class CsScrollPageModule {
}
