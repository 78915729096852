import { NgModule } from '@angular/core';


import { CsSharedModule }           from '@cs/components/shared';
import { CommonModule }             from '@angular/common';
import { TranslateModule }          from '@ngx-translate/core';
import { DataSourceHoverComponent } from './data-source-hover/data-source-hover.component';

import { MatTooltipModule }    from '@angular/material/tooltip';
import { CsCommonPipesModule } from '@cs/common';


const COMPONENTS = [
	DataSourceHoverComponent
];

@NgModule({
	imports:         [
		CommonModule,
		CsSharedModule,
		MatTooltipModule,
		TranslateModule.forChild(),
		CsCommonPipesModule
	],
	exports:         [],
	declarations:    [...COMPONENTS],
	providers:       [],
	entryComponents: [...COMPONENTS]
})
export class PmPopoversModule {
}
