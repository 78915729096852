import {forkJoin, Observable}              from 'rxjs';
import {TranslateLoader}                   from '@ngx-translate/core';
import {HttpClient}                        from '@angular/common/http';
import {ComponentTranslationLoaderService} from '@cs/common';
import {map}                               from 'rxjs/operators';


export class CsTranslationLoader implements TranslateLoader {
	private readonly http: HttpClient;
	private readonly location: string;
	private readonly hash: string;

	constructor(location: string, http: HttpClient, private componentLoader?: ComponentTranslationLoaderService, hash?: string) {
		this.http = http;
		this.location = location;
		this.hash = hash;
	}

	getTranslation(lang: string): Observable<any> {

		const subs = [];
		if (this.componentLoader) {
			for (const component of Array.from(this.componentLoader.getComponentModuleNames())) {
				subs.push(this.componentLoader.setupComponentModule(component));
			}
		}
		return forkJoin(...subs, this.http.get(`${this.location}/${lang}.json?version=` + this.hash))
			.pipe(
				map((value: Array<{ [key: string]: string }>) =>
					value.reduce((previousValue, currentValue) => {
						return {...previousValue, ...currentValue};
					}, {})
				));
	}
}
