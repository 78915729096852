import { CsHttpRequestOptions, Result } from "@cs/core";
import { SimpleRepositoryReference } from "./models/SimpleRepositoryReference";
import { ProcessProgressInfo } from "./models/ProcessProgressInfo";
import { IConfigService } from "@cs/performance-manager/shared";
import { Observable } from "rxjs";
import { Repository } from "./models/Repository";

export abstract class GitGraphConfigService implements IConfigService {

  // this is used for asset and translation resolvement
  moduleName: string = 'gitGraph';
  

  /**
   * Gets list of submodule identifiers
   * @param options 
   */
  public abstract getSubmoduleList(options?: CsHttpRequestOptions): Observable<Result<SimpleRepositoryReference[]>>;

  /**
   * Gets annotated submodule
   * @param identifier 
   * * @param options 
   */
  public abstract getAnnotatedSubmoduleTree(identifier: string, options?: CsHttpRequestOptions): Observable<Result<Repository>>;

  /**
   * Gets the progress of filling the cache
   * @param options 
   */
  public abstract GetProcessProgressInfo(options?: CsHttpRequestOptions): Observable<Result<ProcessProgressInfo>>;
}
