import { ModuleWithProviders, NgModule, Type }               from '@angular/core';
import { CommonModule }                                      from '@angular/common';
import { ReportingComponent }                                from './reporting.component';
import { ReportingRoutingModule }                            from './reporting-routing.module';
import { FormsModule }                                       from '@angular/forms';
import { RouterModule }                                      from '@angular/router';
import {
	TranslateLoader,
	TranslateModule,
	TranslateService
}                                                            from '@ngx-translate/core';
import { HttpClient }                                        from '@angular/common/http';
import { CsApplicationSettings }                             from '@cs/performance-manager/shared';
import { ReportingConfigService }                            from './reporting-config.service';
import { CsTranslationLoader }                               from '@cs/performance-manager/shared';
import { IConfigService }                                    from '@cs/performance-manager/shared';
import { LoggerUtil }                                        from '@cs/core';
import {
	CsDataPickerCultureModule, CsDatepickerModule,
	CsFormGeneratorModule,
	CsSharedModule,
	ImageSliderModule
}                                                            from '@cs/components';
import { MatRippleModule }                                   from '@angular/material/core';
import { MatDialogModule }                                   from '@angular/material/dialog';
import { ToastService }                                                              from '@cs/performance-manager/shared';
import { ComponentTranslationLoaderService, CsCommonPipesModule, CsCultureProvider } from '@cs/common';
import { MatTooltipModule }                                                          from '@angular/material/tooltip';

export function reportingTranslationLoader(client: HttpClient,
																					 pmAppSettings: CsApplicationSettings,
																					 service: IConfigService,
																					 componentLoader: ComponentTranslationLoaderService) {
	return new CsTranslationLoader(`${pmAppSettings.translationLocation}/${service.moduleName}`,
		client, componentLoader, pmAppSettings.deploymentDetails.buildDate);
}

@NgModule({
	declarations: [ReportingComponent],
	providers:    [ToastService],
	imports:      [
		CommonModule,
		FormsModule,
		RouterModule,
		CsDataPickerCultureModule,
		CsDatepickerModule,
		MatRippleModule,
		MatDialogModule,
		ImageSliderModule,
		CsSharedModule,
		CsCommonPipesModule,
		ReportingRoutingModule,
		CsFormGeneratorModule,
		TranslateModule.forChild({
			loader:  {
				provide:    TranslateLoader,
				useFactory: reportingTranslationLoader,
				deps:       [HttpClient, CsApplicationSettings, ReportingConfigService, ComponentTranslationLoaderService]
			},
			isolate: true
		}),
		MatTooltipModule
	]
})
export class PmReportingModule {
	static forRoot(moduleConfig: Type<ReportingConfigService>,
								 appSetting: CsApplicationSettings): ModuleWithProviders<PmReportingModule> {
		return {
			ngModule:  PmReportingModule,
			providers: [
				{
					provide:  ReportingConfigService,
					useClass: moduleConfig
				},
				{
					provide:  CsApplicationSettings,
					useValue: appSetting
				}
			]
		};
	}

	constructor(translate: TranslateService, culture: CsCultureProvider) {

		if (!translate) {
			LoggerUtil.warn('Translation service is not setup');
			return;
		}

		// this language will be used as a fallback when a translation isn't found in the current language
		translate.setDefaultLang(culture.getDefaultCulture());

		// the lang to use, if the lang isn't available, it will use the current loader to get them
		translate.use(culture.getCulture());
	}
}
