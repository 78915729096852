import {
	Component, OnInit, ChangeDetectionStrategy, Inject,
	ChangeDetectorRef, ViewChild, AfterViewInit
}                                                                                     from '@angular/core';
import { ComponentLoaderDataContext, WizardContentComponent }                         from '@cs/components/shared';
import { DataDescribed, DataDescribedArray, FormDataDescribed, LayoutAnnotationForm } from '@cs/core';

@Component({
						 selector:        'cs-wizard-form-nxt',
						 templateUrl:     './wizard-form-nxt.component.html',
						 styleUrls:       ['./wizard-form-nxt.component.scss'],
						 changeDetection: ChangeDetectionStrategy.OnPush
					 })
export class WizardFormNxtComponent
	extends WizardContentComponent<DataDescribedArray<any, LayoutAnnotationForm<any>>, DataDescribedArray<any, LayoutAnnotationForm<any>>>
	implements OnInit,
						 AfterViewInit {

	static readonly TYPE_NAME = 'Form';


	constructor(@Inject(
								ComponentLoaderDataContext) readonly context: ComponentLoaderDataContext<DataDescribedArray<any, LayoutAnnotationForm<any>>>,
							private changeRef: ChangeDetectorRef) {
		super(context);
	}

	override ngOnInit(): void {
		super.ngOnInit();
		this.dataContext = new FormDataDescribed(this.context.data);
		this.changeRef.detectChanges();
	}

	ngAfterViewInit(): void {

	}

}
