import { GridActions, GridItemType }         from '../enums/data-grid.enum';
import { isNullOrUndefined }                 from '@cs/core';
import { Calculations }                      from '../utils/calculations';
import { DataGridAction }                    from './data-grid-action';
import { IDataGridCalculationOptionsAction } from './i-data-grid-calculation-options-action';

export class DataGridCalculationOptionsAction implements DataGridAction, IDataGridCalculationOptionsAction {
  readonly type: GridActions = GridActions.SetCalculationOptions;
  calcFunction: Function;
  aggregationFactor = 1;
  scope: GridItemType = GridItemType.Sheet;
  useFunctionWithName: string;

  constructor(initial: Partial<DataGridCalculationOptionsAction> = null) {
    if (!isNullOrUndefined(initial))
      Object.assign(this, initial);
    if (!isNullOrUndefined(this.useFunctionWithName)) {
      this.calcFunction = Calculations.getCalculationMethod(this.useFunctionWithName);
    }
  }
}
