import { ModuleWithProviders, NgModule, Type }                                from '@angular/core';
import { CommonModule }                                                       from '@angular/common';
import { DownloadsComponent }                                                 from './downloads.component';
import { DownloadsRoutingModule }                                             from './downloads-routing.module';
import { HttpClient }                                                         from '@angular/common/http';
import { IConfigService }                                                     from '@cs/performance-manager/shared';
import { CsApplicationSettings }                                              from '@cs/performance-manager/shared';
import { CsTranslationLoader }                                                from '@cs/performance-manager/shared';
import {
	TranslateLoader,
	TranslateModule,
	TranslateService
}                                                                             from '@ngx-translate/core';
import { DownloadsConfigService }                                             from './downloads-config.service';
import { LoggerUtil }                                                         from '@cs/core';
import {
	CsHyperlinkModule, CsSharedModule,
	CsPlaceholderModule
}                                                                             from '@cs/components';
import { DownloadItemsResolver }                                                                      from './resolver/download-items.resolver';
import { CsCultureModule, ComponentTranslationLoaderService, CsCommonPipesModule, CsCultureProvider } from '@cs/common';


export function downloadsTranslationLoader(client: HttpClient,
																					 pmAppSettings: CsApplicationSettings,
																					 service: IConfigService,
																					 componentLoader: ComponentTranslationLoaderService) {
	return new CsTranslationLoader(`${pmAppSettings.translationLocation}/${service.moduleName}`,
		client, componentLoader, pmAppSettings.deploymentDetails.buildDate);
}

@NgModule({
	declarations: [
		DownloadsComponent
	],
	imports:      [
		CommonModule,
		CsCommonPipesModule,
		CsCultureModule,
		CsHyperlinkModule,
		TranslateModule.forChild({
			loader:  {
				provide:    TranslateLoader,
				useFactory: downloadsTranslationLoader,
				deps:       [HttpClient, CsApplicationSettings, DownloadsConfigService, ComponentTranslationLoaderService]
			},
			isolate: true
		}),
		DownloadsRoutingModule,
		CsPlaceholderModule,
		CsSharedModule
	],
	providers:    [DownloadItemsResolver]
})
export class PmDownloadsModule {
	static forRoot(moduleConfig: Type<DownloadsConfigService>,
								 appSetting: CsApplicationSettings): ModuleWithProviders<PmDownloadsModule> {
		return {
			ngModule:  PmDownloadsModule,
			providers: [
				{
					provide:  DownloadsConfigService,
					useClass: moduleConfig
				},
				{
					provide:  CsApplicationSettings,
					useValue: appSetting
				}
			]
		};
	}

	constructor(translate: TranslateService, culture: CsCultureProvider) {

		if (!translate) {
			LoggerUtil.warn('Translation service is not set-up');
			return;
		}

		// this language will be used as a fallback when a translation isn't found in the current language
		translate.setDefaultLang(culture.getDefaultCulture());

		// the lang to use, if the lang isn't available, it will use the current loader to get them
		translate.use(culture.getCulture());
	}
}

